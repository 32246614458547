export const PLAN_STATUS_LIST_URL = "/planstatuslist";  //ステータス一覧
export const DEMAND_FORECAST_LIST_URL = "/demandforecastlist";  //需要計画一覧
export const DEMAND_FORECAST_URL = "/demandforecast";  //需要計画
export const DEMAND_FORECAST_PLAN_URL = "/demandforecastplan";  //需要・調達計画
export const POWER_FORECAST_LIST_URL = "/powerforecastlist";    //発電計画一覧
export const POWER_FORECAST_URL = "/powerforecast";    //発電計画
export const POWER_FORECAST_PLAN_URL = "/powerforecastplan";    //発電・販売計画
export const DEMAND_MONITORING_URL = "/demandmonitoring"; //需要インバランス監視
export const POWER_GENERATION_MONITORING_URL = "/powergenerationmonitoring"; //発電インバランス監視
export const SPOT_BIDDING_URL = "/spotbidding";  //スポット入札
export const INTRADAY_BIDDING_URL = "/intradaybidding"; //1時間前入札
export const BALANCING_PLAN_URL = "/balancingplan";  //同時同量計画
export const SETTING_URL = "/setting";  //設定
export const NOTIFICATION_URL = "/notification"; //お知らせ
export const CONNECTION_ERROR_URL = "/error"; //通信エラー
export const DOWNLOAD_REPORT_URL = "/downloadreport"; // 帳票ダウンロード
