import React, { useContext, useEffect, useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import styled from "styled-components";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomModal from "../../common/customComponents/CustomModal";
import { StyledButton } from "../../common/styledComponents/styledButton";
import { TimeTable } from "./CreateTime";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import axios, { Axios, AxiosError } from "axios";
import { LanguageContext } from "../../common/localization/localization";
import { addDays, format } from "date-fns";
import CustomDatePickerTryal from "../../common/customComponents/CustomDatePickerTryal";
import { convertToObject } from "typescript";
import { useAuthedApi } from "../../../common/axios";
import CustomDialog, { ButtonType } from "../../common/customComponents/CustomDialog";
import { Button } from "@mui/material";
import { ERR_CODE_BAD_REQUEST } from "../../../common/constant";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

const HeaderCell = styled(StyledTableHeaderG)`
  && {
    text-align: center;
    padding: 0px;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 5px 2px;
    font-size: 14px;
  }
`;

//テーブルのセル
const AboveBelowCell = styled(TableCell)`
  && {
    text-align: center;
  }
`;

// apiのパス
const MARKET_DETAIL_DATA = "_api/marketDetails";

export interface tagDetailData {
  orderQu: number;
  orderPrice: number;
  buysellKind: number;
}

export interface pieceDetailData {
  values: tagDetailData[];
}

export interface datyDetailData {
  data: pieceDetailData[];
}

//小数点第二位までかつカンマ区切りに変換
const convertToLocaleString = (value: number) => {
  return Number(value).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

//市場詳細のモーダル
const MarketDetails = (
  open: boolean,
  HaldleClose: () => void,
  areaId: string
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  
  const { time } = useContext(TimeContext);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [targetDate, setTargetDate] = useState(time);
  const [maxDate, setMaxDate] = useState<Date>(time);
  const startDate = useRef<Date>(
    new Date(time.getFullYear(), time.getMonth(), time.getDate(), 17)
  );

  //カスタムダイアログ
  const [digOpen, setDigOpen] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  //ダイアログ表示後処理
  const acceptHandler = async () => {
    if (areaId !== "" && areaId !== "00") {
      setIsLoaded(false);
      await axios
        .get(MARKET_DETAIL_DATA, {
          params: {
            targetDate: format(targetDate, "yyyy-MM-dd"),
            areaId: areaId,
          },
        })
        .then((res) => {
          setDetailData(res.data.result);
        })
        .catch((e: AxiosError) => {
          setDetailData({
            data: new Array(48).fill({values: new Array(16)}),
          });
          if(e.response?.status === ERR_CODE_BAD_REQUEST){
            setDialogMessage(
              languageContext.words.could_not_communicate_jepx +
              languageContext.words.try_to_get_again
            );
          } else {
            setDialogMessage(
              languageContext.words.could_not_obtain_market_details +
              languageContext.words.try_to_get_again
            );
          }
          setDigOpen(true);
        });
      setIsLoaded(true);
    }
  };

  // 画面に表示するデータ
  const [detailData, setDetailData] = useState<datyDetailData>({
data: [
  {
    values: [
      {
        orderQu: 0,
        orderPrice: 0,
        buysellKind: 0,
      },
    ],
  },
],
  });

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      setIsLoaded(false);
      (async() => {
        await axios
          .get(MARKET_DETAIL_DATA, {
            params: {
              targetDate: format(targetDate, "yyyy-MM-dd"),
              areaId: areaId,
            },
          })
          .then((res) => {
            setDetailData(res.data.result);
          })
          .catch((e: AxiosError) => {
            if(open === true) {
              setDetailData({
                data: new Array(48).fill({values: new Array(16)}),
              });
              setDialogMessage(
                languageContext.words.could_not_obtain_market_details +
                languageContext.words.try_to_get_again
              );
              setDigOpen(true);
            }
          });
      })();
      setIsLoaded(true);
    }

  }, [targetDate, areaId, open]);

  useEffect(() => {
    if (time >= startDate.current) {
      setMaxDate(addDays(time, 1));
    } else {
      setMaxDate(time);
    }
    startDate.current = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      17
    );
  }, [time]);


  //市場詳細のテーブルのレンダー
  const MarketDetailsTableRender = () => {
    return (
      <TableContainer
        sx={{
          width: "100%",
          height: "auto",
          display: "flexed",
          overflowY: "scroll",
          overflowX: "scroll",
          "&::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "transparent",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
        }}
      >
        {detailData.data.map((data, index) => (
          <StyledTable
            key={"detaildata" + index}
            sx={{ width: "20%", height: "auto", borderCollapse: "separate" }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "rgb(200,250,255)",
              }}
            >
              <TableRow>
                <HeaderCell colSpan={3}>
                  {timeData[index]}～{timeData[index + 1]}
                </HeaderCell>
              </TableRow>
              <TableRow>
                <HeaderCell>
                  {languageContext.words.sell}
                  <br />
                  (MWh/h)
                  <br />
                </HeaderCell>
                <HeaderCell>
                  {languageContext.words.price_yen_kwh}
                  <br />({languageContext.words.yen_kwh})
                  <br />
                </HeaderCell>
                <HeaderCell>
                  {languageContext.words.buy}
                  <br />
                  (MWh/h)
                  <br />
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: "rgb(255,255,255)" }}>
              {data.values.map((value, index2) => {
                if (index2 === 0) {
                  return (
                    <TableRow key={"firstValue" + index2 + index}>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 1
                          ? null
                          : convertToLocaleString(value.orderQu)}
                      </TableCell>
                      <AboveBelowCell>
                        {languageContext.words.or_more}
                      </AboveBelowCell>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 0
                          ? null
                          : convertToLocaleString(value.orderQu)}
                      </TableCell>
                    </TableRow>
                  );
                } else if (index2 === data.values.length - 1) {
                  return (
                    <TableRow key={"endValue" + index2 + index}>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 1
                          ? null
                          : convertToLocaleString(value.orderQu)}
                      </TableCell>
                      <AboveBelowCell>
                        {languageContext.words.or_less}
                      </AboveBelowCell>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 0
                          ? null
                          : convertToLocaleString(value.orderQu)}
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={"centerValue" + index2 + index}>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 1
                          ? null
                          : convertToLocaleString(value.orderQu)}
                      </TableCell>
                      <AboveBelowCell>
                        {value.orderPrice.toFixed(2)}
                      </AboveBelowCell>
                      <TableCell sx={{ textAlign: "center" }} style={{padding: "5px 8px 5px 0px"}}>
                        {value.buysellKind === 0
                          ? null
                          : convertToLocaleString(value.orderQu)} 
                      </TableCell>
                    </TableRow>
                  );
                }
                // }
              })}
            </TableBody>
          </StyledTable>
        ))}
      </TableContainer>
    );
  };

  //市場詳細
  return (
    <>
      <CustomModal
        title={languageContext.words.market_details}
        open={open}
        onAccept={() => {
          /*console.log("accept")*/
        }}
        onClose={() => {
          HaldleClose();
          setTargetDate(time);
        }}
        btnText={languageContext.words.modal_close}
      >
        <Stack sx={{ width: "1100px", height: "600px" }} spacing={1}>
          <Stack spacing={1} direction="row" sx={{ width: "100%", height: "5%" }}>
            {languageContext.words.delivery_date_of_power}：
            <CustomDatePickerTryal setValue={setTargetDate} value={time} maxDate={maxDate}/>
            <StyledButton onClick={acceptHandler}>
              <AutorenewIcon />
            </StyledButton>
          </Stack>
          <Box sx={{ width: "100%", height: "95%" }}>
            {MarketDetailsTableRender()}
          </Box>
        </Stack>
        <CustomModalProgress open={!isLoaded} />
      </CustomModal>
      <CustomDialog
        title={languageContext.words.market_details} 
        message={dialogMessage}
        buttonType={ButtonType.OkCancel}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>

  );
};
export default MarketDetails;
