//エリアID定数
export const AREA_ID = {
  NONE: "00",
  HOKKAIDO: "01",
  TOHOKU: "02",
  TOKYO: "03",
  CHUBU: "04",
  HOKURIKU: "05",
  KANSAI: "06",
  CHUGOKU: "07",
  SHIKOKU: "08",
  KYUSYU: "09",
  OKINAWA: "10",
} as const;

// 時刻コード
export const TIME_CODE_MAX = 48;

//電圧階級
export const VOLTAGE_LEVEL = {
  NONE: "0",
  EXTRA_HIGH: "1",
  HIGH: "2",
  LOW: "3",
};

//情報区分コード
export const INFO_KIND_CODE = {} as const;

//ロールID
export const ROLE_ID = {
  ALL: "1111",
  SYSTEM: "00",
  TENANT: "01",
  MULTI_AREA: "10",
  SINGLE_AREA: "20",
  POWER: "40",
  DEMAND: "41",
  FIT: "99",
} as const;

export const BP_KIND = {
  OWN: 1,
  OTHER: 2,
};

//電源種別
export const POWER_SUPPLY_TYPE_ID = {
  NONE: 0,
  UNADJUSTED: 1,
  ADJUSTMENT: 2,
} as const;

//管理種別
export const MANAGEMENT_TYPE = {
  OWN_COMPANY: "00",
  OTHER_POWER_PLANT: "01",
  OTHER_RETAIL: "02",
  JBU: "03",
} as const;

//発電方式
export const POWER_ENERGY_KIND = {
  NONE: "0",
  THERMAL: "1", //火力
  HYDRO: "2", //水力
  SOLAR: "3", //太陽光
  SMALL_HYDRO: "4", //小水力
  WIND: "5", //風力
  BINARY: "6", //バイナリ
  BIOMASS: "7", //バイオマス
} as const;

//電源契約者
export const POWER_CONTRACTOR = {
  NONE: "0",
  COMMON: "1",
  OTHER_COMPANIES: "2",
} as const;

//販売先種別
export const SALE_KIND = {
  NONE: "0",
  INSIDE: "12",
  OUTSIDE: "13",
  MARKET: "14",
} as const;

// 帳票種別
export const DOWNLOAD_REPORT_KIND = {
  BALANCE_OF_PAYMENTS: "0",
};
// 蓄電池有無
export const BATTERY_EXIST = {
  NOT_EXIST: "0",
  EXIST: "1",
} as const;

// エリアプライス参照間隔
export const AREA_PRICE_REF_INTERVAL = {
  DAY: 1,
  WEEK: 7,
} as const;

//APIで使用するURL
export const API_URL = {
  //共通（ロール、エリア関連）
  CHECK_EDIT_ROLE: "/canLoginUserEditSetting", //編集権限チェック
  GET_AREA_MST: "/getAreaMst",
  GET_BP_AREA: "/getAreaOfBp",
  GET_AREA_NAME: "/getAreaNameList",
  GET_DEMAND_AREA_LIST: "/getBpDemandAreaNameList",
  GET_AREA_LIST: "/getBpAreaList",

  //発電事業者取得
  GET_POWER_BP: "/getPowerBp",

  //エリアロス
  GET_AREA_LOSS: "/getAreaLoss",
  SET_AREA_LOSS: "/setAreaLoss",

  //証明書登録
  OCCTO_POST: "/postOcctoCertificate",
  GENERAL_POST: "/postAreaCertificate",
  OCCTO_GET: "/getOCCTOCert",
  GENERAL_GET: "/getAreaCert",

  //発電BG情報取得URL
  GET_POWERBG_LIST: "/getPowerBgList",

  //発電事業者
  GET_BP: "/getPowerBpList",
  GET_BP_DETAIL: "/getPowerBpDetail",

  //発電契約者
  GET_POWERCONTRACTOR_LIST: "/getAllPowerContractorList",

  //発電所
  POWERPLANT_LIST: "/getAllPowerPlantList",

  //販売先グループ内訳
  GET_SALEDETAS: "/getSaleDetas",

  //販売先グループ一覧
  GET_SALEGROUP_LIST: "/getSaleGroupList",

  //接続供給契約
  GET_CONTRACTOR: "/getContractorList",
  GET_CONTRACTOR_MST: "/getMstContractorList",

  //JEPXユーザ情報
  GET_JEPX_PARTICIPANT: "/getJepxGuest", // JEPX参加者取得
  GET_JEPX_USER: "/getJepxUser", //JEPXユーザ取得
  GET_JEPX_CERTIFICATE: "/getCertificateList", //JEPXユーザ証明書取得
  POST_JEPX_DATA: "/postJepxData", //JEPXユーザ情報登録
  POST_ADDITIONAL_JEPX_DATA: "/postAddJepxUserData", //JEPX追加ユーザ情報登録

  //部分供給
  GET_ONE_DEMANDDATA: "/getDemandPartInfoLinkedPK", //一件入手
  UPDATE_PART_DEMAND_INFO: "/updatePartDemandList",
  DELETE_PART_DEMAND_INFO: "/deletePartDemandInfo",

  //発電BG
  ADD_POWERBG_INFO: "/addPowerBgInfo",
  GET_ONE_POWERBGDATA: "/getPowerBgInfoLinkedPK",
  UPDATE_POWERBG_INFO: "/updatePowerBgInfoLinkedPK",
  DELETE_POWERBG_INFO: "/deletePowerBg",

  //発電事業者
  INSERT_BP: "/insertPowerBpInfo",
  UPDATE_BP: "/updatePowerBpInfo",
  UPDATE_RERATED_BP: "/updatePowerChildren",
  DELETE_BP: "/deletePowerBpInfo",

  //発電契約者
  ADD_POWERCONTRACTOR_INFO: "/insertPowerContractorInfo",
  DELETE_POWERCONTRACTOR_INFO: "/deletePowerContractorInfo",
  GET_RECORD_CONTRACTOR: "/getPowerContractorInfoLinkedPK",
  UPDATE_POWERCONTRACTOR_INFO: "/updatePowerContractorInfo",
  GET_POWER_BP_AND_SUBAREA: "/getPowerContractorBpInfo",

  //発電閾値
  UPDATE_LIMIT_POWERPLANT_INFO: "/updatePowerPlantLimitList",
  GET_LIMITDATA: "/getPowerPlantLimitInfoLinkedPK",

  // 蓄電池情報
  GET_STORAGE_INFO: "/getStorageInfoLinkedPK",
  UPDATE_STORAGE_INFO: "/updateStorageInfo",
  DELETE_STORAGE_INFO: "/deleteStorageInfo",

  //発電所
  SUBMIT: "/submitPower",
  GET_POWERBG_MST: "/getMstPowerBgIDList",
  GET_ONE_POWERPLANT_DATA: "/getOnePowerPlant",
  ADD_POWERPLANT_INFO: "/insertPowerPlantInfo",
  UPDATE_POWERPLANT_INFO: "/updatePowerPlantInfo",
  DELETE_POWERPLANT_INFO: "/deletePowerPlant",
  GET_PANNEL_RISE: "/getPannelRiseType", // パネル設置形態
  GET_PANNEL_DOWNWARD: "/getPannelDownwardType", // パネル型

  //販売先グループ内訳
  ADD_SALEDETAIL_INFO: "/insertSaleDetailInfo",
  GET_ONE_SALEDETAIL_DATA: "/getSaleDetailInfoLinkedPK",
  UPDATE_SALEDETAIL_INFO: "/updateSaleDetailInfo",
  DELETE_SALEDETAIL_INFO: "/deleteSaleDetailInfo",
  GET_AREA_POWER: "/getPowerNameList",
  GET_AREA_DEMAND: "/demand",
  GET_SALEDETAIL_OPTIONS: "/saleDetailOptions",

  //販売先グループ一覧
  ADD_SALEGROUP_INFO: "/addSaleGroupInfo",
  GET_ONE_SALEGR_DATA: "/getSaleGroupInfoLinkedPK",
  UPDATE_SALEGROUP_INFO: "/updateSaleGroupInfo",
  DELETE_SALEGROUP_INFO: "/deleteSaleGroupInfo",
  GET_SALES_TYPE_AREA: "/getSalesTypeArea",
  GET_SALEGROUP_OPTIONS: "/saleGroupOptions",

  //需要BG
  SET_DEMANDBG_LIST_API: "/setDemandBgList", // DB登録
  GET_ONE_DEMANDBGDATA: "/getDemandBgInfoLinkedPK", // 詳細取得
  UPDATE_DEMANDBG_INFO: "/updateDemandBgInfo", // 更新
  DELETE_DEMANDBG_INFO: "/deleteDemandBg", // 削除
  GET_BG_LIST: "/getDemandBgList", // 一覧取得
  GET_BG_SETTING_INFO: "/getSettingInfo", // 設定情報(事業者情報、エリアプルダウン)

  //需要家
  SET_DEMAND_LIST_API: "/setDemandList",
  GET_RECORD_DEMAND_LIST_API: "/getRecordDemandList", //1行
  UPDATE_RECORD_DEMAND_LIST_API: "/updateRecordDemandList", //1行更新
  DELETE_DEMAND_INFO: "/deleteDemandInfo", //削除
  GET_DEMANDBG_MST: "/getMstDemandBgIDList", //需要BGのデータ入手（セレクター用）
  GET_POWER_BP_DEMAND:"/getMstPowerBpList", // 発電事業者の取得（需要家画面）

  //ユーザ一覧
  GET_ROLE: "/targetRoleList",
  SET_USER_LIST_API: "/setUserList",
  GET_RECORD_USER_LIST_API: "/getRecordUserList", //1行取得
  UPDATE_RECORD_USER_LIST_API: "/updateRecordUserList", //1行更新
  DELETE_RECORD_USER_LIST_API: "/deleteRecordUserList", //ユーザ削除
  CHECK_DELETE_USER: "/checkDeleteUser", //ユーザ削除前確認
  GET_USER_ROLE: "/getUserRole", //ユーザのロール取得

  //需要家一覧
  DEMAND_LIST_API: "/getDemandList",

  //ユーザ一覧
  USER_LIST_API: "/getUserList",
} as const;

// レスポンスのステータスコード
export const SUCCESS_CODE = 200;
export const ERR_CODE_BAD_REQUEST = 400; // リクエストエラー
export const ERR_CODE_INTERNAL_SERVER_ERR = 500; // サーバーエラー
export const ERR_CODE_SOAP_ERR = 504; // ソープ通信エラー

//結果コード
export const RESULT_CODE = {
  SUCCESS: "0",
  NO_EXIST_ALL_DATA: "1",
  NO_EXIST_SOME_DATA: "2",
  DUPLICATED_ID: "3",
  NO_NEED_TO_UPDATE: "4",
  EXCESS_QUANTITY: "5",
  NO_BP_DATA:"7",
  NO_AREA:"8",
  NO_CONTRACTOR:"9",
  NO_POWER_BG:"10",
  NO_DEMAND_BG:"11",
  NO_SALE_GROUP:"12",
} as const;

/**
 * ビジネスモデル
 */
export const BUSINESS_MODEL = {
  IKINAI: "1",
  IKIGAI_POWER: "2",
  IKIGAI_DEMAND: "3",
  MARKET_TRAN: "4",
  IKINAI_AND_IKIGAI_POWER: "5",
  IKINAI_AND_IKIGAI_DEMAND: "6",
  IKINAI_AND_MARKET_TRAN: "7",
};

// 売買種別
export const BUY_SELL_KIND = {
  SELL: "1",
  BUY: "2",
};

// ステータス一覧 ペーパー
export const PAPER_KIND = {
  POWER_FORECAST: "1",
  DEMAND_FORECAST: "2",
  SPOT_BIDDING: "3",
  ONEHOUR_BIDDING: "4",
  BALANCING_POWER: "5",
  BALANCING_DEMAND: "6",
  BALANCING_PARTS: "7",
};
