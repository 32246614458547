import React, { useState, useEffect, useContext, useMemo } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  ConfirmLeavePage,
  LeavePageContext,
  LeavePageContextType,
} from "./CustomConfirmLeavePage";
import { FormHelperText } from "@mui/material";
import { LanguageContext } from "../localization/localization";
import { useAreaUtility } from "../../../common/area";
import { searchInfo } from "../../occto/PowerForecast/PowerForecastList";
import { AREA_ID } from "../../../common/constant";

const CustomSelectList = (props: any) => {
  const {
    label,
    value,
    options,
    onChange,
    disabled,
    error,
    helper,
    digMessage,
    blocked = true,
  } = props;
  const { words } = useContext(LanguageContext);
  const helperText: string = helper ? helper : words.setting_required;
  const dialogMessage: string = digMessage
    ? digMessage
    : words.setting_change_selections_message;
  const [selectValue, setSelectValue] = useState<string>(value ?? "");
  const [digOpen, setDigOpen] = useState(false);
  const [changeVal, setChangeVal] = useState<string>(value ?? "");
  const { blockLeave, setBlockLeave } =
    useContext<LeavePageContextType>(LeavePageContext);

  useEffect(() => {
    setSelectValue(value === "" ? label : value);
    setChangeVal(value === "" ? label : value);
  }, [label, value]);

  const handleChange = (e: SelectChangeEvent) => {
    setChangeVal(e.target.value);
    if (blockLeave && blocked) {
      setDigOpen(true);
    } else {
      setSelectValue(e.target.value);
      onChange(e.target.value);
      setBlockLeave(!blocked);
    }
  };

  const acceptHandler = () => {
    setSelectValue(changeVal);
    onChange(changeVal);
    setBlockLeave(!blocked);
  };

  return (
    <>
      <FormControl
        color="primary"
        variant="outlined"
        sx={{
          marginLeft: "0px",
          marginRight: "8px",
          marginTop: "8px",
          marginBottom: "8px",
          width: 160,
        }}
      >
        <Select
          error={error === undefined ? false : error}
          value={selectValue}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          disabled={Boolean(disabled)}
          sx={{
            minWidth: 160,
            fontSize: "14px",
            height: "40px",
          }}
        >
          {options.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
        {error !== undefined && (
          <FormHelperText error={error === true ? true : false}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
        message={dialogMessage}
      />
    </>
  );
};

export interface powerDemandInfo {
  areaId: string;
  bgId: number;
  bgName: string;
  powerDemandId: number;
  powerDemandName: string;
}

interface Props {
  children?: React.ReactNode;
  powerFg: boolean;
  list: powerDemandInfo[];
  areaId: string;
  bgId: number;
  powerDemandId: number;
  handleValueChangeArea: React.Dispatch<React.SetStateAction<string>>;
  handleValueChangeBG: React.Dispatch<React.SetStateAction<number>>;
  handleValueChangePowerDemand: React.Dispatch<React.SetStateAction<number>>;
  getData: (areaIds: string, bgId: number, powerDemandId: number) => void;
}

//発電・需要計画、発電・需要計画一覧で使用する三連コンポーネント
export const CustomMultipleSelectList = (props: Props) => {
  const {
    powerFg,
    list,
    areaId,
    bgId,
    powerDemandId,
    handleValueChangeArea,
    handleValueChangeBG,
    handleValueChangePowerDemand,
    getData,
  } = props;

  const { words } = useContext(LanguageContext);
  const { areaOptions } = useAreaUtility();

  //選択されているものの各セットメソッド
  const setArea = (value: string) => {
    handleValueChangeArea(value);
  };

  const setBg = (value: number) => {
    handleValueChangeBG(value);
  };

  const setPowerDemand = (value: number) => {
    handleValueChangePowerDemand(value);
  };

  //各セレクトリストで表示するリスト内容
  const areaList = useMemo(
    () => [...new Set(list.map((v) => v.areaId))],
    [list]
  );
  const powerBgList = useMemo(() => {
    const allBgList: searchInfo[] = list
      .filter((v) => v.areaId === areaId || areaId === AREA_ID.NONE)
      .map((v) => {
        return {
          id: v.bgId,
          name: v.bgName,
        };
      });

    //同じ発電BGに異なる発電所が複数所属していることもあるため重複データ削除
    const reducedList: searchInfo[] = [
      ...new Map(allBgList.map((v) => [v.id, v])).values(),
    ];
    const result = [{ id: 0, name: words.triple_component_all_bg }].concat(
      reducedList
    );
    return result;
  }, [list, areaId, powerDemandId]);

  const powerPlantList = useMemo(() => {
    const tmpList: searchInfo[] = list
      .filter(
        (v) =>
          (v.areaId === areaId && v.bgId === bgId) || //エリア、BGともに選択されているとき
          (v.areaId === areaId && bgId === 0) || //エリアが選択されており、BGは全てのとき
          (areaId === AREA_ID.NONE && bgId === 0)
      ) //全ての発電所
      .map((v) => {
        return {
          id: v.powerDemandId,
          name: v.powerDemandName,
        };
      });

    const result = [
      {
        id: 0,
        name:
          powerFg === true
            ? words.triple_component_all_power_plants
            : words.triple_component_all_demand,
      },
    ].concat(tmpList);
    return result;
  }, [list, areaId, bgId]);

  //エリア選択時
  const onChangeArea = (selectedArea: string) => {
    setArea(selectedArea);

    //特定のエリアから別のエリアに切り替えられたとき
    //全てのBGが選択されている状態で全てのエリアから特定のエリアに切り替えたとき
    if (
      selectedArea !== AREA_ID.NONE &&
      selectedArea !==
        list.filter((v) => v.bgId === bgId).map((v) => v.areaId)[0]
    ) {
      const tmp = list.filter((v) => v.areaId === selectedArea);
      const tmpBg = [...new Set(tmp.map((v) => v.bgId))];
      const tmpPowerDemand = [...new Set(tmp.map((v) => v.powerDemandId))];
      let tmpSelectedBg = 0;
      let tmpSelectedPowerDemand = 0;
      //選択したエリアに所属するBGが1つの場合、そのBGを選択されているBGに設定
      if (tmpBg.length === 1) {
        tmpSelectedBg = tmpBg[0];
        setBg(tmpBg[0]);
      } else {
        setBg(0);
      }
      //選択したエリアに所属する発電所・需要家が1つの場合、その発電所を選択されている発電所・需要家に設定
      if (tmpPowerDemand.length === 1) {
        tmpSelectedPowerDemand = tmpPowerDemand[0];
        setPowerDemand(tmpPowerDemand[0]);
      } else {
        setPowerDemand(0);
      }
      // Stateは非同期のため、setしても次のレンダリングまで値が更新されないので、setしている値でデータを取得する
      getData(selectedArea, tmpSelectedBg, tmpSelectedPowerDemand);
      //全てのエリア選択時はBG、発電所・需要家をリセット
    } else if (selectedArea === AREA_ID.NONE) {
      setBg(0);
      setPowerDemand(0);
      getData(selectedArea, 0, 0);
    }
  };

  //BG選択時
  const onChangeBg = (selectedBg: number) => {
    setBg(selectedBg);
    let tmpSelectedPowerDemand = 0;
    if (
      selectedBg !== 0 &&
      (areaId !==
        list.filter((v) => v.bgId === selectedBg).map((v) => v.areaId)[0] ||
        selectedBg !==
          list
            .filter((v) => v.powerDemandId === tmpSelectedPowerDemand)
            .map((v) => v.bgId)[0])
    ) {
      const tmp = list.filter((v) => v.bgId === selectedBg);
      const tmpPowerDemand = tmp.map((v) => v.powerDemandId);
      const tmpArea = tmp.map((v) => v.areaId)[0]; // BG選択時に全てのエリアが選択されている時にGetDataのAreaIdsをフィルタリングする用
      setArea(tmpArea);
      if (tmpPowerDemand.length === 1) {
        tmpSelectedPowerDemand = tmpPowerDemand[0];
        setPowerDemand(tmpPowerDemand[0]);
      } else {
        setPowerDemand(0);
      }
      getData(tmpArea, selectedBg, tmpSelectedPowerDemand);
    } else if (areaId !== AREA_ID.NONE && selectedBg === 0) {
      setPowerDemand(0);
      getData(areaId, selectedBg, 0);
    }
  };

  //発電所・需要家選択時
  const onChangePowerDemand = (selectedPowerDemand: number) => {
    setPowerDemand(selectedPowerDemand);
    let tmpSelectBg = 0;
    if (selectedPowerDemand !== 0 && tmpSelectBg === 0) {
      // 決まった発電所・需要家かつ全ての発電所・需要家
      const tmp = list.filter((v) => v.powerDemandId === selectedPowerDemand);
      const tmpArea = tmp.map((v) => v.areaId)[0]; // 発電所・需要家選択時に全てのエリアが選択されている時にGetDataのAreaIdsをフィルタリングする用
      setArea(tmpArea);
      setBg(tmp.map((v) => v.bgId)[0]);
      tmpSelectBg = tmp.map((v) => v.bgId)[0];
      getData(tmpArea, tmpSelectBg, selectedPowerDemand);
    } else if (selectedPowerDemand === 0 && bgId !== 0) {
      // 全ての発電所・需要家した時かつBGが決まっている時
      const tmpBg = list.filter((v) => v.bgId === bgId);
      tmpSelectBg = tmpBg[0].bgId;
      setBg(tmpBg[0].bgId);
      getData(areaId, tmpSelectBg, selectedPowerDemand);
    } else {
      getData(areaId, tmpSelectBg, selectedPowerDemand);
    }
  };

  return (
    <>
      <CustomSelectList
        label={words.triple_component_all_areas}
        value={areaId}
        options={areaOptions(areaList, true, true)}
        onChange={(e: string) => {
          onChangeArea(e);
        }}
      />
      <CustomSelectList
        label={words.triple_component_all_bg}
        value={bgId}
        options={powerBgList}
        onChange={(e: number) => {
          onChangeBg(e);
        }}
      />
      <CustomSelectList
        label={
          powerFg === true
            ? words.triple_component_all_power_plants
            : words.triple_component_all_demand
        }
        value={powerDemandId}
        options={powerPlantList}
        onChange={(e: number) => {
          onChangePowerDemand(e);
        }}
      />
    </>
  );
};

export default CustomSelectList;
