import React, { useContext, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { StyledButtonMenu } from "../../styledComponents/styledButton";
import { LanguageContext } from "../../localization/localization";
import { AreaContext } from "../../customComponents/CustomAreaContext";
import { BusinessModelContext } from "../../customComponents/BusinessModelProvider";
import { BUSINESS_MODEL } from "../../../../common/constant";
import {
  ConfirmLeavePage,
  LeavePageContext,
} from "../../customComponents/CustomConfirmLeavePage";
import {
  POWER_FORECAST_PLAN_URL,
  DEMAND_FORECAST_PLAN_URL,
} from "../../../../common/url";

const OneHourMenu = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId } = useContext(AreaContext);
  const { businessModel } = useContext(BusinessModelContext);
  const leavePageContext = useContext(LeavePageContext);
  const [digOpen, setDigOpen] = useState(false);
  const url = useRef<string | null>("");
  const navigate = useNavigate();
  // ビジネスモデルによるボタンの表示・非表示
  const [isDemandModel, setIsDemandModel] = useState<boolean>();

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  // ビジネスモデルによって計画遷移画面の表示・非表示を切り替え
  useEffect(() => {
    const displayModel = businessModel.filter((v) => v.areaId === areaId).map((v) => v.businessModel);
    
    if(displayModel[0] === BUSINESS_MODEL.IKIGAI_DEMAND ||
      displayModel[0] === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ) {
      setIsDemandModel(true);
    } else {
      setIsDemandModel(false);
    }
  }, [areaId]);

  return (
    <Box>
      {!isDemandModel &&
        <StyledButtonMenu
          onClick={() => {
            url.current = POWER_FORECAST_PLAN_URL;
            if (areaId !== "") {
              url.current = `${POWER_FORECAST_PLAN_URL}?areaId=${areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.powerforecastplan}
        </StyledButtonMenu>
      }

      {isDemandModel &&
        <StyledButtonMenu
          sx={{marginLeft: "15px"}}
          onClick={() => {
            url.current = DEMAND_FORECAST_PLAN_URL;
            if (areaId !== "") {
              url.current = `${DEMAND_FORECAST_PLAN_URL}?areaId=${areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.demandforecastplan}
        </StyledButtonMenu>
      }

      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      ></ConfirmLeavePage>
    </Box>
  );
};

export default OneHourMenu;
