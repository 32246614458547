import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CustomModal from "../../common/customComponents/CustomModal";
import TextField from "@mui/material/TextField";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import Grid from "@mui/material/Grid";
import { display } from "@mui/system";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import { createAutomationSettingsListData } from "./OneHourAdvanceBidPlan";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { format } from "date-fns";
import FormHelperText from "@mui/material/FormHelperText";
import { useAuthedApi } from "../../../common/axios";
import CustomDialog, { ButtonType } from "../../common/customComponents/CustomDialog";
import { resHourBid } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";



const POST_AUTO_SETTING = "/postAutoSetting"; //自動化設定登録
const GET_AUTO_SETTING = "/getAutoSetting"; //自動化設定取得

//アイテムグリッド
const ItemGrid = styled(Grid)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

//内容を表示するグリッド
const TitleGrid = styled(ItemGrid)`
  && {
    color: rgb(150, 150, 150);
  }
`;

//内容を表示するグリッド
const SelectListGrid = styled(ItemGrid)`
  && {
    margin: -8px;
  }
`;

//自動入札設定のモーダル
const AutoDeleteSettings = (
  AutomationSettingsList: createAutomationSettingsListData,
  open: boolean,
  HaldleClose: () => void,
  areaId: string,
  setSwitchActivity: React.Dispatch<React.SetStateAction<boolean>>,
  startTime: string,
  deleteTime: string,
  priceAdjustment: string,
  deadlineTime: string,
  adjustmentCycle: string,
  adjustmentAmount: number,
  setDeadline: React.Dispatch<React.SetStateAction<string>>,
  setPriceAdjustment: React.Dispatch<React.SetStateAction<string>>,
  setDeleteTime: React.Dispatch<React.SetStateAction<string>>,
  setStartTime: React.Dispatch<React.SetStateAction<string>>,
  setAdjustmentCycle: React.Dispatch<React.SetStateAction<string>>,
  setAdjustmentAmount: React.Dispatch<React.SetStateAction<number>>,
  getIntradayData: () => Promise<void>,
  getAutoSetting: () => Promise<void>,
  hidden: boolean
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const api = useAuthedApi();

  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  //自動価格調整のセレクトボックス
  const [modalPriceAdjustment, setModalPriceAdjustment] =
    useState<string>(priceAdjustment);
  //入札締め切り時間のセレクトボックス
  const [modalDeadline, setModalDeadline] =
    useState<string>(deadlineTime);
  //自動札削除のセレクトボックス
  const [modalAutoDelete, setModalAutoDelete] =
    useState<string>(deleteTime);
  //開始時間のセレクトボックス
  const [modalStartTime, setModalStartTime] = useState<string>(startTime);
  //調整周期のセレクトボックス
  const [modalAdjustmentCycle, setModalAdjustmentCycle] =
    useState<string>(adjustmentCycle);
  //調整量のテキストフィールド
  const [modalAdjustmentAmount, setModalAdjustmentAmount] =
    useState<number>(adjustmentAmount);
  //入札締め切り時間のセレクトボックス
  const DeadlineTimeHandleChange = (selectItem: number) => {
    setModalDeadline(selectItem.toString().padStart(2, "0"));
  };
  //自動札削除時間のセレクトボックス
  const DeleteTimeHandleChange = (selectItem: number) => {
    setModalAutoDelete(selectItem.toString().padStart(2, "0"));
  };

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [digOpen, setDigOpen] = useState<boolean>(false);
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  //DBに設定内容を登録する
  const postSetting = async () => {
    await api
      .post(POST_AUTO_SETTING, {
        areaId: areaId,
        startTime: modalStartTime,
        deleteTime: modalAutoDelete,
        priceAdjust: modalPriceAdjustment,
        bidDeadline: modalDeadline,
        adjustCycle: modalAdjustmentCycle,
        adjustValue: modalAdjustmentAmount,
      })
      .then((res) => {
        setDeadline(modalDeadline);
        setPriceAdjustment(modalPriceAdjustment);
        setDeleteTime(modalAutoDelete);
        setStartTime(modalStartTime);
        setAdjustmentCycle(modalAdjustmentCycle);
        setAdjustmentAmount(modalAdjustmentAmount);
        setDialogMessage(languageContext.words.automatic_delete_deadline_setting_saved);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.automatic_delete_deadline_setting_saved_failed);
        setDigOpen(true);
        console.log(err);
      });

  };

  //DBから設定内容を取得する
  const getSetting = async () => {
    await api
      .get(GET_AUTO_SETTING, {
        params: { areaId: areaId },
      })
      .then((res) => {
        setDeadline(Number(res.data.result.bidDeadline).toString().padStart(2, "0"));
        setPriceAdjustment(
          Number(res.data.result.priceAdjust).toString().padStart(2, "0")
        );
        setDeleteTime(Number(res.data.result.deleteTime).toString().padStart(2, "0"));
        setStartTime(Number(res.data.result.startTime).toString().padStart(2, "0"));
        setAdjustmentCycle(
          Number(res.data.result.adjustCycle).toString().padStart(2, "0")
        );
        setAdjustmentAmount(Number(res.data.result.adjustValue));

        setModalDeadline(
          Number(res.data.result.bidDeadline).toString().padStart(2, "0")
        );
        setModalPriceAdjustment(
          Number(res.data.result.priceAdjust).toString().padStart(2, "0")
        );
        setModalAutoDelete(
          Number(res.data.result.deleteTime).toString().padStart(2, "0")
        );
        setModalStartTime(
          Number(res.data.result.startTime).toString().padStart(2, "0")
        );
        setModalAdjustmentCycle(
          Number(res.data.result.adjustCycle).toString().padStart(2, "0")
        );
        setModalAdjustmentAmount(Number(res.data.result.adjustValue));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      setIsLoaded(false);
      (async() => {
        await getSetting();
      })();
      setIsLoaded(true);
    }
  }, [open]);

  //保存ボタン
  const SaveButtonEvent = async () => {
    if (areaId !== "" && areaId !== "00") {
      setIsLoaded(false);
      await postSetting();
      Promise.all([
        getIntradayData(),
        getAutoSetting(),
      ]).finally(() => {
        setIsLoaded(true);
      });
    }
  };

  //モーダルを閉じる処理
  const ModalCloseEvent = () => {
    HaldleClose();
  };

  return (
    <>
      <CustomModal
        hidden={hidden}
        title={languageContext.words.automatic_delete_deadline_setting}
        open={open}
        onAccept={async () => await SaveButtonEvent()}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.save}
      >
        <Box sx={{ height: "110px", width: "410px" }}>
          <Grid container spacing={2}>
            <Grid item xs={5.5}>
              <Grid container>
                <TitleGrid item>
                  {languageContext.words.bidding_deadline_time}
                </TitleGrid>
                <SelectListGrid item>
                  <CustomSelectList
                    label={languageContext.words.actual_demand_one_hour_ago}
                    value={Number(deadlineTime)}
                    options={AutomationSettingsList.deadlineTime.map(
                      (listData) => listData
                    )}
                    onChange={(value: number) => DeadlineTimeHandleChange(value)}
                  />
                </SelectListGrid>
              </Grid>
            </Grid>
            <Grid item xs={5.5}>
              <Grid container>
                <TitleGrid item>
                  {languageContext.words.auto_bid_deletion_time}
                </TitleGrid>
                <SelectListGrid item>
                  <CustomSelectList
                    label={languageContext.words.actual_demand_one_hour_ago}
                    value={Number(deleteTime)}
                    options={AutomationSettingsList.deleteTime.map(
                      (listData) => listData
                    )}
                    onChange={(value: number) => DeleteTimeHandleChange(value)}
                  />
                  <FormHelperText>
                    {languageContext.words.intraday_auto_deletion_message}
                  </FormHelperText>
                </SelectListGrid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </>
  );
};
export default AutoDeleteSettings;
