import React, { useState, useEffect, useContext, useMemo } from "react";
import "./css/SetPowerContractList.css";
import { FormGroup, FormLabel, Stack, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./SaleList";
import {
  SaleGroupOptions,
  resSaleGroupDetail,
  SaleGroupDetail,
} from "../../types/master/SetSaleList";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { useAreaUtility } from "../../common/area";
import { useSaleKindUtility } from "../../common/saleKind";
import { useAuthedApi } from "../../common/axios";
import {
  API_URL,
  AREA_ID,
  RESULT_CODE,
  SALE_KIND,
} from "../../common/constant";
import { CommonResponse } from "../../types/common/Api";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  groupName: boolean;
  saleKind: boolean;
  specificCode: boolean;
  areaId: boolean;
  targetAreaId: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  groupName: false,
  saleKind: false,
  specificCode: false,
  areaId: false,
  targetAreaId: false,
};

const SetPowerContractList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  saleGrIdPK: number
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  // 通信エラー
  const api = useAuthedApi();

  //エリアリスト作成
  const { areaOptions } = useAreaUtility();
  //販売先種別リスト作成
  const { saleKindOptions } = useSaleKindUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  //saleDataの中にモーダル内の情報を管理してもらう
  //初期化用
  // 詳細

  const initialSaleGroupInfo: SaleGroupDetail = {
    groupId: 0, //グループID PK
    groupName: "", //販売先グループ名称
    bpId: 0,
    saleKind: SALE_KIND.NONE, //販売先種別
    specificCode: "",
    areaId: AREA_ID.NONE, //エリアID
    targetAreaId: AREA_ID.NONE,
    remarks: "", //備考
  };
  const [saleGrData, setSaleGrData] = useState<SaleGroupDetail | null>(null);
  const [saleGroupOptions, setSaleGroupOptions] = useState<SaleGroupOptions>();

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  // 販売先種別リスト
  const selectableSaleKind = useMemo(
    () => saleGroupOptions?.areaOptions.map((v) => v.saleKind) ?? [],
    [saleGroupOptions]
  );

  // 供給元エリアリスト
  // 域外の時供給先エリアに選択しているエリアは表示しない
  const selectableAreas = useMemo(
    () =>
      saleGroupOptions?.areaOptions
        .find((v) => v.saleKind === saleGrData?.saleKind)
        ?.areaIds.filter(
          (v) =>
            saleGrData?.saleKind !== SALE_KIND.OUTSIDE ||
            saleGrData?.targetAreaId !== v
        ) ?? [],
    [saleGroupOptions, saleGrData]
  );

  // 供給先エリアリスト
  // 域外の時供給元エリアに選択しているエリアは表示しない
  const selectableTargetAreas = useMemo(
    () =>
      saleGroupOptions?.areaOptions
        .find((v) => v.saleKind === saleGrData?.saleKind)
        ?.targetAreaIds.filter(
          (v) =>
            saleGrData?.saleKind !== SALE_KIND.OUTSIDE ||
            saleGrData?.areaId !== v
        ) ?? [],
    [saleGroupOptions, saleGrData]
  );

  // 成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化

  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        (async () => {
          Promise.all([
            getSaleGroupOptions(saleGrIdPK),
            getSaleGroupDetail(saleGrIdPK),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        (async () => {
          Promise.all([
            getSaleGroupOptions(saleGrIdPK),
            setSaleGrData(initialSaleGroupInfo),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      }
    } else {
      setSaleGrData(initialSaleGroupInfo);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  // 選択できるエリアがない時のメッセージ処理
  useEffect(() => {
    if (!toOpen) return;
    if (mode === modalMode.editMode) return;
    if (
      ((saleGrData?.saleKind === SALE_KIND.MARKET ||
        saleGrData?.saleKind === SALE_KIND.INSIDE) &&
        selectableAreas.length < 1) ||
      (saleGrData?.saleKind === SALE_KIND.OUTSIDE &&
        (selectableAreas.length < 1 || selectableTargetAreas.length < 1))
    ) {
      setDialogMessage(languageContext.words.setting_can_not_select_area);
      setDigOpen(true);
    }
  }, [saleGrData?.saleKind, saleGrData?.areaId, saleGrData?.targetAreaId]);

  //////販売先種別関連ここまで

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo: SaleGroupDetail = Object.assign({}, saleGrData);
    switch (name) {
      case "groupName":
        tmpInfo.groupName = value;
        break;
      case "specificCode":
        tmpInfo.specificCode = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      default:
        break;
    }
    setSaleGrData(tmpInfo);
  }

  //新規追加
  function AddDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post<CommonResponse<undefined>>(API_URL.ADD_SALEGROUP_INFO, saleGrData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.resultCode === RESULT_CODE.SUCCESS) {
            setJobClear(true);
            setDialogMessage(languageContext.words.sale_list_added);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.specific_code_are_duplicated
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.sale_list_failed_add);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  }

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (saleGrData?.groupName === "") {
      error = true;
      tempInputErrors.groupName = true;
    }
    if (saleGrData?.saleKind === SALE_KIND.NONE) {
      error = true;
      tempInputErrors.saleKind = true;
    }
    if (
      saleGrData?.saleKind === SALE_KIND.OUTSIDE &&
      saleGrData?.specificCode.length !== 5
    ) {
      error = true;
      tempInputErrors.specificCode = true;
    }
    if (saleGrData?.areaId === AREA_ID.NONE) {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (
      (saleGrData?.saleKind === SALE_KIND.INSIDE ||
        saleGrData?.saleKind === SALE_KIND.OUTSIDE) &&
      saleGrData?.targetAreaId === AREA_ID.NONE
    ) {
      error = true;
      tempInputErrors.targetAreaId = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // 販売先グループ詳細取得API
  function getSaleGroupDetail(saleGrId: number) {
    return api
      .get<CommonResponse<resSaleGroupDetail>>(API_URL.GET_ONE_SALEGR_DATA, {
        params: { saleGrId },
      })
      .then((res) => {
        setSaleGrData(res.data.result);
      })
      .catch((err) => {
        setSaleGrData(initialSaleGroupInfo);
      });
  }

  // 設定情報(事業者+種別毎設定可能エリア)
  function getSaleGroupOptions(saleGrId: number) {
    return api
      .get<CommonResponse<SaleGroupOptions>>(API_URL.GET_SALEGROUP_OPTIONS, {
        params: { saleGrId },
      })
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_BP_DATA) {
          setDialogMessage(languageContext.words.setting_can_not_display_bp);
          setDigOpen(true);
        }

        setSaleGroupOptions(res.data.result);
        // 事業者が初期値の場合(新規登録の場合)オプションからセット
        setSaleGrData((prev) =>
          prev && !prev.bpId ? { ...prev, bpId: res.data.result.bpId } : prev
        );
      });
  }

  //groupIdの値をもとにデータを更新する
  function UpdateDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_SALEGROUP_INFO, saleGrData)
        .then((res) => {
          setIsLoading(false);
          if (res.data.resultCode === RESULT_CODE.SUCCESS) {
            setJobClear(true);
            setDialogMessage(languageContext.words.sale_list_updated);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.specific_code_are_duplicated
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.sale_list_failed_update);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  }

  // 削除(論理削除)
  const Delete = async (saleGrId: number | undefined) => {
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_SALEGROUP_INFO, { saleGrId: saleGrId })
      .then((res) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(languageContext.words.sale_list_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.sale_list_failed_delete);
        setDigOpen(true);
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  //セーブして閉じる
  const DoModeJob = () => {
    if (mode === modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.sales_destinations_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(saleGrData?.groupId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack direction="column" sx={{ width: "550px" }}>
        <CustomBoxBig>
          <FormLabel required>
            {languageContext.words.sales_destinations_name}
          </FormLabel>
          <CustomTextFieldBig
            type="text"
            value={saleGrData?.groupName}
            onChange={onChangeValue}
            name="groupName"
            inputProps={{
              maxLength: 64,
            }}
            error={inputError.groupName}
          ></CustomTextFieldBig>
        </CustomBoxBig>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography>
              {languageContext.words.power_bp_name}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={saleGroupOptions?.bpName}
              disabled
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_bp_code}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={saleGroupOptions?.bpCode}
              disabled
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>
                {languageContext.words.sales_destinations_type}
              </FormLabel>
            </FormGroup>
            <CustomSelectListSmall
              name="saleKind"
              label={languageContext.words.area_unselected}
              value={saleGrData?.saleKind}
              options={saleKindOptions(selectableSaleKind)}
              onChange={(e: string) => {
                setSaleGrData((prev) =>
                  prev
                    ? {
                        ...prev,
                        saleKind: e,
                        areaId: AREA_ID.NONE,
                        targetAreaId: AREA_ID.NONE,
                        specificCode: "",
                      }
                    : prev
                );
              }}
              error={inputError.saleKind}
              disabled={mode === modalMode.editMode}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <FormLabel required={saleGrData?.saleKind === SALE_KIND.OUTSIDE}>
              {languageContext.words.specific_code}
            </FormLabel>
            <CustomBoxSmall>
              <CustomTextFieldSmall
                type="text"
                value={saleGrData?.specificCode}
                onChange={onChangeValue}
                name="specificCode"
                inputProps={{
                  maxLength: 5,
                }}
                FormHelperTextProps={{
                  sx: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  title: languageContext.words.setting_available_characters_7,
                }}
                error={inputError.specificCode}
                helperText={
                  saleGrData?.saleKind === SALE_KIND.OUTSIDE
                    ? languageContext.words.setting_available_characters_7
                    : ""
                }
                disabled={saleGrData?.saleKind !== SALE_KIND.OUTSIDE}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>
                {languageContext.words.supplier_area}
              </FormLabel>
            </FormGroup>
            <CustomSelectListSmall
              name="areaId"
              value={saleGrData?.areaId}
              options={areaOptions(selectableAreas)}
              onChange={(e: string) => {
                setSaleGrData((prev) =>
                  prev
                    ? prev.saleKind === SALE_KIND.INSIDE
                      ? { ...prev, areaId: e, targetAreaId: e }
                      : { ...prev, areaId: e }
                    : prev
                );
              }}
              error={inputError.areaId}
              disabled={mode === modalMode.editMode}
            />
          </CustomBoxSmall>

          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required={saleGrData?.saleKind === SALE_KIND.OUTSIDE}>
                {languageContext.words.supply_destination_area}
              </FormLabel>
            </FormGroup>
            <CustomSelectListSmall
              name="targetAreaId"
              value={saleGrData?.targetAreaId}
              options={areaOptions(selectableTargetAreas)}
              onChange={(e: string) => {
                setSaleGrData((prev) =>
                  prev ? { ...prev, targetAreaId: e } : prev
                );
              }}
              error={inputError.targetAreaId}
              disabled={
                saleGrData?.saleKind !== SALE_KIND.OUTSIDE ||
                mode === modalMode.editMode
              }
            />
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.remarks}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={saleGrData?.remarks}
            onChange={onChangeValue}
            name="remarks"
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.sales_destinations_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerContractList;
