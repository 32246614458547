import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { clearTimeout } from "timers";

interface Props {
  open: boolean;
}

export const CustomProgress = () => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <CircularProgress size="5%" />
    </Box>
  );
};

export const CustomModalProgress = (props: Props) => {
  const { open } = props;
  const [isDelay, setIsDelay] = useState(false);
  const timerRef = useRef<number>();

  useEffect(() => {
    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    if (!open) return;

    if (timerRef.current) window.clearTimeout(timerRef.current);
    setIsDelay(true);
    timerRef.current = window.setTimeout(() => {
      setIsDelay(false);
    }, 800);
  }, [open]);

  return (
    <Backdrop open={open || isDelay} sx={{ zIndex: 1300 }}>
      <CircularProgress />
    </Backdrop>
  );
};
