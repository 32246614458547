import React, { useContext, useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";
import { LanguageContext } from "../localization/localization";

export const StyledButton = styled(Button)`
  && {
    min-height: 24px;
    width: auto;
    font-size: 13px;
    padding 0px 10px;
  }
`;

interface Props {
  message: string;
  onAccept: () => void;
  hidden: boolean;
  disabled?: boolean;
}

const SaveDataButton = (props: Props & ButtonProps) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const [digOpen, setDigOpen] = useState(false);

  return (
    <React.Fragment>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={digOpen}
        onClose={() => setDigOpen(false)}
        message={languageContext.words.note_saved}
      /> */}
      <StyledButton
        {...props}
        sx={{ display: props.hidden ? "none" : undefined, ...props.sx }}
        variant="contained"
        disabled={props.disabled}
        onClick={() => {
          setDigOpen(true);
          props.onAccept();
        }}
      >
        {languageContext.words.save}
      </StyledButton>
    </React.Fragment>
  );
};

//移行前実装済みの保存ボタン用デフォルト引数
//移行後削除
SaveDataButton.defaultProps = {
  message: "作成中",
  onAccept: () => console.log("onAccept"),
};

export default SaveDataButton;
