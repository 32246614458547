import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { StyledButtonMenu } from "../../styledComponents/styledButton";
import { Typography } from "@mui/material";
import { LanguageContext } from "../../localization/localization";
import { AreaContext } from "../../customComponents/CustomAreaContext";
import { BusinessModelContext } from "../../../common/customComponents/BusinessModelProvider";
import { BUSINESS_MODEL } from "../../../../common/constant";
import {
  ConfirmLeavePage,
  LeavePageContext,
} from "../../customComponents/CustomConfirmLeavePage";
import { PaletteContext } from "../../paletteMode";
import {
  POWER_FORECAST_PLAN_URL,
  DEMAND_FORECAST_PLAN_URL,
} from "../../../../common/url";

// 発電計画画面 画面名右横のコンポーネント

const BiddingScreenMenu = (props: any) => {
  // 言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const areaContext = useContext(AreaContext);
  const { businessModel } = useContext(BusinessModelContext);
  const leavePageContext = useContext(LeavePageContext);
  const [digOpen, setDigOpen] = useState(false);
  // ビジネスモデルによるボタンの表示・非表示
  const [isDemandModel, setIsDemandModel] = useState<boolean>(true);
  const url = useRef<string | null>("");
  const navigate = useNavigate();

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  // ダークモード対応
  const {PaletteMode} = useContext(PaletteContext);
  // propsでスポット主画面からステータスや入札時刻、入札制限値等を受け取る
  const { bidStatus, areaId, getBidStatus, getBidInfo } =
    props;

  // ステータスを数値から文字に変換
  const convertNumToStatus = (statusNum: number) => {
    switch (statusNum) {
      case 0:
        return languageContext.words.waiting_for_bid;
      case 3:
        return languageContext.words.unplanned;
      case 1:
        return languageContext.words.waiting_for_contract;
      case 2:
        return languageContext.words.contracted;
      case 6:
        return languageContext.words.unsuccessful_bid;
      case 7:
        return languageContext.words.delete_failed;
      case 9:
        return languageContext.words.bid_canceled;
      default:
        return "";
    }
  };

  const getBidData = async () => {
    await getBidInfo();
    await getBidStatus();
  };

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      getBidData();
    }
  }, []);

  // ビジネスモデルによって計画遷移画面の表示・非表示を切り替え
  useEffect(() => {
    const displayModel = businessModel.filter((v) => v.areaId === areaId).map((v) => v.businessModel);
    
    if(displayModel[0] === BUSINESS_MODEL.IKIGAI_DEMAND ||
      displayModel[0] === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ) {
      setIsDemandModel(true);
    } else {
      setIsDemandModel(false);
    }
  }, [areaId]);

  return (
    <Box sx={{ display: "flex", justifyContent: "end" }}>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.status}
      </Typography>
      <Typography
        sx={{
          width: "160px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
        }}
      >
        {bidStatus.status === undefined
          ? ""
          : convertNumToStatus(bidStatus.status)}
      </Typography>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.bid_number}
      </Typography>
      <Typography
        sx={{
          width: "160px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
        }}
      >
        {bidStatus.ucTender === 0
          ? ""
          : bidStatus.ucTender === undefined
          ? ""
          : bidStatus.status !== 0 &&
            bidStatus.status !== 3 &&
            bidStatus.status !== 6
          ? bidStatus.ucTender //入札番号入札前から表示されている対応
          : ""}
      </Typography>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.bidding_time_jikoku}
      </Typography>
      <Typography
        sx={{
          width: "150px",
          border: "solid 1px lightgray",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
          marginRight: "15px",
        }}
      >
        {bidStatus.bitDate === undefined ||
        bidStatus.status === 0 ||
        bidStatus.status === 3 ||
        bidStatus.status === 6
          ? ""
          : languageContext.convertDateToLocalizedStringGoodAcu(
              new Date(bidStatus.bitDate)
            )}
      </Typography>
      {!isDemandModel && 
        <StyledButtonMenu
          sx={{ marginLeft: "15px" }}
          onClick={() => {
            url.current = POWER_FORECAST_PLAN_URL;
            if (areaContext.areaId !== "") {
              url.current = `${POWER_FORECAST_PLAN_URL}?areaId=${areaContext.areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.powerforecastplan}
        </StyledButtonMenu>
      }
      {isDemandModel && 
        <StyledButtonMenu
          sx={{ marginLeft: "15px" }}
          onClick={() => {
            url.current = DEMAND_FORECAST_PLAN_URL;
            if (areaContext.areaId !== "") {
              url.current = `${DEMAND_FORECAST_PLAN_URL}?areaId=${areaContext.areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.demandforecastplan}
        </StyledButtonMenu>
      }
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      ></ConfirmLeavePage>
    </Box>
  );
};

export default BiddingScreenMenu;
