import React, { useContext, useEffect, useRef, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import "./css/UserList.css";
// ユーザ一覧
import Box from "@mui/material/Box";
import SetSupplyBgList from "./SetSupplyBgList";
import { LanguageContext } from "../common/localization/localization";
import { demandBGInfo } from "../../types/master/SupplyBgList";
import { Typography } from "@mui/material";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
export enum modalMode {
  editMode,
  addMode,
}

const SupplyBgList = () => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  //通信エラー
  const api = useAuthedApi();

  const { convertIdToAreaName } = useAreaUtility();

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);

  //showがtrueになった時モーダルが開かれる
  const [show, setShow] = useState(false);
  //DBから取得したデータをpowerBgに格納して描画
  const [demandBgs, setDemandBgs] = useState<demandBGInfo[]>([
    {
      bgId: 0,
      bgName: "",
      bgCode: "",
      bpName: "",
      areaId: "",
      remarks: "",
    },
  ]);
  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);
  //編集ボタンを押した行のBGコード情報
  const [bgIDForEdit, setBgCode] = useState<number>(0); // ここをBGIDにする
  //登録・編集ボタンの押下可能チェッカー
  const [enableEdit, setEnableEdit] = useState(false);

  // show : falseの時一覧取得apiコール
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show) return;
    setIsLoading(true);
    (async () => {
      requestDemandBgsInfo().finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show]);

  // 初期化 画面ロード時、編集可能ユーザーか確認
  useEffect(() => {
    (async () => {
      Promise.all([checkEditRoll(), requestDemandBgsInfo()]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  // 設定モーダル閉じる
  const HandleClose = () => {
    setShow(false);
  };

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードにする
    setMode(modalMode.addMode);
    //bgCodePK内の情報を初期化
    setBgCode(0);
    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (bgID: number) => {
    //編集モードにする
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setBgCode(bgID);
    setShow(true);
  };

  // 需要BG一覧取得
  const requestDemandBgsInfo = () => {
    let responseData: demandBGInfo[] = [];
    return api
      .get(API_URL.GET_BG_LIST)
      .then((response) => {
        responseData = response.data.result as demandBGInfo[];
        dispDatas(responseData);
      })
      .catch((err) => {
        console.log(err);
        dispDatas([{ user_id: "test", role_id: 99 }] as never);
      });
  };

  // テーブルの列データ
  const dispDatas = (responseData: demandBGInfo[]) => {
    let demandBgList: demandBGInfo[] = [];
    try {
      demandBgList = responseData;
    } catch (error) {
      console.log(error);
    }

    if (demandBgList === undefined) {
      return;
    }
    setDemandBgs(demandBgList);
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit === true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ height: "40px" }}>
          {enableEdit && (
            <NewRegistrationButton
              variant="outlined"
              onClick={() => HandleOpenForAddNew()}
            >
              {languageContext.words.new_registration}
            </NewRegistrationButton>
          )}
          {SetSupplyBgList(show, HandleClose, mode, bgIDForEdit)}
        </Box>
      </Box>
      <CustomModalProgress open={isLoading} />
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.demand_bg_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.area}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.demand_bg_code}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig>
                {languageContext.words.bp_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {demandBgs?.map((demandBg, index) => (
              <TableRow key={index}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={demandBg.bgName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {demandBg.bgName}
                    </Typography>
                    {enableEdit && (
                      <ModalButton
                        variant="outlined"
                        onClick={() => HandleOpenForEdit(demandBg.bgId)}
                      >
                        {languageContext.words.edit}
                      </ModalButton>
                    )}
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToAreaName(demandBg.areaId)}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {demandBg.bgCode}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={demandBg.bpName}
                  sx={{ textAlign: "left !important" }}
                >
                  {demandBg.bpName}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={demandBg.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {demandBg.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default SupplyBgList;
