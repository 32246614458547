import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import "./css/UserList.css";

// 発電契約者
import { Box, Typography } from "@mui/material";
import SetPowerContractList from "./SetPowerContractList";
import { LanguageContext } from "../common/localization/localization";
import { powerContractorInfo } from "../../types/master/PowerContractList";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export enum modalMode {
  editMode,
  addMode,
}

const PowerContractList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);
  // 通信エラー
  const api = useAuthedApi();
  //エリア名作成
  const { convertIdToAreaName } = useAreaUtility();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  //showがtrueになった時、モーダルが開かれる
  const [show, setShow] = useState(false);

  //DBから取得したデータをpowerContractInfoに格納して描画
  const [powerContractInfo, setPowerContractInfo] =
    useState<powerContractorInfo[]>();

  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行の発電契約者ID情報
  const [contractIdForEdit, setContractIdForEdit] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isFirstRendering = useRef<boolean>(true);

  useEffect(() => {
    // 初回ロール取得
    (async () => {
      Promise.all([checkEditRoll(), requestPowerContractInfo()]).finally(() => {
        setIsLoading(false);
        isFirstRendering.current = false;
      });
    })();
  }, []);

  //初回、モーダルが閉じたら更新
  useEffect(() => {
    if (isFirstRendering.current) return;
    if (show) return;
    setIsLoading(true);
    (async () => {
      requestPowerContractInfo().finally(() => {
        setIsLoading(false);
      });
    })();
  }, [show]);

  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    setContractIdForEdit(0);
    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (contractId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //行のPK値を設定
    setContractIdForEdit(contractId);

    setShow(true);
  };

  //データベースから発電契約者一覧取得
  const requestPowerContractInfo = () => {
    // 発電契約者一覧取得
    let responseData: powerContractorInfo[] = [];
    return api
      .get(API_URL.GET_POWERCONTRACTOR_LIST)
      .then(async (response) => {
        responseData = response.data.result as powerContractorInfo[];
        await dispDatas(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    return api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit === true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function dispDatas(responseData: powerContractorInfo[]) {
    let powerContractList: powerContractorInfo[] = [];
    try {
      powerContractList = responseData;
    } catch (error) {
      console.log(error);
    }
    if (powerContractList === undefined) {
      return;
    }
    setPowerContractInfo(powerContractList);
  }

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          flexDirection: "row-reverse",
          paddingBottom: "16px",
        }}
      >
        <Box sx={{ height: "40px" }}>
          {enableEdit && (
            <NewRegistrationButton
              variant="outlined"
              onClick={() => HandleOpenForAddNew()}
            >
              {languageContext.words.new_registration}
            </NewRegistrationButton>
          )}
          {SetPowerContractList(show, HandleClose, mode, contractIdForEdit)}
        </Box>
      </Box>
      <CustomModalProgress open={isLoading} />
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.power_gen_contractor}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.plan_submitter_code}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.area}
              </StyledTableHeaderSmall>

              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {powerContractInfo?.map((contractor) => (
              <TableRow key={contractor.contractId}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      title={contractor.contractorName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {contractor.contractorName}
                    </Typography>
                    {enableEdit && (
                      <ModalButton
                        sx={{ marginLeft: "auto" }}
                        variant="outlined"
                        onClick={() => HandleOpenForEdit(contractor.contractId)}
                      >
                        {languageContext.words.edit}
                      </ModalButton>
                    )}
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {contractor.contractCode}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {convertIdToAreaName(contractor.areaId)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={contractor.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {contractor.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default PowerContractList;
