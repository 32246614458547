import React, { useState, useEffect, useContext } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { light } from "@mui/material/styles/createPalette";
import { isBooleanObject } from "util/types";
import { FormHelperText } from "@mui/material";
import {
  ConfirmLeavePage,
  LeavePageContext,
  LeavePageContextType,
} from "../CustomConfirmLeavePage";
import { PaletteContext } from "../../paletteMode";
import { LanguageContext } from "../../localization/localization";

const CustomSelectListSmall = (props: any) => {
  const {
    label,
    value,
    options,
    onChange,
    disabled,
    error,
    helperText,
    blocked = true,
  } = props;
  const [selectValue, setSelectValue] = useState<string>(value ?? "");
  const [digOpen, setDigOpen] = useState(false);
  const [changeVal, setChangeVal] = useState<string>(value ?? "");
  const { blockLeave, setBlockLeave } =
    useContext<LeavePageContextType>(LeavePageContext);
  const { words } = useContext(LanguageContext);

  useEffect(() => {
    setSelectValue(value === "" ? label : value);
    setChangeVal(value === "" ? label : value);
  }, [label, value]);

  const handleChange = (e: SelectChangeEvent) => {
    setChangeVal(e.target.value);
    if (blockLeave && blocked) {
      setDigOpen(true);
    } else {
      setSelectValue(e.target.value);
      onChange(e.target.value);
      setBlockLeave(!blocked);
    }
  };

  const acceptHandler = () => {
    setSelectValue(changeVal);
    onChange(changeVal);
    setBlockLeave(!blocked);
  };

  //モードによってボタンの背景変化するように,あとテキストカラーも
  return (
    <>
      <FormControl
        color="primary"
        variant="outlined"
        sx={{
          marginLeft: "0px",
          marginRight: "8px",
          marginTop: "0px",
          marginBottom: "25px",
        }}
      >
        <Select
          error={error === undefined ? false : error}
          value={selectValue}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          disabled={Boolean(disabled)}
          sx={{
            width: "160px",
            fontSize: "14px",
            height: "43px",
          }}
        >
          {options.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
        {error !== undefined && (
          <FormHelperText sx={{ color: "red" }}>{helperText}</FormHelperText>
        )}
      </FormControl>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
        message={words.setting_change_selections_message}
      />
    </>
  );
};

export default CustomSelectListSmall;
