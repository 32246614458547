import { useCallback, useContext, useMemo } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { BATTERY_EXIST } from "./constant";

export const useExistTypeUtility = () => {
  const { words } = useContext(LanguageContext);

  const batteryExistOptions = useMemo(
    () => [
      {
        id: BATTERY_EXIST.NOT_EXIST,
        name: words.storage_battery_unowned,
      },
      {
        id: BATTERY_EXIST.EXIST,
        name: words.storage_battery_owned,
      },
    ],
    [words]
  );

  const convertIdToBatteryExistName = useCallback(
    (id: string) => {
      const powerSupplyNames = [
        words.storage_battery_unowned,
        words.storage_battery_owned,
      ];
      return powerSupplyNames[Number(id)]
        ? powerSupplyNames[Number(id)]
        : "undefined";
    },
    [words]
  );

  return { batteryExistOptions, convertIdToBatteryExistName };
};
