import React, { useContext, useState, useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";

import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../common/localization/localization";
import {
  tempAlarmListType,
  alarmListType,
} from "../../../types/system/SubAlarm";
import axios from "axios";
import { format } from "date-fns";
import { PaletteContext } from "../../common/paletteMode";
import { SETTING_URL } from "../../../common/url";
import { ConnectionErrorDisplay } from "./ConnectionErrorComponent";
import { useAuthedModalApi } from "../../../common/axios";

const GET_ARALMLIST_API = "/getAlarmList";

//a要素ボタン
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    border-radius: 0px;
    color: black;
    font-size: 14px;
  }
` as typeof Button;

interface SubAlarmProps {
  closeHandler: () => void;
}

// 通知一覧画面
const SubAlarm = (props: SubAlarmProps) => {
  const { closeHandler } = props;
  //アラーム一覧のセレクトボックス
  const [alarmList, setAlarmList] = useState<alarmListType[]>([]);
  //データ取得時のエラー発生を監視
  const [error, setError] = useState<boolean>(false);
  const languageContext = useContext(LanguageContext);
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const api = useAuthedModalApi(setError);

  //アラームリスト取得
  useEffect(() => {
    getAlarmList();
  }, []);

  //アラーム一覧をDBより取得
  function getAlarmList() {
    console.log("getAlarmList");

    let responseData: never[] = [];
    api
      .get(GET_ARALMLIST_API)
      .then((response) => {
        setError(false);
        responseData = response.data.result;
        records(responseData);
      })
      .catch((err) => {
        console.log("getAlarmList失敗:" + err);
        records([{ user_id: "test", role_id: 99 }] as never);
      });
  }

  // テーブルの列データ
  function records(responseData: tempAlarmListType[]) {
    const alarmList: alarmListType[] = [];

    //DBから取ってきた情報を成型してalarmListに入れる
    responseData.map((data) => {
      const date = new Date(data.alart_date);
      date.setHours(date.getHours() - 9);
      alarmList.push({
        alarmDate: date,
        alarmTime: format(date, "HH:mm"),
        message: data.message,
      });
    });
    setAlarmList(alarmList);
  }

  return (
    <div
      className={`cn-sub-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box
        sx={{
          width: "100%",
          height: "8%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "gray", marginY: "auto", paddingLeft: "8px" }}>
          {languageContext.words.notification_tuti}({alarmList.length})
        </Typography>
        <ButtonLink
          component={Link}
          to={SETTING_URL}
          sx={{
            WebkitTextFillColor: PaletteMode === "dark" ? "#ffffff" : "#000000",
          }}
          onClick={closeHandler}
        >
          {languageContext.words.notification_setting}
        </ButtonLink>
      </Box>
      {error ? (
        <ConnectionErrorDisplay />
      ) : (
        <TableContainer
          sx={{
            minWidth: "100%",
            height: "350px",
            display: "flexed",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <StyledTable
            sx={{
              width: "100%",
              height: "100%",
              position: "sticky",
              left: 0,
              zIndex: 1,
              borderCollapse: "separate",
            }}
          >
            <TableHead
              sx={{
                background: "rgb(220,230,241)",
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: 30,
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.notification_date}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.timeB}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.message}
                </StyledTableHeaderLG>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{ background: "rgb(255,255,255)", overflowY: "scroll" }}
            >
              {alarmList.map((data, index) => (
                <TableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {languageContext.convertDateToLocalizedString(
                      data.alarmDate
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {data.alarmTime}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ height: "30px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {data.message}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
    </div>
  );
};

export default SubAlarm;
