import { AxiosResponse } from "axios";
import { CommonResponse } from "../../types/common/Api";
import { businessModelList } from "../../types/system/Login";
import api from "../../common/axios";

const GET_BUSINESS_MODEL = "/getBusinessModel";

/**
 * ビジネスモデルを取得
 * @param なし
 */
export const getBusinessModel = async () => {
  let businessModel: businessModelList[] = [];

  await api
    .get<any, AxiosResponse<CommonResponse<businessModelList[]>>>(
      GET_BUSINESS_MODEL
    )
    .then((res) => {
      businessModel = res.data.result;
    })
    .catch((err) => {
      // displayErrorDialog(err);
    });
  return businessModel;
};
