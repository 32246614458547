import React, { useState, useEffect, useContext, useMemo } from "react";
import "./css/SetPowerBgList.css";
import { FormGroup, FormLabel, Stack } from "@mui/material";

import {
  powerBGInfoSet,
  resContractorInfo,
} from "../../types/master/SetPowerBgList";
import { modalMode } from "./PowerBgList";
import { LanguageContext } from "../common/localization/localization";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { usePowerSupplyTypeUtility } from "../../common/powerSupplyType";
import { API_URL, RESULT_CODE } from "../../common/constant";
import { useAuthedApi } from "../../common/axios";
import { CommonResponse } from "../../types/common/Api";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

//初期化用
const initialPowerBGInfo: powerBGInfoSet = {
  bgId: 0,
  bgName: "",
  bgCode: "",
  areaId: "00",
  contractantID: 0,
  powerKind: 0,
  contractId1: "",
  remarks: "",
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bgName: boolean;
  bgCode: boolean;
  powerKind: boolean;
  areaId: boolean;
  contractantID: boolean;
  contractId1: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bgName: false,
  bgCode: false,
  powerKind: false,
  areaId: false,
  contractantID: false,
  contractId1: false,
};

const SetPowerBgList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  bgIdPK: number
) => {
  type contractorInfo = {
    id: number;
    name: string;
    code: string;
    contractor_areaId: string;
  };

  const contractInit = {
    id: 0,
    name: "",
    code: "",
    contractor_areaId: "",
  };
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // 通信エラー
  const api = useAuthedApi();

  const { convertIdToAreaName } = useAreaUtility();
  const { supplyTypeOptions } = usePowerSupplyTypeUtility();

  //発電契約者一覧
  const [contractInfo, setContractInfo] = useState<contractorInfo[]>([
    contractInit,
  ]);

  // 発電BG詳細(全部これにまとめる)
  const [powerBGInfo, setPowerBGInfo] =
    useState<powerBGInfoSet>(initialPowerBGInfo);
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // 選択されている接続エリア
  const selectedAreaId = useMemo(
    () =>
      contractInfo.find((v) => v.id === powerBGInfo?.contractantID)
        ?.contractor_areaId ?? "",
    [powerBGInfo, contractInfo]
  );

  // 選択されている契約者コード
  const selectedContractCode = useMemo(
    () =>
      contractInfo.find((v) => v.id === powerBGInfo?.contractantID)?.code ?? "",
    [powerBGInfo, contractInfo]
  );

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化

  useEffect(() => {
    if (toOpen === true) {
      // モーダルオープン
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        // 編集
        (async () => {
          Promise.all([
            getDataLinkedBgCode(bgIdPK),
            getContractorMst(),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        // 新規登録
        (async () => {
          getContractorMst().finally(() => {
            setIsLoading(false);
          });
        })();
        // 発電BGの表示内容を初期化
        setPowerBGInfo(initialPowerBGInfo);
      }
    } else {
      //モーダルクローズ
      ContractorCompornentChanged(contractInit);
      setPowerBGInfo(initialPowerBGInfo);

      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  /**
   * 登録済発電契約者の一覧を取得する
   */
  const getContractorMst = () => {
    return api
      .get<CommonResponse<resContractorInfo[]>>(API_URL.GET_CONTRACTOR_MST)
      .then((response) => {
        if (response.data.resultCode === RESULT_CODE.NO_CONTRACTOR) {
          setDialogMessage(
            languageContext.words.setting_can_not_select_power_contractant
          );
          setDigOpen(true);
        }

        setContractInfo(
          convertContractorData(response.data.result as resContractorInfo[])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * 発電契約者の一覧を作成する
   * @param result
   * @returns
   */
  const convertContractorData = (result: resContractorInfo[]) => {
    const retContractorData: contractorInfo[] = [];
    const unselectedData: contractorInfo = {
      id: 0,
      name: languageContext.words.setting_unselected,
      code: "",
      contractor_areaId: "",
    };
    retContractorData.push(unselectedData);
    result.map((data, index) => {
      const contractData: contractorInfo = {
        id: data.contractorId,
        name: data.name,
        code: data.code,
        contractor_areaId: data.contractor_areaId,
      };
      retContractorData.push(contractData);
    });
    return retContractorData;
  };

  /**
   * 発電契約者プルダウン変更時に発火
   * @param e
   */
  const setContractor = (e: number) => {
    setContractInfoForEdit(e);
  };

  //エリア、発電契約者セット
  const ContractorCompornentChanged = async (resContractor: contractorInfo) => {
    const tmpPowerBGInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    tmpPowerBGInfo.areaId = resContractor.contractor_areaId;
    tmpPowerBGInfo.contractantID = resContractor.id;

    // 更新
    setPowerBGInfo(tmpPowerBGInfo);
  };

  /**
   * 発電BG1件分のデータを取得
   * @param bgId
   */
  const getDataLinkedBgCode = (bgId: number) => {
    let responseData: powerBGInfoSet[] = [];
    return api
      .get<CommonResponse<powerBGInfoSet[]>>(API_URL.GET_ONE_POWERBGDATA, {
        params: { bgId: bgId },
      })
      .then(async (response) => {
        responseData = response.data.result as powerBGInfoSet[];
        if (responseData.length > 0) {
          setPowerBGInfo(responseData[0]);
        } else {
          setPowerBGInfo(initialPowerBGInfo);
        }
      })
      .catch((err) => {
        console.log(err);
        setPowerBGInfo(initialPowerBGInfo);
      });
  };

  /**
   * 発電契約者プルダウンを操作した際に接続エリアと発電契約者コードを再セットする
   * @param keyId
   * @returns
   */
  const setContractInfoForEdit = async (keyId: number) => {
    if (!keyId) {
      // 未選択
      await ContractorCompornentChanged(contractInit);
      return;
    }

    contractInfo.map(async (contractorInfo) => {
      if (keyId === contractorInfo.id) {
        await ContractorCompornentChanged(contractorInfo);
      }
    });
  };

  //新規追加
  function AddDatas() {
    let error = false;

    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      setIsLoading(true);
      api
        .post(API_URL.ADD_POWERBG_INFO, powerBGInfo)
        .then((response) => {
          if (response.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.power_bg_duplication_message
            );
            setDigOpen(true);
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_bg_added);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_bg_failed_add);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerBGInfo?.bgName === "") {
      error = true;
      tempInputErrors.bgName = true;
    }
    if (powerBGInfo?.bgCode === "") {
      error = true;
      tempInputErrors.bgCode = true;
    }
    if (powerBGInfo?.areaId === "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (powerBGInfo?.contractantID === 0) {
      error = true;
      tempInputErrors.contractantID = true;
    }
    if (powerBGInfo?.contractId1 === "") {
      error = true;
      tempInputErrors.contractId1 = true;
    }
    if (powerBGInfo.powerKind === 0) {
      error = true;
      tempInputErrors.powerKind = true;
    }
    setInputError(tempInputErrors);
    return error;
  };

  //BGコードの値をもとにデータを更新する
  function UpdateDatas() {
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      setIsLoading(true);
      api
        .post<CommonResponse<powerBGInfoSet>>(
          API_URL.UPDATE_POWERBG_INFO,
          powerBGInfo
        )
        .then((response) => {
          if (response.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(
              languageContext.words.power_bg_duplication_message
            );
            setDigOpen(true);
          } else {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_bg_update);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_bg_failed_update);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  // BG削除(論理削除)
  const Delete = async (bgId: number | undefined) => {
    // 削除API呼出
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_POWERBG_INFO, { bgId: bgId })
      .then((response) => {
        setJobClear(true);
        setDialogMessage(languageContext.words.power_bg_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.power_bg_failed_delete);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });

    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  //セーブして閉じる
  const SaveAndClose = () => {
    if (mode === modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.generation_bg_setting}
      open={toOpen}
      onAcceptLeft={() => SaveAndClose()}
      onAcceptRight={() => Delete(powerBGInfo?.bgId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack direction="row" sx={{ width: "550px" }}>
        <Stack direction="column" spacing={0}>
          <CustomBoxBig>
            <FormLabel required>
              {languageContext.words.power_gen_bg_name}
            </FormLabel>
            <CustomTextFieldBig
              type="text"
              value={powerBGInfo?.bgName}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setPowerBGInfo((prev) =>
                  prev
                    ? {
                        ...prev,
                        bgName: e.target.value,
                      }
                    : prev
                );
              }}
              name={"bgName"}
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bgName}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>{languageContext.words.bg_code}</FormLabel>
              <CustomTextFieldSmall
                type="text"
                value={powerBGInfo?.bgCode}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setPowerBGInfo((prev) =>
                    prev
                      ? {
                          ...prev,
                          bgCode: e.target.value,
                        }
                      : prev
                  );
                }}
                name="bgCode"
                inputProps={{
                  maxLength: 5,
                }}
                error={inputError.bgCode}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.power_gen_contractor}
                </FormLabel>
              </FormGroup>
              <CustomSelectListBig
                name="manegeId"
                label={languageContext.words.area_unselected}
                disabled={mode === modalMode.editMode ? true : false}
                value={powerBGInfo.contractantID}
                options={contractInfo}
                onChange={(e: number) => {
                  setContractor(e);
                }}
                error={inputError.contractantID}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_gen_contractor_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={selectedContractCode}
                name="contractantId"
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>{/* 空領域 */}</CustomBoxSmall>

            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.area}
              </CustomModalTypography>
              <CustomTextFieldSmall
                name="areaId"
                value={convertIdToAreaName(selectedAreaId)}
                disabled={true}
                error={inputError.areaId}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormGroup>
                <FormLabel required>
                  {languageContext.words.power_supply_type}
                </FormLabel>
              </FormGroup>
              <CustomSelectListSmall
                name="powerKind"
                label={"powerList"}
                value={powerBGInfo.powerKind}
                options={supplyTypeOptions}
                onChange={(e: number) => {
                  setPowerBGInfo((prev) =>
                    prev
                      ? {
                          ...prev,
                          powerKind: Number(e),
                        }
                      : prev
                  );
                }}
                error={inputError.powerKind}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.contract_id_number_1}
              </FormLabel>
              <CustomTextFieldSmall
                type="text"
                value={powerBGInfo?.contractId1}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setPowerBGInfo((prev) =>
                    prev
                      ? {
                          ...prev,
                          contractId1: e.target.value,
                        }
                      : prev
                  );
                }}
                name="contractId1"
                inputProps={{
                  maxLength: 20,
                }}
                error={inputError.contractId1}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerBGInfo?.remarks}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setPowerBGInfo((prev) =>
                  prev
                    ? {
                        ...prev,
                        remarks: e.target.value,
                      }
                    : prev
                );
              }}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Stack>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.generation_bg_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerBgList;
