import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomModal from "../../common/customComponents/CustomModal";
import Checkbox from "@mui/material/Checkbox";
import { TimeTable } from "./CreateTime";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { LanguageContext } from "../../common/localization/localization";
import { BidingCell } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import { ariaBitCell } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import axios, { AxiosError } from "axios";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { CONTRACT_STATUS } from "./IntradayConstant";
import { useAuthedApi } from "../../../common/axios";
import { ERR_CODE_SOAP_ERR, RESULT_CODE } from "../../../common/constant";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

const HeaderCell = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//テーブルのセル
const ValueTableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 5px 8px 5px 0px;
    box-sizing: content-box;
    font-size: 14px;
    width: 89px;
  }
`;

const POST_BULK_DELTE_DATA = "/updateBulkDelete"; //一括取消情報登録
const BULK_DELETE = "/bulkDeleteIntradayBid"; //一括取消実行

//一括取り消しのテーブルのレンダー
const BulkUndoTableRender = (props: any) => {
  const {
    handleSelectAllClick,
    parentBoxChecked,
    parentBoxIndeterminate,
    dayBit,
    validTime,
    firstTimeNumber,
    lastTimeNumber,
    tradingType,
    boxChecked,
    handleClick,
    areaInfo,
    createTypeText,
    createStatusText,
  } = props;

  const dayData: BidingCell[] = dayBit;

  const languageContext = useContext(LanguageContext);

  //小数点第二位までかつカンマ区切りに変換
  const ConvertToLocaleString = (value: number) => {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  return (
    <TableContainer
      sx={{
        width: "100%",
        height: "80%",
      }}
    >
      <StyledTable
        sx={{
          width: "100%",
          height: "100%",
          borderCollapse: "separate",
        }}
      >
        <TableHead
          sx={{
            width: "100%",
            height: "auto",
            display: "block",
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
        >
          <TableRow sx= {{display: "flex"}}>
            <HeaderCell 
              sx={{ 
                width: "45px",
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center"  
                }}
              >
              <Checkbox
                onClick={handleSelectAllClick}
                checked={parentBoxChecked}
                indeterminate={parentBoxIndeterminate}
              />
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "45px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
              }}
            >
              {languageContext.words.status_jokyo}           
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "121px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
              }}
            >
              {languageContext.words.bidding_time_jikoku}
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "85px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
              }}
              title = {languageContext.words.salebuy_type}  
            >
              <Box 
                sx = {{
                  textOverflow: "ellipsis", 
                  overflow: "hidden", 
                  whiteSpace: "nowrap"
                }}
              >
                {languageContext.words.salebuy_type}
              </Box>
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "97px", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
              }}
              title = {
                languageContext.words.bid_price +
                " (" +
                languageContext.words.yen_kwh +
                ")"
              }
            >
              <Box 
                sx = {{
                  textOverflow: "ellipsis", 
                  overflow: "hidden", 
                  whiteSpace: "nowrap"
                }}
              >
                {languageContext.words.bid_price}
                <br />
                ({languageContext.words.yen_kwh})
              </Box>
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "97px", 
                display: "block", 
                justifyContent: "center", 
                alignItems: "center"
              }}
              title = {
                languageContext.words.bidding_volume +
                " (MWh/h)"
              }
            >
              <Box 
                sx = {{
                  textOverflow: "ellipsis", 
                  whiteSpace: "nowrap", 
                  overflow:"hidden"
                }}
              >
                {languageContext.words.bidding_volume}
                <br />
                (MWh/h)
              </Box>
            </HeaderCell>
            <HeaderCell 
              sx={{ 
                width: "121px",
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center"  
              }}
            >
              {languageContext.words.contract_status_jokyo}
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            width: "100%",
            height: "259px",
            overflowX: "hidden",
            overflowY: "scroll",
            display: "block",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {dayData.map((data, index) => {
            if (data.type !== "") {
              let initialFirstTimetNumber = validTime;
              if (0 < firstTimeNumber) {
                initialFirstTimetNumber = firstTimeNumber;
              }
              if (initialFirstTimetNumber <= Number(data.timeCd) - 1 && Number(data.timeCd) - 1 <= lastTimeNumber) {
                if (
                  tradingType === languageContext.words.all &&
                  (data.contractStatus === CONTRACT_STATUS.waitingContract ||
                    data.contractStatus === CONTRACT_STATUS.cancellationFailure)
                ) {
                  return (
                    <TableRow key={`${data.bidPrice}_${index}`}>
                      <TableCell
                        sx={{ width: "45px" }}
                        key={`${data.bidPrice}_CheckBox_${index}`}
                      >
                        <Checkbox
                          sx={{ zIndex: 1 }}
                          checked={boxChecked[index]}
                          onChange={() => handleClick(index)}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ width: "45px" }}
                        key={`${data.bidPrice}_Status_${index}`}
                      >
                        {areaInfo[index].productStatus}
                      </TableCell>
                      <TableCell
                        sx={{ width: "121px" }}
                        key={`${data.bidPrice}_Time_${index}`}
                      >
                        {timeData[Number(data.timeCd) - 1]}～{timeData[Number(data.timeCd)]}
                      </TableCell>
                      <TableCell
                        sx={{ width: "85px" }}
                        key={`${data.bidPrice}_TradingType_${index}`}
                      >
                        {createTypeText(data.type)}
                      </TableCell>
                      <ValueTableCell
                        key={`${data.bidPrice}_BidPrice_${index}`}
                      >
                        {ConvertToLocaleString(data.bidPrice)}
                        {/* 入札価格 */}
                      </ValueTableCell>
                      <ValueTableCell
                        key={`${data.bidPrice}_BidAmount_${index}`}
                      >
                        {ConvertToLocaleString(data.bidValue)}
                        {/* 入札量 */}
                      </ValueTableCell>
                      <TableCell
                        sx={{ width: "121px" }}
                        key={`${data.bidPrice}_Situation_${index}`}
                      >
                        <label style={{ color: "rgb(255,156,10)" }}>
                          {createStatusText(data.contractStatus)}
                        </label>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  if (
                    createTypeText(data.type) === tradingType &&
                    (data.contractStatus === CONTRACT_STATUS.waitingContract ||
                      data.contractStatus ===
                        CONTRACT_STATUS.cancellationFailure)
                  ) {
                    return (
                      <TableRow key={`${data.bidPrice}_${index}`}>
                        <TableCell
                          sx={{ width: "45px" }}
                          key={`${data.bidPrice}_CheckBox_${index}`}
                        >
                          <Checkbox
                            onChange={() => handleClick(index)}
                            checked={boxChecked[index]}
                          />
                        </TableCell>
                        <TableCell
                          sx={{ width: "45px" }}
                          key={`${data.bidPrice}_Status_${index}`}
                        >
                          {areaInfo[Number(data.timeCd) - 1].productStatus}
                        </TableCell>
                        <TableCell
                          sx={{ width: "121px" }}
                          key={`${data.bidPrice}_Time_${index}`}
                        >
                          {timeData[Number(data.timeCd) - 1]}～{timeData[Number(data.timeCd)]}
                        </TableCell>
                        <TableCell
                          sx={{ width: "85px" }}
                          key={`${data.bidPrice}_TradingType_${index}`}
                        >
                          {createTypeText(data.type)}
                        </TableCell>
                        <ValueTableCell
                          key={`${data.bidPrice}_BidPrice_${index}`}
                        >
                          {data.bidPrice.toFixed(2)}
                        </ValueTableCell>
                        <ValueTableCell
                          key={`${data.bidPrice}_BidAmount_${index}`}
                        >
                          {data.bidValue.toFixed(2)}
                        </ValueTableCell>
                        <TableCell
                          sx={{ width: "121px" }}
                          key={`${data.bidPrice}_Situation_${index}`}
                        >
                          <label style={{ color: "rgb(255,156,10)" }}>
                            {createStatusText(data.contractStatus)}
                          </label>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
              }
            }
          })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

const TypeSelectList = (props: any) => {
  const { tradingTypeId, TradingTypeHandleChange } = props;
  const languageContext = useContext(LanguageContext);
  return (
    <CustomSelectList
      label={languageContext.words.salebuy_type}
      value={tradingTypeId}
      options={[
        { id: 0, name: languageContext.words.all },
        { id: 1, name: languageContext.words.sell },
        { id: 2, name: languageContext.words.buy },
      ]}
      onChange={TradingTypeHandleChange}
      sx={{ marginY: "-8px" , marginX: "8px" }}
    />
  );
};

const TimeSelectList = (props: any) => {
  const {
    firstTimeNumber,
    FirstTimeHandleChange,
    lastTimeNumber,
    LastTimeHandleChange,
    TimeList,
  } = props;

  return (
    <Stack direction="row" sx={{ marginY: "-8px" }}>
      <CustomSelectList 
        label={""}
        value={firstTimeNumber}
        options={TimeList()}
        onChange={FirstTimeHandleChange}
      />
      <label style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>～</label>
      <CustomSelectList
        label={""}
        value={lastTimeNumber}
        options={TimeList()}
        onChange={LastTimeHandleChange}
      />
    </Stack>
  );
};

//一括削除のモーダル
const BulkUndo = (
  open: boolean,
  HaldleClose: () => void,
  validTime: number,
  validTimeList: string[],
  dayBit: BidingCell[],
  areaInfo: ariaBitCell[],
  targetDate: string,
  areaId: string,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  getIntradayData: () => Promise<void>,
  getAutoSetting: () => Promise<void>,
  hidden: boolean,
  createTypeText: (type: string) => string,
  createStatusText: (status: string) => string,
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const dateContext = useContext(DateContext);

  const api = useAuthedApi();

  //時間のセレクトボックスの生成
  const TimeList = () => {
    const timeDataList = [];
    const editableTableList = validTimeList;
    let count = 0;
    for (let i = 0; i < editableTableList.length; i++) {
      if (editableTableList[i] === "rgb(255,255,255)") {
        timeDataList[count] = {
          id: i,
          name: `${timeData[i]}～${timeData[i + 1]}`,
        };
        count++;
      }
    }
    return timeDataList;
  };

  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  //販売種別のセレクトボックス
  const [tradingType, setTradingType] = useState<string>(
    languageContext.words.all
  );
  const [tradingTypeId, setTradingTypeId] = useState<number>(0);
  //始めの時間のセレクトボックス
  const [firstTimeNumber, setFirstTimeNumber] =
    useState<number>(validTime);
  const [firstTime, setFirstTime] = useState<string>("00:00 ～ 00:30");
  //終わりの時間のセレクトボックス
  const [lastTimeNumber, setLastTimeNumber] = useState<number>(47);
  const [lastTime, setLastTime] = useState<string>("23:30 ～ 00:00");
  //親チェックボックスのチェックの管理
  const [parentBoxChecked, setParentBoxChecked] =
    useState<boolean>(false);
  //親チェックボックスの未選択要素があるときの表示
  const [parentBoxIndeterminate, setParentBoxIndeterminate] =
    useState<boolean>(false);
  //チェックボックスのチェックの管理
  const boxCheckedList = new Array(48).fill(0).map(() => false);
  const [boxChecked, setBoxChecked] = useState<boolean[]>(boxCheckedList);

  useEffect(() => {
    setFirstTimeNumber(validTime);
  }, [dateContext.pickDate, open, validTime]);

  //時間の文字列
  const CreateTimeText = () => {
    const timeDataList = [];
    for (let i = 0; i < validTimeList.length; i++) {
      timeDataList[i] = `${timeData[i]}～${timeData[i + 1]}`;
    }
    return timeDataList;
  };

  //販売種別のセレクトボックス
  const TradingTypeHandleChange = (selectItem: number) => {
    setTradingType(createBuysellType(selectItem));
    setTradingTypeId(selectItem);
    let initialFirstTimetNumber = 0;
    initialFirstTimetNumber = validTime;
    if (firstTimeNumber === 0) {
      setFirstTimeNumber(initialFirstTimetNumber);
    }
    //親のチェックボックスの表示形態の処理
    let isAllBoxChecked = false;
    let isParentBoxIndeterminate = false;
    let confirmedNumber = 0;
    let checkedBoxCount = 0;
    for (let i = 0; i < dayBit.length; i++) {
      if(firstTimeNumber <= Number(dayBit[i].timeCd) - 1 && Number(dayBit[i].timeCd) - 1 <= lastTimeNumber) {
        const typeText = createTypeText(dayBit[i].type);
        if (
          createBuysellType(selectItem) === typeText ||
          createBuysellType(selectItem) === languageContext.words.all
        ) {
          if (boxChecked[i] === true) {
            checkedBoxCount++;
          }
          confirmedNumber++;
        }
      }
    }

    if (confirmedNumber === 0 && checkedBoxCount === 0) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    } else if (confirmedNumber === checkedBoxCount) {
      isAllBoxChecked = true;
      isParentBoxIndeterminate = false;
    } else if (0 < checkedBoxCount) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = true;
    } else {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    }
    setParentBoxIndeterminate(isParentBoxIndeterminate);
    setParentBoxChecked(isAllBoxChecked);
  };

  //時間IDから時間文字列を作成
  const createTime = (timeId: number) => {
    let timeName = "";
    TimeList().forEach((time) => {
      if (time.id === timeId) {
        timeName = time.name;
      }
    });
    return timeName;
  };

  //始めの時間のセレクトボックスのハンドル
  const FirstTimeHandleChange = (selectItem: number) => {
    setFirstTime(createTime(selectItem));
    // const selectFirstTimeNumber = CreateTimeText().indexOf(
    //   createTime(selectItem)
    // );
    const selectFirstTimeNumber = selectItem;
    setFirstTimeNumber(selectFirstTimeNumber);
    //親のチェックボックスの表示形態の処理
    let isAllBoxChecked = false;
    let isParentBoxIndeterminate = false;
    let confirmedNumber = 0;
    let checkedBoxCount = 0;
    for (let i = selectFirstTimeNumber; i <= lastTimeNumber; i++) {
      const typeText = createTypeText(dayBit[i].type);
      if (
        tradingType === typeText ||
        tradingType === languageContext.words.all
      ) {
        if (boxChecked[i] === true) {
          checkedBoxCount++;
        }
        confirmedNumber++;
      }
    }
    if (confirmedNumber === 0 && checkedBoxCount === 0) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    } else if (confirmedNumber === checkedBoxCount) {
      isAllBoxChecked = true;
      isParentBoxIndeterminate = false;
    } else if (0 < checkedBoxCount) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = true;
    } else {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    }
    setParentBoxIndeterminate(isParentBoxIndeterminate);
    setParentBoxChecked(isAllBoxChecked);
  };

  //終わりの時間のセレクトボックスのハンドル
  const LastTimeHandleChange = (selectItem: number) => {
    setLastTime(createTime(selectItem));
    const selectLastTimeNumber = CreateTimeText().indexOf(
      createTime(selectItem)
    );
    setLastTimeNumber(selectLastTimeNumber);
    //親のチェックボックスの表示形態の処理
    let isAllBoxChecked = false;
    let isParentBoxIndeterminate = false;
    let confirmedNumber = 0;
    let checkedBoxCount = 0;
    for (let i = firstTimeNumber; i <= selectLastTimeNumber; i++) {
      const typeText = createTypeText(dayBit[i].type);
      if (
        tradingType === typeText ||
        tradingType === languageContext.words.all
      ) {
        if (boxChecked[i] === true) {
          checkedBoxCount++;
        }
        confirmedNumber++;
      }
    }
    if (confirmedNumber === 0 && checkedBoxCount === 0) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    } else if (confirmedNumber === checkedBoxCount) {
      isAllBoxChecked = true;
      isParentBoxIndeterminate = false;
    } else if (0 < checkedBoxCount) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = true;
    } else {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    }
    setParentBoxIndeterminate(isParentBoxIndeterminate);
    setParentBoxChecked(isAllBoxChecked);
  };

  //親のチェックボックス押下時の処理
  const handleSelectAllClick = () => {
    const checkBoxList = Object.assign({}, boxChecked); //子供のチェックボックスのチェック判定(仮保持)
    let parentBoxCheck = false; //親のチェックボックスのチェック判定(仮保持)
    if (parentBoxChecked === true) {
      for (let i = 0; i < dayBit.length; i++) {
        if(firstTimeNumber <= Number(dayBit[i].timeCd) - 1 && Number(dayBit[i].timeCd) - 1 <= lastTimeNumber) {
          const typeText = createTypeText(dayBit[i].type);
          if (
            typeText !== "" &&
            (tradingType === typeText ||
              tradingType === languageContext.words.all)
          ) {
            checkBoxList[i] = false;
            parentBoxCheck = false;
          } else if (tradingType === "") {
            parentBoxCheck = false;
            break;
          }
        }
      }
    } else if (parentBoxChecked === false) {
      for (let i = 0; i < dayBit.length; i++) {
        if(firstTimeNumber <= Number(dayBit[i].timeCd) - 1 && Number(dayBit[i].timeCd) - 1 <= lastTimeNumber) {        
          const typeText = createTypeText(dayBit[i].type);
          if (
            typeText !== "" &&
            (tradingType === typeText ||
              tradingType === languageContext.words.all)
          ) {
            checkBoxList[i] = true;
            parentBoxCheck = true;
          } else if (tradingType === "") {
            parentBoxCheck = false;
            break;
          }
        }
      }
    }
    setBoxChecked(checkBoxList);
    setParentBoxChecked(parentBoxCheck);
    setParentBoxIndeterminate(false);
  };

  //チェックボックスをクリックした時の処理
  const handleClick = (checkedBoxNumber: number) => {
    const checkBoxList = Object.assign({}, boxChecked);
    if (boxChecked[checkedBoxNumber] === true) {
      checkBoxList[checkedBoxNumber] = false;
    } else if (boxChecked[checkedBoxNumber] === false) {
      checkBoxList[checkedBoxNumber] = true;
    } else {
      // console.log("else");
    }
    setBoxChecked(checkBoxList);
    //親のチェックボックスの表示形態の判定
    let isAllBoxChecked = false;
    let isParentBoxIndeterminate = false;
    let confirmedNumber = 0;
    let checkedBoxCount = 0;
    for (let i = 0; i < dayBit.length; i++) {
      if(firstTimeNumber <= Number(dayBit[i].timeCd) - 1 && Number(dayBit[i].timeCd) - 1 <= lastTimeNumber) {
        const typeText = createTypeText(dayBit[i].type);
        if (
          tradingType === typeText ||
          tradingType === languageContext.words.all
        ) {
          if (checkBoxList[i] === true) {
            checkedBoxCount++;
          }
          confirmedNumber++;
        }
      } 
    }
    if (confirmedNumber === 0 && checkedBoxCount === 0) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    } else if (confirmedNumber === checkedBoxCount) {
      isAllBoxChecked = true;
      isParentBoxIndeterminate = false;
    } else if (0 < checkedBoxCount) {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = true;
    } else {
      isAllBoxChecked = false;
      isParentBoxIndeterminate = false;
    }
    setParentBoxIndeterminate(isParentBoxIndeterminate);
    setParentBoxChecked(isAllBoxChecked);
  };

  //モーダルを閉じる処理
  const ModalCloseEvent = () => {
    setTradingType(languageContext.words.all);
    setTradingTypeId(0);
    setFirstTime("00:00 ～ 00:30");
    setLastTime("23:00 ～ 00:00");
    setFirstTimeNumber(validTime);
    setLastTimeNumber(47);
    setParentBoxChecked(false);
    setParentBoxIndeterminate(false);
    setBoxChecked(boxCheckedList);
    HaldleClose();
  };

  //取り消す商品のステータスを取消要求状態に書き換える
  async function updateDeleteStatus (reqBidInfo: BidingCell[]): Promise<string> {
    return api
      .post(POST_BULK_DELTE_DATA, {
        itemIdArray: reqBidInfo.map((v) => v.timeCd),
        tenderNumArray: reqBidInfo.map((v) => v.tenderNum),
        targetDate: targetDate,
        areaId: areaId,
      })
      .then((res) => {
        return Promise.resolve(res.data.resultCode);
      })
      .catch((err) => {
        setDialogMessage(
          languageContext.words.intraday_bulk_cancellation_failed
        );
        setDigOpen(true);
      });
  };

  //入札削除APIを呼び出す
  const deleteBidIntraday = async (reqBidInfo: BidingCell[]) => {
    await api
      .get(BULK_DELETE, {
        params: {
          itemIdArray: reqBidInfo.map((v) => v.timeCd),
          tenderNumArray: reqBidInfo.map((v) => v.tenderNum),
          targetDate: targetDate,
          areaId: areaId,
        },
      })
      .then((res) => {
        setDialogMessage(
          languageContext.words.intraday_excute_bulk_cancellation
        );
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === ERR_CODE_SOAP_ERR) {
          setDialogMessage(
            languageContext.words.could_not_communicate_jepx
          );
        } else {
          setDialogMessage(
            languageContext.words.intraday_bulk_cancellation_failed
          );
        }
      });
    setDigOpen(true);
  };

  //取消ボタンの処理
  const CancelButtonEvent = async () => {
    const reqBidInfo: BidingCell[] = [];
    for (let i = 0; i < dayBit.length; i++) {
      if (boxChecked[i] === true && 
        (tradingTypeId === Number(dayBit[i].type) ||
        tradingTypeId === 0) && 
        firstTimeNumber <= Number(dayBit[i].timeCd) - 1 && 
        Number(dayBit[i].timeCd) - 1 <= lastTimeNumber) {
        reqBidInfo.push({
          timeCd: dayBit[i].timeCd,
          tenderNum: dayBit[i].tenderNum,
          type: dayBit[i].type,
          bidValue: dayBit[i].bidValue,
          contractValue: dayBit[i].contractValue,
          bidPrice: dayBit[i].bidPrice,
          contractPrice: dayBit[i].contractPrice,
          contractStatus: dayBit[i].contractStatus,
        });
      }
    }
    setIsLoaded(false);
    const resultCode = await updateDeleteStatus(reqBidInfo);
    if(resultCode === RESULT_CODE.SUCCESS) {      
      await deleteBidIntraday(reqBidInfo);
    }
    Promise.all([
      getIntradayData(),
      getAutoSetting(),
    ]).finally(() => {
      setIsLoaded(true);
    });
    setTradingType(languageContext.words.all);
    setTradingTypeId(0);
    setFirstTime("00:00 ～ 00:30");
    setLastTime("23:00 ～ 00:00");
    setFirstTimeNumber(validTime);
    setLastTimeNumber(47);
    setParentBoxChecked(false);
    setParentBoxIndeterminate(false);
    setBoxChecked(boxCheckedList);
    HaldleClose();
  };

  //売買種別IDから売買種別を作成
  const createBuysellType = (typeId: number) => {
    let typeName = "";
    if (typeId === 0) {
      typeName = languageContext.words.all;
    } else if (typeId === 1) {
      typeName = languageContext.words.sell;
    } else if (typeId === 2) {
      typeName = languageContext.words.buy;
    }
    return typeName;
  };

  //一括取消
  return (
    <>
      <CustomModal
        hidden={hidden}
        title={languageContext.words.bulk_revocation}
        open={open}
        onAccept={() => CancelButtonEvent()}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.execute_bid_cancellation}
      >
        <Box sx={{ height: "400px", width: "630px" }}>
          <Stack
            spacing={5}
            direction="row"
            sx={{ height: "20%", width: "100%", margin: "0px 0px 10px 0px" }}
          >
            <Box>
              <label
                style={{
                  width: "100%",
                  color: "rgb(150, 150, 150)",
                  fontSize: "14px",
                }}
              >
                {languageContext.words.salebuy_type}
              </label>
              <Box sx={{ marginY: "-8px" }}>
                <TypeSelectList
                  tradingTypeId={tradingTypeId}
                  TradingTypeHandleChange={TradingTypeHandleChange}
                />
              </Box>
            </Box>
            <Box>
              <label
                style={{
                  width: "100%",
                  color: "rgb(150, 150, 150)",
                  fontSize: "14px",
                }}
              >
                {languageContext.words.bidding_time_zone}
              </label>
              <TimeSelectList
                firstTimeNumber={firstTimeNumber}
                FirstTimeHandleChange={FirstTimeHandleChange}
                lastTimeNumber={lastTimeNumber}
                LastTimeHandleChange={LastTimeHandleChange}
                TimeList={TimeList}
              />
            </Box>
          </Stack>
          <BulkUndoTableRender
            handleSelectAllClick={handleSelectAllClick}
            parentBoxChecked={parentBoxChecked}
            parentBoxIndeterminate={parentBoxIndeterminate}
            dayBit={dayBit}
            validTime={validTime}
            firstTimeNumber={firstTimeNumber}
            lastTimeNumber={lastTimeNumber}
            tradingType={tradingType}
            boxChecked={boxChecked}
            handleClick={handleClick}
            areaInfo={areaInfo}
            createTypeText={createTypeText}
            createStatusText={createStatusText}
          />
        </Box>
      </CustomModal>
      <CustomModalProgress open={!isLoaded} />
    </>
  );
};
export default BulkUndo;
