import React, { useState, useEffect, useContext } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { light } from "@mui/material/styles/createPalette";
import { isBooleanObject } from "util/types";
import { FormHelperText } from "@mui/material";
import {
  ConfirmLeavePage,
  LeavePageContext,
  LeavePageContextType,
} from "../CustomConfirmLeavePage";
import { PaletteContext } from "../../paletteMode";
import { LanguageContext } from "../../localization/localization";

const CustomSelectListBig = (props: any) => {
  const {
    label,
    value,
    options,
    onChange,
    disabled,
    error,
    digMessage,
  } = props;
  const { words } = useContext(LanguageContext);
  const dialogMessage: string = digMessage
    ? digMessage
    : words.setting_change_selections_message;
  const [selectValue, setSelectValue] = useState<string>(value ?? "");
  const [digOpen, setDigOpen] = useState(false);
  const [changeVal, setChangeVal] = useState<string>(value ?? "");
  const { blockLeave, setBlockLeave } =
    useContext<LeavePageContextType>(LeavePageContext);
  const palletContextBase = useContext(PaletteContext);
  const [errorStatus, setErrorStatus] = useState<boolean>(false); //セレクトリストにエラー属性を付与するか否かを管理する

  useEffect(() => {
    setSelectValue(value === "" ? label : value);
    setChangeVal(value === "" ? label : value);
  }, [label, value]);

  useEffect(() => {
    if (blockLeave) {
      setDigOpen(true);
    } else {
      setSelectValue(changeVal);
      onChange(parseInt(changeVal));
      setBlockLeave(false);
    }
  }, [changeVal]);

  const handleChange = (e: SelectChangeEvent) => {
    setChangeVal(e.target.value as string);
    if (blockLeave) {
      setDigOpen(true);
    } else {
      setSelectValue(changeVal);
      onChange(parseInt(e.target.value) as number);
      setBlockLeave(false);
    }
  };

  const acceptHandler = () => {
    setSelectValue(changeVal);
    onChange(parseInt(changeVal));
    setBlockLeave(false);
  };

  // if (options[0].name !== label) {
  //   options.unshift(label);
  // }

  //モードによってボタンの背景変化するように,あとテキストカラーも
  return (
    <>
      <FormControl
        color="primary"
        variant="outlined"
        sx={{
          marginLeft: "0px",
          marginRight: "8px",
          marginTop: "0px",
        }}
      >
        <Select
          error={error === undefined ? false : error}
          value={selectValue}
          onChange={handleChange}
          onClick={() => {
            if (blockLeave) {
              setDigOpen(true);
            } else {
              setSelectValue(changeVal);
              onChange(parseInt(changeVal));
              setBlockLeave(false);
            }
          }}
          inputProps={{ "aria-label": "Without label" }}
          disabled={Boolean(disabled)}
          sx={{
            width: "267px",
            fontSize: "14px",
            height: "43px",
          }}
        >
          {options.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
        message={dialogMessage}
      />
    </>
  );
};

export default CustomSelectListBig;
