// 需要調達計画
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useRef,
} from "react";
import { ClipboardEvent } from "react";
import { format, isBefore } from "date-fns";

import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import Typography from "@mui/material/Typography";
import { StyledButton } from "../../common/styledComponents/styledButton";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import { cellTime } from "../../../common/cellTime";

import * as DfpType from "../../../types/occto/DemandForecastPlan";

import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../../common/area";
import { useVoltageLevelUtility } from "../../../common/voltageLevel";
import { AREA_ID, BUSINESS_MODEL } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { useLocation } from "react-router-dom";
import {
  SalesTableRender,
  ProviderTableRender,
} from "../PowerForecast/PowerForecastPlanTableRender";
import * as PlanType from "../../../types/occto/Common";
import { Category } from "@mui/icons-material";
import { changeDateToTimeCd } from "../../common/changeDateCd";
import { autoScrollPage } from "../../common/ScrollPage";
import { BusinessModelContext } from "../../common/customComponents/BusinessModelProvider";

// API URL
// エリア一覧
const AREA_LIST_URL = "/getDemandAreaNameList";

// データ取得
const GET_DEMAND_TRAN = "/demandPlan/getTransactionPlan"; // 取引計画
const GET_DEMAND_PLAN = "/demandPlan/getDemnadPlan"; // 需要計画
const GET_PROVIDE_PLAN = "/demandPlan/getProvidePlan"; // 調達計画
const GET_SUPPLY_PLAN = "/demandPlan/getSupplyPlan"; // 販売計画
const GET_DEMAND_HEADER = "/demandPlan/getDemandHeader"; // 計画ヘッダ

// データ登録
const SET_DEMAND_PLAN = "/demandPlan/setDemnadPlan";
// const SET_SUPPLY_PLAN = "/demandPlan/setSupplyPlan";
// const SET_PROVIDE_PLAN = "/demandPlan/setProvidePlan";

// 複写用契約情報取得
const GET_DEMAND_SALEDETAIL_URL = "/getPowerForecastPlanSale"; // 販売計画

// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan";

// テーブル行数
const CELLCOUNT = 48;

/**
 * 需要・調達計画画面
 * @returns
 */
const DemandForecastPlan = () => {
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // エリアコンテキスト
  const { areaId, setAreaId } = useContext(AreaContext);
  // サーバ時刻
  const timeContext = useContext(TimeContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  // データ入力感知
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  // 描画監視
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [digOpen, setDigOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpenOkCancel, setDigOpenOkCancel] = useState<boolean>(false);
  const [digMessageOkCancel, setDigMessageOkCancel] = useState<string>("");

  // タブによるテーブルの切替
  const DemandTable = 2;
  const ProcurementTable = 3;
  const SalesTable = 4;
  const [tableType, setTabletype] = useState<number>(DemandTable);

  // 通信エラーを監視
  const api = useAuthedApi();

  const uchiwakeCellH = 53;
  const ikinaiGrCellH = 83;
  const marketGrCellH = 27;
  const uchiwakeCellW = 124;

  // セレクトリスト
  const [areas, setAreas] = useState<string[]>([]); //エリア一覧
  // ビジネスモデルコンテキスト
  const { businessModel } = useContext(BusinessModelContext);

  // 変換Utility
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  const { convertIdToVolLevelName } = useVoltageLevelUtility();

  // 取引計画テーブル用テーブルヘッダ定義
  const TABLE_DEFINE = [
    { label: languageContext.words.demand_plan, key: "survice" },
    { label: languageContext.words.procurement_plan, key: "demandGeneration" },
    { label: languageContext.words.sales_plan, key: "supply" },
    { label: languageContext.words.shortage_of_procurement, key: "sale" },
  ];

  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();

  // 計画総計テーブル表示
  const [summaryData, setSummaryData] = useState<DfpType.dfpSummary[]>([]);

  // 取引計画列情報
  const [tranPlanItems, setTranPlanItems] = useState<
    DfpType.transactionPlanList[]
  >([]);

  // タブ別テーブル表示情報
  // 需要計画
  const [dfpDemandItems, setDfpDemandItems] = useState<DfpType.powerPlanList[]>(
    []
  );

  // 調達計画
  // 自己託送
  const [dfpSelfProcurementItems, setDfpSelfProcurementItems] = useState<
    PlanType.midAreaList[]
  >([]);
  // スポット複写用生データ
  // スポット表示用サマリデータ
  const [resDfpSpotProcurementItems, setResDfpSpotProcurementItems] = useState<
    PlanType.midAreaList[]
  >([]);
  const [dfpSpotProcurementItems, setDfpSpotProcurementItems] = useState<
    PlanType.midAreaList[]
  >([]);
  // 1時間前複写用生データ
  // 1時間前表示用サマリデータ
  const [resDfpHourProcurementItems, setResDfpHourProcurementItems] = useState<
    PlanType.midAreaList[]
  >([]);
  const [dfpHourProcurementItems, setDfpHourProcurementItems] = useState<
    PlanType.midAreaList[]
  >([]);

  // 販売計画
  // スポット複写用生データ
  // スポット表示用サマリデータ
  const [resDfpSalesSpotItems, setResDfpSalesSpotItems] = useState<
    PlanType.rightAreaList[]
  >([]);
  const [dfpSalesSpotItems, setDfpSalesSpotItems] = useState<
    PlanType.rightAreaList[]
  >([]);
  // 1時間前複写用生データ
  // 1時間前表示用サマリデータ
  const [resDfpSalesHourItems, setResDfpSalesHourItems] = useState<
    PlanType.rightAreaList[]
  >([]);
  const [dfpSalesHourItems, setDfpSalesHourItems] = useState<
    PlanType.rightAreaList[]
  >([]);

  // オートスクロール制御用
  const [scrollFlag, setScrollFlag] = useState<boolean>(false);

  //現在時刻をコマの番号に変換
  const convertTimeToCd = (time: Date) => {
    let timeCd = 0;
    cellTime.forEach((value, index) => {
      if (
        time.toLocaleTimeString().slice(0, -3).padStart(5, "0") >= value &&
        index !== 48
      ) {
        timeCd = index;
      }
    });
    return timeCd;
  };

  const jepxDispFlag = React.useRef(false);

  //saletype --スポットの時に当日だったら非活性にする用
  const isDisabled = (index: number, type: string) => {
    const timeCd = convertTimeToCd(timeContext.time);
    //スポット
    if (type === "spot") {
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd")
      ) {
        return true;
      }
      return false;
    } else {
      //スポット以外
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        (format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd") &&
          timeCd + 2 >= index)
      ) {
        return true;
      }
      return false;
    }
  };

  // 初期検索条件の取得
  useEffect(() => {
    // 初期検索
    initAreaList();
    dicideSaveButtonVisibility();
    leavePageContext.setBlockLeave(false);
  }, []);

  // 計画総計テーブル表示更新
  useEffect(() => {
    if (
      summaryData === null ||
      summaryData === undefined ||
      summaryData.length === 0
    ) {
      const tempList: DfpType.dfpSummary[] = new Array(CELLCOUNT)
        .fill(true)
        .map((_, index) => ({
          timeCd: `${cellTime[index]} ～ ${cellTime[index + 1]}`,
          demand: 0,
          procurement: 0,
          sale: 0,
          procurementShortage: 0,
        }));
      setSummaryData(tempList);
      setScrollFlag(true);
    }
  }, []);

  // 各タブ計画値取得
  useEffect(() => {
    setIsLoaded(false);
    autoScrollMethod();
    // 日付、またはエリアが変更になった
    if (areaId !== AREA_ID.NONE) {
      getDemandTran();
      getDemandHd();
      getDemandPlan();
      getProvidePlan();
    }
  }, [areaId, dateContext]);

  // 計画総計テーブル表示更新
  useEffect(() => {
    ConstantDataTables();
  }, [
    dfpDemandItems,
    dfpSelfProcurementItems,
    dfpSpotProcurementItems,
    dfpHourProcurementItems,
    dfpSalesSpotItems,
    dfpSalesHourItems,
    ignored,
  ]);

  useEffect(() => {
    // 初期表示時のみ有効
    if (scrollFlag) {
      autoScrollMethod();
      setScrollFlag(false);
    }
  }, [summaryData]);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // オートスクロール
  const autoScrollMethod = () => {
    if (
      format(dateContext.pickDate as Date, "yyy/MM/dd") ===
      format(timeContext.time as Date, "yyy/MM/dd")
    ) {
      autoScrollPage(
        "demandForecastPlanTimeCell" + changeDateToTimeCd(timeContext.time)
      );
    }
  };

  // エリアプルダウン初期表示
  const initAreaList = () => {
    setAreas(
      businessModel
        .filter(
          (model) =>
            model.businessModel === BUSINESS_MODEL.IKINAI ||
            model.businessModel === BUSINESS_MODEL.IKIGAI_DEMAND ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN
        )
        .map((v) => v.areaId)
    );
  };

  // 取引計画取得
  const getDemandTran = () => {
    const requestParam: DfpType.reqDemandForecastPlanTransaction = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_DEMAND_TRAN, { params: requestParam })
      .then((res) => {
        if (res.data.result !== undefined || res.data.result !== null) {
          res.data.result.transactionPlanList.forEach(
            (element: DfpType.transactionPlanList) => {
              if (element.demand === null) {
                element.demand = 0;
              }
              if (element.procurement === null) {
                element.procurement = 0;
              }
              if (element.sale === null) {
                element.sale = 0;
              }
              if (element.procurementShortage === null) {
                element.procurementShortage = 0;
              }
            }
          );
          if (res.data.result.transactionPlanList.length == 0) {
            const tempList: DfpType.resDemandForecastPlanTransaction = {
              areaId: areaId,
              targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
              transactionPlanList: new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  demand: 0,
                  procurement: 0,
                  sale: 0,
                  procurementShortage: 0,
                })),
            };
            setTranPlanItems(tempList.transactionPlanList);
          } else {
            setTranPlanItems(res.data.result.transactionPlanList);
          }
        } else {
          const tempList: DfpType.resDemandForecastPlanTransaction = {
            areaId: areaId,
            targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
            transactionPlanList: new Array(CELLCOUNT)
              .fill(true)
              .map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                demand: 0,
                procurement: 0,
                sale: 0,
                procurementShortage: 0,
              })),
          };
          setTranPlanItems(tempList.transactionPlanList);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 計画ヘッダ取得
  const getDemandHd = () => {
    const requestParam: DfpType.reqDemandForecastPlanHeader = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_DEMAND_HEADER, { params: requestParam })
      .then((res) => {
        if (
          res.data.result !== undefined &&
          res.data.result.lastUpdate !== undefined &&
          res.data.result.lastUpdateUser !== undefined &&
          res.data.result.lastUpdate !== null &&
          res.data.result.lastUpdateUser !== null
        ) {
          setLastUpdateInfo({
            date: new Date(res.data.result.lastUpdate),
            user: res.data.result.lastUpdateUser,
          });
        } else {
          setLastUpdateInfo(undefined);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 需要計画取得
  function getDemandPlan() {
    const requestParam: DfpType.reqDemandForecastPlanPower = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    // 需要一覧の取得
    api
      .get(GET_DEMAND_PLAN, { params: requestParam })
      .then((res) => {
        if (
          res.data.result !== undefined ||
          res.data.result.demandPlan.length !== 0
        ) {
          res.data.result.demandPlan.forEach(
            (element: DfpType.powerPlanList) => {
              if (
                element.valueList === undefined ||
                element.valueList.length === 0
              ) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            }
          );
          setDfpDemandItems(res.data.result.demandPlan);
        } else {
          setDfpDemandItems([]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoaded(true);
      });
  }

  // 調達計画取得(発電所、JEPX)
  function getProvidePlan() {
    // 調達計画の取得
    const requestParam: DfpType.reqDemandForecastPlanProvide = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PROVIDE_PLAN, { params: requestParam })
      .then((res) => {
        // 自己託送
        // 計画値の取得(調達計画のデータがない場合は表示できない)
        if (
          res.data.result.selfConsignmentList !== undefined &&
          res.data.result.selfConsignmentList.length !== 0
        ) {
          const tempProvideSelfItem: PlanType.midAreaList[] = [];
          res.data.result.selfConsignmentList.forEach((element: any) => {
            if (element.valueList.length == 0) {
              element.valueList = new Array(48).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              }));
            }
            tempProvideSelfItem.push({
              demandId: element.demandId,
              powerId: element.powerId,
              customerName: element.powerName,
              areaId: element.areaId,
              saleId: element.saleId,
              saleGroupId: element.saleGroupId,
              salePlanKind: element.salePlanKind,
              marketKind: element.marketKind,
              valueList: element.valueList,
            });
          });
          setDfpSelfProcurementItems(tempProvideSelfItem);
        } else {
          // TODO エラーメッセージ
          setDfpSelfProcurementItems([]);
        }
        // スポット
        if (
          res.data.result.spotList !== undefined &&
          res.data.result.spotList.length !== 0
        ) {
          jepxDispFlag.current = true;
          const tempProvideSpotItem: PlanType.midAreaList[] = [];
          res.data.result.spotList.forEach((element: any) => {
            if (element.valueList.length === 0) {
              element.valueList = new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  value: 0,
                }));
            }
            tempProvideSpotItem.push({
              demandId: element.demandId,
              powerId: element.powerId,
              customerName: element.powerName,
              areaId: element.areaId,
              saleId: element.saleId,
              saleGroupId: element.saleGroupId,
              salePlanKind: element.salePlanKind,
              marketKind: element.marketKind,
              valueList: element.valueList,
            });
          });
          setResDfpSpotProcurementItems(tempProvideSpotItem);
          const uniqSpotProcurementItems = structuredClone(
            Array.from(
              new Map(
                tempProvideSpotItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqSpotProcurementItems);
          setDfpSpotProcurementItems(
            //---------- step2では需要調達は市場からの調達を行わないため絞り込み(将来的に削除予定)----------
            uniqSpotProcurementItems.filter(
              (item: any) => item.powerId !== 0 && item.demandId !== 0
            )
            //------------------------------------------------------------------------
          );
        } else {
          jepxDispFlag.current = false;
          setDfpSpotProcurementItems([]);
        }
        // 1時間前
        if (
          res.data.result.oneHourList !== undefined &&
          res.data.result.oneHourList.length !== 0
        ) {
          jepxDispFlag.current = true;
          const tempProvideHourItem: PlanType.midAreaList[] = [];
          res.data.result.oneHourList.forEach((element: any) => {
            if (element.valueList.length === 0) {
              element.valueList = new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  value: 0,
                }));
            }
            tempProvideHourItem.push({
              demandId: element.demandId,
              powerId: element.powerId,
              customerName: element.powerName,
              areaId: element.areaId,
              saleId: element.saleId,
              saleGroupId: element.saleGroupId,
              salePlanKind: element.salePlanKind,
              marketKind: element.marketKind,
              valueList: element.valueList,
            });
          });
          setResDfpHourProcurementItems(tempProvideHourItem);
          const uniqHourProcurementItems = structuredClone(
            Array.from(
              new Map(
                tempProvideHourItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqHourProcurementItems);
          setDfpHourProcurementItems(
            //---------- step2では需要調達は市場からの調達を行わないため絞り込み(将来的に削除予定)----------
            uniqHourProcurementItems.filter(
              (item: any) => item.powerId !== 0 && item.demandId !== 0
            )
            //------------------------------------------------------------------------
          );
        } else {
          jepxDispFlag.current = false;
          setDfpHourProcurementItems([]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoaded(true);
      });
  }

  // 販売計画取得(JEPXのみ)
  // step2ビジネスモデルでは対象外
  function getSupplyPlan() {
    const requestParam: DfpType.reqDemandForecastPlanSale = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_SUPPLY_PLAN, { params: requestParam })
      .then((res) => {
        // 販売計画での事業者間やり取りはなし
        if (
          res.data.result.oneHourList !== undefined &&
          res.data.result.oneHourList.length !== 0
        ) {
          const tempSaleSpotItem: PlanType.rightAreaList[] = [];
          jepxDispFlag.current = true;
          res.data.result.spotList.forEach(
            (element: PlanType.rightAreaList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((e: any) => ({ timeCd: "", value: 0 }));
              }
              tempSaleSpotItem.push({
                demandId: element.demandId,
                powerId: element.powerId,
                customerName: element.customerName,
                areaId: element.areaId,
                saleId: element.saleId,
                saleGroupId: element.saleGroupId,
                salePlanKind: element.salePlanKind,
                marketKind: element.marketKind,
                valueList: element.valueList,
              });
            }
          );
          setResDfpSalesSpotItems(tempSaleSpotItem);
          const uniqSalesSpotItems = structuredClone(
            Array.from(
              new Map(
                tempSaleSpotItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqSalesSpotItems);
          setDfpSalesSpotItems(uniqSalesSpotItems);
        } else {
          jepxDispFlag.current = false;
          setDfpSalesSpotItems([]);
        }

        if (
          res.data.result.oneHourList !== undefined &&
          res.data.result.oneHourList.length !== 0
        ) {
          const tempSaleHourItem: PlanType.rightAreaList[] = [];
          jepxDispFlag.current = true;
          res.data.result.oneHourList.forEach(
            (element: PlanType.rightAreaList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((e: any) => ({ timeCd: "", value: 0 }));
              }
              tempSaleHourItem.push({
                demandId: element.demandId,
                powerId: element.powerId,
                customerName: element.customerName,
                areaId: element.areaId,
                saleId: element.saleId,
                saleGroupId: element.saleGroupId,
                salePlanKind: element.salePlanKind,
                marketKind: element.marketKind,
                valueList: element.valueList,
              });
            }
          );
          setResDfpSalesHourItems(tempSaleHourItem);
          const uniqSalesHourItems = structuredClone(
            Array.from(
              new Map(
                tempSaleHourItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqSalesHourItems);
          setDfpSalesHourItems(uniqSalesHourItems);
        } else {
          jepxDispFlag.current = false;
          setDfpSalesHourItems([]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setIsLoaded(true);
      });
  }

  // 保存ボタン押下
  const OnAccept = () => {
    if (isDivfifty()) {
      setDemandPlan();
    } else {
      setDigMessageOkCancel(languageContext.words.save_check);
      setDigOpenOkCancel(true);
    }
  };

  const isDivfifty = () => {
    let flagDivFifty = true;
    const cells = new Array(CELLCOUNT).fill(0);
    cells.map((cell, index) => {
      //調達計画のスポット計画に50kWhで割り切れない数があるかチェック
      dfpSpotProcurementItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一時間前計画に50kWhで割り切れない数があるかチェック
      dfpHourProcurementItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一スポット計画に50kWhで割り切れない数があるかチェック
      dfpSalesSpotItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一時間前計画に50kWhで割り切れない数があるかチェック
      dfpSalesHourItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
    });

    return flagDivFifty;
  };

  // 需調計画保存
  function setDemandPlan() {
    setIsLoaded(false);
    // 保存処理
    // 需要家別需要計画

    // 販売グループ別調達計画

    // 販売グループ取引達計画

    const tmpDemandPlan: DfpType.postDemandPlanList[] = [];
    for (let i = 0; i < dfpDemandItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        tmpDemandPlan.push({
          demandId: dfpDemandItems[i].demandId,
          timeCd: dfpDemandItems[i].valueList[j].timeCd,
          demandQu: dfpDemandItems[i].valueList[j].value as number,
          dataChgCd: dfpDemandItems[i].valueList[j].dataChgCd,
        });
      }
    }

    // 調達計画("自己託送",スポット、1時間前)
    const tmpProvidePlan: DfpType.postProvidePlanList[] = [];
    if (
      dfpSelfProcurementItems !== null ||
      dfpSelfProcurementItems !== undefined
    ) {
      for (let i = 0; i < dfpSelfProcurementItems.length; i++) {
        for (let j = 0; j < CELLCOUNT; j++) {
          const tmp: DfpType.postProvidePlanList = {
            saleId: dfpSelfProcurementItems[i].saleId,
            saleGroupId: dfpSelfProcurementItems[i].saleGroupId,
            salePlanKind: dfpSelfProcurementItems[i].salePlanKind,
            marketKind: dfpSelfProcurementItems[i].marketKind,
            powerId: dfpSelfProcurementItems[i].powerId,
            timeCd: dfpSelfProcurementItems[i].valueList[j].timeCd,
            demandQu: dfpSelfProcurementItems[i].valueList[j].value as number,
            dataChgCd: dfpSelfProcurementItems[i].valueList[j].dataChgCd,
          };
          // 自己託送
          tmpProvidePlan.push(tmp);
        }
      }
    }
    // 調達計画(自己託送,"スポット",1時間前)
    for (let i = 0; i < dfpSpotProcurementItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: DfpType.postProvidePlanList = {
          saleId: dfpSpotProcurementItems[i].saleId,
          saleGroupId: dfpSpotProcurementItems[i].saleGroupId,
          salePlanKind: dfpSpotProcurementItems[i].salePlanKind,
          marketKind: dfpSpotProcurementItems[i].marketKind,
          powerId: dfpSpotProcurementItems[i].powerId,
          timeCd: dfpSpotProcurementItems[i].valueList[j].timeCd,
          demandQu: dfpSpotProcurementItems[i].valueList[j].value as number,
          dataChgCd: dfpSpotProcurementItems[i].valueList[j].dataChgCd,
        };
        tmpProvidePlan.push(tmp);
      }
    }
    // 調達計画(自己託送,スポット,"1時間前")
    for (let i = 0; i < dfpHourProcurementItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: DfpType.postProvidePlanList = {
          saleId: dfpHourProcurementItems[i].saleId,
          saleGroupId: dfpHourProcurementItems[i].saleGroupId,
          salePlanKind: dfpHourProcurementItems[i].salePlanKind,
          marketKind: dfpHourProcurementItems[i].marketKind,
          powerId: dfpHourProcurementItems[i].powerId,
          timeCd: dfpHourProcurementItems[i].valueList[j].timeCd,
          demandQu: dfpHourProcurementItems[i].valueList[j].value as number,
          dataChgCd: dfpHourProcurementItems[i].valueList[j].dataChgCd,
        };
        tmpProvidePlan.push(tmp);
      }
    }

    // 販売計画("スポット",1時間前)
    const tmpSalePlan: DfpType.postSalePlanList[] = [];
    for (let i = 0; i < dfpSalesSpotItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: DfpType.postSalePlanList = {
          saleId: dfpSalesSpotItems[i].saleId,
          saleGroupId: dfpSalesSpotItems[i].saleGroupId,
          salePlanKind: dfpSalesSpotItems[i].salePlanKind,
          marketKind: dfpSalesSpotItems[i].marketKind,
          powerId: dfpSalesSpotItems[i].powerId,
          timeCd: dfpSalesSpotItems[i].valueList[j].timeCd,
          demandQu: dfpSalesSpotItems[i].valueList[j].value as number,
          dataChgCd: dfpSalesSpotItems[i].valueList[j].dataChgCd,
        };
        tmpSalePlan.push(tmp);
      }
    }

    // 販売計画(スポット,"1時間前")
    for (let i = 0; i < dfpSalesHourItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: DfpType.postSalePlanList = {
          saleId: dfpSalesHourItems[i].saleId,
          saleGroupId: dfpSalesHourItems[i].saleGroupId,
          salePlanKind: dfpSalesHourItems[i].salePlanKind,
          marketKind: dfpSalesHourItems[i].marketKind,
          powerId: dfpSalesHourItems[i].powerId,
          timeCd: dfpSalesHourItems[i].valueList[j].timeCd,
          demandQu: dfpSalesHourItems[i].valueList[j].value as number,
          dataChgCd: dfpSalesHourItems[i].valueList[j].dataChgCd,
        };
        tmpSalePlan.push(tmp);
      }
    }

    // 取引計画(全体合計)
    const tmpTranPlan: DfpType.postTransactionPlanList[] = [];
    for (let i = 0; i < CELLCOUNT; i++) {
      const tmp: DfpType.postTransactionPlanList = {
        timeCd: (i + 1).toString().padStart(2, "0"),
        demand: summaryData[i].demand as number,
        procurement: summaryData[i].procurement as number,
        sale: summaryData[i].sale as number,
        procurementShortage: summaryData[i].procurementShortage as number,
      };
      tmpTranPlan.push(tmp);
    }

    api
      .post(SET_DEMAND_PLAN, {
        areaId: areaId,
        targetDate: dateContext.pickDate?.toLocaleDateString(),
        demandPlanList: tmpDemandPlan,
        providePlanList: tmpProvidePlan,
        salePlanList: tmpSalePlan,
        transactionList: tmpTranPlan,
      })
      .then((res) => {
        // 保存後の処理
        setDigOpen(true); // OKonlyDig
        if (res.data.result.duplicated === true) {
          setDialogMessage(languageContext.words.saved_dupicated);
        } else {
          setDialogMessage(languageContext.words.note_saved);
        }

        leavePageContext.setBlockLeave(false);
        getDemandTran();
        getDemandHd();
        getDemandPlan();
        getProvidePlan();
        //getSupplyPlan();

        setIsLoaded(true);
      })
      .catch((error) => {
        setDialogMessage(languageContext.words.note_not_saved);
        setDigOpen(true);
        setError(error);
        setIsLoaded(true);
      });
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  function checkNumber(val: number | undefined) {
    if (val == undefined) {
      return 0;
    } else {
      return val;
    }
  }

  //線の色
  const changeBordercolor = () => {
    let borderColor: string;

    if (PaletteMode === "dark") {
      borderColor = "1px solid #808080";
    } else {
      borderColor = "1px solid #d3d3d3";
    }

    return borderColor;
  };

  // 複写
  const powerPlanTransferProvidePlan = () => {
    const tmpAreaSpot = dfpSpotProcurementItems.map((item) => {
      return item.areaId;
    });
    const groupAreaSpot = tmpAreaSpot.filter((element, index) => {
      return tmpAreaSpot.indexOf(element) == index;
    });

    const paramsArea = [areaId].concat(groupAreaSpot);

    api
      .get(GET_DEMAND_SALEDETAIL_URL, {
        params: {
          areaId: paramsArea,
          targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
        },
      })
      .then((res) => {
        if (res.data.result !== undefined || res.data.result.length !== 0) {
          // 発電販売計画が保存されているかチェック
          if (
            res.data.result.selfConsignmentList.filter(
              (element: PlanType.midAreaList) => element.valueList.length !== 0
            ).length !== 0 ||
            res.data.result.spotList.filter(
              (element: PlanType.midAreaList) => element.valueList.length !== 0
            ).length !== 0 ||
            res.data.result.oneHourList.filter(
              (element: PlanType.midAreaList) => element.valueList.length !== 0
            ).length !== 0
          ) {
            // 発販計画が保存されていた場合以下複写処理実行
            // 複写元域内valueがnullの場合0埋め
            res.data.result.selfConsignmentList.forEach(
              (element: PlanType.midAreaList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            // 複写先域内初期化
            dfpSelfProcurementItems.map((v) => {
              v.valueList.map((v) => {
                v.value = 0;
              });
            });
            // 複写
            res.data.result.selfConsignmentList.map(
              (item: DfpType.pwspSelfConsignmentList) => {
                for (const e of dfpSelfProcurementItems) {
                  if (
                    e.powerId === item.powerId &&
                    e.demandId === item.demandId
                  ) {
                    e.valueList.map((v, index) => {
                      if (!isDisabled(index, "")) {
                        v.value =
                          Number(v.value) + Number(item.valueList[index].value);
                      }
                    });
                  }
                }
              }
            );

            // 複写元スポットvalueがnullの場合0埋め
            res.data.result.spotList.forEach(
              (element: PlanType.midAreaList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            // 複写先スポット初期化
            dfpSpotProcurementItems.map((v) => {
              v.valueList.map((v, index) => {
                if (!isDisabled(index, "spot")) {
                  v.value = 0;
                }
              });
            });
            // 複写
            res.data.result.spotList.map(
              (item: DfpType.pwspSpotOneHourList) => {
                for (const e of dfpSpotProcurementItems) {
                  if (
                    e.demandId === item.demandId &&
                    e.saleId === item.saleId
                  ) {
                    e.valueList.map((v, index) => {
                      if (!isDisabled(index, "spot")) {
                        v.value =
                          Number(v.value) +
                          Number(
                            Math.floor(Number(item.valueList[index].value) / 50)
                          ) *
                            50;
                      }
                    });
                  }
                }
              }
            );
            // 複写元時間前valueがnullの場合0埋め
            res.data.result.oneHourList.forEach(
              (element: PlanType.midAreaList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            // 複写先時間前初期化
            dfpHourProcurementItems.map((v) => {
              v.valueList.map((v, index) => {
                if (!isDisabled(index, "")) {
                  v.value = 0;
                }
              });
            });
            // 複写
            res.data.result.oneHourList.map(
              (item: DfpType.pwspSpotOneHourList) => {
                for (const e of dfpHourProcurementItems) {
                  if (
                    e.demandId === item.demandId &&
                    e.saleId === item.saleId
                  ) {
                    e.valueList.map((v, index) => {
                      if (!isDisabled(index, "")) {
                        v.value =
                          Number(v.value) +
                          Number(
                            Math.floor(Number(item.valueList[index].value) / 50)
                          ) *
                            50;
                      }
                    });
                  }
                }
              }
            );

            setDfpSelfProcurementItems(dfpSelfProcurementItems);
            setDfpSpotProcurementItems(dfpSpotProcurementItems);
            setDfpHourProcurementItems(dfpHourProcurementItems);
            leavePageContext.setBlockLeave(true);
            forceUpdate();
          } else {
            // 発販計画が保存されていない場合
            setDialogMessage("発販計画が保存されていません");
            setDigOpen(true);
          }
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  /**
   * 自動計算部分のテーブルの構成
   * @returns
   */
  const ConstantDataTables = () => {
    if (
      dfpDemandItems === undefined ||
      dfpDemandItems === null ||
      dfpSelfProcurementItems === undefined ||
      dfpSelfProcurementItems === null ||
      dfpSpotProcurementItems === undefined ||
      dfpSpotProcurementItems === null ||
      dfpHourProcurementItems === undefined ||
      dfpHourProcurementItems === null ||
      dfpSalesSpotItems === undefined ||
      dfpSalesSpotItems === null ||
      dfpSalesHourItems === undefined ||
      dfpSalesHourItems === null ||
      tranPlanItems === undefined ||
      tranPlanItems === null ||
      tranPlanItems.length === 0
    ) {
      return;
    }

    const tempList: DfpType.dfpSummary[] = [];
    let tempDeal: number;
    for (let index = 0; index < CELLCOUNT; index++) {
      // 調達不足計算
      tempDeal =
        dfpDemandItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) -
        dfpSelfProcurementItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) -
        dfpSpotProcurementItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) -
        dfpHourProcurementItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
        (dfpSalesSpotItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
          dfpSalesHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ));

      const temp: DfpType.dfpSummary = {
        // 時刻
        timeCd: cellTime[index] + "～" + cellTime[index + 1],
        // 需要計画
        // Σ右表の需要計画値
        demand: dfpDemandItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ),
        // 調達計画
        // Σ右表の調達計画値
        procurement:
          dfpSelfProcurementItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          dfpSpotProcurementItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          dfpHourProcurementItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 販売計画
        // Σエリア販売計画値 + Σ右表の販売計画値
        sale:
          dfpSalesSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          dfpSalesHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 調達不足
        // Σ需要計画 + Σ調達計画 - Σ販売計画
        procurementShortage: tempDeal,
      };
      tempList.push(temp);
    }
    setSummaryData(tempList);
  };

  // 自動計算部分のテーブルの構成
  const ConstantTableRender = () => {
    return (
      <StyledTable
        sx={{
          width: "30%",
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{
          borderRightWidth: "2px",
          borderTop: "none",
          borderBottom: "none",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            //background: "rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
          }}
          style={{ borderRight: "none", borderTop: changeBordercolor() }}
        >
          <TableRow
            sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <StyledTableHeaderLG
              align="center"
              sx={{
                minWidth: "80px",
                display: "flex",
                height: "105px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <TableRow>
              <StyledTableHeaderLG
                align="center"
                sx={{ display: "block" }}
                style={{ borderRight: "none" }}
              >
                {languageContext.words.balance_supply_and_demand}
              </StyledTableHeaderLG>
              <TableRow sx={{ display: "flex" }}>
                {/* 取引計画表示 */}
                {TABLE_DEFINE.map((data, index) => (
                  <StyledTableHeaderLG
                    sx={{ display: "block", width: "75px" }}
                    key={index}
                    align="center"
                    style={{
                      borderRight: index === 3 ? "none" : "",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    title={data.label}
                  >
                    {data.label}
                    <br />
                    (kWh)
                  </StyledTableHeaderLG>
                ))}
              </TableRow>
            </TableRow>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            <>
              {summaryData.map((value, index) => (
                <TableRow
                  key={"demandForecastPlan_" + index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <StyledTableCell
                    key={"demandForecastData_" + index + "_time"}
                    id={"demandForecastPlanTimeCell" + index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 42,
                      width: 112,
                      padding: 0,
                      backgroundColor: isDisabled(index, "")
                        ? PaletteMode === "dark"
                          ? "rgb(90,90,90)"
                          : "rgb(200,200,200)"
                        : "",
                    }}
                    align="center"
                  >
                    {value.timeCd} {/* 時刻 */}
                  </StyledTableCell>

                  <StyledTableCell
                    key={"demandForecastData_1" + index + "_supply"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 42,
                      width: 99,
                      padding: "0px 8px 0px 0px",
                      textAlign: "right",
                      backgroundColor: isDisabled(index, "")
                        ? PaletteMode === "dark"
                          ? "rgb(90,90,90)"
                          : "rgb(200,200,200)"
                        : "",
                    }}
                    align="center"
                  >
                    {value.demand.toLocaleString()} {/* 供給力 */}
                  </StyledTableCell>

                  <StyledTableCell
                    key={"demandForecastData_2" + index + "_supply"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 42,
                      width: 99,
                      padding: "0px 8px 0px 0px",
                      textAlign: "right",
                      backgroundColor: isDisabled(index, "")
                        ? PaletteMode === "dark"
                          ? "rgb(90,90,90)"
                          : "rgb(200,200,200)"
                        : "",
                    }}
                    align="center"
                  >
                    {value.procurement.toLocaleString()} {/* 調達計画 */}
                  </StyledTableCell>

                  <StyledTableCell
                    key={"demandForecastData_3" + index + "_supply"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 42,
                      width: 99,
                      padding: "0px 8px 0px 0px",
                      textAlign: "right",
                      backgroundColor: isDisabled(index, "")
                        ? PaletteMode === "dark"
                          ? "rgb(90,90,90)"
                          : "rgb(200,200,200)"
                        : "",
                    }}
                    align="center"
                  >
                    {value.sale.toLocaleString()} {/* 販売計画 */}
                  </StyledTableCell>

                  <StyledTableCell
                    key={"demandForecastData_4" + index + "_supply"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 42,
                      width: 99,
                      padding: "0px 8px 0px 0px",
                      textAlign: "right",
                      backgroundColor: isDisabled(index, "")
                        ? PaletteMode === "dark"
                          ? "rgb(90,90,90)"
                          : "rgb(200,200,200)"
                        : "",
                    }}
                    align="center"
                    style={{ borderRight: "none" }}
                  >
                    {/* 調整量 */}
                    {value.procurementShortage.toLocaleString()}{" "}
                    {/* 販売計画 */}
                  </StyledTableCell>
                </TableRow>
              ))}
            </>
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  // 需要計画テーブル構成
  const DemandTableRender = () => {
    // 電圧種別毎に需要家をグループ化
    const tmpCategory = dfpDemandItems.map((item) => {
      return item.volCategory;
    });
    const groupCategory = tmpCategory.filter((element, index) => {
      return tmpCategory.indexOf(element) == index;
    });
    //ヘッダーデータの生成
    return (
      <StyledTable
        sx={{
          width: "auto",
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{ borderRight: "none", borderTop: "none", borderBottom: "none" }}
      >
        <TableHead
          sx={{
            display: "block",
            background: "#00000000", //"rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
            borderTop: changeBordercolor(),
          }}
        >
          <TableRow>
            {groupCategory.map((volCategory, index) => {
              return (
                <>
                  <StyledTableHeaderLG
                    key={"demandData_" + index + "_category"}
                    sx={{ height: 56, padding: 0 /*, width: 124*/ }}
                    align="center"
                    colSpan={
                      dfpDemandItems.filter(
                        (item) => item.volCategory === volCategory
                      ).length
                    }
                  >
                    {convertIdToVolLevelName(volCategory)}
                    {/* 需要家カテゴリ(特高、高圧、低圧) */}
                  </StyledTableHeaderLG>
                </>
              );
            })}
          </TableRow>
          <TableRow>
            {groupCategory.map((volCategory) => {
              return dfpDemandItems
                .filter((item) => item.volCategory === volCategory)
                .map((demand, index) => {
                  return (
                    <>
                      <StyledTableHeaderLG
                        key={"demandData_" + index + "_demandName"}
                        sx={{
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          //display: "flex",
                          height: 80,
                          minWidth: uchiwakeCellW,
                          maxWidth: uchiwakeCellW,
                          padding: 0,
                        }}
                        align="center"
                      >
                        <Box
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            marginX: "5px",
                          }}
                        >
                          {/* 需要家 */}
                          {demand.demandName}
                          <br />
                          (kWh)
                        </Box>
                      </StyledTableHeaderLG>
                    </>
                  );
                });
            })}
          </TableRow>
        </TableHead>
        {DemandDataTable()}
      </StyledTable>
    );
  };

  // 需要計画データテーブル構成
  const DemandDataTable = () => {
    // 電圧種別毎に需要家をグループ化
    const tmpCategory = dfpDemandItems.map((item) => {
      return item.volCategory;
    });
    const groupCategory = tmpCategory.filter((element, index) => {
      return tmpCategory.indexOf(element) == index;
    });
    //ヘッダーデータの生成
    const cells = new Array(48);
    cells.fill(0);

    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <>
            {cells.map((cell, index) => {
              return (
                <TableRow key={index}>
                  {groupCategory.map((volCategory) => {
                    return dfpDemandItems
                      .filter((item) => item.volCategory === volCategory)
                      .map((item, i) => {
                        return (
                          <StyledTableCell
                            key={"demandData_" + i + "_areaName"}
                            sx={{
                              height: 42,
                              padding: "0px",
                              paddingRight: "8px",
                              width: 116,
                              backgroundColor: isDisabled(index, "")
                                ? PaletteMode === "dark"
                                  ? "rgb(90,90,90)"
                                  : "rgb(200,200,200)"
                                : "",
                            }}
                            align="right"
                          >
                            {Number(item.valueList[index].value) <= -1
                              ? ""
                              : item.valueList[index].value === undefined
                              ? ""
                              : Number(
                                  checkNumber(item.valueList[index].value)
                                ).toFixed(0)}
                          </StyledTableCell>
                        );
                      });
                  })}
                </TableRow>
              );
            })}
          </>
        </TableBody>
      </ScrollSyncPane>
    );
  };

  const TableRender = (): JSX.Element => {
    if (tableType === DemandTable) {
      return DemandTableRender();
    } else if (tableType === ProcurementTable) {
      const props: PlanType.MidPlanTableProps = {
        midTabSelfItems: dfpSelfProcurementItems,
        midTabSpotItems: dfpSpotProcurementItems,
        midTabHourItems: dfpHourProcurementItems,
        setMidTabSelfItems: setDfpSelfProcurementItems,
        setMidTabSpotItems: setDfpSpotProcurementItems,
        setMidTabHourItems: setDfpHourProcurementItems,
        jepxDispFlag: jepxDispFlag.current,
        forceUpdate: forceUpdate,
      };
      return <SalesTableRender {...props} />;
    } else {
      const props: PlanType.RightPlanTableProps = {
        rightTabSpotItems: dfpSalesSpotItems,
        rightTabHourItems: dfpSalesHourItems,
        setRightTabSpotItems: setDfpSalesSpotItems,
        setRightTabHourItems: setDfpSalesHourItems,
        jepxDispFlag: jepxDispFlag.current,
        forceUpdate: forceUpdate,
      };
      return <ProviderTableRender {...props} />;
    }
  };

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <Box sx={{ display: "flex", width: "50%", alignItems: "center" }}>
          <Box sx={{ width: "200px", margin: "4px" }}>
            <CustomSelectList
              value={areaId}
              options={areaOptions(areas, false)}
              onChange={setAreaId}
            />
          </Box>
          <GlobalDatePicker isDipsTodayBtn={true} />
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{
              marginLeft: "30px",
              height: "40px",
              whiteSpace: "nowrap",
            }}
          >
            {/* 需要計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === DemandTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
              onClick={(event) => {
                setTabletype(DemandTable);
              }}
            >
              {languageContext.words.demand_plan}
            </StyledButton>
            {/* 調達計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === ProcurementTable
                    ? "rgba(25, 118, 210, 0.25)"
                    : "",
              }}
              onClick={() => {
                setTabletype(ProcurementTable);
              }}
            >
              {languageContext.words.procurement_plan}
            </StyledButton>
            {/* 販売計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === SalesTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
              onClick={(event) => {
                setTabletype(SalesTable);
              }}
              disabled={true}
            >
              {languageContext.words.sales_plan}
            </StyledButton>
          </ButtonGroup>
          <StyledButton
            sx={{
              marginLeft: "30px",
              height: "40px",
              minWidth: "40px",
            }}
            onClick={() => {
              powerPlanTransferProvidePlan();
            }}
          >
            {languageContext.words.copy}
          </StyledButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "50%",
            height: "40px",
            justifyContent: "flex-end",
            marginRight: "40px",
          }}
        >
          <Typography
            sx={{
              color: PaletteMode === "dark" ? "lightgray" : "gray",
              width: "660px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {lastUpdateInfo
              ? `${languageContext.words.last_update_info}
                : ${languageContext.convertDateToLocalizedString(
                  lastUpdateInfo.date,
                  "HH:mm:ss"
                )}
                (${lastUpdateInfo.user})`
              : ""}
          </Typography>
          <SaveDataButton
            hidden={hidden}
            disabled={
              hidden ||
              isBefore(
                new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
                new Date(format(timeContext.time as Date, "yyyy/MM/dd"))
              )
            }
            message={languageContext.words.saved}
            onAccept={OnAccept}
          />
        </Box>
      </Box>
      <ScrollSync>
        <TableContainer
          sx={{
            height: "90%",
            backgroundColor: "#00000000",
            overflow: "auto",
            width: "100%",
            scrollPaddingTop: "138px",
            margin: "10px",
            marginTop: "15px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#00000000",
            }}
          >
            {ConstantTableRender()}
            {TableRender()}
          </Box>
        </TableContainer>
      </ScrollSync>
      <CustomDialog
        title={languageContext.words.demandforecastplan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <CustomDialog
        title={languageContext.words.demandforecastplan}
        message={digMessageOkCancel}
        buttonType={ButtonType.OkCancel}
        open={digOpenOkCancel}
        onAccept={setDemandPlan} //OK押下後の挙動
        onClose={() => setDigOpenOkCancel(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </div>
  );
};
export default DemandForecastPlan;
