// システム定数
export interface reqStatusList {
  targetDate: Date;
  area: string;
}

export interface systemTimeInfo {
  spotTimeLimit: Date;
  balancingTimeLimit: Date;
}

export const systemTime: systemTimeInfo = {
  spotTimeLimit: new Date(new Date().toDateString() + " 10:00:00"),
  balancingTimeLimit: new Date(new Date().toDateString() + " 12:00:00"),
};

/**
 * ステータス一覧表示項目
 */
export interface statusListInfo {
  areaName: string;
  areaId: string;
  powerFixedTime: string;
  demandFixedTime: string;
  spotFixedTime: Date | null;
  spotPlanCount: number;
  spotPlanBitCount: number;
  hourFixedTime: Date | null;
  hourPlanCountBeforeGC: number;
  hourPlanBitCountBeforeGC: number;
  hourPlanCountAfterGC: number;
  hourPlanBitCountAfterGC: number;
  BalancingPowerFixedTime: Date | null;
  BalancingDemandFixedTime: Date | null;
  BalancingPartsFixedTime: Date | null;
  BalancingPowerSendStatus: string;
  BalancingDemandSendStatus: string;
  BalancingPartsSendStatus: string;
}

export type areaStatusType = {
  [key: string]: string;
};
