import { useCallback, useContext } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { VOLTAGE_LEVEL } from "./constant";

export const useVoltageLevelUtility = () => {
  const { words } = useContext(LanguageContext);

  const volLevelOptions = useCallback(
    (areas: string[]) => {
      const areaNames = [
        { id: VOLTAGE_LEVEL.NONE, name: words.setting_unselected },
        { id: VOLTAGE_LEVEL.EXTRA_HIGH, name: words.extra_high_voltage },
        { id: VOLTAGE_LEVEL.HIGH, name: words.high_voltage },
        { id: VOLTAGE_LEVEL.LOW, name: words.low_voltage },
      ];

      return areaNames.filter(
        (v) => areas.find((e) => e === v.id) || v.id === VOLTAGE_LEVEL.NONE
      );
    },
    [words]
  );

  const convertIdToVolLevelName = useCallback(
    (id: string) => {
      const levelNames = [
        words.extra_high_voltage,
        words.high_voltage,
        words.low_voltage,
      ];
      const index = parseInt(id) - 1;
      return levelNames[index] ? levelNames[index] : "undefined";
    },
    [words]
  );

  return { volLevelOptions, convertIdToVolLevelName };
};
