import React, {
  useState,
  useEffect,
  useContext,
  SetStateAction,
  Dispatch,
  useMemo,
  useRef,
  useCallback,
} from "react";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { StyledButton } from "../../common/styledComponents/styledButton";
import {
  DataGrid,
  GridColDef,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  jaJP,
} from "@mui/x-data-grid";
import styled from "styled-components";
import axios, { AxiosError } from "axios";
import CustomModal from "../../common/customComponents/CustomModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DownloadReportButton from "../../common/customComponents/DownloadReportButton";
import { LanguageContext } from "../../common/localization/localization";
import { useAuthedApi } from "../../../common/axios";
import {
  BalancingPlanList,
  BalancingPlanwithId as BalancingPlanWithId,
  SubmitBalancingPlan,
  reqReportDownload,
  reqSubmitBalancingPlan,
  resReportDownload,
} from "../../../types/occto/BalancingPlan";
import { Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GlobalDatePicker,
  DateContext,
} from "../../common/customComponents/GlobalDatePicker";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { format } from "date-fns";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import {
  DEMAND_FORECAST_PLAN_URL,
  POWER_FORECAST_PLAN_URL,
  BALANCING_PLAN_URL,
} from "../../../common/url";
import { BusinessModelContext } from "../../common/customComponents/BusinessModelProvider";
import { AREA_ID, BUSINESS_MODEL } from "../../../common/constant";

enum TabNumber {
  Unsent = 0,
  Sent = 1,
  Received = 2,
  Confirm = 3,
}

const InfoKindCode = {
  PWSP: "0150",
  PWSPNotice: "0151",
  DPP: "0250",
  DPPNotice: "0251",
  SpotSupply: "0610",
} as const;

const DownloadReportButtonRatherLarge = styled(DownloadReportButton)`
  && {
    min-height: 30px;
    min-width: 150px;
    padding 0px 0px;
  }
`;

const StyledButtonRatherLarge = styled(StyledButton)`
  && {
    min-height: 30px;
    min-width: 150px;
    padding 0px 0px;
  }
`;

interface balancingInfo {
  id: number;
  eria: number;
  reportName: string;
  separeteCount: number;
  changeNumber: number;
  updateDate: string;
  planStatus: number;
  commitStatus: number;
}

// エリア一覧
const AREA_LIST_URL = "/getAreaUser";
// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan";
//帳票一覧取得
const GET_BALANCING_PLAN = "/balancingPlan";
//提出状況取得
const GET_PLANNED_STATUS = "/planedStatus";
//計画提出
const SUBMIT_PLAN = "/submitPlan";
//計画確定
const CONFIRM_PLAN = "/confirmPlan";
//受信応答再開
const RESUME_SEND_RESPONSE = "/resumeSendResponse";
//帳票ダウンロード
const DOWNLOAD_REPORT = "downloadReport";
//通告結果取得
const GET_NOTIFICATION_RESULT = "/notificationResult";
//xml解析
const RECIEVE_PLAN = "recievePlan";

//エリア情報などの数値を人間が理解できるstringに直すためにセットにしたtypeを置く
type areaNumAndStr = { id: number; name: string };
type planStatusNumAndStr = { planStatusNum: number; planStatusStr: string };
type commitStatusNumAndStr = {
  commitStatusNum: number;
  commitStatusStr: string;
};

//データグリッドのスタイル
const GetGridStyle = () => {
  const { PaletteMode } = useContext(PaletteContext);
  const styles = {
    grid: {
      height: "80%",
      width: "100%",
      marginTop: 2,
      ".MuiDataGrid-cell": {
        paddingX: "8px",
      },
      ".MuiDataGrid-columnHeaders": {
        backgroundColor: PaletteMode === "dark" ? "#3e3e3e" : "#eceff1",
      },
      ".MuiDataGrid-iconButtonContainer": {
        visibility: "visible",
      },
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        width: "10px",
        backgroundColor: "transparent",
        height: "10px",
      },
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        backgroundColor: "#AAA",
        borderRadius: "10px",
      },
    },
  };
  return styles;
};

//同時同量計画
const BalancingPlan = () => {
  //言語を切り替えるデータコンテキスト
  const { words, mode, convertDateToLocalizedStringGoodAcu } =
    useContext(LanguageContext);
  const { setBlockLeave } = useContext(LeavePageContext);
  const { pickDate } = useContext(DateContext);
  const { time } = useContext(TimeContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);
  const navigate = useNavigate();

  const [areas, setAreas] = useState<string[]>([]);
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);
  // ビジネスモデルコンテキスト
  const { businessModel } = useContext(BusinessModelContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  //同時同量計画一覧
  //送信前一覧
  const [unsendPlans, setUnsendPlans] = useState<BalancingPlanWithId[]>([]);
  //送信済み一覧
  const [sendPlans, setSendPlans] = useState<BalancingPlanWithId[]>([]);
  //受信一覧
  const [receivePlans, setRecievePlans] = useState<BalancingPlanWithId[]>([]);
  //確定一覧
  const [confirmPlans, setConfirmPlans] = useState<BalancingPlanWithId[]>([]);

  //選択された行の各一覧
  const [checkedUnsendPlans, setCheckedUnsendPlans] = useState<
    BalancingPlanWithId[]
  >([]);
  const [checkedSendPlans, setCheckedSendPlans] = useState<
    BalancingPlanWithId[]
  >([]);
  const [checkedReceivePlans, setCheckedReceivePlans] = useState<
    BalancingPlanWithId[]
  >([]);
  const [checkedConfirmPlans, setCheckedConfirmPlans] = useState<
    BalancingPlanWithId[]
  >([]);

  //選択タブ
  const [tabValue, setTabValue] = React.useState(0);

  const [isPlaned, setIsPlaned] = useState(true);

  //ToDo:ボタンの活性非活性制御
  //ダウンロードボタン
  const [downroadButtonActivity, setDownroadButtonActivity] =
    React.useState(true);
  //計画提出ボタン
  const [submissionButtonActivity, setSubmissionButtonActivity] =
    React.useState(false);
  //計画確定ボタン
  const [confirmButtonActivity, setConfirmButtonActivity] =
    React.useState(false);
  //送信応答取得再開ボタン
  const [resumeButtonActivity, setResumeButtonActivity] = React.useState(true);
  //通告結果ボタン
  const [getNotificationResultActivity, setGetNotificationResultActivity] =
    React.useState(true);
  //混雑処理通知計画反映ボタン
  const [planReflectionButtonActivity, setPlanReflectionButtonActivity] =
    React.useState(false);

  //ボタンのダイアログ
  const [digOpenDownroadButton, setDigOpenDownroadButton] = useState(false);
  const [digOpenSubmissionButton, setDigOpenSubmissionButton] = useState(false);
  const [digOpenConfirmButton, setDigOpenConfirmButton] = useState(false);
  const [digOpenResumeButton, setDigOpenResumeButton] = useState(false);
  const [digOpenPlanReflection, setDigOpenPlanReflection] = useState(false);
  const [digOpenGetNotificationResult, setDigOpenGetNotificationResult] =
    useState(false);

  //不整合通知反映ボタン
  const [digOpenNotificationReflection, setDigOpenNotificationReflection] =
    useState(false);
  const NotifiCationAreaId = useRef("");
  const NotificationInfoKindCd = useRef("");

  // 発電販売計画ボタンの表示・非表示
  const [enableDispPower, setEnableDispPower] = useState<boolean>(false);
  // 需要調達計画ボタンの表示・非表示
  const [enableDispDemand, setEnableDispDemand] = useState<boolean>(false);

  //データグリッドのカラム構成
  const columns: GridColDef[] = [
    { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 15 },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "area",
      headerName: words.area,
      minWidth: 140,
      flex: 1,
      editable: false,
      valueGetter: (params) => convertIdToAreaName(params.row.areaId),
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center" }}>
          <Stack sx={{ width: "100%" }}>
            {convertIdToAreaName(params.row.areaId)}
          </Stack>
        </Box>
      ),
    },
    {
      field: "reportName",
      headerName: words.form_name,
      minWidth: 600,
      flex: 3,
      editable: false,
      headerAlign: "center",
      align: "left",

      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Stack sx={{ width: "100%" }}>
            {convertCodeToReportName(params.row.infoKindCd)}
          </Stack>
          {tabValue === TabNumber.Received ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                NotifiCationAreaId.current = params.row.areaId;
                NotificationInfoKindCd.current = params.row.infoKindCd;
                setDigOpenNotificationReflection(true);
              }}
            >
              {words.bp_notice_reflect}
            </Button>
          ) : null}
        </Box>
      ),
    },
    {
      field: "partitionNo",
      headerName: words.number_of_divisions,
      minWidth: 100,
      flex: 0.8,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center"}}>
          <Stack sx={{ width: "100%" }}>
            {params.row.partitionNo}
          </Stack>
        </Box>
      ),
    },
    {
      field: "updateNo",
      headerName: words.change_no,
      minWidth: 100,
      flex: 0.8,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center"}}>
          <Stack sx={{ width: "100%" }}>
            {params.row.updateNo}
          </Stack>
        </Box>
      ),
    },
    {
      field: "updateDate",
      headerName: words.update_date,
      minWidth: 140,
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        convertDateToLocalizedStringGoodAcu(new Date(params.row.lastUpdate)),
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center"}}>
          <Stack sx={{ width: "100%" }}>
            {convertDateToLocalizedStringGoodAcu(new Date(params.row.lastUpdate))}
          </Stack>
        </Box>
      ),
    },
    {
      field: "planStatus",
      headerName: words.status,
      minWidth: 140,
      flex: 1,
      editable: false,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => convertNumToStatusName(params.row.planStatus),
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center" }}>
          <Stack sx={{ width: "100%" }}>
            {convertNumToStatusName(params.row.planStatus)}
          </Stack>
        </Box>
      ),
    },
    /*{
        field: "commitStatus",
        headerName: languageContext.words.reflection_status,
        minWidth: 140,
        flex: 0.9,
        editable: true,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) =>
          convertNumToCommitStatus(params.row.commitStatus),
      },*/
  ];

  //計画状態名称変換
  const convertNumToStatusName = useCallback(
    (statusId: number) => {
      const planStatuses = [
        words.bp_status_planning,
        words.bp_status_submitting,
        words.bp_status_submitted,
        words.bp_status_recieved,
      ];

      return planStatuses[statusId] ? planStatuses[statusId] : "undefind";
    },
    [words]
  );
  //帳票名称変換
  const convertCodeToReportName = useCallback(
    (infoKindCd: string) => {
      const reportNames = [
        { id: InfoKindCode.PWSP, name: words.bp_report_pgsp_next },
        { id: InfoKindCode.DPP, name: words.bp_report_dpp_next },
        { id: InfoKindCode.PWSPNotice, name: words.bp_report_pgsp_notice },
        { id: InfoKindCode.DPPNotice, name: words.bp_report_dpp_notice },
        { id: InfoKindCode.SpotSupply, name: words.bp_report_partial },
      ];
      //ToDo 帳票データに情報区分コード追加時に修整
      let tempCode = infoKindCd;
      if (tabValue === TabNumber.Received) {
        if (infoKindCd == InfoKindCode.PWSP) tempCode = InfoKindCode.PWSPNotice;
        if (infoKindCd == InfoKindCode.DPP) tempCode = InfoKindCode.DPPNotice;
      }
      return reportNames.find((v) => v.id === tempCode)?.name;
    },
    [words, tabValue]
  );

  // 非同期通信
  // ステータス情報APIデータ取得
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  // エラーポップアップ
  const [digOpen, setDigOpen] = useState<boolean>(false);
  const [digMessage, setDigMessage] = useState<string>("");

  // エラー画面
  const api = useAuthedApi();

  // DB編集系ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  //初期化処理
  useEffect(() => {
    dicideSaveButtonVisibility();
    initAreaList();
    setBlockLeave(false);
  }, []);

  //エリア取得後,日付とエリア更新時
  //一覧取得
  useEffect(() => {
    if (areas.length) {
      getBalancingPlans(0, setUnsendPlans);
      getBalancingPlans(1, setSendPlans);
      getBalancingPlans(2, setRecievePlans);
      getBalancingPlans(3, setConfirmPlans);
      getPlanedStatus();
    }
  }, [areas, pickDate, areaId]);

  //日付とエリア,更新時
  useEffect(() => {
    setCheckedUnsendPlans([]);
    setCheckedSendPlans([]);
    setCheckedReceivePlans([]);
    setCheckedConfirmPlans([]);
  }, [pickDate, areaId]);

  //帳票選択,tab移動時
  useEffect(() => {
    ChangeButtonsEnable();
  }, [
    checkedUnsendPlans,
    checkedSendPlans,
    checkedReceivePlans,
    checkedConfirmPlans,
    tabValue,
    pickDate,
  ]);

  // エリア選択時
  useEffect(() => {
    // ビジネスモデルによって発販・需調ボタンの表示制御をする
    const dispModel = businessModel
      .filter((v) => v.areaId === areaId)
      .map((v) => v.businessModel);

    if (
      dispModel[0] === BUSINESS_MODEL.IKIGAI_POWER ||
      dispModel[0] === BUSINESS_MODEL.MARKET_TRAN
    ) {
      setEnableDispPower(true);
      setEnableDispDemand(false);
    } else if (dispModel[0] === BUSINESS_MODEL.IKIGAI_DEMAND) {
      setEnableDispPower(false);
      setEnableDispDemand(true);
    } else if (
      dispModel[0] === BUSINESS_MODEL.IKINAI ||
      dispModel[0] === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER ||
      dispModel[0] === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ||
      dispModel[0] === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN
    ) {
      setEnableDispPower(true);
      setEnableDispDemand(true);
    } else {
      setEnableDispPower(false);
      setEnableDispDemand(false);
    }
  }, [areaId]);

  //選択中のタブに対応する計画
  const CurrentTabPlans = useMemo((): BalancingPlanWithId[] => {
    const empty: BalancingPlanWithId[] = [];
    switch (tabValue) {
      case TabNumber.Unsent:
        return unsendPlans;
      case TabNumber.Sent:
        return sendPlans;
      case TabNumber.Received:
        return receivePlans;
      case TabNumber.Confirm:
        return confirmPlans;
      default:
        return empty;
    }
  }, [confirmPlans, receivePlans, sendPlans, tabValue, unsendPlans]);

  console.log(CurrentTabPlans);

  //タブに対応する選択されている計画
  const CurrentTabCheckedPlans = useMemo((): BalancingPlanWithId[] => {
    const empty: BalancingPlanWithId[] = [];
    switch (tabValue) {
      case TabNumber.Unsent:
        return checkedUnsendPlans;
      case TabNumber.Sent:
        return checkedSendPlans;
      case TabNumber.Received:
        return checkedReceivePlans;
      case TabNumber.Confirm:
        return checkedConfirmPlans;
      default:
        return empty;
    }
  }, [
    tabValue,
    checkedUnsendPlans,
    checkedSendPlans,
    checkedReceivePlans,
    checkedConfirmPlans,
  ]);

  //表示用 計画のID管理
  const CurrentTabCheckedId = useMemo(() => {
    return CurrentTabCheckedPlans.map((item) => item.id);
  }, [CurrentTabCheckedPlans]);

  //ボタンの活性,非活性管理
  const ChangeButtonsEnable = () => {
    switch (tabValue) {
      case TabNumber.Unsent:
        setSubmissionButtonActivity(
          Boolean(checkedUnsendPlans.length) && isSubmittableTime()
        );
        setConfirmButtonActivity(false);
        setResumeButtonActivity(false);
        setGetNotificationResultActivity(false);
        setDownroadButtonActivity(false);
        break;
      case TabNumber.Sent:
        setSubmissionButtonActivity(false);
        setConfirmButtonActivity(
          checkedSendPlans.filter((v) => v.planStatus != 0).length != 0
        );
        setResumeButtonActivity(true);
        setGetNotificationResultActivity(false);
        setDownroadButtonActivity(Boolean(checkedSendPlans.length === 1));
        break;
      case TabNumber.Received:
        setSubmissionButtonActivity(false);
        setConfirmButtonActivity(false);
        setResumeButtonActivity(false);
        setGetNotificationResultActivity(true);
        setDownroadButtonActivity(Boolean(checkedReceivePlans.length === 1));
        break;
      case TabNumber.Confirm:
        setSubmissionButtonActivity(false);
        setConfirmButtonActivity(false);
        setResumeButtonActivity(false);
        setGetNotificationResultActivity(false);
        setDownroadButtonActivity(false);
        break;
    }
    setDownroadButtonActivity(Boolean(CurrentTabCheckedPlans.length === 1));
  };

  //提出期限チェック
  const isSubmittableTime = () => {
    if (!pickDate) return false;
    const y = pickDate.getFullYear();
    const m = pickDate.getMonth();
    const d = pickDate.getDate();
    const todayStart = 17;
    const nextDayEnd = 12;
    const todayEnd = 23;
    //提出期限
    //翌日計画の時(17時以降から当日計画)
    if (time < new Date(y, m, d - 1, todayStart)) {
      //前日12時まで提出可能
      return time < new Date(y, m, d - 1, nextDayEnd);
    } else {
      //当日のゲートクローズまで提出可能
      return time < new Date(y, m, d, todayEnd);
    }
  };

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // エリアプルダウン初期表示
  const initAreaList = () => {
    const initArea = query.get("areaId") ?? AREA_ID.NONE;
    setAreas(businessModel.map((v) => v.areaId));
    setAreaId(initArea);
  };

  //ステートごとの同時同量一覧の取得
  const getBalancingPlans = (
    status: number,
    setState: Dispatch<SetStateAction<BalancingPlanWithId[]>>
  ) => {
    api
      .get<BalancingPlanList>(GET_BALANCING_PLAN, {
        params: {
          areaIds:
            areaId === "00"
              ? areas.filter((e) => e !== "00") //すべてのエリアの時は配列返す
              : areaId,
          targetDate: format(pickDate as Date, "yyyy-MM-dd"),
          sendStatus: status,
        },
      })
      .then((res) => {
        const src = res.data as BalancingPlanList;
        const dist: BalancingPlanWithId[] = src.map((item, index) => {
          return { id: index, ...item };
        });
        setState(dist);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPlanedStatus = () => {
    setIsLoaded(false);
    api
      .get(GET_PLANNED_STATUS, {
        params: {
          areaIds:
            areaId === "00"
              ? areas.filter((e) => e !== "00") //すべてのエリアの時は配列返す
              : areaId,
          targetDate: format(pickDate as Date, "yyyy-MM-dd"),
        },
      })
      .then((res) => {
        setIsPlaned(res.data);
        setIsLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //計画提出の処理
  const submitPlan = () => {
    setIsLoaded(false);
    //選択済み一覧から提出に必要な情報を抽出
    const submitPlans = checkedUnsendPlans.map((item): SubmitBalancingPlan => {
      return { areaId: item.areaId, infoKindCd: item.infoKindCd };
    });
    const reqBody: reqSubmitBalancingPlan = {
      targetDate: format(pickDate as Date, "yyyy-MM-dd"),
      submitBalancingPlan: submitPlans,
    };

    //計画提出API
    api
      .post(SUBMIT_PLAN, reqBody)
      .then((res) => {
        if (res.data == submitPlans.length) {
          setDigMessage(words.bp_submit_success);
        } else {
          setDigMessage(words.bp_submit_failed);
        }
        setIsLoaded(true);
        setDigOpen(true);
        getBalancingPlans(0, setUnsendPlans);
        getBalancingPlans(1, setSendPlans);
        setCheckedUnsendPlans([]);
        setCheckedSendPlans([]);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDigMessage(words.could_not_communicate_occto);
        } else {
          setDigMessage(words.bp_submit_error);
        }
        setIsLoaded(true);
        setDigOpen(true);
      });
  };

  //計画確定の処理
  const confirmPlan = () => {
    setIsLoaded(false);
    const confirmPlans = checkedSendPlans.map((item): SubmitBalancingPlan => {
      return { areaId: item.areaId, infoKindCd: item.infoKindCd };
    });
    const reqBody: reqSubmitBalancingPlan = {
      targetDate: format(pickDate as Date, "yyyy-MM-dd"),
      submitBalancingPlan: confirmPlans,
    };

    //計画確定API
    console.log("befor api");
    api
      .post(CONFIRM_PLAN, reqBody)
      .then((res) => {
        setCheckedSendPlans([]);
        setCheckedConfirmPlans([]);
        getBalancingPlans(1, setSendPlans);
        getBalancingPlans(3, setConfirmPlans);
        setDigMessage(words.bp_confirm_success);
        setIsLoaded(true);
        setDigOpen(true);
        console.log("inside then");
      })
      .catch((e) => {
        setDigMessage(words.bp_confirm_error);
        setIsLoaded(true);
        setDigOpen(true);
      });
  };

  //受信応答再開
  const resumeSendResponse = () => {
    setIsLoaded(false);
    api
      .post(RESUME_SEND_RESPONSE)
      .then((res) => {
        setCheckedSendPlans([]);
        getBalancingPlans(1, setSendPlans);
        setIsLoaded(true);
      })
      .catch((e) => {
        if (e.response?.status === 504) {
          setDigMessage(words.could_not_communicate_occto);
        } else {
          setDigMessage(words.bp_resume_error);
        }
        setIsLoaded(true);
        setDigOpen(true);
      });
  };

  //帳票ダウンロード
  const downloadReport = () => {
    const targetReport = CurrentTabCheckedPlans.map(
      (item): reqReportDownload => {
        return {
          targetDate: format(pickDate as Date, "yyyy-MM-dd"),
          areaId: item.areaId,
          infoKindCd: item.infoKindCd,
          sendStatus: tabValue,
          updateNo: item.updateNo,
        };
      }
    );
    api
      .post(DOWNLOAD_REPORT, targetReport[0])
      .then((res) => {
        const { reportName, reportData } = res.data as resReportDownload;
        const file = new Blob(
          [decodeURIComponent(escape(window.atob(reportData)))],
          {
            type: "text/xml",
          }
        );
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.download = reportName;
        link.href = url;
        link.click();

        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //通告結果取得
  const getNotificationResult = () => {
    setIsLoaded(false);
    api
      .post(GET_NOTIFICATION_RESULT, {
        targetDate: format(pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        getBalancingPlans(2, setRecievePlans);
        setCheckedReceivePlans([]);
        setIsLoaded(true);
      })
      .catch((e) => {
        if (e.response?.status === 504) {
          setDigMessage(words.could_not_communicate_occto);
        } else {
          setDigMessage(words.bp_notification_error);
        }
        setIsLoaded(true);
        setDigOpen(true);
      });
  };

  enum InfoKind {
    PowerPlan,
    DemandProvide,
    Error,
  }
  //xml解析
  const RecieveBalancingPlan = (areaId: string, infoKindCd: string) => {
    //通告結果取得API
    api
      .post(RECIEVE_PLAN, {
        areaId: areaId,
        infoKindCd: infoKindCd,
        targetDate: format(pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        NavigatePlanPage(areaId, infoKindCd);
      });
  };

  //計画反映の処理
  const reflectPlan = () => {
    //console.log("計画反映処理");
  };

  //画面遷移メソッド
  const NavigatePlanPage = (areaId: string, infoKindCode: string) => {
    let url = "";
    switch (infoKindCode) {
      case "0151":
        url = ".." + POWER_FORECAST_PLAN_URL;
        break;
      case "0251":
        url = ".." + DEMAND_FORECAST_PLAN_URL;
        break;
      default:
        return;
    }
    url += "?areaId=" + areaId;
    setAreaId(areaId);
    navigate(url);
  };

  //タブ切り替え
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  //d.iino 確認済み

  //反映状態
  const commitStatuses = ["", words.plan_reflected];

  const createCommitStatusesForCalc = (
    commitStatusNum: number,
    commitStatusStr: string
  ) => {
    return { commitStatusNum, commitStatusStr };
  };

  const commitStatusInfos: commitStatusNumAndStr[] = [];
  for (let i = 0; i < commitStatuses.length; i++) {
    commitStatusInfos.push(createCommitStatusesForCalc(i, commitStatuses[i]));
  }

  //反映状態を表示するときに反映状態ナンバーを反映状態名に変換する関数
  const convertNumToCommitStatus = (commitStatusNum: number) => {
    let commitStatusString = "";
    for (let i = 0; i < commitStatusInfos.length; i++) {
      if (commitStatusNum === commitStatusInfos[i].commitStatusNum) {
        commitStatusString = commitStatusInfos[i].commitStatusStr;
        break;
      }
    }
    return commitStatusString;
  };

  const CustomUnsortedIcon = (): JSX.Element => {
    return <FilterAltOutlinedIcon />;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <div
      key={"BalancingPlan_div"}
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <CustomSelectList
          name="areaId"
          label={words.area_unselected}
          value={areaId}
          options={areaOptions(areas)}
          sx={{ width: "320px" }}
          onChange={setAreaId}
        />
        <GlobalDatePicker isDipsTodayBtn={true} />
      </Box>
      <Tabs
        key={"BalancingPlan_Tab"}
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tab
          key={"BalancingPlan_Tab_unsent"}
          label={words.waiting_for_send}
        ></Tab>
        <Tab key={"BalancingPlan_Tab_sent"} label={words.sent}></Tab>
        <Tab key={"BalancingPlan_Tab_received"} label={words.receive}></Tab>
        <Tab key={"BalancingPlan_Tab_cofirmed"} label={words.confirmed}></Tab>
      </Tabs>

      {isPlaned ? (
        <>
          <Stack
            key={"BalancingPlan_Stack"}
            justifyContent="space-between"
            direction="row"
            sx={{ marginTop: 2 }}
          >
            <Box>
              <StyledButtonRatherLarge
                key={"BalancingPlan_SubmissionButton"}
                sx={{
                  marginRight: 2,
                  visibility: hidden ? "hidden" : "visible",
                }}
                variant="outlined"
                disabled={!submissionButtonActivity || hidden}
                onClick={() => setDigOpenSubmissionButton(true)}
              >
                {words.plan_submitted}
              </StyledButtonRatherLarge>
              <CustomDialog
                key={"BalancingPlan_SubmissionButton_Modal"}
                title={words.plan_submitted}
                open={digOpenSubmissionButton}
                onAccept={() => {
                  submitPlan();
                }}
                onClose={() => setDigOpenSubmissionButton(false)}
                buttonType={ButtonType.OkCancel}
              >
                {words.note_submit_notification}
              </CustomDialog>

              <StyledButtonRatherLarge
                key={"BalancingPlan_DownroadButton"}
                sx={{ visibility: hidden ? "hidden" : "visible" }}
                variant="outlined"
                disabled={!downroadButtonActivity || hidden}
                onClick={() => setDigOpenDownroadButton(true)}
              >
                {words.download}
              </StyledButtonRatherLarge>
              <CustomDialog
                key={"BalancingPlan_DownroadButton_Modal"}
                title={words.download}
                open={digOpenDownroadButton}
                onAccept={() => {
                  downloadReport();
                }}
                onClose={() => setDigOpenDownroadButton(false)}
                buttonType={ButtonType.OkCancel}
              >
                {words.note_download_selected_forms}
              </CustomDialog>

              <Box>
                <Typography gutterBottom></Typography>
              </Box>
            </Box>
            <Box>
              <StyledButtonRatherLarge
                key={"BalancingPlan_ConfirmButton"}
                sx={{
                  marginRight: 2,
                  visibility: hidden ? "hidden" : "visible",
                }}
                variant="outlined"
                disabled={!confirmButtonActivity || hidden}
                onClick={() => setDigOpenConfirmButton(true)}
              >
                {words.plan_confirmed}
              </StyledButtonRatherLarge>

              <CustomDialog
                key={"BalancingPlan_ConfirmButton_Modal"}
                title={words.plan_confirmed}
                open={digOpenConfirmButton}
                onAccept={() => {
                  confirmPlan();
                }}
                onClose={() => setDigOpenConfirmButton(false)}
                buttonType={ButtonType.OkCancel}
              >
                {words.updates_selected_form_confirmed}
              </CustomDialog>

              {/* コメントアウト(送信応答取得再開)
              <StyledButtonRatherLarge
                key={"BalancingPlan_ResumeButton"}
                sx={{
                  marginRight: 2,
                  visibility: hidden ? "hidden" : "visible",
                }}
                variant="outlined"
                disabled={!resumeButtonActivity || hidden}
                onClick={() => setDigOpenResumeButton(true)}
              >
                {words.resume_send_response_acquisition}
              </StyledButtonRatherLarge>

              <CustomDialog
                key={"BalancingPlan_ResumeButton_Modal"}
                title={words.resume_send_response_acquisition}
                open={digOpenResumeButton}
                onAccept={() => {
                  resumeSendResponse();
                }}
                onClose={() => setDigOpenResumeButton(false)}
                buttonType={ButtonType.OkCancel}
              >
                {words.bp_resume_check}
              </CustomDialog>
              */}

              {/*ステップ対象外のためコメントアウト
                <StyledButtonRatherLarge
                  key={"BalancingPlan_PlanReflectionButton"}
                  sx={{ marginRight: 2, visibility: hidden ? "hidden" : "visible" }}
                  variant="outlined"
                  disabled={!planReflectionButtonActivity || hidden}
                  onClick={() => setDigOpenPlanReflection(true)}
                >
                  {languageContext.words.reflects_congestion_note}
                </StyledButtonRatherLarge>
                
                <CustomModal
                  key={"BalancingPlan_PlanReflectionButton_Modal"}
                  title={languageContext.words.reflects_congestion_note}
                  open={digOpenPlanReflection}
                  onAccept={() => {
                    reflectPlan();
                  }}
                  onClose={() => setDigOpenPlanReflection(false)}
                  btnText={languageContext.words.yes}
                >
                  {languageContext.words.reflected_system_planned_values}
                </CustomModal>
                */}
              <StyledButtonRatherLarge
                key={"BalancingPlan_GetNotificationResultButton"}
                sx={{ visibility: hidden ? "hidden" : "visible" }}
                variant="outlined"
                disabled={!getNotificationResultActivity || hidden}
                onClick={() => setDigOpenGetNotificationResult(true)}
              >
                {words.note_result_acquisition}
              </StyledButtonRatherLarge>

              <CustomDialog
                key={"BalancingPlan_GetNotificationResultButton_Modal"}
                title={words.note_result_acquisition}
                open={digOpenGetNotificationResult}
                onAccept={getNotificationResult}
                onClose={() => setDigOpenGetNotificationResult(false)}
                buttonType={ButtonType.OkCancel}
              >
                {words.get_latest_notification_results}
              </CustomDialog>
              {/* <DownloadReportButton /> */}
            </Box>
          </Stack>
          <DataGrid
            key={"BalancingPlan_DataGrid_DataGrid"}
            sx={GetGridStyle().grid}
            rows={CurrentTabPlans}
            // 選択された行の情報を専用stateに格納する処理
            onSelectionModelChange={(RowId) => {
              const selectedRowId = new Set(RowId);
              const selectedRows = CurrentTabPlans.filter((dataGridRow) =>
                selectedRowId.has(dataGridRow.id)
              );
              switch (tabValue) {
                case TabNumber.Unsent:
                  setCheckedUnsendPlans(selectedRows);
                  break;
                case TabNumber.Sent:
                  setCheckedSendPlans(selectedRows);
                  break;
                case TabNumber.Received:
                  setCheckedReceivePlans(selectedRows);
                  break;
                case TabNumber.Confirm:
                  setCheckedConfirmPlans(selectedRows);
                  break;
              }
            }}
            columns={columns}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            showColumnRightBorder
            showCellRightBorder
            hideFooter
            columnVisibilityModel={{
              id: false,
            }}
            rowHeight={42}
            headerHeight={42}
            localeText={
              mode === "jp"
                ? jaJP.components.MuiDataGrid.defaultProps.localeText
                : undefined
            }
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
            }}
            selectionModel={CurrentTabCheckedId}
          />
          <CustomDialog
            key={"BalancingPlan_Notice_Modal"}
            title={words.bp_notification_title}
            open={digOpenNotificationReflection}
            onAccept={() => {
              RecieveBalancingPlan(
                NotifiCationAreaId.current,
                NotificationInfoKindCd.current
              );
            }}
            onClose={() => setDigOpenNotificationReflection(false)}
            buttonType={ButtonType.OkCancel}
          >
            {words.bp_notification_check}
          </CustomDialog>
          <CustomDialog
            title={words.simultaneous_commensurate_plan}
            message={digMessage}
            buttonType={ButtonType.OkOnly}
            open={digOpen}
            onAccept={acceptHandler}
            onClose={() => setDigOpen(false)}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorOutlineIcon
            sx={{ fontSize: "96px", margin: "8px" }}
            color="error"
          />
          <Typography sx={{ fontWeight: "bold" }} variant="h5" margin="8px">
            {words.bp_unplaned_msg}
          </Typography>
          <Typography margin="8px">{words.bp_unplaned_hint}</Typography>
          <Box>
            {enableDispPower ? (
              <Button
                sx={{ margin: "8px" }}
                size="large"
                variant="outlined"
                onClick={() => {
                  navigate(POWER_FORECAST_PLAN_URL + "?areaId=" + areaId);
                }}
              >
                {words.power_gen_and_sales_plan}
              </Button>
            ) : null}

            {enableDispDemand ? (
              <Button
                sx={{
                  margin: "8px",
                  width: mode === "en" ? "275.78px" : "auto",
                }}
                size="large"
                variant="outlined"
                onClick={() => {
                  navigate(DEMAND_FORECAST_PLAN_URL + "?areaId=" + areaId);
                }}
              >
                {words.demand_and_procurement_plan}
              </Button>
            ) : null}
          </Box>
        </Box>
      )}
      <CustomModalProgress open={!isLoaded} />
    </div>
  );
};

export default BalancingPlan;
