import { useCallback, useContext } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { AREA_ID } from "./constant";



export const useAreaUtility = () => {
  const { words } = useContext(LanguageContext);

  const areaOptions = useCallback(
    (areas: string[], unselectable = true, allAreaFlag = false) : {id: string,name: string}[] =>  {
      const areaNames = [
        {
          id: AREA_ID.NONE,
          name: allAreaFlag === true ? words.all_areas : words.area_unselected,
        },
        { id: AREA_ID.HOKKAIDO, name: words.hokkaido_region },
        { id: AREA_ID.TOHOKU, name: words.tohoku_region },
        { id: AREA_ID.TOKYO, name: words.tokyo_region },
        { id: AREA_ID.CHUBU, name: words.chubu_region },
        { id: AREA_ID.HOKURIKU, name: words.hokuriku_region },
        { id: AREA_ID.KANSAI, name: words.kansai_region },
        { id: AREA_ID.CHUGOKU, name: words.chugoku_region },
        { id: AREA_ID.SHIKOKU, name: words.shikoku_region },
        { id: AREA_ID.KYUSYU, name: words.kyushu_region },
        { id: AREA_ID.OKINAWA, name: words.okinawa_region },
      ];

      return areaNames.filter(
        (v) =>
          areas.find((e) => e === v.id) ||
          (v.id === AREA_ID.NONE && unselectable)
      );
    },
    [words]
  );

  const convertIdToAreaName = useCallback(
    (id: string) : string => {
      const areaNames = [
        words.hokkaido_region,
        words.tohoku_region,
        words.tokyo_region,
        words.chubu_region,
        words.hokuriku_region,
        words.kansai_region,
        words.chugoku_region,
        words.shikoku_region,
        words.kyushu_region,
        words.okinawa_region,
      ];
      const index = parseInt(id) - 1;
      return areaNames[index] ? areaNames[index] : "";
    },
    [words]
  );

  return { areaOptions, convertIdToAreaName };
};
