import { useCallback, useContext } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { DOWNLOAD_REPORT_KIND } from "./constant";

export const useDownloadReportKindUtility = () => {
    const { words } = useContext(LanguageContext);

    const downloadReportKindOptions = useCallback(
        (kinds: string[]) => {
          const reportKinds = [
            { id: DOWNLOAD_REPORT_KIND.BALANCE_OF_PAYMENTS, name: words.balance_of_payments_report},
          ];

          return reportKinds.filter(
            (v) => kinds.find((e) => e === v.id)
          );
        },
        [words]
    );

    const convertIdToDownloadReportName = useCallback(
        (id: string) => {
          const downloadReportNames = [
            words.balance_of_payments_report,
          ];
          const index = parseInt(id);
          return downloadReportNames[index] ? downloadReportNames[index] : "undefined";
        },
        [words]
    );

    return{ downloadReportKindOptions, convertIdToDownloadReportName };

}