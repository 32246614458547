import {
  Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext, ReactNode } from "react";

import { LanguageContext } from "../localization/localization";

// ボタン種別
export enum ButtonType {
  OkOnly = "OkOnly",
  OkCancel = "OkCancel",
}

interface Props {
  title: string;
  buttonType: string;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
  children?: ReactNode;
  message?: string;
}

const CustomDialog = (props: Props) => {
  // プロパティの受け取り
  const { title, message, onAccept, onClose, open, buttonType, children } =
    props;
  const dialogMessage: string = message as string;

  const [dialogOpen, setDialogOpen] = useState(false);

  const { words } = useContext(LanguageContext);

  // 承諾（OK または YES ボタンをクリック）した時
  const handleAccept = () => {
    handleClose();
    onAccept();
  };

  // ダイアログクローズ
  const handleClose = () => {
    setDialogOpen(false);
    onClose();
  };

  // openの値が変化した時
  useEffect(() => setDialogOpen(open), [open]);

  return (
    <Dialog open={dialogOpen} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: "lightgray", color: "black" }}>
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        {
          <Box sx={{ padding: "20px" }}>
            {message
              ? dialogMessage.split("\n").map((message, index) => (
                  <span key={`${message + index}`}>
                    {message}
                    <br />
                  </span>
                ))
              : children}
          </Box>
        }
      </DialogContent>
      <DialogActions>
        {buttonType === ButtonType.OkOnly && (
          <Button autoFocus onClick={handleAccept}>{words.ok}</Button>
        )}
        {buttonType === ButtonType.OkCancel && (
          <>
            <Button autoFocus onClick={handleAccept}>{words.yes}</Button>
            <Button onClick={handleClose}>{words.no}</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

// プロパティ
CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  buttonType: PropTypes.oneOf([ButtonType.OkOnly, ButtonType.OkCancel])
    .isRequired,
};

export default CustomDialog;
