import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CustomModal from "../../common/customComponents/CustomModal";
import { TimeTable } from "./CreateTime";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import Grid from "@mui/material/Grid";
import { LanguageContext } from "../../common/localization/localization";
import axios, { AxiosError } from "axios";
import { IndividualBid } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import { isConstructorDeclaration } from "typescript";
import { PaletteContext } from "../../common/paletteMode";
import { useAuthedApi } from "../../../common/axios";
import { BUY_SELL_KIND, ERR_CODE_SOAP_ERR, RESULT_CODE } from "../../../common/constant";
import { BidingCell } from "../../../types/jepx/OneHourAdvanceBidPlan";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//グリッドのコンテナ
const GetGridConStyle = () => {
  const { PaletteMode } = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#2e2e2e" : "rgb(245, 245, 245)";
};
const GridContainer = styled(Grid)`
  && {
    padding: 8px;
    border-radius: 5px;
    background-color: ${GetGridConStyle};
  }
`;

//アイテムグリッド
const ItemGrid = styled(Grid)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

//内容を表示するグリッド
const TitleGrid = styled(ItemGrid)`
  && {
    color: rgb(150, 150, 150);
  }
`;

//内容を表示するグリッド
const ContentGrid = styled(ItemGrid)`
  && {
    font-weight: 700;
  }
`;

const POST_DELETE_DATA = "/updateBidRequest"; //入札取消要求登録
const DELETE_INTRADAY_BID = "/deleteIntradayBid"; //入札取消実行

//個別取消のモーダル
const BidDelete = (
  open: boolean,
  HaldleClose: () => void,
  selectedCellInfo: BidingCell,
  areaId: string,
  areaName: string,
  targetDate: string,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  getIntradayData: () => Promise<void>,
  getAutoSetting: () => Promise<void>
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  //datePecker
  const dateValue = useContext(DateContext);

  const api = useAuthedApi();
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  //DBの注文ステータスに入札取消要求を登録
  async function updateBidStatus(): Promise<string> {
    return api
      .post(POST_DELETE_DATA, {
        targetDate: targetDate,
        areaId: areaId,
        itemId: (selectedCellInfo.timeCd).toString().padStart(2, "0"),
        buySellKind: selectedCellInfo.type,
        tenderNum: selectedCellInfo.tenderNum,
      })
      .then((res) => {
        return Promise.resolve(res.data.resultCode);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.could_not_canceled_bid);
        setDigOpen(true);
      });
  };

  //入札削除APIを呼び出す
  const deleteBidIntraday = async () => {
    await api
      .get(DELETE_INTRADAY_BID, {
        params: {
          targetDate: targetDate,
          areaId: areaId,
          itemId: (selectedCellInfo.timeCd).toString().padStart(2, "0"),
          buySellKind: selectedCellInfo.type,
          tenderNum: selectedCellInfo.tenderNum,
        },
      })
      .then((res) => {
        setDialogMessage(languageContext.words.canceled_bid);
        setDigOpen(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === ERR_CODE_SOAP_ERR) {
          setDialogMessage(
            languageContext.words.could_not_communicate_jepx
          );
        } else {
          setDialogMessage(languageContext.words.could_not_canceled_bid);
        }
        setDigOpen(true);
      });
  };

  //入札日の出力とnullチェック
  const CreateBidDate = () => {
    let bidDate = "Date get error";
    if (dateValue.pickDate != null) {
      bidDate = languageContext.convertDateToLocalizedStringPoorAcu(
        dateValue.pickDate as Date
      ) as string;
    }
    return bidDate;
  };

  //削除ボタン
  const CancelButtonEvent = async () => {
    setIsLoaded(false);
    const resultCode = await updateBidStatus();
    if(resultCode === RESULT_CODE.SUCCESS) {
      await deleteBidIntraday();
    }
    Promise.all([
      getIntradayData(),
      getAutoSetting(),
    ]).finally(() => {
      setIsLoaded(true);
    })
    HaldleClose();
  };

  //小数点第二位までかつカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  //入札取り消し
  return (
    <>
      <CustomModal
        title={languageContext.words.intraday_bid_delete}
        open={open}
        onAccept={() => CancelButtonEvent()}
        onClose={HaldleClose}
        btnText={languageContext.words.execute_bid_cancellation}
      >
        <Box sx={{ height: "180px", width: "550px", flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <GridContainer container>
                <TitleGrid item>{languageContext.words.area}</TitleGrid>
                <ContentGrid item>{areaName}</ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>
                  {languageContext.words.bidding_date_and_time}
                </TitleGrid>
                <ContentGrid item>{CreateBidDate()}</ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>{languageContext.words.salebuy_type}</TitleGrid>
                <ContentGrid item>
                  {selectedCellInfo.type === BUY_SELL_KIND.BUY
                    ? languageContext.words.buy
                    : languageContext.words.sell}
                </ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>
                  {languageContext.words.bidding_time_zone}
                </TitleGrid>
                <ContentGrid item>
                  {timeData[Number(selectedCellInfo.timeCd) - 1] +
                    "～" +
                    timeData[Number(selectedCellInfo.timeCd)]}
                </ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>{languageContext.words.bid_price}</TitleGrid>
                <ContentGrid item>
                  {convertToLocaleString(selectedCellInfo.bidPrice) + languageContext.words.yen_kwh}
                  {/* 入札価格 */}
                </ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>{languageContext.words.bidding_volume}</TitleGrid>
                <ContentGrid item>
                  {convertToLocaleString(selectedCellInfo.bidValue) + "MWh/h"}
                  {/* 入札量 */}
                </ContentGrid>
              </GridContainer>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <CustomModalProgress open={!isLoaded} />
    </>
  );
};
export default BidDelete;
