/**
 * 渡した時刻を時刻コードに変換する
 * @param date
 * @returns
 */
export function changeDateToTimeCd(date: Date) {
  const HOUR = date.getHours();
  const timeCode = Number((HOUR * 10) / 5 + 1);
  return timeCode;
}
