import { Button } from "@mui/material";
import styled from "styled-components";

//設定の新規登録ボタン
export const NewRegistrationButton = styled(Button)`
  margin-left: auto;
  width: 180px;
  height: 40px;
  && {
    margin-left: auto;
  }
`;

//設定のモーダル出現ボタン
export const ModalButton = styled(Button)`
  && {
    margin-right: 5px;
  }
`;
