import React, { ReactNode } from "react";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//モーダルウィンドウもそろえた方がいいんじゃないか説
interface Props {
  children: ReactNode;
  title: string;
  btnText: string;
  onClose: () => void;
  open: boolean;
}

const CustomModalViewOnly = (props: Props) => {
  const { children, title, btnText, onClose, open } = props;

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        //背景クリックで閉じるのを無効
        onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <Container sx={{ position: "relative" }} maxWidth={false}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={onClose}
            sx={{ position: "relative", right: "-50px" }}
          >
            <CloseIcon fontSize="large" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Paper>
          <DialogTitle id="modal-title">{title}</DialogTitle>
          <Divider />
          <DialogContent
            id="modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              margin: "auto",
              overflowY: "unset",
            }}
          >
            {children}
          </DialogContent>
        </Paper>
      </Container>
    </Modal>
  );
};

export default CustomModalViewOnly;
