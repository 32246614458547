import React, { useState, useEffect, useContext } from "react";
import "./css/SetSupplyList.css";
import { FormLabel, Stack, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { LanguageContext } from "../common/localization/localization";
import { DemandInfoPartModal } from "../../types/master/SetSupplyList";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

type ErrorInput = {
  followSupplierCode: boolean; //負荷追随分供給コード
  followSupplierNo: boolean; //負荷追随分契約者識別番号
  baseSupplierCode: boolean; //ベース分供給コード
  baseSupplierNo: boolean; //ベース分契約者識別番号
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  followSupplierCode: false,
  followSupplierNo: false,
  baseSupplierCode: false,
  baseSupplierNo: false,
};

const SetPartsSupplyList = (
  toOpen: boolean,
  HandleClose: () => void,
  demandIdPK: number,
  demandName: string,
  partSettingFlag: number
) => {
  console.log(partSettingFlag);
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const [demandListInfo, setDemandListInfo] = useState<DemandInfoPartModal>();
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 通信エラー
  const api = useAuthedApi();

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化
  ////

  // 初期化用
  const initializeDemandListInfo: DemandInfoPartModal = {
    demandId: 0,
    followSupplierCode: "",
    followSupplierName: "",
    followSupplierNo: "",
    baseSupplierCode: "",
    baseSupplierName: "",
    baseSupplierNo: "",
    priority: 0,
  };
  useEffect(() => {
    if (toOpen === true) {
      getDemandDataLinkedDemandId();
    } else {
      setDemandListInfo(initializeDemandListInfo);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  //PKに紐づくデータ入手
  const getDemandDataLinkedDemandId = () => {
    setIsLoading(true);
    api
      .get(API_URL.GET_ONE_DEMANDDATA, { params: { demandId: demandIdPK } })
      .then((response) => {
        setIsLoading(false);
        const responseData: DemandInfoPartModal = response.data.result;
        setDemandListInfo(responseData);
      })
      .catch((err) => {
        console.log(err);
        setDemandListInfo(initializeDemandListInfo);
      });
  };

  const onChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name: string = e.currentTarget.name;
    let value: string = e.currentTarget.value;

    const tmpInfo: DemandInfoPartModal = Object.assign({}, demandListInfo);
    switch (name) {
      case "followSupplierCode":
        tmpInfo.followSupplierCode = value;
        break;
      case "followSupplierName":
        tmpInfo.followSupplierName = value;
        break;
      case "followSupplierNo":
        tmpInfo.followSupplierNo = value;
        break;
      case "baseSupplierCode":
        tmpInfo.baseSupplierCode = value;
        break;
      case "baseSupplierName":
        tmpInfo.baseSupplierName = value;
        break;
      case "baseSupplierNo":
        tmpInfo.baseSupplierNo = value;
        break;
      case "priority":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9, 0)) {
          tmpInfo.priority = parseInt(value);
        }
        break;
      default:
        break;
    }
    setDemandListInfo(tmpInfo);
  };

  //テキストフィールドに正しい数値が入っているかチェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseInt(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  //正しい情報かどうか検査
  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (demandListInfo?.followSupplierCode === "") {
      error = true;
      tempInputErrors.followSupplierCode = true;
    }

    if (demandListInfo?.followSupplierNo === "") {
      error = true;
      tempInputErrors.followSupplierNo = true;
    }

    if (demandListInfo?.baseSupplierCode === "") {
      error = true;
      tempInputErrors.baseSupplierCode = true;
    }

    if (demandListInfo?.baseSupplierNo === "") {
      error = true;
      tempInputErrors.baseSupplierNo = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  //demandIdの値をもとにデータを更新する
  function UpdateDatas() {
    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
      return false;
    } else if (error === false) {
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_PART_DEMAND_INFO, demandListInfo)
        .then((response) => {
          setIsLoading(false);
          setJobClear(true);
          setDialogMessage(
            languageContext.words.demand_setting_partial_updated
          );
          setDigOpen(true);
          return true;
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.demand_setting_partial_failed_update
          );
          setDigOpen(true);
          return false;
        });
    }
  }

  // 削除(論理削除)
  const Delete = (demandId: number | undefined) => {
    setIsLoading(true);
    api
      .post(API_URL.DELETE_PART_DEMAND_INFO, { demandId: demandId })
      .then((response) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(
          languageContext.words.demand_setting_partial_cancellation
        );
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.demand_setting_partial_failed_cancellation
        );
        setDigOpen(true);
      });
  };

  const DoEdit = () => {
    if (UpdateDatas()) {
      toOpen = false;
    } else {
      toOpen = true;
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.notified_partial_supply_setting}
      open={toOpen}
      onAcceptLeft={() => DoEdit()}
      onAcceptRight={() => Delete(demandIdPK)}
      onClose={() => HandleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        Number(partSettingFlag) === 0 ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack sx={{ height: "540px", width: "550px" }}>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography>
              {languageContext.words.demander_name}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={demandName}
              onChange={onChangeValue}
              name="followDemandName"
              inputProps={{
                maxLength: 5,
              }}
              disabled={true}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>
        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.supplier_to_following_the_load}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={demandListInfo?.followSupplierName}
            onChange={onChangeValue}
            name="followSupplierName"
            inputProps={{
              maxLength: 50,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.contract_id_to_following_load}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={demandListInfo?.followSupplierNo}
              onChange={onChangeValue}
              name="followSupplierNo"
              inputProps={{
                maxLength: 20,
              }}
              error={inputError.followSupplierNo}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.supplier_code_following_load}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={demandListInfo?.followSupplierCode}
              onChange={onChangeValue}
              name="followSupplierCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.followSupplierCode}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.base_supplier}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={demandListInfo?.baseSupplierName}
            onChange={onChangeValue}
            name="baseSupplierName"
            inputProps={{
              maxLength: 50,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.base_contract_id}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={demandListInfo?.baseSupplierNo}
              onChange={onChangeValue}
              name="baseSupplierNo"
              inputProps={{
                maxLength: 20,
              }}
              error={inputError.baseSupplierNo}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.base_supplier_code}
            </FormLabel>
            <CustomTextFieldSmall
              type="text"
              value={demandListInfo?.baseSupplierCode}
              onChange={onChangeValue}
              name="baseSupplierCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.baseSupplierCode}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>
        <CustomBoxSmall>
          <CustomModalTypography gutterBottom>
            {languageContext.words.priority}
          </CustomModalTypography>
          <CustomTextFieldSmall
            type="number"
            onFocus={(e) => {
              if (Number(demandListInfo?.priority) === 0) {
                e.currentTarget.select();
              }
            }}
            value={Number(demandListInfo?.priority).toFixed(0)}
            onChange={onChangeValue}
            name="priority"
            inputProps={{
              maxLength: 1,
            }}
          ></CustomTextFieldSmall>
        </CustomBoxSmall>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.notified_partial_supply_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPartsSupplyList;
