import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import styled from "styled-components";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TimeTable } from "../jepx/OneHourAdvanceBid/CreateTime";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { FormControl, FormLabel, Stack } from "@mui/material";
import { LeavePageContext } from "../common/customComponents/CustomConfirmLeavePage";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAuthedApi } from "../../common/axios";
import { API_URL, TIME_CODE_MAX } from "../../common/constant";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { CustomTextFieldBig } from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  consumptionSetting,
  resCpnsumptionSetting,
} from "../../types/master/SetOwnConumption";
import { CommonResponse } from "../../types/common/Api";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 0px;
  }
`;

interface Props {
  open: boolean;
  HandleClose: () => void;
  powerId: number;
  contractPower: number;
}

const initValue = {
  consumptionNo: 1,
  consumptionName: "",
  enabled: false,
  values: Array.from({ length: TIME_CODE_MAX }, (v, i) => ({
    timeCode: ("00" + (i + 1)).slice(-2),
    value: 0,
  })),
};

//モーダル
const SetOwnConsumption = (props: Props) => {
  const { open, powerId, contractPower, HandleClose } = props;
  //言語切り替え用データコンテキスト
  const { words } = useContext(LanguageContext);
  const { setBlockLeave } = useContext(LeavePageContext);
  //ログイン中ユーザーのロールコンテキスト
  const { role } = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  const handleClose = () => {
    setSelectedNo(initValue.consumptionNo);
    setInputerror(false);
    HandleClose();
  }

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // 設定情報
  const [ownComsumptionsSettings, setOwnConsumptionSettings] = useState<
    consumptionSetting[]
  >([]);
  // 変更値
  const [newOwnComsumptionsSetting, setNewOwnComsumptionsSetting] =
    useState<consumptionSetting>(initValue);
  // 選択中の設定番号
  const [selectedNo, setSelectedNo] = useState(1);
  // 選択中の設定情報
  const selectedOwnConsumptionSetting = useMemo(
    () => ownComsumptionsSettings?.find((v) => v.consumptionNo === selectedNo),
    [ownComsumptionsSettings, selectedNo]
  );

  const settingOptions = Array.from({ length: 5 }).map((_, i) => ({
    id: i + 1,
    name: words.setting + (i + 1),
  }));

  const enabledOptions = [
    { id: 0, name: words.invalid },
    { id: 1, name: words.valid },
  ];

  // 入力内容チェック
  const isCorrectInfo = useCallback(() => {
    let error = false;
    // 名称未入力チェック
    if (!newOwnComsumptionsSetting?.consumptionName) {
      error = true;
    }

    setInputerror(error);
    return error;
  }, [newOwnComsumptionsSetting]);

  // 設定取得API
  const getOwnConsumptionSettings = useCallback(() => {
    return api
      .get<CommonResponse<resCpnsumptionSetting>>("/ownConsumptionSettings", {
        params: { powerId },
      })
      .then((res) => {
        setOwnConsumptionSettings(res.data.result);
        setNewOwnComsumptionsSetting(
          res.data.result.find((v) => v.consumptionNo === selectedNo) ||
            initValue
        );
      })
      .catch((err) => {
        //setOwnConsumptionSettings(initValue);
      });
  }, [api, powerId, selectedNo]);

  // 設定保存API
  const saveOwnConsumptionSetting = useCallback(() => {
    if (isCorrectInfo()) {
      setDialogMessage(words.setting_failed_update);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post<CommonResponse<undefined>>("/saveOwnConsumptionSetting", {
          powerId,
          record: newOwnComsumptionsSetting,
        })
        .then((res) => {
          setDialogMessage(words.own_consumption_setting_saved);
          setDigOpen(true);
          // 再取得
          getOwnConsumptionSettings();
          setBlockLeave(false);
        })
        .catch((err) => {
          setDialogMessage(words.own_consumption_setting_failed_save);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [api, isCorrectInfo, words, newOwnComsumptionsSetting, powerId]);

  // 設定削除API
  const deleteOwnConsumptionSetting = useCallback(() => {
    setIsLoading(true);
    api
      .post<CommonResponse<undefined>>("/deleteOwnConsumptionSetting", {
        powerId,
        consumptionNo: selectedNo,
      })
      .then((res) => {
        setDialogMessage(words.own_consumption_setting_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(words.own_consumption_setting_faled_delete);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, powerId, selectedNo]);

  // 編集権限取得API
  const checkEditRoll = useCallback(() => {
    return api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: role } })
      .then((res) => {
        setEnableEdit(res.data);
      });
  }, [api, role]);

  // 初期表示
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      (async () => {
        Promise.all([checkEditRoll(), getOwnConsumptionSettings()]).finally(
          () => {
            setIsLoading(false);
          }
        );
      })();
    } else {
      setNewOwnComsumptionsSetting(initValue);
      setBlockLeave(false);
    }
  }, [open]);

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [inputError, setInputerror] = useState<boolean>(false); // 必須項目チェック
  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  return (
    <>
      <CustomSettingModal
        title={words.own_consumption_setting_tittle}
        btnTextLeft={words.registration}
        btnTextRight={words.delete}
        onAcceptLeft={async () => {
          saveOwnConsumptionSetting();
        }}
        onAcceptRight={() => {
          deleteOwnConsumptionSetting();
        }}
        onClose={handleClose}
        open={open}
        btnType={enableEdit ? NumOfButton.Double : NumOfButton.Single}
      >
        <Stack direction="column" sx={{ width: "550px" }}>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormControl>
                <FormLabel>{words.own_consumption_setting_tittle}</FormLabel>
                <CustomSelectListSmall
                  value={Number(newOwnComsumptionsSetting?.enabled)}
                  options={enabledOptions}
                  onChange={(v: number) => {
                    setNewOwnComsumptionsSetting((prev) =>
                      prev
                        ? {
                            ...prev,
                            enabled: Boolean(v),
                          }
                        : prev
                    );
                  }}
                  blocked={false}
                />
              </FormControl>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormControl>
                <FormLabel>{words.own_consumption_setting_no}</FormLabel>
                <CustomSelectListSmall
                  value={selectedNo}
                  options={settingOptions}
                  onChange={(id: number) => {
                    setSelectedNo(id);
                    setNewOwnComsumptionsSetting((prev) =>
                      prev
                        ? {
                            ...(ownComsumptionsSettings.find(
                              (v) => v.consumptionNo === id
                            ) ?? initValue),
                            consumptionNo: id,
                          }
                        : prev
                    );
                    setInputerror(false);
                  }}
                />
              </FormControl>
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxBig>
              <FormLabel required>
                {words.own_consumption_setting_name}
              </FormLabel>
              <CustomTextFieldBig
                value={newOwnComsumptionsSetting?.consumptionName}
                onChange={(e) => {
                  setBlockLeave(true);
                  setNewOwnComsumptionsSetting((prev) =>
                    prev ? { ...prev, consumptionName: e.target.value } : prev
                  );
                }}
                inputProps={{
                  maxLength: 64,
                }}
                error={inputError}
              />
            </CustomBoxBig>
          </Stack>
          <TableContainer sx={{ width: "100%", height: "470px" }}>
            <StyledTable
              sx={{
                width: "100%",
                height: "100%",
                borderCollapse: "separate",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                }}
              >
                <TableRow>
                  <StyledTableHeaderLG align="center" rowSpan={2}>
                    {words.timeB}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG align="center" colSpan={2}>
                    {words.own_consumption_setting_value}
                  </StyledTableHeaderLG>
                </TableRow>
                <TableRow>
                  <StyledTableHeaderLG align="center">
                    {words.set_value}
                    <br></br>
                    {"(kWh)"}
                  </StyledTableHeaderLG>
                  <StyledTableHeaderLG align="center">
                    {words.changed}
                    <br></br>
                    {"(kWh)"}
                  </StyledTableHeaderLG>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from({ length: TIME_CODE_MAX }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell align="center" style={{ textAlign: "center" }}>
                      {timeData[index]}～{timeData[index + 1]}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ padding: "0px 8px 0px 0px" }}
                    >
                      {selectedOwnConsumptionSetting?.values[index].value || 0}
                    </TableCell>
                    <TableCell style={{ padding: 2 }}>
                      <TextField
                        error={false}
                        type="number"
                        inputProps={{
                          tabIndex: index + 1,
                          step: 1,
                          min: 0,
                          max: (contractPower / 2),
                          style: {
                            padding: 0,
                            textAlign: "right",
                            width: "100%",
                          },
                        }}
                        onFocus={(e) => {
                          if (
                            Number(
                              newOwnComsumptionsSetting?.values[index].value
                            ) === 0
                          ) {
                            e.currentTarget.select();
                          }
                        }}
                        value={newOwnComsumptionsSetting?.values[index].value}
                        onChange={(e) => {
                          setBlockLeave(true);
                          let inputValue = Number(e.target.value);
                          // 入力が数値であるかチェックし、範囲外の場合は制限する
                          if (isNaN(inputValue)) {
                            inputValue = 0; // 無効な値の場合、初期化（必要に応じて調整可能）
                          } else if (inputValue < 0) {
                            inputValue = 0; // min値未満の場合
                          } else if (inputValue > (contractPower / 2)) {
                            inputValue = (contractPower / 2); // max値を超える場合
                          }
                          setNewOwnComsumptionsSetting((prev) => ({
                            ...prev,
                            values: prev.values.map((v, i) =>
                              i === index
                                ? {
                                    timeCode: (i + 1)
                                      .toString()
                                      .padStart(2, "0"),
                                    value: inputValue,
                                  }
                                : v
                            ),
                          }));
                        }}
                      ></TextField>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Stack>
        <CustomModalProgress open={isLoading} />
      </CustomSettingModal>
      <CustomDialog
        title={words.own_consumption_setting_tittle}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={() => {
          // 処理なし
        }}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};
export default SetOwnConsumption;
