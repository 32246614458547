// 発電販売計画
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
  useMemo,
  useRef,
} from "react";
import { ClipboardEvent } from "react";
import { format, isBefore } from "date-fns";

import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import Typography from "@mui/material/Typography";
import { StyledButton } from "../../common/styledComponents/styledButton";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import { cellTime } from "../../../common/cellTime";

import * as PfpType from "../../../types/occto/PowerForecastPlan";

import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../../common/area";
import { usePowerPlantUtility } from "../../../common/powerEnergy";
import { AREA_ID, BUSINESS_MODEL } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import {
  SalesTableRender,
  ProviderTableRender,
} from "./PowerForecastPlanTableRender";
import * as PlanType from "../../../types/occto/Common";
import { changeDateToTimeCd } from "../../common/changeDateCd";
import { autoScrollPage } from "../../common/ScrollPage";
import { BusinessModelContext } from "../../common/customComponents/BusinessModelProvider";

// API URL
// エリア一覧
//const AREA_LIST_URL = "/getAreaNameList";

// データ取得
const GET_PFP_TRAN_URL = "/getPowerForecastPlanTran"; // 取引計画
const GET_PFP_POWER_URL = "/getPowerForecastPlanPower"; // 発電計画
const GET_PFP_SALE_URL = "/getPowerForecastPlanSale"; // 販売計画
const GET_PFP_PROVIDE_URL = "/getPowerForecastPlanProvide"; // 調達計画
const GET_PFP_HEADER_URL = "/getPowerForecastPlanHeader"; // ヘッダ

// データ登録
const POST_PFP_DATA_URL = "/postPowerForecastPlan";

// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan";

// テーブル行数
const CELLCOUNT = 48;

/**
 * 発電・販売計画画面
 * @returns
 */
const PowerForecastPlan = () => {
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // エリアコンテキスト
  const { areaId, setAreaId } = useContext(AreaContext);
  // サーバ時刻
  const timeContext = useContext(TimeContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  // データ入力感知
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  // 描画監視
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [digOpenOkOnly, setDigOpenOkOnly] = useState<boolean>(false);
  const [digMessageOkOnly, setDigMessageOkOnly] = useState<string>("");
  const [digOpenOkCancel, setDigOpenOkCancel] = useState<boolean>(false);
  const [digMessageOkCancel, setDigMessageOkCancel] = useState<string>("");

  // タブによるテーブルの切替
  const powerTable = 2;
  const salesTable = 3;
  const procurementTable = 4;
  const [tableType, setTabletype] = useState<number>(powerTable);

  // 通信エラーを監視
  const api = useAuthedApi();

  const uchiwakeCellH = 53;
  const ikinaiGrCellH = 83;
  const marketGrCellH = 27;
  const uchiwakeCellW = 124;

  // セレクトリスト
  const [areaList, setAreaList] = useState<string[]>([]); //エリア一覧
  // ビジネスモデルコンテキスト
  const { businessModel } = useContext(BusinessModelContext);

  // 変換Utility
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  const { convertIdToEnergyName } = usePowerPlantUtility();

  // 取引計画テーブル用テーブルヘッダ定義
  const TABLE_DEFINE = [
    { label: languageContext.words.surviceForce, key: "surviceForce" },
    { label: languageContext.words.powerforecast, key: "powerGeneration" },
    { label: languageContext.words.procurement_plan, key: "procurement_plan" },
    { label: languageContext.words.sales_plan, key: "sales_plan" },
    {
      label: languageContext.words.excess_or_deficiency,
      key: "deficiency",
    },
    { label: languageContext.words.reserveForce, key: "reserveForce" },
    {
      label: languageContext.words.sellable_quantity,
      key: "sellable_quantity",
    },
  ];

  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();

  // 計画総計テーブル表示
  const [summaryData, setSummaryData] = useState<PfpType.pfpSummary[]>([]);

  // 取引計画列情報
  const [tranPlanItems, setTranPlanItems] = useState<
    PfpType.transactionPlanList[]
  >([]);

  // タブ別テーブル表示情報
  // 発電計画
  const [pfpPowerItems, setPfpPowerItems] = useState<PfpType.powerPlanList[]>(
    []
  );

  // 販売計画
  // 自己託送
  const [pfpSaleSelfItems, setPfpSaleSelfItems] = useState<
    PlanType.midAreaList[]
  >([]);
  // スポット複写用生データ
  // スポット表示用サマリデータ
  const [resPfpSaleSpotItems, setResPfpSaleSpotItems] = useState<
    PlanType.midAreaList[]
  >([]);
  const [pfpSaleSpotItems, setPfpSaleSpotItems] = useState<
    PlanType.midAreaList[]
  >([]);
  // 1時間前複写用生データ
  // 1時間前表示用サマリデータ
  const [resPfpSaleOneHourItems, setResPfpSaleOneHourItems] = useState<
    PlanType.midAreaList[]
  >([]);
  const [pfpSaleOneHourItems, setPfpSaleOneHourItems] = useState<
    PlanType.midAreaList[]
  >([]);

  //調達計画
  // スポット複写用生データ
  // スポット表示用サマリデータ
  const [resPfpProvideSpotItems, setResPfpProvideSpotItems] = useState<
    PlanType.rightAreaList[]
  >(null!);
  const [pfpProvideSpotItems, setPfpProvideSpotItems] = useState<
    PlanType.rightAreaList[]
  >(null!);
  // １時間前複写用生データ
  // １時間前表示用サマリデータ
  const [resPfpProvideOneHourItems, setResPfpProvideOneHourItems] = useState<
    PlanType.rightAreaList[]
  >(null!);
  const [pfpProvideOneHourItems, setPfpProvideOneHourItems] = useState<
    PlanType.rightAreaList[]
  >(null!);

  // オートスクロール制御用
  const [scrollFlag, setScrollFlag] = useState<boolean>(false);

  //現在時刻をコマの番号に変換
  const convertTimeToCd = (time: Date) => {
    console.log(time.toLocaleTimeString().slice(0, -3).padStart(5, "0"));
    let timeCd = 0;
    cellTime.forEach((value, index) => {
      if (
        time.toLocaleTimeString().slice(0, -3).padStart(5, "0") >= value &&
        index !== 48
      ) {
        timeCd = index;
      }
    });
    return timeCd;
  };

  const jepxDispFlag = React.useRef(false);

  // 初期表示情報セット
  useEffect(() => {
    initAreaList();
    dicideSaveButtonVisibility();
    leavePageContext.setBlockLeave(false);
  }, []);

  // 計画総計テーブル表示更新
  useEffect(() => {
    if (
      summaryData === null ||
      summaryData === undefined ||
      summaryData.length === 0
    ) {
      const tempList: PfpType.pfpSummary[] = new Array(CELLCOUNT)
        .fill(true)
        .map((_, index) => ({
          timeCd: `${cellTime[index]} ～ ${cellTime[index + 1]}`,
          supply: 0,
          power: 0,
          procurement: 0,
          sale: 0,
          excessOrDeficiency: 0,
          sparePower: 0,
          possibleBudget: 0,
          demandPlan: 0,
        }));
      setSummaryData(tempList);
      setScrollFlag(true);
    }
  }, []);

  // 各タブ計画値取得
  useEffect(() => {
    if (areaId !== AREA_ID.NONE) {
      setIsLoaded(false);
      getPlans();
      autoScrollMethod();
    }
  }, [areaId, dateContext.pickDate]);

  // 計画総計テーブル表示更新
  useEffect(() => {
    ConstantDataTable();
  }, [
    pfpPowerItems,
    pfpSaleSelfItems,
    pfpSaleSpotItems,
    pfpSaleOneHourItems,
    pfpProvideSpotItems,
    pfpProvideOneHourItems,
    ignored,
  ]);

  useEffect(() => {
    // 初期表示時のみ有効
    if (scrollFlag) {
      autoScrollMethod();
      setScrollFlag(false);
    }
  }, [summaryData]);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 保存ボタン押下
  const OnAccept = () => {
    if (isDivfifty()) {
      execPfpPower();
    } else {
      setDigMessageOkCancel(languageContext.words.save_check);
      setDigOpenOkCancel(true);
    }
  };

  const isDivfifty = () => {
    let flagDivFifty = true;
    const cells = new Array(CELLCOUNT).fill(0);
    //販売計画のスポット計画に50kWhで割り切れない数があるかチェック
    cells.map((cell, index) => {
      pfpSaleSpotItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一時間前計画に50kWhで割り切れない数があるかチェック
      pfpSaleOneHourItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一スポット計画に50kWhで割り切れない数があるかチェック
      pfpProvideSpotItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一時間前計画に50kWhで割り切れない数があるかチェック
      pfpProvideOneHourItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
    });

    return flagDivFifty;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // オートスクロール
  const autoScrollMethod = () => {
    if (
      format(dateContext.pickDate as Date, "yyy/MM/dd") ===
      format(timeContext.time as Date, "yyy/MM/dd")
    ) {
      autoScrollPage(
        "powerForecastPlanTimeCell" + changeDateToTimeCd(timeContext.time)
      );
    }
  };

  //ヘッダーデータの生成
  // エリア名
  const initAreaList = () => {
    setAreaList(
      businessModel
        .filter(
          (model) =>
            model.businessModel === BUSINESS_MODEL.IKINAI ||
            model.businessModel === BUSINESS_MODEL.IKIGAI_POWER ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ||
            model.businessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN ||
            model.businessModel === BUSINESS_MODEL.MARKET_TRAN
        )
        .map((v) => v.areaId)
    );
  };

  // 取引計画取得
  const getPfpTran = () => {
    const requestParam: PfpType.reqPowerForecastPlanTransaction = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_TRAN_URL, { params: requestParam })
      .then((res) => {
        if (
          res.data.result.transactionPlanList !== undefined &&
          res.data.result.transactionPlanList.length !== 0
        ) {
          res.data.result.transactionPlanList.forEach(
            (element: PfpType.transactionPlanList) => {
              if (element.supply === null) {
                element.supply = 0;
              }
              if (element.power === null) {
                element.power = 0;
              }
              if (element.procurement === null) {
                element.procurement = 0;
              }
              if (element.sale === null) {
                element.sale = 0;
              }
              if (element.excessOrDeficiency === null) {
                element.excessOrDeficiency = 0;
              }
              if (element.sparePower === null) {
                element.sparePower = 0;
              }
              if (element.possibleBudget === null) {
                element.possibleBudget = 0;
              }
            }
          );
          if (res.data.result.transactionPlanList.length == 0) {
            const tempList: PfpType.resPowerForecastPlanTransaction = {
              areaId: areaId,
              targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
              transactionPlanList: new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  supply: 0,
                  power: 0,
                  procurement: 0,
                  sale: 0,
                  excessOrDeficiency: 0,
                  sparePower: 0,
                  possibleBudget: 0,
                })),
            };
            setTranPlanItems(tempList.transactionPlanList);
          } else {
            setTranPlanItems(res.data.result.transactionPlanList);
          }
        } else {
          const tempList: PfpType.resPowerForecastPlanTransaction = {
            areaId: areaId,
            targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
            transactionPlanList: new Array(CELLCOUNT)
              .fill(true)
              .map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                supply: 0,
                power: 0,
                procurement: 0,
                sale: 0,
                excessOrDeficiency: 0,
                sparePower: 0,
                possibleBudget: 0,
              })),
          };
          setTranPlanItems(tempList.transactionPlanList);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 計画ヘッダ取得
  const getPfpPowerHd = () => {
    const requestParam: PfpType.reqPowerForecastPlanHeader = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_HEADER_URL, { params: requestParam })
      .then((res) => {
        if (
          res.data.result !== undefined &&
          res.data.result.lastUpdate !== undefined &&
          res.data.result.lastUpdateUser !== undefined &&
          res.data.result.lastUpdate !== null &&
          res.data.result.lastUpdateUser !== null
        ) {
          setLastUpdateInfo({
            date: new Date(res.data.result.lastUpdate),
            user: res.data.result.lastUpdateUser,
          });
        } else {
          setLastUpdateInfo(undefined);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        setLastUpdateInfo(undefined);
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 発電計画取得
  const getPfpPower = () => {
    const requestParam: PfpType.reqPowerForecastPlanPower = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_POWER_URL, { params: requestParam })
      .then((res) => {
        if (
          res.data.result !== undefined ||
          res.data.result.powerPlan.length !== 0
        ) {
          res.data.result.powerPlan.forEach(
            (element: PfpType.powerPlanList) => {
              if (
                element.valueList === undefined ||
                element.valueList.length === 0
              ) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            }
          );
          setPfpPowerItems(res.data.result.powerPlan);
        } else {
          const tempList: PfpType.powerPlanList = {
            powerId: 0,
            powerName: "",
            powerCategory: "",
            maxPower: 0,
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpPowerItems([tempList]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 販売計画取得
  const getPfpSale = () => {
    const requestParam: PfpType.reqPowerForecastPlanSale = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_SALE_URL, { params: requestParam })
      .then((res) => {
        // 自己託送
        if (
          res.data.result.selfConsignmentList !== undefined &&
          res.data.result.selfConsignmentList.length !== 0
        ) {
          const tempSaleSelfItem: PlanType.midAreaList[] = [];
          res.data.result.selfConsignmentList.forEach(
            (element: PlanType.midAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
              tempSaleSelfItem.push({
                demandId: element.demandId,
                powerId: element.powerId,
                customerName: element.customerName,
                areaId: element.areaId,
                saleId: element.saleId,
                saleGroupId: element.saleGroupId,
                salePlanKind: element.salePlanKind,
                marketKind: element.marketKind,
                valueList: element.valueList,
              });
            }
          );
          setPfpSaleSelfItems(tempSaleSelfItem);
        } else {
          setPfpSaleSelfItems([]);
        }

        // スポット
        if (
          res.data.result.spotList !== undefined &&
          res.data.result.spotList.length !== 0
        ) {
          jepxDispFlag.current = true;
          const tempSaleSpotItem: PlanType.midAreaList[] = [];
          res.data.result.spotList.forEach((element: PlanType.midAreaList) => {
            if (element.valueList.length === 0) {
              element.valueList = new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  value: 0,
                }));
            }

            tempSaleSpotItem.push({
              demandId: element.demandId,
              powerId: element.powerId,
              customerName: element.customerName,
              areaId: element.areaId,
              saleId: element.saleId,
              saleGroupId: element.saleGroupId,
              salePlanKind: element.salePlanKind,
              marketKind: element.marketKind,
              valueList: element.valueList,
            });
          });
          setResPfpSaleSpotItems(tempSaleSpotItem);
          const uniqSaleSpotItems = structuredClone(
            Array.from(
              new Map(
                tempSaleSpotItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqSaleSpotItems);
          setPfpSaleSpotItems(uniqSaleSpotItems);
        } else {
          jepxDispFlag.current = false;
          setPfpSaleSpotItems([]);
        }

        // 1時間前
        if (
          res.data.result.oneHourList !== undefined &&
          res.data.result.oneHourList.length !== 0
        ) {
          jepxDispFlag.current = true;
          const tempSaleHourItem: PlanType.midAreaList[] = [];
          res.data.result.oneHourList.forEach(
            (element: PlanType.midAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
              tempSaleHourItem.push({
                demandId: element.demandId,
                powerId: element.powerId,
                customerName: element.customerName,
                areaId: element.areaId,
                saleId: element.saleId,
                saleGroupId: element.saleGroupId,
                salePlanKind: element.salePlanKind,
                marketKind: element.marketKind,
                valueList: element.valueList,
              });
            }
          );
          setResPfpSaleOneHourItems(tempSaleHourItem);
          const uniqSaleOneHourItems = structuredClone(
            Array.from(
              new Map(
                tempSaleHourItem.map((item) => [item.demandId, item])
              ).values()
            )
          );
          console.log(uniqSaleOneHourItems);
          setPfpSaleOneHourItems(uniqSaleOneHourItems);
        } else {
          jepxDispFlag.current = false;
          setPfpSaleOneHourItems([]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 調達計画取得
  const getPfpProvide = () => {
    const requestParam: PfpType.reqPowerForecastPlanProvide = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_PROVIDE_URL, { params: requestParam })
      .then((res) => {
        // スポット
        if (res.data.result !== undefined) {
          if (
            res.data.result.spotList !== undefined &&
            res.data.result.spotList.length !== 0
          ) {
            const tempProvideSpotItem: PlanType.rightAreaList[] = [];
            jepxDispFlag.current = true;
            res.data.result.spotList.forEach(
              (element: PlanType.rightAreaList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
                tempProvideSpotItem.push({
                  demandId: element.demandId,
                  powerId: element.powerId,
                  customerName: element.customerName,
                  areaId: element.areaId,
                  saleId: element.saleId,
                  saleGroupId: element.saleGroupId,
                  salePlanKind: element.salePlanKind,
                  marketKind: element.marketKind,
                  valueList: element.valueList,
                });
              }
            );
            setResPfpProvideSpotItems(tempProvideSpotItem);
            const uniqProvideSpotItems = structuredClone(
              Array.from(
                new Map(
                  tempProvideSpotItem.map((item) => [item.demandId, item])
                ).values()
              )
            );
            console.log(uniqProvideSpotItems);
            setPfpProvideSpotItems(uniqProvideSpotItems);
          } else {
            jepxDispFlag.current = false;
            setPfpProvideSpotItems([]);
          }

          // 1時間前
          if (
            res.data.result.oneHourList !== undefined &&
            res.data.result.oneHourList.length !== 0
          ) {
            jepxDispFlag.current = true;
            const tempProvideHourItem: PlanType.rightAreaList[] = [];
            res.data.result.oneHourList.forEach(
              (element: PlanType.rightAreaList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
                tempProvideHourItem.push({
                  demandId: element.demandId,
                  powerId: element.powerId,
                  customerName: element.customerName,
                  areaId: element.areaId,
                  saleId: element.saleId,
                  saleGroupId: element.saleGroupId,
                  salePlanKind: element.salePlanKind,
                  marketKind: element.marketKind,
                  valueList: element.valueList,
                });
              }
            );
            setResPfpProvideOneHourItems(tempProvideHourItem);
            const uniqProvideOneHourItems = structuredClone(
              Array.from(
                new Map(
                  tempProvideHourItem.map((item) => [item.demandId, item])
                ).values()
              )
            );
            console.log(uniqProvideOneHourItems);
            setPfpProvideOneHourItems(uniqProvideOneHourItems);
          } else {
            jepxDispFlag.current = false;
            setPfpProvideOneHourItems([]);
          }
        } else {
          jepxDispFlag.current = false;
          setPfpProvideSpotItems([]);
          setPfpProvideOneHourItems([]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setIsLoaded(true);
      });
  };

  // 計画登録
  const execPfpPower = () => {
    setIsLoaded(false);
    let index = 0;
    // 発電計画セット
    const tempPowerList: PfpType.postPowerPlanList[] = [];
    for (let i = 0; i < pfpPowerItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        tempPowerList.push({
          powerId: pfpPowerItems[i].powerId,
          timeCd: pfpPowerItems[i].valueList[j].timeCd,
          powerQu: pfpPowerItems[i].valueList[j].value as number,
          dataChgCd: pfpPowerItems[i].valueList[j].dataChgCd,
        });
      }
    }

    // 販売計画セット
    const tempSaleList: PfpType.postSalePlanList[] = [];
    if (pfpSaleSelfItems !== null || pfpSaleSelfItems !== undefined) {
      for (let i = 0; i < pfpSaleSelfItems.length; i++) {
        for (let j = 0; j < CELLCOUNT; j++) {
          // 自己託送
          const tmp: PfpType.postSalePlanList = {
            saleId: pfpSaleSelfItems[i].saleId, // 使っていない
            saleGroupId: pfpSaleSelfItems[i].saleGroupId,
            salePlanKind: pfpSaleSelfItems[i].salePlanKind,
            marketKind: pfpSaleSelfItems[i].marketKind,
            demandId: pfpSaleSelfItems[i].demandId,
            timeCd: pfpSaleSelfItems[i].valueList[j].timeCd,
            powerQu: pfpSaleSelfItems[i].valueList[j].value as number,
            dataChgCd: pfpSaleSelfItems[i].valueList[j].dataChgCd,
          };
          tempSaleList.push(tmp);
        }
      }
    }

    for (let i = 0; i < pfpSaleSpotItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: PfpType.postSalePlanList = {
          saleId: pfpSaleSpotItems[i].saleId, // 使っていない
          saleGroupId: pfpSaleSpotItems[i].saleGroupId,
          salePlanKind: pfpSaleSpotItems[i].salePlanKind,
          marketKind: pfpSaleSpotItems[i].marketKind,
          demandId: pfpSaleSpotItems[i].demandId,
          timeCd: pfpSaleSpotItems[i].valueList[j].timeCd,
          powerQu: pfpSaleSpotItems[i].valueList[j].value as number,
          dataChgCd: pfpSaleSpotItems[i].valueList[j].dataChgCd,
        };
        tempSaleList.push(tmp);
      }
    }

    for (let i = 0; i < pfpSaleOneHourItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: PfpType.postSalePlanList = {
          saleId: pfpSaleOneHourItems[i].saleId, // 使っていない
          saleGroupId: pfpSaleOneHourItems[i].saleGroupId,
          salePlanKind: pfpSaleOneHourItems[i].salePlanKind,
          marketKind: pfpSaleOneHourItems[i].marketKind,
          demandId: pfpSaleOneHourItems[i].demandId,
          timeCd: pfpSaleOneHourItems[i].valueList[j].timeCd,
          powerQu: pfpSaleOneHourItems[i].valueList[j].value as number,
          dataChgCd: pfpSaleOneHourItems[i].valueList[j].dataChgCd,
        };
        tempSaleList.push(tmp);
      }
    }

    // 調達計画セット
    index = 0;
    const tempProvideList: PfpType.postProvidePlanList[] = [];
    for (let i = 0; i < pfpProvideSpotItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: PfpType.postProvidePlanList = {
          saleId: pfpProvideSpotItems[i].saleId,
          saleGroupId: pfpProvideSpotItems[i].saleGroupId,
          salePlanKind: pfpProvideSpotItems[i].salePlanKind,
          marketKind: pfpProvideSpotItems[i].marketKind,
          demandId: pfpProvideSpotItems[i].demandId,
          timeCd: pfpProvideSpotItems[i].valueList[j].timeCd,
          powerQu: pfpProvideSpotItems[i].valueList[j].value as number,
          dataChgCd: pfpProvideSpotItems[i].valueList[j].dataChgCd,
        };
        tempProvideList.push(tmp);
      }
    }

    for (let i = 0; i < pfpProvideOneHourItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: PfpType.postProvidePlanList = {
          saleId: pfpProvideOneHourItems[i].saleId,
          saleGroupId: pfpProvideOneHourItems[i].saleGroupId,
          salePlanKind: pfpProvideOneHourItems[i].salePlanKind,
          marketKind: pfpProvideOneHourItems[i].marketKind,
          demandId: pfpProvideOneHourItems[i].demandId,
          timeCd: pfpProvideOneHourItems[i].valueList[j].timeCd,
          powerQu: pfpProvideOneHourItems[i].valueList[j].value as number,
          dataChgCd: pfpProvideOneHourItems[i].valueList[j].dataChgCd,
        };
        tempProvideList.push(tmp);
      }
    }

    // 取引計画セット
    index = 0;
    const tempTransaction: PfpType.postTransactionPlanList[] = [];
    for (let i = 0; i < CELLCOUNT; i++) {
      const tmp: PfpType.postTransactionPlanList = {
        timeCd: (index + 1).toString().padStart(2, "0"),
        supply: summaryData[i].supply as number,
        power: summaryData[i].power as number,
        procurement: summaryData[i].procurement as number,
        sale: summaryData[i].sale as number,
        excessOrDeficiency: summaryData[i].excessOrDeficiency as number,
        sparePower: summaryData[i].sparePower as number,
        possibleBudget: summaryData[i].possibleBudget as number,
      };
      tempTransaction.push(tmp);
      index++;
    }

    const requestParam: PfpType.reqPostPowerForecastPlan = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
      powerPlanList: tempPowerList,
      salePlanList: tempSaleList,
      providePlanList: tempProvideList,
      transactionList: tempTransaction,
    };
    api
      .post(POST_PFP_DATA_URL, requestParam)
      .then((res) => {
        // setMessage(
        //   `保存日時 (最終保存者)：${res.data.result.date} (${res.data.result.user})`
        // ); // 保存日、保存者の表示
        setDigOpenOkOnly(true);
        if (res.data.result.duplicated === true) {
          setDigMessageOkOnly(languageContext.words.saved_dupicated);
        } else {
          setDigMessageOkOnly(languageContext.words.note_saved);
        }

        leavePageContext.setBlockLeave(false);
        //不整合通知箇所取得用に再取得
        getPlans();
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        setDigMessageOkOnly(languageContext.words.note_not_saved);
        setDigOpenOkOnly(true);
        console.log(error);
      });
  };

  //saletype --スポットの時に当日だったら非活性にする用
  const isDisabled = (index: number, saletype: number) => {
    const timeCd = convertTimeToCd(timeContext.time);
    //スポット
    if (saletype == 1) {
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd")
      ) {
        return true;
      }
      return false;
    } else {
      //スポット以外
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        (format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd") &&
          timeCd + 2 >= index)
      ) {
        return true;
      }
      return false;
    }
  };

  const getPlans = () => {
    getPfpTran(); // 取引計画
    getPfpPowerHd(); // 発電ヘッダ
    getPfpPower(); // 発電計画
    getPfpSale(); // 販売計画
    getPfpProvide(); // 調達計画
  };

  // 複写
  const powerPlanTransferSalePlan = () => {
    pfpSaleSelfItems.map((salePlan) => {
      pfpPowerItems
        .filter((item) => item.powerId === salePlan.powerId)
        .map((powerItem) => {
          // 域内初期化
          for (let index = 0; index < CELLCOUNT; index++) {
            if (!isDisabled(index, 0)) {
              salePlan.valueList[index].value = 0;
            }
          }
          // 複写
          for (let index = 0; index < CELLCOUNT; index++) {
            if (!isDisabled(index, 0)) {
              salePlan.valueList[index].value =
                Number(salePlan.valueList[index].value) +
                Number(powerItem.valueList[index].value);
            }
          }
        });
    });

    // pfpPowerItemsを取得する際、販売先内訳からdemandIdを取得できていればこのブロックは必要ない
    resPfpSaleSpotItems.map((salePlan) => {
      pfpPowerItems
        .filter((item) => item.powerId === salePlan.powerId)
        .map((powerItem) => {
          // スポット初期化
          for (let index = 0; index < CELLCOUNT; index++) {
            if (!isDisabled(index, 1)) {
              salePlan.valueList[index].value = 0;
            }
          }
          // 複写
          for (let index = 0; index < CELLCOUNT; index++) {
            if (!isDisabled(index, 1)) {
              salePlan.valueList[index].value =
                Number(salePlan.valueList[index].value) +
                Number(
                  Math.floor(Number(powerItem.valueList[index].value) / 50)
                ) *
                  50;
            }
          }
        });
    });

    pfpSaleSpotItems.map((salePlan) => {
      // スポット初期化
      for (let index = 0; index < CELLCOUNT; index++) {
        if (!isDisabled(index, 1)) {
          salePlan.valueList[index].value = 0;
        }
      }
      resPfpSaleSpotItems
        .filter((item) => item.demandId === salePlan.demandId)
        .map((powerItem) => {
          // 複写
          for (let index = 0; index < CELLCOUNT; index++) {
            if (!isDisabled(index, 1)) {
              salePlan.valueList[index].value =
                Number(salePlan.valueList[index].value) +
                Number(
                  Math.floor(Number(powerItem.valueList[index].value) / 50)
                ) *
                  50;
            }
          }
        });
    });

    forceUpdate();
    leavePageContext.setBlockLeave(true);
  };

  /**
   * 自動計算部分のテーブルの構成
   * @returns
   */
  const ConstantDataTable = () => {
    if (
      pfpPowerItems === undefined ||
      pfpPowerItems === null ||
      pfpProvideSpotItems === undefined ||
      pfpProvideSpotItems === null ||
      pfpProvideOneHourItems === undefined ||
      pfpProvideOneHourItems === null ||
      pfpSaleSelfItems === undefined ||
      pfpSaleSelfItems === null ||
      pfpSaleSpotItems === undefined ||
      pfpSaleSpotItems === null ||
      pfpSaleOneHourItems === undefined ||
      pfpSaleOneHourItems === null ||
      tranPlanItems === undefined ||
      tranPlanItems === null ||
      tranPlanItems.length === 0
    ) {
      return;
    }

    const tempList: PfpType.pfpSummary[] = [];
    let tempExcessOrDeficiency: number;
    for (let index = 0; index < CELLCOUNT; index++) {
      // 過不足計算
      tempExcessOrDeficiency =
        pfpPowerItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
        pfpProvideSpotItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
        pfpProvideOneHourItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) -
        (pfpSaleSelfItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
          pfpSaleSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ));

      const temp: PfpType.pfpSummary = {
        // 時刻
        timeCd: cellTime[index] + "～" + cellTime[index + 1],
        // 供給力
        // Σ発電計画 + Σ調達計画
        supply:
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 発電計画
        // Σ右表の発電計画値
        power: pfpPowerItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ),
        // 調達計画
        // Σ右表の調達計画値
        procurement:
          pfpProvideSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 販売計画
        // Σエリア販売計画値 + Σ右表の販売計画値
        sale:
          pfpSaleSelfItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 過不足
        // Σ発電計画 + Σ調達計画 - Σ販売計画
        excessOrDeficiency: tempExcessOrDeficiency,
        // 発電余力
        // Σ最大発電可能量 - Σ発電計画
        sparePower:
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.maxPower)),
            0
          ) / 2 -
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 販売可能量
        // INT（（供給力－販売計画の残量）／50）*50
        possibleBudget: Math.floor(tempExcessOrDeficiency / 50) * 50,
        // 需要計画(販売計画の自己託送より転写)
        demandPlan: pfpSaleSelfItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ),
      };
      tempList.push(temp);
    }
    setSummaryData(tempList);
  };

  const checkNumber = (val: number | undefined) => {
    if (val == undefined) {
      return 0;
    } else {
      return val;
    }
  };

  //線の色
  const changeBordercolor = () => {
    let borderColor: string;

    if (PaletteMode === "dark") {
      borderColor = "1px solid #808080";
    } else {
      borderColor = "1px solid #d3d3d3";
    }

    return borderColor;
  };

  /**
   * 左側 テーブルの構成
   * @returns
   */
  const ConstantTableRender = () => {
    return (
      <StyledTable
        sx={{
          width: 904 / 2,
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{
          borderRightWidth: "2px",
          borderTop: "none",
          borderBottom: "none",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            //background: "rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
            borderTop: changeBordercolor(),
          }}
        >
          <TableRow
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
            }}
          >
            <StyledTableHeaderLG
              align="center"
              sx={{
                width: "75px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <TableRow sx={{ display: "block" }}>
              <StyledTableHeaderLG align="center" sx={{ display: "block" }}>
                {languageContext.words.power_gen_contractor}
              </StyledTableHeaderLG>
              <TableRow sx={{ display: "flex" }}>
                {TABLE_DEFINE.map((data, index) => (
                  <StyledTableHeaderLG
                    key={index + "constant"}
                    align="center"
                    sx={{
                      display: "flex",
                      padding: 0,
                      width: 92,
                      height: 80,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title={data.label}
                  >
                    <Box
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.label}
                      <br />
                      (kWh)
                    </Box>
                  </StyledTableHeaderLG>
                ))}
              </TableRow>
            </TableRow>
            <StyledTableHeaderLG
              align="center"
              sx={{
                width: "108px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              style={{ borderRight: "none" }}
            >
              {languageContext.words.demand_plan}
              <br />
              (kWh)
            </StyledTableHeaderLG>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "0px",
              },
            }}
          >
            {summaryData.map((value, index) => (
              <TableRow
                key={"powerForecastPlan_1" + index}
                sx={{
                  display: "flex",
                }}
              >
                <StyledTableCell
                  key={"powerForecastData_2" + index + "_time"}
                  id={"powerForecastPlanTimeCell" + index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 42,
                    width: 107,
                    padding: 0,
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.timeCd} {/* 時刻 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_3" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.supply.toLocaleString()} {/* 供給力 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_4" + index + "_power"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.power.toLocaleString()} {/* 発電計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_5" + index + "_sale"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.procurement.toLocaleString()} {/* 調達計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_6" + index + "_provide"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.sale.toLocaleString()} {/* 販売計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_7" + index + "_unmatch"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.excessOrDeficiency.toLocaleString()} {/* 過不足 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_8" + index + "_reserve"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.sparePower.toLocaleString()} {/* 発電余力 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_9" + index + "_deal"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.possibleBudget.toLocaleString()} {/* 販売可能量 */}
                </StyledTableCell>

                <StyledTableCell
                  key={"powerForecastData_10" + index + "_demandPlan"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 132,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                  style={{ borderRight: "none" }}
                >
                  {value.demandPlan.toLocaleString()} {/* 需要計画 */}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  /**
   * 右側 発電計画テーブル構成
   * @returns
   */
  const PowerTableRender = () => {
    // 発電種別毎に発電所をグループ化
    const tmpCategory = pfpPowerItems.map((item) => {
      return item.powerCategory;
    });
    const groupCategory = tmpCategory.filter((element, index) => {
      return tmpCategory.indexOf(element) == index;
    });
    // データ返ってくる順番が悪いとpfpPowerItems undefinedで落ちるので直したい
    return (
      <Box sx={{ display: "flex", width: "100%", zIndex: "0" }}>
        <>
          {pfpPowerItems.length === 0 ||
          pfpPowerItems === undefined ||
          areaId === AREA_ID.NONE ? null : (
            <StyledTable
              sx={{
                // display: "block",
                width: "auto",
                height: "100%",
                position: "sticky",
                left: 0,
                zIndex: 0,
                borderCollapse: "separate",
              }}
              style={{
                borderRight: "none",
                borderTop: "none",
                borderBottom: "none",
              }}
            >
              <TableHead
                sx={{
                  display: "block",
                  background: "rgb(220,230,241)",
                  borderTop: changeBordercolor(),
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                  height: 138,
                }}
              >
                <TableRow>
                  {groupCategory.map((category, index) => {
                    return (
                      <>
                        <StyledTableHeaderLG
                          key={"powerData_1" + index + "_areaName"}
                          sx={{ height: 56, padding: 0 /*, width: 124*/ }}
                          align="center"
                          colSpan={
                            pfpPowerItems.filter(
                              (item) => item.powerCategory === category
                            ).length
                          }
                        >
                          {convertIdToEnergyName(category)}
                          {/* 電源名称 */}
                        </StyledTableHeaderLG>
                      </>
                    );
                  })}
                </TableRow>
                <TableRow>
                  {groupCategory.map((category) => {
                    return pfpPowerItems
                      .filter((item) => item.powerCategory === category)
                      .map((val, index) => {
                        return (
                          <>
                            <StyledTableHeaderLG
                              key={"powerData_2" + index + "_areaName"}
                              sx={{
                                //display: "flex",
                                height: 80,
                                padding: 0,
                                minWidth: uchiwakeCellW,
                                maxWidth: uchiwakeCellW,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              title={val.powerName}
                              align="center"
                            >
                              <Box
                                sx={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  marginX: "5px",
                                }}
                              >
                                {val.powerName === undefined ||
                                val.powerName === ""
                                  ? ""
                                  : val.powerName}{" "}
                                <br />
                                (kWh)
                                {/* 発電所名称 */}
                              </Box>
                            </StyledTableHeaderLG>
                          </>
                        );
                      });
                  })}
                </TableRow>
              </TableHead>
              {PowerDataTable()}
            </StyledTable>
          )}
        </>
      </Box>
    );
  };

  // 発電計画データテーブル構成
  const PowerDataTable = () => {
    // 発電種別毎に発電所をグループ化
    const tmpCategory = pfpPowerItems.map((item) => {
      return item.powerCategory;
    });
    const groupCategory = tmpCategory.filter((element, index) => {
      return tmpCategory.indexOf(element) == index;
    });
    const cells = new Array(CELLCOUNT).fill(0);
    return (
      <>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {cells.map((cell, i) => {
              return (
                <TableRow key={i + "powerData"}>
                  {groupCategory.map((category) => {
                    return pfpPowerItems
                      .filter((item) => item.powerCategory === category)
                      .map((item, j) => {
                        return (
                          <StyledTableCell
                            key={"powerData_3" + j + "_areaName"}
                            sx={{
                              height: 42,
                              padding: "0px",
                              paddingRight: "8px",
                              width: 116,
                              backgroundColor: isDisabled(i, 0)
                                ? PaletteMode === "dark"
                                  ? "rgb(90,90,90)"
                                  : "rgb(200,200,200)"
                                : "",
                            }}
                            align="right"
                          >
                            {Math.floor(
                              Number(checkNumber(item.valueList[i].value))
                            ).toFixed(0)}
                          </StyledTableCell>
                        );
                      });
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </ScrollSyncPane>
      </>
    );
  };

  // 右表の要素数取得
  const getCellElement = () => {
    let count = 0;
    count += pfpSaleSelfItems.length;
    count += pfpSaleSpotItems.length;
    count += pfpSaleOneHourItems.length;

    // 自己託送エリア毎に需要家をグループ化
    const tmpSelfAreaCount = pfpSaleSelfItems.map((item) => {
      return item.areaId;
    });
    count += tmpSelfAreaCount.filter((element, index) => {
      return tmpSelfAreaCount.indexOf(element) == index;
    }).length;

    // スポットエリア毎に需要家をグループ化
    const tmpSpotAreaCount = pfpSaleSpotItems.map((item) => {
      return item.areaId;
    });
    count += tmpSpotAreaCount.filter((element, index) => {
      return tmpSpotAreaCount.indexOf(element) == index;
    }).length;
    count += 1; // 全エリア計列分

    // 時間前エリア毎に需要家をグループ化
    const tmpHourAreaCount = pfpSaleSpotItems.map((item) => {
      return item.areaId;
    });
    count += tmpHourAreaCount.filter((element, index) => {
      return tmpHourAreaCount.indexOf(element) == index;
    }).length;
    count += 1; // 全エリア計列分
    return count;
  };

  const TableRender = (): JSX.Element => {
    if (tableType === powerTable) {
      return PowerTableRender();
    } else if (tableType === salesTable) {
      const props: PlanType.MidPlanTableProps = {
        midTabSelfItems: pfpSaleSelfItems,
        midTabSpotItems: pfpSaleSpotItems,
        midTabHourItems: pfpSaleOneHourItems,
        setMidTabSelfItems: setPfpSaleSelfItems,
        setMidTabSpotItems: setPfpSaleSpotItems,
        setMidTabHourItems: setPfpSaleOneHourItems,
        jepxDispFlag: jepxDispFlag.current,
        forceUpdate: forceUpdate,
      };
      return <SalesTableRender {...props} />;
    } else {
      const props: PlanType.RightPlanTableProps = {
        rightTabSpotItems: pfpProvideSpotItems,
        rightTabHourItems: pfpProvideOneHourItems,
        setRightTabSpotItems: setPfpProvideSpotItems,
        setRightTabHourItems: setPfpProvideOneHourItems,
        jepxDispFlag: jepxDispFlag.current,
        forceUpdate: forceUpdate,
      };
      return <ProviderTableRender {...props} />;
    }
  };

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <Box sx={{ display: "flex", width: "50%", alignItems: "center" }}>
          <Box sx={{ width: "200px", margin: "4px" }}>
            <CustomSelectList
              value={areaId}
              options={areaOptions(areaList, false)}
              onChange={setAreaId}
            />
          </Box>
          <GlobalDatePicker isDipsTodayBtn={true} />
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{
              marginLeft: "30px",
              height: "40px",
              whiteSpace: "nowrap",
            }}
          >
            <StyledButton
              onClick={() => {
                setTabletype(powerTable);
                //decideTabsColor(0);
              }}
              style={{
                backgroundColor:
                  tableType === powerTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
            >
              {languageContext.words.power_gen_plan}
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTabletype(salesTable);
                //decideTabsColor(1);
              }}
              style={{
                backgroundColor:
                  tableType === salesTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
            >
              {languageContext.words.sales_plan}
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTabletype(procurementTable);
                //decideTabsColor(2);
              }}
              style={{
                backgroundColor:
                  tableType === procurementTable
                    ? "rgba(25, 118, 210, 0.25)"
                    : "",
              }}
            >
              {languageContext.words.procurement_plan}
            </StyledButton>
          </ButtonGroup>
          <StyledButton
            sx={{
              marginLeft: "30px",
              height: "40px",
              minWidth: "40px",
            }}
            onClick={() => {
              powerPlanTransferSalePlan();
            }}
          >
            {languageContext.words.copy}
          </StyledButton>
        </Box>
        <Box
          sx={{
            height: "40px",
            display: "flex",
            width: "50%",
            justifyContent: "flex-end",
            marginRight: "40px",
          }}
        >
          <Typography
            sx={{
              color: PaletteMode === "dark" ? "lightgray" : "gray",
              width: "660px",
              height: "40px",
              display: "flex",
              alignItems: "center",
            }}
            // align="right"
          >
            {lastUpdateInfo
              ? `${languageContext.words.last_update_info}
              : ${languageContext.convertDateToLocalizedString(
                lastUpdateInfo.date,
                "HH:mm:ss"
              )}
              (${lastUpdateInfo.user})`
              : ""}
          </Typography>
          <SaveDataButton
            hidden={hidden}
            disabled={
              areaId === AREA_ID.NONE ||
              hidden ||
              isBefore(
                new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
                new Date(format(timeContext.time as Date, "yyyy/MM/dd"))
              )
                ? true
                : false
            }
            message={languageContext.words.note_saved}
            onAccept={OnAccept}
          />
        </Box>
      </Box>
      <ScrollSync>
        <TableContainer
          sx={{
            display: "flexed",
            height: "90%",
            backgroundColor: "#00000000",
            width: "auto",
            scrollPaddingTop: "138px",
            overflow: "auto",
            margin: "10px",
            marginTop: "15px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: 904 + getCellElement() * uchiwakeCellW,
              backgroundColor: "#00000000",
            }}
          >
            {ConstantTableRender()}
            {TableRender()}
          </Box>
        </TableContainer>
      </ScrollSync>
      <CustomDialog
        title={languageContext.words.powerforecastplan}
        message={digMessageOkOnly}
        buttonType={ButtonType.OkOnly}
        open={digOpenOkOnly}
        onAccept={acceptHandler}
        onClose={() => setDigOpenOkOnly(false)}
      />
      <CustomDialog
        title={languageContext.words.powerforecastplan}
        message={digMessageOkCancel}
        buttonType={ButtonType.OkCancel}
        open={digOpenOkCancel}
        onAccept={execPfpPower} //OK押下後の挙動
        onClose={() => setDigOpenOkCancel(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </div>
  );
};

export default PowerForecastPlan;
