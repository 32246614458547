// 送電ロス
import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../common/styledComponents/styledTable";

import { Box, Button, TextField, styled } from "@mui/material";
import { LanguageContext } from "../common/localization/localization";
import { PaletteContext } from "../common/paletteMode";
import CustomArealossDatePicker from "../common/customComponents/CustomDatePicker";
import axios from "axios";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  StyledMasterTableContainer,
  StyledMasterTable,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import SaveDataButton from "../common/customComponents/SaveDataButton";
import { TimeContext } from "../common/globalMenu/LiveClock";
import { isValid } from "date-fns";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

export const StyledButton = styled(Button)`
  && {
    min-height: 24px;
    width: auto;
    color: white;
    font-size: 13px;
    padding 6px 16px;
  }
`;

// エリア別送電ロス情報
export interface localLossInfos {
  area: number;
  areaName: string;
  ultraHeighValue: number;
  heighValue: number;
  lowValue: number;
  acceptDate: Date | null;
  ultraHeighValueAfter: string | null;
  heighValueAfter: string | null;
  lowValueAfter: string | null;
}

//バックとのやり取り用エリア別送電ロス情報
export interface lossInfos {
  area: number;
  areaName: string;
  ultraHeighValue: number;
  heighValue: number;
  lowValue: number;
  acceptDate: string | null;
  ultraHeighValueAfter: string | null;
  heighValueAfter: string | null;
  lowValueAfter: string | null;
}

//inputError初期化用
const initInputErrorArr: boolean[] = new Array(30).fill(false);

// 定数一覧一覧画面
const AreaLossList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);
  // 現在時刻
  const { time } = useContext(TimeContext);
  // 当日0:00
  const today = new Date(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    0
  );

  // 通信エラー
  const api = useAuthedApi();

  const { convertIdToAreaName } = useAreaUtility();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  //定数
  const [localLossInfosList, setLocalLossInfosList] = useState<
    localLossInfos[]
  >([
    {
      area: 1,
      areaName: "",
      ultraHeighValue: 0,
      heighValue: 0,
      lowValue: 0,
      acceptDate: null,
      ultraHeighValueAfter: null,
      heighValueAfter: null,
      lowValueAfter: null,
    },
  ]);
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [buttonType, setButtonType] = useState(ButtonType.OkOnly);
  const [inputError, setInputerror] = useState<boolean[]>(initInputErrorArr); //各セルにエラー属性を付与するか否かを管理する
  const [isSaved, setIsSaved] = useState(false);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // 初期表示
    (async () => {
      Promise.all([checkEditRoll(), getLoss()]).finally(() => {
        setIsLoading(false);
      });
    })();
  }, []);

  //即時反映のため、保存時に再レンダリング
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      getLoss().finally(() => {
        setIsSaved(false);
        setIsLoading(false);
      });
    })();
  }, [isSaved]);

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function getLoss() {
    return api
      .get(API_URL.GET_AREA_LOSS)
      .then((res) => {
        //nullを0に置き換え
        const lossList: lossInfos[] = res.data;
        for (let i = 0; i < lossList.length; i++) {
          if (
            // lossList[i].heighValueAfter == null ||
            lossList[i].heighValueAfter == "0"
          ) {
            lossList[i].heighValueAfter = "0.0";
          }

          if (
            // lossList[i].lowValueAfter == null ||
            lossList[i].lowValueAfter == "0"
          ) {
            lossList[i].lowValueAfter = "0.0";
          }

          if (
            // lossList[i].ultraHeighValueAfter == null ||
            lossList[i].ultraHeighValueAfter == "0"
          ) {
            lossList[i].ultraHeighValueAfter = "0.0";
          }
        }

        const localLossList: localLossInfos[] = lossList.map((lossList) => {
          //          const tmp = lossList.heighValue.toFixed(1);

          const localLossList = {
            area: lossList.area,
            areaName: lossList.areaName,
            ultraHeighValue: lossList.ultraHeighValue,
            heighValue: lossList.heighValue,
            lowValue: lossList.lowValue,
            acceptDate:
              lossList.acceptDate === null
                ? null
                : new Date(lossList.acceptDate),
            ultraHeighValueAfter:
              lossList.ultraHeighValueAfter === null
                ? null
                : Number(lossList.ultraHeighValueAfter).toFixed(1),
            heighValueAfter:
              lossList.heighValueAfter === null
                ? null
                : Number(lossList.heighValueAfter).toFixed(1),
            lowValueAfter:
              lossList.lowValueAfter === null
                ? null
                : Number(lossList.lowValueAfter).toFixed(1),
          };
          return localLossList;
        });
        setLocalLossInfosList(localLossList);
      })
      .catch((e) => {
        // 取得エラー処理
        setDialogMessage(languageContext.words.loss_failed_obtain_information);
        setButtonType(ButtonType.OkOnly);
        setDigOpen(true);
      });
  }

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    let tempVal: string = value;
    const valueNum = parseFloat(tempVal);

    const tmpInfo: localLossInfos[] = structuredClone(localLossInfosList);
    switch (name) {
      case "ultraHeighValueAfter":
        if (value === "") {
          tempVal = "";
        } else if (tempVal.match(/^\.\d*$/)) {
          tempVal = tempVal.replace(".", "0.");
        } else if (tempVal.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          tempVal = tempVal.replace("0", "");
        }
        if (
          // parseFloat(tempVal) >= 0 &&
          // parseFloat(tempVal) <= 6.0 &&
          !tempVal.match(/\d+(\.\d\d+)+/)
        ) {
          tmpInfo[index].ultraHeighValueAfter = tempVal;
        }
        break;
      case "heighValueAfter":
        if (value === "") {
          tempVal = "";
        } else if (tempVal.match(/^\.\d*$/)) {
          tempVal = tempVal.replace(".", "0.");
        } else if (tempVal.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          tempVal = tempVal.replace("0", "");
        }
        if (
          // parseFloat(tempVal) >= 1.0 &&
          // parseFloat(tempVal) <= 8.0 &&
          !tempVal.match(/\d+(\.\d\d+)+/)
        ) {
          tmpInfo[index].heighValueAfter = tempVal;
        }
        break;
      case "lowValueAfter":
        if (value === "") {
          tempVal = "";
        } else if (tempVal.match(/^\.\d*$/)) {
          tempVal = tempVal.replace(".", "0.");
        } else if (tempVal.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          tempVal = tempVal.replace("0", "");
        }
        if (
          // parseFloat(tempVal) >= 1.0 &&
          // parseFloat(tempVal) <= 12.0 &&
          !tempVal.match(/\d+(\.\d\d+)+/)
        ) {
          tmpInfo[index].lowValueAfter = tempVal;
        }
        break;
      default:
        break;
    }

    setLocalLossInfosList(tmpInfo);
  }

  function onChangeDateValue(e: Date, index: number) {
    const tmpInfo: localLossInfos[] = structuredClone(localLossInfosList);
    tmpInfo[index].acceptDate = e;
    setLocalLossInfosList(tmpInfo);
  }

  function onSave() {
    // 入力された情報を取得
    //.log(lossInfosList);
    if (IsSafeInfo()) {
      if (isValidDate()) {
        if (isFutureDate()) {
          // DB登録
          setLoss();
        } else {
          setDialogMessage(languageContext.words.loss_err_message_2);
          setButtonType(ButtonType.OkCancel);
          setDigOpen(true);
        }
      } else {
        setDialogMessage(languageContext.words.loss_err_message_3);
        setButtonType(ButtonType.OkOnly);
        setDigOpen(true);
      }
    } else {
      setDialogMessage(languageContext.words.loss_err_message_1);
      setButtonType(ButtonType.OkOnly);
      setDigOpen(true);
    }
  }

  function isValidDate() {
    let safe = true;
    localLossInfosList.forEach((info) => {
      if (!isValid(info.acceptDate) && info.acceptDate !== null) {
        safe = false;
      }
    });
    return safe;
  }

  function isFutureDate() {
    let safe = true;
    localLossInfosList.forEach((info, index) => {
      if ((info.acceptDate as Date) < today && info.acceptDate !== null) {
        safe = false;
      }
    });
    return safe;
  }

  function IsSafeInfo() {
    const errorInputs = [...initInputErrorArr];
    let safeInfo = true;
    localLossInfosList.forEach((info, index) => {
      if (info.acceptDate !== null) {
        if (
          Number(info.ultraHeighValueAfter) > 6 ||
          Number(info.ultraHeighValueAfter) < 0.1 ||
          isNaN(Number(info.ultraHeighValueAfter)) ||
          info.ultraHeighValueAfter == null
        ) {
          errorInputs[index * 3 + 0] = true;
          safeInfo = false;
        }

        if (
          Number(info.heighValueAfter) > 8 ||
          Number(info.heighValueAfter) < 1 ||
          isNaN(Number(info.heighValueAfter)) ||
          info.heighValueAfter == null
        ) {
          errorInputs[index * 3 + 1] = true;
          safeInfo = false;
        }

        if (
          Number(info.lowValueAfter) > 12 ||
          Number(info.lowValueAfter) < 3 ||
          info.lowValueAfter == null ||
          isNaN(Number(info.lowValueAfter))
        ) {
          errorInputs[index * 3 + 2] = true;
          safeInfo = false;
        }
      }
    });
    setInputerror(errorInputs);
    return safeInfo;
  }

  function setLoss() {
    const lossInfoList: lossInfos[] = localLossInfosList.map(
      (localLossInfo) => {
        const lossInfo: lossInfos = {
          area: localLossInfo.area,
          areaName: localLossInfo.areaName,
          ultraHeighValue: Number(localLossInfo.ultraHeighValue),
          heighValue: Number(localLossInfo.heighValue),
          lowValue: Number(localLossInfo.lowValue),
          acceptDate:
            localLossInfo.acceptDate === null
              ? null
              : localLossInfo.acceptDate.toLocaleString(),
          ultraHeighValueAfter: localLossInfo.ultraHeighValueAfter,
          heighValueAfter: localLossInfo.heighValueAfter,
          lowValueAfter: localLossInfo.lowValueAfter,
        };
        return lossInfo;
      }
    );
    setIsLoading(true);
    api
      .post(API_URL.SET_AREA_LOSS, lossInfoList)
      .then((response) => {
        setIsSaved(true);
        // 保存完了ダイアログの表示
        setDialogMessage(languageContext.words.loss_saved_area_information);
        setButtonType(ButtonType.OkOnly);
        setDigOpen(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((r) => {
        setDialogMessage(
          languageContext.words.loss_failed_save_area_infomation
        );
        setButtonType(ButtonType.OkOnly);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    if (buttonType === ButtonType.OkCancel) {
      setLoss();
    }
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    setText: React.Dispatch<React.SetStateAction<localLossInfos[]>>
  ) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    const text = structuredClone(localLossInfosList);
    const errMsg =
      languageContext.words.loss_outside_limit_message +
      " " +
      languageContext.words.range_specification +
      `\n` +
      languageContext.words.extra_high_voltage_range_limit +
      `\n` +
      languageContext.words.high_voltage_range_limit +
      `\n` +
      languageContext.words.low_voltage_range_limit;
    const tmpInputErr = structuredClone(inputError);

    switch (name) {
      case "ultraHeighValueAfter":
        if (
          (0.1 <= parseFloat(value) && parseFloat(value) <= 6.0) ||
          value === ""
        ) {
          text[index].ultraHeighValueAfter =
            text[index].ultraHeighValueAfter === null ||
            text[index].ultraHeighValueAfter === ""
              ? null
              : Number(value).toFixed(1);
          tmpInputErr[index * 3 + 0] = false;
        } else {
          tmpInputErr[index * 3 + 0] = true;
          setDialogMessage(errMsg);
          setButtonType(ButtonType.OkOnly);
          setDigOpen(true);
          //if
          // (parseFloat(value) < 0.1) {
          //   text[index].ultraHeighValueAfter = "0.1";
          // } else if (parseFloat(value) > 6.0) {
          //   text[index].ultraHeighValueAfter = "6.0";
          // } else {
          //   text[index].ultraHeighValueAfter = null;
        }
        break;
      case "heighValueAfter":
        if (
          (1.0 <= parseFloat(value) && parseFloat(value) <= 8.0) ||
          value === ""
        ) {
          text[index].heighValueAfter =
            text[index].heighValueAfter === null ||
            text[index].heighValueAfter === ""
              ? null
              : Number(value).toFixed(1);
          tmpInputErr[index * 3 + 1] = false;
        } else {
          tmpInputErr[index * 3 + 1] = true;
          setDialogMessage(errMsg);
          setButtonType(ButtonType.OkOnly);
          setDigOpen(true);
          // if (parseFloat(value) < 1.0) {
          //   text[index].heighValueAfter = "1.0";
          // } else if (parseFloat(value) > 8.0) {
          //   text[index].heighValueAfter = "8.0";
          // } else {
          //  text[index].heighValueAfter = null;
        }
        break;
      case "lowValueAfter":
        if (
          (3.0 <= parseFloat(value) && parseFloat(value) <= 12.0) ||
          value === ""
        ) {
          text[index].lowValueAfter =
            text[index].lowValueAfter === null ||
            text[index].lowValueAfter === ""
              ? null
              : Number(value).toFixed(1);
          tmpInputErr[index * 3 + 2] = false;
        } else {
          tmpInputErr[index * 3 + 2] = true;
          setDialogMessage(errMsg);
          setButtonType(ButtonType.OkOnly);
          setDigOpen(true);
          // if (parseFloat(value) < 3.0) {
          //   text[index].lowValueAfter = "3.0";
          // } else if (parseFloat(value) > 12.0) {
          //   text[index].lowValueAfter = "12.0";
          // } else {
          // text[index].lowValueAfter = null;
        }
        break;
      default:
        break;
    }
    setInputerror(tmpInputErr);
    setLocalLossInfosList(text);
  };

  const palletContextBase = useContext(PaletteContext);
  return (
    <div
      className={`cn-setting-display ${
        palletContextBase.PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Box
        sx={{
          width: "4%",
          height: "56px",
          display: "flex",

          marginLeft: "auto",
        }}
      >
        <SaveDataButton
          hidden={!enableEdit}
          onAccept={() => onSave()}
          sx={{ height: "40px" }}
        />
      </Box>
      <CustomDialog
        title={languageContext.words.area_transmission_loss_info}
        message={dialogMessage}
        buttonType={buttonType}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <TableHead
            sx={{
              background: "rgb(220,230,241)",
              position: "sticky",
              top: 0,
              zIndex: 1,
              height: 42,
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.area}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.extra_high_voltage}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.high_voltage}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.low_voltage}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.extra_high_voltage}(
                {languageContext.words.changed})
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.high_voltage}(
                {languageContext.words.changed})
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.low_voltage}(
                {languageContext.words.changed})
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.date_of_change_reflection}
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ background: "rgb(255,255,255)", overflowY: "scroll" }}
          >
            {localLossInfosList.map((data, index) => (
              <TableRow key={index}>
                <StyledTableCell
                  align="center"
                  sx={{ height: "42px", padding: "0px", width: "260px" }}
                >
                  {convertIdToAreaName(data.area.toString())}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ height: "42px", padding: "0px 8px", width: "auto" }}
                >
                  {parseFloat(data.ultraHeighValue.toString()).toFixed(1)}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ height: "42px", padding: "0px 8px", width: "auto" }}
                >
                  {parseFloat(data.heighValue.toString()).toFixed(1)}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ height: "42px", padding: "0px 8px", width: "auto" }}
                >
                  {parseFloat(data.lowValue.toString()).toFixed(1)}
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{
                    height: "42px",
                    padding: "0px",
                    width: "180px",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    disabled={!enableEdit ? true : false}
                    error={inputError[index * 3 + 0]}
                    name="ultraHeighValueAfter"
                    sx={{ margin: "2px" }}
                    inputProps={{
                      step: "0.1",
                      min: "0.1",
                      max: "6.0",
                      style: {
                        padding: 0,
                        width: "195px",
                        height: "30px",
                        textAlign: "right",
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    type="number"
                    onFocus={(e) => {
                      if (Number(data.ultraHeighValueAfter) === 0) {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      processText(e, index, setLocalLossInfosList);
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={data.ultraHeighValueAfter || ""}
                    onChange={(e) => onChangeValue(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{
                    height: "42px",
                    padding: "0px",
                    width: "180px",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    disabled={!enableEdit ? true : false}
                    error={inputError[index * 3 + 1]}
                    name="heighValueAfter"
                    sx={{ margin: "2px" }}
                    inputProps={{
                      step: "0.1",
                      min: "1.0",
                      max: "8.0",
                      style: {
                        padding: 0,
                        width: "195px",
                        height: "30px",
                        textAlign: "right",
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    type="number"
                    onFocus={(e) => {
                      if (Number(data.heighValueAfter) === 0) {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      processText(e, index, setLocalLossInfosList);
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={data.heighValueAfter || ""}
                    onChange={(e) => onChangeValue(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{
                    height: "42px",
                    padding: "0px",
                    width: "180px",
                    textAlign: "center",
                  }}
                >
                  <TextField
                    disabled={!enableEdit ? true : false}
                    error={inputError[index * 3 + 2]}
                    name="lowValueAfter"
                    sx={{ margin: "2px" }}
                    inputProps={{
                      step: "0.1",
                      min: "3.0",
                      max: "12.0",
                      style: {
                        padding: 0,
                        width: "195px",
                        height: "30px",
                        textAlign: "right",
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    type="number"
                    onFocus={(e) => {
                      if (Number(data.lowValueAfter) === 0) {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      processText(e, index, setLocalLossInfosList);
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    value={data.lowValueAfter || ""}
                    onChange={(e) => onChangeValue(e, index)}
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ height: "42px", padding: "0px", width: "160px" }}
                >
                  <CustomArealossDatePicker
                    enableEdit={enableEdit}
                    minDate={time}
                    name="acceptDate"
                    setValue={(e: Date) => onChangeDateValue(e, index)}
                    value={data.acceptDate}
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

export default AreaLossList;
