import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import { StyledButton } from "../common/styledComponents/styledButton";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import CustomDialog, { ButtonType }  from "../common/customComponents/CustomDialog";
import { LanguageContext } from "../common/localization/localization";
import { PaletteContext } from "../common/paletteMode";
import { useAuthedApi } from "../../common/axios";
import BalanceOfPaymentsReport from "./BalanceOfPaymentsReport";
import { useDownloadReportKindUtility } from "../../common/downloadReportKind";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { DOWNLOAD_REPORT_KIND } from "../../common/constant";

// API URL
const GET_REPORT_INFO_URL = "/getReportInfo";

/**
 * 帳票出力画面
 */
const DownloadReport = () => {
  // 言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  const { downloadReportKindOptions } = useDownloadReportKindUtility();
  // エラー用のState
  const [error, setError] = useState<any>();
  // レンダリング対応
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  // ボタン制御
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const api = useAuthedApi();
  // セレクトタブのState
  const [formKind, setFromKind] = useState<number>();

  const [isBidBalanceOpen, setIsBidBalanceOpen] = useState<boolean>(false);
  const HandleClose = () => {
    setIsBidBalanceOpen(false);
  };

  // 帳票種別リスト
  const [downladReportKindList, setDownloadReportKindList] = useState<string[]>([]);
  // エリア一覧
  const [areaList, setAreaList] = useState<string[]>([]);
  // 発電実績が存在する最古の日付（操作日前日以前）
  const [powerActualEarliestDate, setPowerActualEarliestDate] = useState<Date>();

  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [digOpen, setDigOpen] = useState<boolean>(false);

  const acceptHandler = () => {
    // 特になし
  }

  // 出力ボタン押下
  const Output = () => {
    if(formKind !== undefined && formKind.toString() === DOWNLOAD_REPORT_KIND.BALANCE_OF_PAYMENTS){
      if(powerActualEarliestDate === undefined) {
        setDialogMessage(languageContext.words.unable_to_download_balance_of_payments_report);
        setDigOpen(true);
      } else {
        setIsBidBalanceOpen(true);
      }
    }
  }

  /**
   * 帳票情報取得
   */
  const getReportInfo = () => {
    api
      .get(GET_REPORT_INFO_URL)
      .then((res) => {
        setAreaList(res.data.areaId.concat());
        setDownloadReportKindList(res.data.reportKind.concat());
        if(res.data.reportKind.length !== 0) {
          setFromKind(res.data.reportKind[0]);
        } else {
          setIsDisabled(true);
        }
        setPowerActualEarliestDate(res.data.minDate === undefined ? undefined : new Date(res.data.minDate));
        setIsLoaded(true);
      })
  }
  
  // 初期表示
  useEffect(() => {
    getReportInfo();
  }, []);

  return (
    <div
      key={"FormOutput_div"}
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <label>{languageContext.words.report_kind}</label>
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <CustomSelectList
          label={""}
          value={formKind}
          options={downloadReportKindOptions(downladReportKindList)}
          onChange={(e: number) => setFromKind(e)}
        />
      <StyledButton 
        sx={{ height: "40px" }}
        disabled={isDisabled}
        onClick={Output}
      >
        {languageContext.words.output}
      </StyledButton>
      <BalanceOfPaymentsReport
        isBidBalanceOpen={isBidBalanceOpen}
        HandleClose={HandleClose}
        areaList={areaList}
        minDate={powerActualEarliestDate as Date}
      ></BalanceOfPaymentsReport>
    </Box>   
    <CustomModalProgress open={!isLoaded} />
    <CustomDialog
        title={languageContext.words.output_report}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
  </div>
  )
};
export default DownloadReport;
