import { createContext, useEffect, useState } from "react";
import React from "react";
import { JpStrings } from "./jp";
import { EnStrings } from "./en";
import { format } from "date-fns";
import { ja } from "date-fns/locale";

export type language = {
  [key: string]: string;
};
export const LanguageLocalStrageKey = "language";

enum areaID {
  hokkaido = "01",
  tohoku = "02",
  tokyo = "03",
  tyubu = "04",
  hokuriku = "05",
  kansai = "06",
  tyugoku = "07",
  shikoku = "08",
  kyusyu = "09",
  okinawa = "10",
}

/*どの言語を使うか選ぶ際の型定義、Modeは日付表示の年部分を先頭か後尾どちらに配置するか決めるのか等、単に単語の置き換えだけでは済まなそうな部分で場合分けするために使う
  その下にあるwordsの中に適宜連想配列を入れ替えて各コンポーネントで使用する
*/
type languageType = {
  mode: string;
  words: language;
  setMode: (mode: React.SetStateAction<string>) => void;
  setWords: (words: React.SetStateAction<language>) => void;
  convertDateToLocalizedString: (date: Date, opt?: string) => string;
  convertDateToLocalizedStringGoodAcu: (date: Date) => string | undefined;
  convertDateToLocalizedStringPoorAcu: (date: Date) => string | undefined;
  convertAreaIdToAreaName: (areaId: areaID) => string;
};

//上のタイプを元にして実際に使うデータコンテキストを作成する
export const LanguageContext = createContext<languageType>({
  mode: "jp",
  words: JpStrings,
  setMode: (mode) => null,
  setWords: (words) => null,
  convertDateToLocalizedString: (date, opt) => "",
  convertDateToLocalizedStringGoodAcu: (date) => "",
  convertDateToLocalizedStringPoorAcu: (date) => "",
  convertAreaIdToAreaName: (num) => "",
});

//App.tsxに並んでいる、使用したいページ間をこのコンポーネントで挟み込む、最初は日本語モード
export const LanguageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [mode, setMode] = useState<string>(initMode);
  const [words, setWords] = useState<language>(JpStrings);

  useEffect(() => {
    switch (mode) {
      case "jp": {
        setWords(JpStrings);
        break;
      }
      case "en": {
        setWords(EnStrings);
        break;
      }
      default: {
        break;
      }
    }
  }, [mode]);

  //各コンポーネントから貰ったDate型を言語モードに応じた表示方法に変えてreturnする関数
  const convertDateToLocalizedString = (date: Date, options = "") => {
    switch (mode) {
      case "jp": {
        return format(date, `yyyy/MM/dd ${options}`, { locale: ja });
      }
      case "en": {
        return format(date, `MM/dd/yyyy ${options}`);
      }
      default: {
        return "";
      }
    }
  };

  //各コンポーネントから貰ったDate型を言語モードに応じた表示方法に変えてreturnする関数
  const convertDateToLocalizedStringGoodAcu = (date: Date) => {
    switch (mode) {
      case "jp": {
        return format(date, "yyyy/MM/dd HH:mm", { locale: ja });
      }
      case "en": {
        return format(date, "MM/dd/yyyy HH:mm");
      }
      default: {
        return;
      }
    }
  };

  //各コンポーネントから貰ったDate型を言語モードに応じた表示方法に変えてreturnする関数
  const convertDateToLocalizedStringPoorAcu = (date: Date) => {
    switch (mode) {
      case "jp": {
        return format(date, "yyyy/MM/dd");
      }
      case "en": {
        return format(date, "MM/dd/yyyy");
      }
      default: {
        return;
      }
    }
  };

  //DBから受け取りフロント側に持ち込まれたstring型エリアIDをstring型地域名に変換する関数
  const convertAreaIdToAreaName = (number: areaID): string => {
    switch (number) {
      case areaID.hokkaido: {
        return words.hokkaido_region;
      }
      case areaID.tohoku: {
        return words.tohoku_region;
      }
      case areaID.tokyo: {
        return words.tokyo_region;
      }
      case areaID.tyubu: {
        return words.tyubu_region;
      }
      case areaID.hokuriku: {
        return words.hokuriku_region;
      }
      case areaID.kansai: {
        return words.kansai_region;
      }
      case areaID.tyugoku: {
        return words.tyugoku_region;
      }
      case areaID.shikoku: {
        return words.shikoku_region;
      }
      case areaID.kyusyu: {
        return words.kyusyu_region;
      }
      case areaID.okinawa: {
        return words.okinawa_region;
      }
      default: {
        return "undefined";
      }
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        mode,
        setMode,
        words,
        setWords,
        convertDateToLocalizedString,
        convertDateToLocalizedStringGoodAcu,
        convertDateToLocalizedStringPoorAcu,
        convertAreaIdToAreaName,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const initMode = () => {
  const ret = localStorage.getItem(LanguageLocalStrageKey);
  return ret ? ret : "jp";
};

//表示単語ベタ書き→key名に置き換えるための作業用関数
export const replaceValueWithKey = () => {
  const keyArray = Object.keys(JpStrings);
  const valueArray = Object.values(JpStrings);
  let code = `
  
  `;

  for (let i = 0; i < keyArray.length; i++) {
    for (let j = 0; j < 100; j++) {
      code = code.replace(
        valueArray[i],
        "{languageContext.words." + keyArray[i] + "}"
      );
    }
  }
};
