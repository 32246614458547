import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import "./css/SetPowerContractList.css";
import { FormGroup, FormLabel, Stack, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LanguageContext } from "../common/localization/localization";
import {
  resSaleGroupList,
  saleDetailSetInfo,
} from "../../types/master/SetSaleDetailList";
import { modalMode } from "./SaleDetailList";
import { resAreaList, resPowerList } from "../../types/common/Search";
import { addDays, format, subDays } from "date-fns";

import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import {
  API_URL,
  AREA_ID,
  RESULT_CODE,
  SALE_KIND,
} from "../../common/constant";
import {
  SaleDetailDetail,
  SaleDetailOptions,
  SaleGroup,
  Target,
} from "../../types/master/SaleDetailList";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { getBusinessModel } from "../common/getBusinessModel";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";

type saleGrInfo = {
  id: number;
  name: string;
  saleGrId: number;
  saleKind: string;
  areaId: string;
  areaName: string;
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  areaId: boolean;
  buyerName: boolean;
  buyerGroupId: boolean;
  demand: boolean;
  power: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  areaId: false,
  buyerName: false,
  buyerGroupId: false,
  demand: false,
  power: false,
};

const SetSaleDetailList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  connectNumberEdit: number
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [saleDetailInfo, setSaleDetailInfo] = useState<SaleDetailDetail>();
  const [saleDetailOptions, setSaleDetailOptions] =
    useState<SaleDetailOptions>();

  // 所属販売先
  const selectableSaleGroups = useMemo(
    () =>
      [
        {
          id: 0,
          name: languageContext.words.setting_unselected,
          saleKind: SALE_KIND.NONE,
          areaId: AREA_ID.NONE,
          targetAreaId: AREA_ID.NONE,
        } as SaleGroup,
      ].concat(saleDetailOptions?.saleGroups ?? []),

    [languageContext.words, saleDetailOptions?.saleGroups]
  );

  // 販売先種別
  const saleKind = useMemo(
    () =>
      selectableSaleGroups.find((v) => v.id === saleDetailInfo?.saleGroupId)
        ?.saleKind,
    [selectableSaleGroups, saleDetailInfo]
  );

  // 供給元エリア
  const selectedAreaId = useMemo(
    () =>
      saleDetailOptions?.saleGroups.find(
        (v) => v.id === saleDetailInfo?.saleGroupId
      )?.areaId ?? AREA_ID.NONE,
    [saleDetailInfo, saleDetailOptions]
  );

  // 供給先エリア
  const selectedTargetAreaId = useMemo(
    () =>
      saleDetailOptions?.saleGroups.find(
        (v) => v.id === saleDetailInfo?.saleGroupId
      )?.targetAreaId ?? AREA_ID.NONE,
    [saleDetailInfo, saleDetailOptions]
  );

  // 発電所
  const selectablePowers = useMemo(
    () =>
      [
        {
          id: 0,
          name: languageContext.words.setting_unselected,
        },
      ].concat(
        saleDetailOptions?.targetOptions.find(
          (v) => v.areaId === selectedAreaId
        )?.powers ?? []
      ),
    [saleDetailOptions, selectedAreaId, languageContext.words]
  );

  // 需要家
  const selectableDemand = useMemo(
    () =>
      [
        {
          id: 0,
          name: languageContext.words.setting_unselected,
        },
      ].concat(
        saleDetailOptions?.targetOptions.find(
          (v) => v.areaId === selectedTargetAreaId
        )?.demands ?? []
      ),
    [saleDetailOptions, selectedTargetAreaId, languageContext.words]
  );

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる

  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化

  //ダイアログ開いたときの初期表示
  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        //編集
        (async () => {
          Promise.all([
            getSaleDetail(connectNumberEdit),
            getSaleDetailOptions(connectNumberEdit),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
      } else {
        (async () => {
          getSaleDetailOptions(connectNumberEdit).finally(() => {
            setIsLoading(false);
          });
        })();
      }
    } else {
      //ダイアログクローズ
      setSaleDetailInfo(initSaleDetailInfo);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化
  ////

  // 発電所・需要家情報取得失敗メッセージの表示判定・
  useEffect(() => {
    if (!toOpen) return;
    if (mode === modalMode.editMode) return;
    if (
      (saleKind === SALE_KIND.INSIDE || saleKind === SALE_KIND.OUTSIDE) &&
      selectablePowers.length <= 1 &&
      selectableDemand.length <= 1
    ) {
      setDialogMessage(
        languageContext.words.setting_can_not_select_power_demand
      );
      setDigOpen(true);
    } else if (
      ((saleKind === SALE_KIND.INSIDE || saleKind === SALE_KIND.OUTSIDE) &&
        selectablePowers.length <= 1 &&
        selectableDemand.length > 1) ||
      (saleKind === SALE_KIND.MARKET && selectablePowers.length <= 1)
    ) {
      setDialogMessage(languageContext.words.setting_can_not_select_power);
      setDigOpen(true);
    } else if (
      (saleKind === SALE_KIND.INSIDE || saleKind === SALE_KIND.OUTSIDE) &&
      selectablePowers.length > 1 &&
      selectableDemand.length <= 1
    ) {
      setDialogMessage(languageContext.words.setting_can_not_select_demand);
      setDigOpen(true);
    }
  }, [saleDetailInfo?.saleGroupId]); // 所属販売先変更時

  //初期化用
  const initSaleDetailInfo: SaleDetailDetail = {
    saleId: 0,
    saleName: "",
    saleGroupId: 0,
    powerId: 0,
    demandId: 0,
    remarks: "",
  };

  // 登録内容チェック
  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (saleDetailInfo?.saleName === "") {
      error = true;
      tempInputErrors.buyerName = true;
    }
    if (saleDetailInfo?.saleGroupId === 0) {
      error = true;
      tempInputErrors.buyerGroupId = true;
    }
    if (saleDetailInfo?.powerId === 0) {
      error = true;
      tempInputErrors.power = true;
    }
    if (
      saleDetailInfo?.demandId === 0 &&
      (saleKind === SALE_KIND.INSIDE || saleKind === SALE_KIND.OUTSIDE)
    ) {
      error = true;
      tempInputErrors.demand = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // 販売先内訳詳細取得API
  const getSaleDetail = (saleId: number) => {
    return api
      .get(API_URL.GET_ONE_SALEDETAIL_DATA, {
        params: { saleId },
      })
      .then((res) => {
        setSaleDetailInfo(res.data.result);
      })
      .catch((err) => {
        setSaleDetailInfo(initSaleDetailInfo);
      });
  };

  // 販売先内訳設定取得API
  function getSaleDetailOptions(saleId: number) {
    return api
      .get(API_URL.GET_SALEDETAIL_OPTIONS, {
        params: { saleId },
      })
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_SALE_GROUP) {
          setDialogMessage(
            languageContext.words.setting_can_not_display_salegr
          );
          setDigOpen(true);
        }

        setSaleDetailOptions(res.data.result);
      });
  }

  // 販売先内訳新規登録API
  async function AddDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      await api
        .post(API_URL.ADD_SALEDETAIL_INFO, saleDetailInfo)
        .then((res) => {
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_detail_added);
          setDigOpen(true);
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.sale_detail_failed_add);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
      (async () => {
        setBusinessModel(await getBusinessModel());
      })();
    }
  }

  // 販売先内訳更新API
  function UpdateDatas() {
    if (isCorrectInfo()) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else {
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_SALEDETAIL_INFO, saleDetailInfo)
        .then((res) => {
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_detail_updated);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.sale_detail_failed_update);
          setDigOpen(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo = Object.assign({}, saleDetailInfo);
    switch (name) {
      case "buyerName":
        tmpInfo.saleName = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;

      default:
        break;
    }
    setSaleDetailInfo(tmpInfo);
  }

  // 販売先内訳削除API
  const Delete = async (saleId: number | undefined) => {
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_SALEDETAIL_INFO, { saleId })
      .then((res) => {
        setJobClear(true);
        setDialogMessage(languageContext.words.sale_detail_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.sale_detail_failed_delete);
        setDigOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  const DoModeJob = () => {
    if (mode === modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.sales_dest_breakdown_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(saleDetailInfo?.saleId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack direction="column" sx={{ width: "550px" }}>
        <CustomBoxBig>
          <FormLabel required>
            {languageContext.words.sales_destination_name}
          </FormLabel>

          <CustomTextFieldBig
            //販売先内訳名称
            type="text"
            value={saleDetailInfo?.saleName}
            onChange={onChangeValue}
            name="buyerName"
            inputProps={{
              maxLength: 64,
            }}
            error={inputError.buyerName}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>
                {languageContext.words.affiliated_sales_destinations}
              </FormLabel>
            </FormGroup>
            <CustomSelectListBig
              value={saleDetailInfo?.saleGroupId}
              options={selectableSaleGroups}
              onChange={(e: number) => {
                setSaleDetailInfo((prev) =>
                  prev
                    ? {
                        ...prev,
                        saleGroupId: e,
                        areaId: AREA_ID.NONE,
                        targetAreaId: AREA_ID.NONE,
                      }
                    : prev
                );
              }}
              error={inputError.buyerGroupId}
              disabled={mode === modalMode.editMode}
            />
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.supplier_area}
            </CustomModalTypography>
            <CustomTextFieldSmall
              value={convertIdToAreaName(selectedAreaId)}
              disabled
            />
          </CustomBoxSmall>

          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>
                {languageContext.words.power_plant}
              </FormLabel>
            </FormGroup>
            <CustomSelectListBig
              value={saleDetailInfo?.powerId}
              options={selectablePowers}
              onChange={(e: number) => {
                setSaleDetailInfo((prev) =>
                  prev ? { ...prev, powerId: e } : prev
                );
              }}
              error={inputError.power}
            />
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.supply_destination_area}
            </CustomModalTypography>

            <CustomTextFieldSmall
              value={convertIdToAreaName(selectedTargetAreaId)}
              disabled={true}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel
                required={
                  saleKind !== SALE_KIND.NONE && saleKind !== SALE_KIND.MARKET
                }
              >
                {languageContext.words.demander}
              </FormLabel>
            </FormGroup>
            <CustomSelectListBig
              value={saleDetailInfo?.demandId}
              options={selectableDemand}
              onChange={(e: number) => {
                setSaleDetailInfo((prev) =>
                  prev ? { ...prev, demandId: e } : prev
                );
              }}
              disabled={
                saleKind === SALE_KIND.NONE || saleKind === SALE_KIND.MARKET
              }
              error={inputError.demand}
            />
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.remarks}
          </CustomModalTypography>
          <CustomTextFieldBig
            //備考
            type="text"
            value={saleDetailInfo?.remarks}
            onChange={onChangeValue}
            name="remarks"
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.sales_dest_breakdown_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetSaleDetailList;
