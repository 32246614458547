import React, { useState, useEffect, useContext } from "react";
import CustomModal from "../common/customComponents/CustomModal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomDatePicker from "../common/customComponents/CustomDatePickerTryal";
import { addDays, subDays, subYears } from "date-fns";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { LanguageContext } from "../common/localization/localization";
import { AreaContext } from "../common/customComponents/CustomAreaContext";
import { useAuthedApi } from "../../common/axios";
import { useAreaUtility } from "../../common/area";
import CustomDialog, { ButtonType } from "../common/customComponents/CustomDialog";
import { AREA_ID, RESULT_CODE } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { TimeContext } from "../common/globalMenu/LiveClock";

interface Props {
  isBidBalanceOpen: boolean,
  HandleClose: () => void,
  areaList: string[],
  minDate: Date
}

const BalanceOfPaymentsReport = (
props : Props
) => {
  const {isBidBalanceOpen, HandleClose, areaList, minDate} = props;

  //API URL
  const DOWNLOAD_BALANCE_OF_PAYMENTS = "/downloadBalanceOfPayments";
  
  // タイムコンテキスト
  const timeContext = useContext(TimeContext);

  const [date, setDate] = useState<Date>(subDays(timeContext.time, 1));
  //DBからデータを取得するまで画面描画を塞き止めるためのstate
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  //言語切り替え用データコンテキスト  
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  const api = useAuthedApi();

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [digOpen, setDigOpen] = useState<boolean>(false);

  const ModalCloseEvent = () => {
    HandleClose();
  };

  const acceptHandler = () => {
    //特になし
  };

  //確定押下
  const DownloadReport = async () => {
    if(areaList.length === 0) {
      setDialogMessage(languageContext.words.no_area_administrated);
      setDigOpen(true);
      return;
    }
    setIsLoaded(false);
    await api
      .post(DOWNLOAD_BALANCE_OF_PAYMENTS, {
        areaId: areaList.filter((v) => v === areaId || areaId === AREA_ID.NONE),
        currentDate: timeContext.time,
        targetYearMonth: date,
      })
      .then((res) => {
        const RES_INDEX = {
          RESULT: 0,
          NO_DATA_AREA: 1,
          FILE_INFO: 2,
        }

        if(res.data[RES_INDEX.RESULT].resultCode === RESULT_CODE.NO_EXIST_ALL_DATA) {
          setDialogMessage(languageContext.words.not_exist_all_balance_of_payments_data);
        } else {
          const binary = atob(res.data[2].fileData);
          const buffer = new Uint8Array(binary.length);
          for (let i = 0; i < binary.length; i++) {
            buffer[i] = binary.charCodeAt(i);
          }
          const blob = new Blob([buffer.buffer], {
            type: res.data[RES_INDEX.FILE_INFO].fileType
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = res.data[RES_INDEX.FILE_INFO].fileName;
          a.click();
          window.URL.revokeObjectURL(url);

          if(res.data[RES_INDEX.RESULT].resultCode === RESULT_CODE.SUCCESS) {
            setDialogMessage(languageContext.words.download_balance_of_payments_success);
          } else if(res.data[RES_INDEX.RESULT].resultCode === RESULT_CODE.NO_EXIST_ALL_DATA) {
            setDialogMessage(languageContext.words.not_exist_all_balance_of_payments_data);
          } else if(res.data[RES_INDEX.RESULT].resultCode === RESULT_CODE.NO_EXIST_SOME_DATA) {
            const areaName: string[] = [];
            res.data[RES_INDEX.NO_DATA_AREA].areaId.forEach((id: string) => {
              areaName.push(convertIdToAreaName(id));
            });
            const msg = languageContext.words.not_exist_some_balance_of_payments_data + "\n" + areaName.join('、');
            setDialogMessage(msg);
          }
        }

        setIsLoaded(true);
        setDigOpen(true);
      })
      .catch((e) => {
        setIsLoaded(true);
        setDialogMessage(languageContext.words.download_balance_of_payments_failed);
        setDigOpen(true);
      });
  };

  if(!isLoaded) {
    return <CustomModalProgress open={true} />;
  }
  return (
    <>
      <CustomModal
        title={languageContext.words.balance_of_payments_report}
        open={isBidBalanceOpen}
        onAccept={DownloadReport}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.confirmed}
        isDoubleBtn={true}
        btnTextDouble={languageContext.words.cancel}
      >
        <Box sx={{ height: "110px", width: "450px" }}>
          <Grid sx={{display: "flex"}}>
            <Grid sx={{display: "grid"}}>
              {languageContext.words.year_and_month}
              <Box sx={{justifyContent: "center"}}>
                <CustomDatePicker 
                  minDate={minDate} 
                  maxDate={subDays(timeContext.time as Date, 1)} 
                  value={date}
                  setValue={setDate}
                  isHiddenDay={true}
                />
              </Box>
            </Grid>
            <Grid sx={{display: "grid"}}>
              {languageContext.words.area}
              <Box sx={{justifyContent: "center"}}>
                <CustomSelectList
                  label={languageContext.words.actual_demand_one_hour_ago}
                  value={areaId}
                  options={areaOptions(areaList, true, true)}
                  onChange={setAreaId}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <CustomDialog
        title={languageContext.words.balance_of_payments_report + languageContext.words.download}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default BalanceOfPaymentsReport;