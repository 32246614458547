import React, {
  ReactNode,
  useId,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "styled-components";
import { ConfirmLeavePage, LeavePageContext } from "./CustomConfirmLeavePage";

interface NavProps {
  pushedEl: HTMLButtonElement | null;
  setPushedEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  text: string;
  icon: ReactNode;
  subNav: NavInfo[];
}
interface NavInfo {
  disabled: boolean;
  text: string;
  to: string;
}

const mainMenuSuffix = "_nav_main";
const subMenuSuffix = "_nav_sub";

const StyledLink = styled(Link)`
  &.MuiButtonBase-root:hover,
  &.MuiButtonBase-root:focus {
    color: white;
    background-color: #1976d2;
  }
`;

const DropDownLink = (props: NavProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isMouseOverMenu, setIsMouseOverMenu] = useState<boolean>(false);
  const [isMouseOverButton, setIsMouseOverButton] = useState<boolean>(false);
  const [digOpen, setDigOpen] = useState(false);
  const { blockLeave, setBlockLeave } = useContext(LeavePageContext);
  const id = useId();
  const refEl = useRef<HTMLButtonElement>(null);
  const url = useRef<string | null>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (refEl.current !== props.pushedEl) {
      handleClose();
    }
  }, [props.pushedEl]);

  const handleEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.type === "mouseenter") {
      setIsMouseOverButton(true);
      setAnchorEl(event.currentTarget);
      props.setPushedEl(event.currentTarget);
    }
    if (event.type === "click") {
      setIsFocused(true);
      setAnchorEl(event.currentTarget);
      props.setPushedEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsMouseOverMenu(false);
    setIsMouseOverButton(false);
    setIsFocused(false);
  };

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  const open = Boolean(isMouseOverButton || isMouseOverMenu || isFocused);

  return (
    <>
      <Button
        ref={refEl}
        id={id + mainMenuSuffix}
        aria-controls={open ? id + subMenuSuffix : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        //onClick={handleEnter}
        onMouseEnter={handleEnter}
        onMouseLeave={() => setIsMouseOverButton(false)}
        disableRipple //クリック時波紋エフェクト無効
        sx={{
          zIndex: 1299,
          height: "100%",
          borderRadius: 0,
          //color: "black",
          "&:hover": {
            color: "white",
            bgcolor: "primary.main",
          },
          "&:focus": {
            color: "white",
            bgcolor: "primary.main",
          },
        }}
        startIcon={props.icon}
        //展開マーク
        //endIcon={<KeyboardArrowDownIcon />
      >
        {props.text}
      </Button>
      <Menu
        id={id + subMenuSuffix}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          onMouseEnter: () => setIsMouseOverMenu(true),
          onMouseLeave: () => setIsMouseOverMenu(false),
        }}
        MenuListProps={{ "aria-labelledby": id + mainMenuSuffix }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        autoFocus={isFocused && !isMouseOverButton}
        sx={{
          zIndex: 1298,
          ".MuiMenu-paper": {
            //marginを設定するとメニューとサブメニュー間が離れる
            borderRadius: 0,
          },
          ".MuiMenu-list": {
            padding: 0,
            margin: 0,
          },
        }}
      >
        {props.subNav.map((item, index) => {
          // {url.current = item.to;}
          return (
            <MenuItem
              component={StyledLink}
              to={item.to}
              key={index}
              disabled={item.disabled}
              onClick={(e: Event) => {
                if (blockLeave) {
                  e.preventDefault();
                  url.current = item.to;
                  setDigOpen(true);
                } else {
                  url.current = item.to;
                  navigate(item.to);
                }
                handleClose();
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Menu>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => {
          setDigOpen(false);
        }}
      />
    </>
  );
};

export default DropDownLink;
