import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

// マスタ大Box
export const CustomBoxBig = styled(Box)(() => ({
  height: "90px",
  width: "550px",
  ".MuiBox-root": {
    marginLeft: "0px",
  },
}));

// マスタ小Box

export const CustomBoxSmall = styled(Box)(() => ({
  height: "90px",
  width: "275px",
  ".MuiBox-root": {
    marginLeft: "0px",
  },
}));
