import React, { ReactNode, createContext, useState } from "react";
import { businessModelList } from "../../../types/system/Login";

type BusinessModelContextType = {
  businessModel: businessModelList[];
  setBusinessModel: React.Dispatch<React.SetStateAction<businessModelList[]>>;
  // initBusinessModelContext: () => void;
};

export const BusinessModelContext = createContext<BusinessModelContextType>(
  {} as BusinessModelContextType
);

/**
 * ビジネスモデル情報を管理するカスタムコンテキスト
 */
const BusinessModelContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [businessModel, setBusinessModel] = useState<businessModelList[]>([
    { areaId: "", businessModel: "" },
  ]);
  // const initBusinessModelContext = () => {
  //   setBusinessModel([{ areaId: "", businessModel: "" }]);
  // };
  return (
    <BusinessModelContext.Provider
      value={{
        businessModel: businessModel,
        setBusinessModel: setBusinessModel,
        // initBusinessModelContext: initBusinessModelContext,
      }}
    >
      {children}
    </BusinessModelContext.Provider>
  );
};

export default BusinessModelContextProvider;
