import { useContext } from "react";
import React from "react";
import { LanguageContext } from "../../common/localization/localization";
import { TableHead, TableRow, Box, TableBody, TextField } from "@mui/material";
import { AREA_ID } from "../../../common/constant";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import * as PlanType from "../../../types/occto/Common";
import { PaletteContext } from "../../common/paletteMode";
import { format } from "date-fns";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { ScrollSyncPane } from "react-scroll-sync";
import { CELLCOUNT } from "../../jepx/OneHourAdvanceBid/IntradayConstant";
import { useAreaUtility } from "../../../common/area";
import { cellTime } from "../../../common/cellTime";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";

// 販売タブタイプ
enum GROUPNUM {
  SELF, // 自己託送
  SPOT, // スポット
  ONE_HOUR, // 1時間前
}

const uchiwakeCellH = 53;
const ikinaiGrCellH = 83;
const marketGrCellH = 27;
const uchiwakeCellW = 124;

const dataCellH = 42;
const dataCellW = uchiwakeCellW;

const powerTable = 2;
const salesTable = 3;
const procurementTable = 4;

const totalColLength = 1;

//線の色
const changeBordercolor = (contxtProps: any) => {
  const { PaletteMode } = contxtProps;
  let borderColor: string;

  if (PaletteMode === "dark") {
    borderColor = "1px solid #808080";
  } else {
    borderColor = "1px solid #d3d3d3";
  }

  return borderColor;
};

// 数値undefチェック
const checkNumber = (val: number | undefined) => {
  if (val == undefined) {
    return 0;
  } else {
    return val;
  }
};

//saletype --スポットの時に当日だったら非活性にする用
const isDisabled = (index: number, saletype: number, contxtProps: any) => {
  const { timeContext, dateContext } = contxtProps;
  const timeCd = convertTimeToCd(timeContext.time);
  //スポット
  if (saletype == 1) {
    if (
      format(timeContext.time, "yyyy-MM-dd") >
        format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
      format(timeContext.time, "yyyy-MM-dd") ===
        format(dateContext.pickDate as Date, "yyyy-MM-dd")
    ) {
      return true;
    }
    return false;
  } else {
    //スポット以外
    if (
      format(timeContext.time, "yyyy-MM-dd") >
        format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
      (format(timeContext.time, "yyyy-MM-dd") ===
        format(dateContext.pickDate as Date, "yyyy-MM-dd") &&
        timeCd + 2 >= index)
    ) {
      return true;
    }
    return false;
  }
};

//現在時刻をコマの番号に変換
const convertTimeToCd = (time: Date) => {
  console.log(time.toLocaleTimeString().slice(0, -3).padStart(5, "0"));
  let timeCd = 0;
  cellTime.forEach((value, index) => {
    if (
      time.toLocaleTimeString().slice(0, -3).padStart(5, "0") >= value &&
      index !== 48
    ) {
      timeCd = index;
    }
  });
  return timeCd;
};
//不整合表示用フラグ
const isMismatch = (chgCd?: string): boolean => {
  return Boolean(chgCd == "2");
};

//販売計画テキストフィールド50kWh単位丸め
const updateBlurPowerPlan = (
  event: any,
  item: any,
  i: number,
  j: number,
  type: number,
  props: PlanType.MidPlanTableProps
) => {
  const {
    //midTabSelfItems,
    midTabSpotItems,
    midTabHourItems,
    //setMidTabSelfItems,
    setMidTabSpotItems,
    setMidTabHourItems,
    forceUpdate,
  } = props;

  if (type === GROUPNUM.SPOT) {
    for (const element of midTabSpotItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          Number(Math.floor(Number(event.target.value) / 50)) * 50;
      }
    }
    setMidTabSpotItems(midTabSpotItems);
  } else if (type === GROUPNUM.ONE_HOUR) {
    // 一時間前
    for (const element of midTabHourItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          Number(Math.floor(Number(event.target.value) / 50)) * 50;
      }
    }
    setMidTabHourItems(midTabHourItems);
  }
  forceUpdate();
};

//調達計画テキストフィールド50kWh単位丸め
const updateBlurProviderPlan = (
  event: any,
  item: any,
  i: number,
  j: number,
  type: number,
  props: PlanType.RightPlanTableProps
) => {
  const {
    rightTabSpotItems,
    rightTabHourItems,
    setRightTabSpotItems,
    setRightTabHourItems,
    forceUpdate,
  } = props;

  if (type === GROUPNUM.SPOT) {
    // スポット
    for (const element of rightTabSpotItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          Number(Math.floor(Number(event.target.value) / 50)) * 50;
      }
    }
    setRightTabSpotItems(rightTabSpotItems);
  } else if (type === GROUPNUM.ONE_HOUR) {
    // 一時間前
    for (const element of rightTabHourItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          Number(Math.floor(Number(event.target.value) / 50)) * 50;
      }
    }
    setRightTabHourItems(rightTabHourItems);
  }
  forceUpdate();
};

//発電販売調達計画のテキストフィールド入力値6桁制限判定
function isMaxLength(value: string) {
  if (value.length > 6) {
    return false;
  } else {
    return true;
  }
}
// 販売計画テキストフィールド更新
function updateSalePlan(
  event: any,
  item: any,
  i: number,
  j: number,
  type: number,
  props: PlanType.MidPlanTableProps
) {
  const {
    midTabSelfItems,
    midTabSpotItems,
    midTabHourItems,
    setMidTabSelfItems,
    setMidTabSpotItems,
    setMidTabHourItems,
    forceUpdate,
  } = props;

  if (type === GROUPNUM.SELF) {
    // 自己託送
    if (midTabSelfItems !== null || midTabSelfItems !== undefined) {
      for (const element of midTabSelfItems) {
        if (element.saleId === item.saleId) {
          element.valueList[j].value =
            event.target.value === "" ? "0" : event.target.value;
        }
      }
    }
    setMidTabSelfItems(midTabSelfItems);
  } else if (type === GROUPNUM.SPOT) {
    // スポット
    for (const element of midTabSpotItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          event.target.value === "" ? "0" : event.target.value;
      }
    }
    setMidTabSpotItems(midTabSpotItems);
  } else if (type === GROUPNUM.ONE_HOUR) {
    // 一時間前
    for (const element of midTabHourItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          event.target.value === "" ? "0" : event.target.value;
      }
    }
    setMidTabHourItems(midTabHourItems);
  }
  forceUpdate();
}

function updateProviderPlan(
  event: any,
  item: any,
  i: number,
  j: number,
  type: number,
  props: PlanType.RightPlanTableProps
) {
  const {
    rightTabSpotItems,
    rightTabHourItems,
    setRightTabSpotItems,
    setRightTabHourItems,
    forceUpdate,
  } = props;

  if (type === GROUPNUM.SPOT) {
    // スポット
    for (const element of rightTabSpotItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          event.target.value === "" ? "0" : event.target.value;
      }
    }
    setRightTabSpotItems(rightTabSpotItems);
  } else {
    // 時間前
    for (const element of rightTabHourItems) {
      if (element.saleId === item.saleId) {
        element.valueList[j].value =
          event.target.value === "" ? "0" : event.target.value;
      }
    }
    setRightTabHourItems(rightTabHourItems);
  }
  forceUpdate();
}

/**
 * 右側 販売計画テーブル構成
 * @returns
 */
export const SalesTableRender = (
  props: PlanType.MidPlanTableProps
): JSX.Element => {
  const { midTabSelfItems, midTabSpotItems, midTabHourItems, jepxDispFlag } =
    props;
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // エリアコンテキスト
  const { areaId } = useContext(AreaContext);
  // サーバ時刻
  const timeContext = useContext(TimeContext);
  // エリア名取得
  const { convertIdToAreaName } = useAreaUtility();
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);

  const contxtProps = {
    languageContext,
    areaId,
    timeContext,
    convertIdToAreaName,
    PaletteMode,
    leavePageContext,
    dateContext,
  };

  const SALEGROUP = [
    languageContext.words.internal_self_consignment,
    languageContext.words.spot,
    languageContext.words.one_hour_ago,
  ];
  return (
    <Box sx={{ display: "flex", width: "100%", zIndex: "0" }}>
      <>
        {/*域内自己託送*/}
        {ConsignmentSaleTableLayout(
          midTabSelfItems,
          GROUPNUM.SELF,
          SALEGROUP[GROUPNUM.SELF],
          jepxDispFlag,
          props,
          contxtProps
        )}
        {/* スポット */}
        {MarketSaleTableLayout(
          midTabSpotItems,
          GROUPNUM.SPOT,
          SALEGROUP[GROUPNUM.SPOT],
          jepxDispFlag,
          props,
          contxtProps
        )}
        {/* 時間前 */}
        {MarketSaleTableLayout(
          midTabHourItems,
          GROUPNUM.ONE_HOUR,
          SALEGROUP[GROUPNUM.ONE_HOUR],
          jepxDispFlag,
          props,
          contxtProps
        )}
      </>
    </Box>
  );
};

// 域内自己託送ヘッダテーブル構成
const ConsignmentSaleTableLayout = (
  pfpItems: PlanType.midAreaList[],
  groupNumber: number,
  saleGroupName: string,
  jepxDispFlag: boolean,
  props: PlanType.MidPlanTableProps,
  contxtProps: any
) => {
  const { languageContext, areaId } = contxtProps;

  return (
    pfpItems === null ||
    pfpItems === undefined ||
    pfpItems.length === 0 ||
    (areaId === AREA_ID.NONE ? null : (
      <StyledTable
        stickyHeader
        sx={{
          padding: 0,
        }}
        style={{
          borderRight: jepxDispFlag === false ? "" : "none",
          width: "auto",
          borderTop: "none",
          borderBottom: "none",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            background: "rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
            borderTop: changeBordercolor(contxtProps),
          }}
        >
          <TableRow>
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: ikinaiGrCellH,
                //width: 124,
              }}
              colSpan={pfpItems.length + totalColLength}
              style={{ borderLeft: "none" }}
            >
              {saleGroupName} {/*域内自己託送*/}
            </StyledTableHeaderLG>
          </TableRow>
          <TableRow>
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: uchiwakeCellH,
                minWidth: uchiwakeCellW,
                maxWidth: uchiwakeCellW,
              }}
            >
              {languageContext.words.subtotal} {/* 小計 */}
              <br />
              (kWh)
            </StyledTableHeaderLG>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {pfpItems.map((item, index) => {
                return (
                  <>
                    <StyledTableHeaderLG
                      key={"costomerName_" + index}
                      sx={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0,
                        height: uchiwakeCellH,
                        minWidth: uchiwakeCellW,
                        maxWidth: uchiwakeCellW,
                      }}
                      style={{
                        borderLeft: "none",
                      }}
                      title={item.customerName}
                    >
                      <Box
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginX: "5px",
                        }}
                      >
                        {item.customerName}
                        <br />
                        (kWh)
                      </Box>
                    </StyledTableHeaderLG>
                  </>
                );
              })}
            </Box>
          </TableRow>
        </TableHead>
        {SalesDataTable(
          [pfpItems[0].areaId],
          pfpItems,
          GROUPNUM.SELF,
          props,
          contxtProps
        )}
      </StyledTable>
    ))
  );
};

// スポット時間前ヘッダテーブル構成
const MarketSaleTableLayout = (
  pfpItems: PlanType.midAreaList[],
  groupNumber: number,
  saleGroupName: string,
  jepxDispFlag: boolean,
  props: PlanType.MidPlanTableProps,
  contxtProps: any
) => {
  const { languageContext, areaId, convertIdToAreaName } = contxtProps;
  const ikigaiPfpItems = pfpItems.filter((v) => v.salePlanKind === "13");
  const sijoPfpItems = pfpItems.filter((v) => v.salePlanKind === "14");
  // エリア毎に需要家をグループ化
  const tmpArea = ikigaiPfpItems.map((item) => {
    return item.areaId;
  });
  const groupArea = tmpArea.filter((element, index) => {
    return tmpArea.indexOf(element) == index;
  });

  return jepxDispFlag === false ||
    pfpItems.length === 0 ||
    pfpItems === undefined ||
    areaId === AREA_ID.NONE ? null : (
    <StyledTable
      stickyHeader
      sx={{
        padding: 0,
      }}
      style={{
        border: "none",
        width: "auto",
      }}
    >
      <TableHead
        sx={{
          display: "block",
          height: 138,
          position: "sticky",
          top: 0,
          zIndex: "1",
          borderTop: changeBordercolor(contxtProps),
        }}
      >
        <TableRow>
          <StyledTableHeaderLG
            sx={{
              textAlign: "center",
              padding: 0,
              height: marketGrCellH,
              //width: 124,
            }}
            colSpan={
              ikigaiPfpItems.length +
              groupArea.length +
              totalColLength +
              (sijoPfpItems.length === 0 ? 0 : 1)
            }
            style={{ borderLeft: "none" }}
          >
            {saleGroupName}
            {/*スポットor時間前*/}
          </StyledTableHeaderLG>
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              colSpan={
                ikigaiPfpItems.length + groupArea.length + totalColLength
              }
              style={{ borderLeft: "none" }}
            >
              {languageContext.words.external_self_consignment}
              {/*域外自己託送*/}
            </StyledTableHeaderLG>
          )}
          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              style={{ borderLeft: "none" }}
            >
              {languageContext.words.market_transactions}
              {/*市場取引*/}
            </StyledTableHeaderLG>
          )}
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
            >
              {languageContext.words.entire_area}
              {/*エリア全体*/}
            </StyledTableHeaderLG>
          )}

          {groupArea.map((items, index) => {
            return (
              <>
                <StyledTableHeaderLG
                  key={"uchiwake_" + index}
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: marketGrCellH,
                    //width: 124,
                  }}
                  // 需要家数+エリア毎の計
                  colSpan={
                    ikigaiPfpItems.filter((element) => element.areaId == items)
                      .length + totalColLength
                  }
                  style={{ borderLeft: "none" }}
                >
                  {convertIdToAreaName(items)}
                  {/*域外エリア名*/}
                </StyledTableHeaderLG>
              </>
            );
          })}
          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              key={"uchiwake_sijo"}
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              style={{ borderLeft: "none" }}
            >
              {convertIdToAreaName(sijoPfpItems[0].areaId)}
              {/*市場エリア名*/}
            </StyledTableHeaderLG>
          )}
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: uchiwakeCellH,
                minWidth: uchiwakeCellW,
                maxWidth: uchiwakeCellW,
              }}
            >
              {languageContext.words.total} {/*エリア全体計*/}
              <br />
              (kWh)
            </StyledTableHeaderLG>
          )}
          {groupArea.map((items, areaIndex) => {
            // 域外自己託送の託送先(元)エリア数分ループ
            return (
              <>
                <StyledTableHeaderLG
                  key={"area_" + areaIndex}
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: uchiwakeCellH,
                    minWidth: uchiwakeCellW,
                    maxWidth: uchiwakeCellW,
                  }}
                >
                  {languageContext.words.subtotal} {/*エリア毎小計*/}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>

                {ikigaiPfpItems
                  .filter((element) => element.areaId == items)
                  .map((item, index) => {
                    // 域外自己託送の託送先(元)エリア内の需要家(発電所)数分ループ
                    return (
                      <StyledTableHeaderLG
                        key={
                          areaIndex +
                          "" +
                          index +
                          (groupNumber == GROUPNUM.SPOT
                            ? "salesSpot"
                            : "salesHour")
                        }
                        sx={{
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 0,
                          height: uchiwakeCellH,
                          minWidth: uchiwakeCellW,
                          maxWidth: uchiwakeCellW,
                        }}
                        style={{ borderLeft: "none" }}
                        title={item.customerName}
                      >
                        <Box
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            marginX: "5px",
                          }}
                        >
                          {item.customerName}
                          {/*需要家/発電所名*/}
                          <br />
                          (kWh)
                        </Box>
                      </StyledTableHeaderLG>
                    );
                  })}
              </>
            );
          })}
          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              key={groupNumber == GROUPNUM.SPOT ? "salesSpot" : "salesHour"}
              sx={{
                //display: "flex",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                height: uchiwakeCellH,
                minWidth: uchiwakeCellW,
                maxWidth: uchiwakeCellW,
              }}
              style={{ borderLeft: "none" }}
              title={sijoPfpItems[0].customerName}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginX: "5px",
                }}
              >
                {sijoPfpItems[0].customerName}
                {/*取引所*/}
                <br />
                (kWh)
              </Box>
            </StyledTableHeaderLG>
          )}
        </TableRow>
      </TableHead>
      {SalesDataTable(groupArea, pfpItems, groupNumber, props, contxtProps)}
    </StyledTable>
  );
};

// 販売計画データテーブル構成
const SalesDataTable = (
  groupArea: any[],
  salesItems: any[],
  saleType: number,
  props: PlanType.MidPlanTableProps,
  contxtProps: any
) => {
  const { PaletteMode, leavePageContext } = contxtProps;
  const cells = new Array(CELLCOUNT).fill(0);
  const ikigaiSalesItems = salesItems.filter(
    (v) => v.salePlanKind === "12" || v.salePlanKind === "13"
  );
  const sijoSalesItems = salesItems.filter((v) => v.salePlanKind === "14");

  return (
    <ScrollSyncPane group="vertical">
      <TableBody
        sx={{
          display: "block",
          overflow: "scroll",
          "&::-webkit-scrollbar": {
            width: "0px",
            backgroundColor: "transparent",
            height: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "0px",
          },
        }}
      >
        {cells.map((cell, row) => {
          return (
            <TableRow key={row}>
              {/* 計 */}
              {saleType === GROUPNUM.SELF ? null : ikigaiSalesItems.length ===
                0 ? null : (
                <StyledTableCell
                  key={"salesData_1" + row + "_areaName"}
                  sx={{
                    height: dataCellH,
                    padding: "0px 8px 0px 0px",
                    width: dataCellW - 8, // padding分減らす
                    backgroundColor: isDisabled(row, 0, contxtProps)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {Math.floor(
                    ikigaiSalesItems.reduce(
                      (sum, val) =>
                        sum + Number(checkNumber(val.valueList[row].value)),
                      0
                    )
                  ).toLocaleString()}
                </StyledTableCell>
              )}

              {groupArea.map((items, areaIndex) => {
                return (
                  <>
                    {/* 計 */}
                    <StyledTableCell
                      key={"salesData_2" + row + "" + areaIndex + "_areaName"}
                      sx={{
                        height: dataCellH,
                        padding: "0px 8px 0px 0px",
                        width: dataCellW - 8, // padding分減らす
                        backgroundColor: isDisabled(row, 0, contxtProps)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="right"
                    >
                      {Math.floor(
                        ikigaiSalesItems
                          .filter((element) => element.areaId == items)
                          .reduce(
                            (sum, val) =>
                              sum +
                              Number(checkNumber(val.valueList[row].value)),
                            0
                          )
                      ).toLocaleString()}
                    </StyledTableCell>
                    {ikigaiSalesItems
                      .filter((element) => element.areaId == items)
                      .map((item, col) => {
                        {
                          /* 入力フィールド */
                        }
                        return (
                          <StyledTableCell
                            key={
                              "salesData_3" +
                              row +
                              "" +
                              areaIndex +
                              "" +
                              col +
                              "_areaName"
                            }
                            sx={{
                              height: dataCellH,
                              padding: "0px",
                              width: dataCellW, //124,
                              backgroundColor: isDisabled(row, 0, contxtProps)
                                ? PaletteMode === "dark"
                                  ? "rgb(90,90,90)"
                                  : "rgb(200,200,200)"
                                : "",
                            }}
                            align="right"
                            style={{ borderLeft: "none" }}
                          >
                            <TextField
                              name="sale"
                              sx={{ margin: "2px" }}
                              inputProps={{
                                style: {
                                  padding: 0,
                                  height: "30px",
                                  textAlign: "right",
                                  backgroundColor: isDisabled(
                                    row,
                                    0,
                                    contxtProps
                                  )
                                    ? PaletteMode === "dark"
                                      ? "rgb(90,90,90)"
                                      : "rgb(200,200,200)"
                                    : "",
                                },
                                min: 0,
                                inputMode: "numeric",
                                tabIndex:
                                  saleType * 100000 +
                                  areaIndex * 10000 +
                                  1 +
                                  col * 100 +
                                  1 +
                                  row,
                                pattern: "[0-9]*",
                                step: saleType === GROUPNUM.SELF ? 1 : 50,
                              }}
                              error={isMismatch(item.valueList[row].dataChgCd)}
                              disabled={isDisabled(row, saleType, contxtProps)}
                              type="number"
                              onKeyDown={(event) => {
                                if (event.key === "." || event.key === "-") {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                if (Number(item.valueList[row].value) === 0) {
                                  e.currentTarget.select();
                                }
                              }}
                              onBlur={(e) => {
                                updateBlurPowerPlan(
                                  e,
                                  item,
                                  col,
                                  row,
                                  saleType,
                                  props
                                );
                              }}
                              value={Math.floor(
                                checkNumber(item.valueList[row].value)
                              ).toFixed(0)}
                              onChange={(e) => {
                                if (isMaxLength(e.target.value)) {
                                  updateSalePlan(
                                    e,
                                    item,
                                    col,
                                    row,
                                    saleType,
                                    props
                                  );
                                }
                                leavePageContext.setBlockLeave(true);
                              }}
                            />
                          </StyledTableCell>
                        );
                      })}
                  </>
                );
              })}
              {sijoSalesItems.length === 0 ? null : (
                <StyledTableCell
                  key={"salesData_3" + row + "_areaName"}
                  sx={{
                    height: dataCellH,
                    padding: "0px",
                    width: dataCellW, //124,
                    backgroundColor: isDisabled(row, 0, contxtProps)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                  style={{ borderLeft: "none" }}
                >
                  <TextField
                    name="sale"
                    sx={{ margin: "2px" }}
                    inputProps={{
                      style: {
                        padding: 0,
                        height: "30px",
                        textAlign: "right",
                        backgroundColor: isDisabled(row, 0, contxtProps)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      },
                      min: 0,
                      inputMode: "numeric",
                      tabIndex: saleType * 100000 + 90000 + row,
                      pattern: "[0-9]*",
                      step: saleType === GROUPNUM.SELF ? 1 : 50,
                    }}
                    error={isMismatch(
                      sijoSalesItems[0].valueList[row].dataChgCd
                    )}
                    disabled={isDisabled(row, saleType, contxtProps)}
                    type="number"
                    onKeyDown={(event) => {
                      if (event.key === "." || event.key === "-") {
                        event.preventDefault();
                      }
                    }}
                    onFocus={(e) => {
                      if (
                        Number(sijoSalesItems[0].valueList[row].value) === 0
                      ) {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      updateBlurPowerPlan(
                        e,
                        sijoSalesItems[0],
                        0,
                        row,
                        saleType,
                        props
                      );
                    }}
                    value={Math.floor(
                      checkNumber(sijoSalesItems[0].valueList[row].value)
                    ).toFixed(0)}
                    onChange={(e) => {
                      if (isMaxLength(e.target.value)) {
                        updateSalePlan(
                          e,
                          sijoSalesItems[0],
                          0,
                          row,
                          saleType,
                          props
                        );
                      }
                      leavePageContext.setBlockLeave(true);
                    }}
                  />
                </StyledTableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </ScrollSyncPane>
  );
};

/**
 * 右側 調達計画テーブル構成
 * @returns
 */
export const ProviderTableRender = (
  props: PlanType.RightPlanTableProps
): JSX.Element => {
  const { rightTabSpotItems, rightTabHourItems, jepxDispFlag } = props;
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // エリアコンテキスト
  const { areaId } = useContext(AreaContext);
  // サーバ時刻
  const timeContext = useContext(TimeContext);
  // エリア名取得
  const { convertIdToAreaName } = useAreaUtility();
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);

  const contxtProps = {
    languageContext,
    areaId,
    timeContext,
    convertIdToAreaName,
    PaletteMode,
    leavePageContext,
    dateContext,
  };

  const PROVIDEGROUP = [
    languageContext.words.internal_other_bg,
    languageContext.words.spot,
    languageContext.words.one_hour_ago,
  ];
  return (
    <Box sx={{ display: "flex", width: "100%", zIndex: "0" }}>
      <>
        {/* スポット */}
        {MarketProvideTableLayout(
          rightTabSpotItems,
          GROUPNUM.SPOT,
          PROVIDEGROUP[GROUPNUM.SPOT],
          jepxDispFlag,
          props,
          contxtProps
        )}
        {/* 時間前 */}
        {MarketProvideTableLayout(
          rightTabHourItems,
          GROUPNUM.ONE_HOUR,
          PROVIDEGROUP[GROUPNUM.ONE_HOUR],
          jepxDispFlag,
          props,
          contxtProps
        )}
      </>
    </Box>
  );
};

// スポット時間前ヘッダテーブル構成
const MarketProvideTableLayout = (
  pfpItems: PlanType.rightAreaList[],
  groupNumber: number,
  saleGroupName: string,
  jepxDispFlag: boolean,
  props: PlanType.RightPlanTableProps,
  contxtProps: any
) => {
  const { languageContext, areaId, convertIdToAreaName } = contxtProps;
  const ikigaiPfpItems: any[] = []; //pfpItems.filter((v) => v.salePlanKind === "13");
  const sijoPfpItems = pfpItems.filter((v) => v.salePlanKind === "14");
  // エリア毎に需要家をグループ化
  const tmpArea = ikigaiPfpItems.map((item) => {
    return item.areaId;
  });
  const groupArea = tmpArea.filter((element, index) => {
    return tmpArea.indexOf(element) == index;
  });

  return jepxDispFlag === false ||
    ((ikigaiPfpItems.length === 0 || ikigaiPfpItems === undefined) &&
      (sijoPfpItems.length === 0 || sijoPfpItems === undefined)) ||
    areaId === AREA_ID.NONE ? null : (
    <StyledTable
      stickyHeader
      sx={{
        padding: 0,
      }}
      style={{
        border: "none",
        width: "auto",
      }}
    >
      <TableHead
        sx={{
          display: "block",
          height: 138,
          position: "sticky",
          top: 0,
          zIndex: "1",
          borderTop: changeBordercolor(contxtProps),
        }}
      >
        <TableRow>
          <StyledTableHeaderLG
            sx={{
              textAlign: "center",
              padding: 0,
              height: marketGrCellH,
              //width: 124,
            }}
            colSpan={
              ikigaiPfpItems.length +
              groupArea.length +
              totalColLength +
              (sijoPfpItems.length === 0 ? 0 : 1)
            }
            style={{ borderLeft: "none" }}
          >
            {saleGroupName}
            {/* スポットor1時間前 */}
          </StyledTableHeaderLG>
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              colSpan={
                ikigaiPfpItems.length + groupArea.length + totalColLength
              }
              style={{ borderLeft: "none" }}
            >
              {languageContext.words.external_self_consignment}
              {/*域外自己託送*/}
            </StyledTableHeaderLG>
          )}

          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              style={{ borderLeft: "none" }}
            >
              {languageContext.words.market_transactions}
              {/*市場取引*/}
            </StyledTableHeaderLG>
          )}
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
            >
              {languageContext.words.entire_area}
              {/*エリア全体*/}
            </StyledTableHeaderLG>
          )}

          {groupArea.map((items, index) => {
            return (
              <>
                <StyledTableHeaderLG
                  key={"uchiwakeProvide_" + index}
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: marketGrCellH,
                    //width: 124,
                  }}
                  // 需要家数+エリア毎の計
                  colSpan={
                    ikigaiPfpItems.filter((element) => element.areaId == items)
                      .length + totalColLength
                  }
                  style={{ borderLeft: "none" }}
                >
                  {convertIdToAreaName(items)}
                  {/*エリア名*/}
                </StyledTableHeaderLG>
              </>
            );
          })}
          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              key={"uchiwakeProvide_sijo"}
              sx={{
                textAlign: "center",
                padding: 0,
                height: marketGrCellH,
                //width: 124,
              }}
              style={{ borderLeft: "none" }}
            >
              {convertIdToAreaName(sijoPfpItems[0].areaId)}
              {/*エリア名*/}
            </StyledTableHeaderLG>
          )}
        </TableRow>
        <TableRow>
          {ikigaiPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              sx={{
                textAlign: "center",
                padding: 0,
                height: uchiwakeCellH,
                minWidth: uchiwakeCellW,
                maxWidth: uchiwakeCellW,
              }}
            >
              {languageContext.words.total} {/*エリア全体計*/}
              <br />
              (kWh)
            </StyledTableHeaderLG>
          )}

          {groupArea.map((items, areaIndex) => {
            return (
              <>
                <StyledTableHeaderLG
                  key={"areaProvide_" + areaIndex}
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: uchiwakeCellH,
                    minWidth: uchiwakeCellW,
                    maxWidth: uchiwakeCellW,
                  }}
                >
                  {languageContext.words.subtotal} {/*小計"*/}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>

                {ikigaiPfpItems
                  .filter((element) => element.areaId == items)
                  .map((item, index) => {
                    return (
                      <StyledTableHeaderLG
                        key={
                          areaIndex +
                          "" +
                          index +
                          (groupNumber == GROUPNUM.SPOT
                            ? "procurementSpot"
                            : "provideHour")
                        }
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          padding: 0,
                          height: uchiwakeCellH,
                          minWidth: uchiwakeCellW,
                          maxWidth: uchiwakeCellW,
                        }}
                        style={{ borderLeft: "none" }}
                        title={item.customerName}
                      >
                        <Box
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            marginX: "5px",
                          }}
                        >
                          {item.customerName}
                          {/*需要家/発電所名*/}
                          <br />
                          (kWh)
                        </Box>
                      </StyledTableHeaderLG>
                    );
                  })}
              </>
            );
          })}
          {sijoPfpItems.length === 0 ? null : (
            <StyledTableHeaderLG
              key={
                groupNumber == GROUPNUM.SPOT ? "procurementSpot" : "provideHour"
              }
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 0,
                height: uchiwakeCellH,
                minWidth: uchiwakeCellW,
                maxWidth: uchiwakeCellW,
              }}
              style={{ borderLeft: "none" }}
              title={sijoPfpItems[0].customerName}
            >
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  marginX: "5px",
                }}
              >
                {sijoPfpItems[0].customerName}
                {/*取引所*/}
                <br />
                (kWh)
              </Box>
            </StyledTableHeaderLG>
          )}
        </TableRow>
      </TableHead>
      {ProviderDataTable(groupArea, pfpItems, groupNumber, props, contxtProps)}
    </StyledTable>
  );
};

// 調達計画データテーブル構成
const ProviderDataTable = (
  groupArea: any[],
  provideItems: any[],
  providerType: number,
  props: PlanType.RightPlanTableProps,
  contxtProps: any
) => {
  const { PaletteMode, leavePageContext } = contxtProps;
  const cells = new Array(CELLCOUNT).fill(0);
  const ikigaiProvideItems: any[] = []; // provideItems.filter(
  //   (v) => v.salePlanKind === "12" || v.salePlanKind === "13"
  // );
  const sijoProvideItems = provideItems.filter((v) => v.salePlanKind === "14");

  return (ikigaiProvideItems.length === 0 ||
    ikigaiProvideItems === undefined) &&
    (sijoProvideItems.length === 0 || sijoProvideItems === undefined) ? null : (
    <ScrollSyncPane group="vertical">
      <TableBody
        sx={{
          display: "block",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "0px",
            backgroundColor: "transparent",
            height: "0px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "0px",
          },
        }}
      >
        {cells.map((cell, row) => {
          return (
            <TableRow key={row + "procurementRow"}>
              {/* 計 */}
              {providerType ===
              GROUPNUM.SELF ? null : ikigaiProvideItems.length === 0 ? null : (
                <StyledTableCell
                  key={"procurement_1" + row + "_areaName"}
                  sx={{
                    height: dataCellH,
                    padding: "0px 8px 0px 0px",
                    width: dataCellW - 8, // padding分減らす
                    backgroundColor: isDisabled(row, 0, contxtProps)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {Math.floor(
                    ikigaiProvideItems.reduce(
                      (sum, val) =>
                        sum + Number(checkNumber(val.valueList[row].value)),
                      0
                    )
                  ).toLocaleString()}
                </StyledTableCell>
              )}

              {/* 入力フィールド */}
              {groupArea.map((items, areaIndex) => {
                return (
                  <>
                    {/* 計 */}
                    <StyledTableCell
                      key={"procurement_2" + row + "" + areaIndex + "_areaName"}
                      sx={{
                        height: dataCellH,
                        padding: "0px 8px 0px 0px",
                        width: dataCellW - 8, // padding分減らす
                        backgroundColor: isDisabled(row, 0, contxtProps)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="right"
                    >
                      {Math.floor(
                        ikigaiProvideItems
                          .filter((element) => element.areaId == items)
                          .reduce(
                            (sum, val) =>
                              sum +
                              Number(checkNumber(val.valueList[row].value)),
                            0
                          )
                      ).toLocaleString()}
                    </StyledTableCell>
                    {ikigaiProvideItems
                      .filter((element) => element.areaId == items)
                      .map((item, col) => {
                        return (
                          <StyledTableCell
                            key={
                              "procurement_3" +
                              row +
                              "" +
                              areaIndex +
                              "" +
                              col +
                              "_areaName"
                            }
                            sx={{
                              height: dataCellH,
                              padding: "0px",
                              width: dataCellW,
                              backgroundColor: isDisabled(row, 0, contxtProps)
                                ? PaletteMode === "dark"
                                  ? "rgb(90,90,90)"
                                  : "rgb(200,200,200)"
                                : "",
                            }}
                            align="right"
                            style={{ borderLeft: "none" }}
                          >
                            <TextField
                              name="provide"
                              sx={{ margin: "2px" }}
                              inputProps={{
                                style: {
                                  padding: 0,
                                  height: "30px",
                                  textAlign: "right",
                                  backgroundColor: isDisabled(
                                    row,
                                    0,
                                    contxtProps
                                  )
                                    ? PaletteMode === "dark"
                                      ? "rgb(90,90,90)"
                                      : "rgb(200,200,200)"
                                    : "",
                                },
                                tabIndex:
                                  providerType * 100000 +
                                  areaIndex * 10000 +
                                  1 +
                                  col * 100 +
                                  1 +
                                  row,
                                min: 0,
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                step: 50,
                              }}
                              error={isMismatch(item.valueList[row].dataChgCd)}
                              disabled={isDisabled(
                                row,
                                providerType,
                                contxtProps
                              )}
                              type="number"
                              onKeyDown={(event) => {
                                if (event.key === "." || event.key === "-") {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                if (Number(item.valueList[row].value) === 0) {
                                  e.currentTarget.select();
                                }
                              }}
                              onBlur={(e) => {
                                updateBlurProviderPlan(
                                  e,
                                  item,
                                  col,
                                  row,
                                  providerType,
                                  props
                                );
                              }}
                              value={Math.floor(
                                Number(checkNumber(item.valueList[row].value))
                              ).toFixed(0)}
                              onChange={(e) => {
                                if (isMaxLength(e.target.value)) {
                                  updateProviderPlan(
                                    e,
                                    item,
                                    col,
                                    row,
                                    providerType,
                                    props
                                  );
                                }
                                leavePageContext.setBlockLeave(true);
                              }}
                            />
                          </StyledTableCell>
                        );
                      })}
                  </>
                );
              })}
              {sijoProvideItems.length === 0 ? null : (
                <StyledTableCell
                  key={"procurement_3" + row + "_areaName"}
                  sx={{
                    height: dataCellH,
                    padding: "0px",
                    width: dataCellW,
                    backgroundColor: isDisabled(row, 0, contxtProps)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                  style={{ borderLeft: "none" }}
                >
                  <TextField
                    name="provide"
                    sx={{ margin: "2px" }}
                    inputProps={{
                      style: {
                        padding: 0,
                        height: "30px",
                        textAlign: "right",
                        backgroundColor: isDisabled(row, 0, contxtProps)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      },
                      tabIndex: providerType * 100000 + 90000 + row,
                      min: 0,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      step: 50,
                    }}
                    error={isMismatch(
                      sijoProvideItems[0].valueList[row].dataChgCd
                    )}
                    disabled={isDisabled(row, providerType, contxtProps)}
                    type="number"
                    onKeyDown={(event) => {
                      if (event.key === "." || event.key === "-") {
                        event.preventDefault();
                      }
                    }}
                    onFocus={(e) => {
                      if (
                        Number(sijoProvideItems[0].valueList[row].value) === 0
                      ) {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      updateBlurProviderPlan(
                        e,
                        sijoProvideItems[0],
                        0,
                        row,
                        providerType,
                        props
                      );
                    }}
                    value={Math.floor(
                      Number(
                        checkNumber(sijoProvideItems[0].valueList[row].value)
                      )
                    ).toFixed(0)}
                    onChange={(e) => {
                      if (isMaxLength(e.target.value)) {
                        updateProviderPlan(
                          e,
                          sijoProvideItems[0],
                          0,
                          row,
                          providerType,
                          props
                        );
                      }
                      leavePageContext.setBlockLeave(true);
                    }}
                  />
                </StyledTableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </ScrollSyncPane>
  );
};
