import React, { useState } from "react";

//30分刻みの時間の文字列の配列の生成
export const TimeTable = () => {
  const oneDayTime = [];
  const date = new Date(2023, 0, 1, 0, 0);
  let hours;
  let minute;
  for (let i = 0; i < 49; i++) {
    hours = ("00" + date.getHours()).slice(-2);
    minute = ("00" + date.getMinutes()).slice(-2);
    oneDayTime[i] = `${hours}:${minute}`;
    date.setMinutes(date.getMinutes() + 30);
  }
  return oneDayTime;
};
