import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ChartData, Chart as ChartJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import zoomPlugin from "chartjs-plugin-zoom";
import { TimeTable } from "./CreateTime";
import { StyledButton } from "../common/styledComponents/styledButton";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import axios from "axios";
import {
  Inbalance,
  demandInbalanceInfo,
  divergence,
} from "../../types/power_transmission/DemandMonitoring";
import { AreaContext } from "../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../common/customComponents/CustomConfirmLeavePage";
import { useLocation, useNavigate } from "react-router-dom";
import { DateContext } from "../common/customComponents/GlobalDatePicker";
import { TimeContext } from "../common/globalMenu/LiveClock";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { PaletteContext } from "../common/paletteMode";
import { DEMAND_FORECAST_URL } from "../../common/url";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { format } from "date-fns";
import { BorderColor } from "@mui/icons-material";
import { useColorUtility } from "../../common/color";
import { changeDateToTimeCd } from "../common/changeDateCd";
import { autoScrollPage } from "../common/ScrollPage";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";
import { BUSINESS_MODEL } from "../../common/constant";

//ボタン
const ButtonColor = () => {
  const { PaletteMode } = useContext(PaletteContext);
  return PaletteMode === "dark" ? "white" : "black";
};
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    color: ${ButtonColor};
    font-size: 14px;
  }
` as typeof Button;

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

const GET_DEMAND_MONITOR = "/getDemandMonitoring";
const GET_LOSS_RATE = "/getLossRate";
const AREA_LIST_URL = "/getDemandAreaNameList";

//以下数行は上書きしたスタイルドコンポーネントが置いてある
const HeaderCell = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px;
    font-size: 14px;
  }
`;

const TableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 10px;
    font-size: 14px;
  }
`;

const AreaHeader = styled(HeaderCell)`
  && {
    width: calc(100% / 4 - 11px);
  }
`;

const DivergenceCell = styled(AreaHeader)`
  && {
    padding: 5px;
  }
`;

//棒グラフの色
const barsColors = [
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
];

//色んな部分の色を決める連想配列
const colors: { [key: string]: string } = {
  upperLimitBorderColor: "rgb(75,172,198)",
  lowerLimitBorderColor: "rgb(255,204,255)",
  demandPlanBorderColor: "rgb(255,0,0)",
  chartDragBackgroundColor: "rgba(225,0,0,0.3)",
  tableHeadBackgroundColor: "rgb(220,230,241)",
  tableBodyBackgroundColor: "rgb(255,255,255)",
  divergenceNormalColor: "",
  DivergenceMinusColor: "rgb(0,0,255)",
  DarkModeDivergenceMinusColor: "aqua",
};

//chart.jsでズーム機能を使えるように登録
ChartJS.register(zoomPlugin);

const initInbalanceInfo = (): demandInbalanceInfo => {
  return {
    targetDate: format(new Date(), "yyyy-MM-dd"),
    area: 0,
    ariaValue: {
      targetDate: format(new Date(), "yyyy-MM-dd"),
      area: 0,
      value: new Array<Inbalance>(48).fill({
        time: 0,
        actual: 0,
        plan: 0,
        actualOrigin: 0,
        planOrigin: 0,
        deviationValue: 0,
        deviationRate: 0,
        deviationValueOrigin: 0,
        deviatiionRateOrigin: 0,
      }),
    },
    customerValue: [],
  };
};

//ここから関数コンポーネント
const DemandMonitoring = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);
  const { businessModel } = useContext(BusinessModelContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  const { borderColor } = useColorUtility();
  //DBから取ってきたデータを入れるstate、chartの情報もテーブルの情報もすべて入っている。1つに入れすぎな気もする
  const [mappingData, setMappingData] = useState<demandInbalanceInfo>(
    initInbalanceInfo()
  );

  //エリア一覧
  const [areaList, setAreaList] = useState<string[]>([]);
  const { areaOptions } = useAreaUtility();

  const api = useAuthedApi();

  const url = useLocation().search;
  const query = new URLSearchParams(url);

  //数値をカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString();
  };
  //損失率あり・無しのフラグ
  const [withLossRate, setwithLossRate] = useState<boolean>(false);

  //上上下下限のデータ保持
  const [upperAndLowerLimitData, setUpperAndLowerLimitData] = useState<
    divergence[]
  >([]);

  //DBからデータを取得するまで画面描画を塞き止めるためのstate
  const [isLoaded, setIsLoaded] = useState<boolean>(false); //初期化用 mapでのエラー対応検討
  const [isLoadingAreas, setIsLoadingAreas] = useState<boolean>(false); //エリアリスト
  const [isLoadingPlan, setIsLoadingPlan] = useState<boolean>(false); //計画
  const [isLoadingLossRate, setIsLoadingLossRate] = useState<boolean>(false); //損失率
  const isLoading = isLoadingAreas || isLoadingPlan || isLoadingLossRate;

  //グラフの最大高を決めるstate、DBから受け取った値の中でジャンル問わず一番大きな数値を格納して、グラフの天井を決める
  //const [maxHeight, setMaxHeight] = useState<number>(0);

  const dateValue = useContext(DateContext);
  const timeValue = useContext(TimeContext);

  // オートスクロール制御用
  const [scrollFlag, setScrollFlag] = useState<boolean>(true);

  const navigate = useNavigate();
  //DBからの取得関数
  const axiosGet = () => {
    setIsLoadingPlan(true);
    api
      .get(GET_DEMAND_MONITOR, { params: { areaId: areaId } })
      .then((response) => {
        setMappingData(response.data as demandInbalanceInfo);
        // setMaxHeight(getMaxHeight(response.data as demandInbalanceInfo));
        setIsLoadingPlan(false);
        // オートスクロール
        if (scrollFlag) {
          autoScrollPage(
            "demandImbalanceCell" + changeDateToTimeCd(timeValue.time)
          );
          setScrollFlag(false);
        }
      })
      .catch((err) => {
        //console.log("エラーで拾えてない");
      });
  };

  //画面自動更新
  const [time, setTime] = useState(new Date());

  //画面遷移時にDBからデータを取ってくる
  useEffect(() => {
    initAreaList();
    leavePageContext.setBlockLeave(false);
  }, []);

  useEffect(() => {
    // 30で画面を更新
    if (areaList.length) {
      axiosGet();
      const id = setInterval(() => {
        setTime(new Date());
        axiosGet();
      }, 1800000);
      //30分⇒ 1800000

      return () => clearInterval(id);
    }
    setIsLoaded(true);
  }, [areaList, time, areaId]);

  // エリア名
  const initAreaList = () => {
    const tmpAreaList = businessModel
      .filter(
        (model) =>
          model.businessModel === BUSINESS_MODEL.IKINAI ||
          model.businessModel === BUSINESS_MODEL.IKIGAI_DEMAND ||
          model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER ||
          model.businessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND ||
          model.businessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN
      )
      .map((v) => v.areaId);
    setAreaList(tmpAreaList);
    setAreaId(
      Number(areaId) === 0 || areaId === "00" || !tmpAreaList.some((v: any) => v === areaId)
        ? tmpAreaList[0].toString().padStart(2, "0")
        : areaId.padStart(2, "0")
    );
  };

  //chartの中身を作る関数、データセットは上から順に上限・下限・実績で、その下にあるfor文では、そのエリアに存在する需要家の数に応じてループしpush、積み上げ棒グラフのデータを作っている
  //ただし上下限は適当なテストデータのようなので、本番では全然違うところから取ってくると思われる
  //積み上げ棒グラフの色は、今のところは置いてあった色の配列から適当に拾ってきているが、需要家の数が配列の中身以上に増えると足りなくなってどうなるか分からない
  //chartjs-plugin-colorschemesというもので簡単に動的に作れるらしい
  const generateChartData = useMemo(() => {
    const chartData: ChartData = {
      labels: timeData,
      datasets: [
        //グラフデータセット
      ],
    };

    if (
      mappingData?.ariaValue == undefined ||
      mappingData?.customerValue == undefined
    ) {
      return chartData;
    }

    //下限
    chartData.datasets.push({
      type: "line" as const,
      label: languageContext.words.lower_limit,
      borderColor: colors.lowerLimitBorderColor,
      stepped: "middle" as const,
      data: mappingData.ariaValue.value.map(
        (areaData) =>
          withLossRate === false
            ? areaData.plan //損失率あり
            : areaData.planOrigin //損失率なし
      ),
      yAxisID: "yAxisForLimitValue",
      borderDash: [6, 4],
    });

    //計画
    chartData.datasets.push({
      type: "line" as const,
      label: languageContext.words.demand_plan,
      borderColor: colors.demandPlanBorderColor,
      stepped: "middle" as const,
      data: mappingData.ariaValue.value.map((data) =>
        withLossRate === false ? data.plan : data.planOrigin
      ),
      yAxisID: "yAxisForPlanAndActualValue",
    });

    //需要家実績
    for (let i = 0; i < mappingData.customerValue.length; i++) {
      chartData.datasets.push({
        type: "bar" as const,
        label: mappingData.customerValue[i].customer,
        backgroundColor: barsColors[i],
        data: mappingData.customerValue[i].deviation.map((data) =>
          withLossRate === false ? data.actual : data.actualOrigin
        ),
        yAxisID: "yAxisForPlanAndActualValue",
        categoryPercentage: 1.1,
      });
    }
    return chartData;
  }, [languageContext.words, mappingData, withLossRate]);

  //グラフの最大高を決める関数。これがないと実績値や上限値のグラフが範囲外に飛び出て見えなくなるかもしれない
  //ただし今現在の処理方法は適当、上下限のデータ取得方法が分かったら置き換える
  const maxHeight = useMemo(() => {
    const actualMaxHeight = mappingData?.ariaValue.value
      ? Math.max(
          ...mappingData.ariaValue.value.map((value) => value.actualOrigin)
        )
      : 0;

    const planMaxHeight = mappingData?.ariaValue.value
      ? Math.max(
          ...mappingData.ariaValue.value.map((value) => value.planOrigin)
        )
      : 0;

    //計画値・実績値の最大値が0のとき、y軸の最大値をデフォルトで1000に設定。計画値・実績値が0の時でも計画値線が0で描画されるようにする。
    //エリア未選択の場合はy軸の最大値設定はしない。
    if (
      mappingData?.area !== 0 &&
      planMaxHeight === 0 &&
      actualMaxHeight === 0
    ) {
      return 1000;
    }
    //実際の数字より盛られているのは、一番高いグラフが天井についていると見栄えが悪い気がするため。このままだと中途半端な上限値になるので調整した方がよい
    return Math.round(Math.max(actualMaxHeight, planMaxHeight) * 1.1);
  }, [mappingData]);

  //chartのオプションを作る関数。↑のchart本体は分けてあるのにこちらが分かれていないのは統一性に欠けるので分離、y軸の基準増やしたくなったら増やしやすいし
  const generateOption = useMemo(() => {
    // グラフデフォルト値
    // ダークモード対応でボーダー線の色等を変える
    ChartJS.defaults.color = PaletteMode === "dark" ? "#999" : "#666";
    ChartJS.defaults.borderColor =
      PaletteMode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
    const options = {
      plugins: {
        legend: {
          position: "top" as const,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy" as const,
            modifierKey: "meta" as const,
            overScaleMode: undefined,
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: colors.chartDragBackgroundColor,
            },
            mode: "x" as const,
          },
          mode: "x",
          limits: {
            x: {
              min: 0,
              max: 47,
            },
            y: {
              min: 0,
              max: 2.0,
            },
          },
        },
      },
      responsiv: true,
      scales: {
        x: {
          stacked: true,
          max: 48,
          min: 0,
          grid: {
            display: false,
          },
        },
        yAxisForPlanAndActualValue: {
          beginAtZero: true,
          title: {
            display: true,
            text: languageContext.words.demandforecast_energy + "(kWh)",
          },
          stacked: true,
          max: maxHeight,
          min: 0,
        },
        yAxisForLimitValue: {
          position: "right" as const,
          beginAtZero: true,
          stacked: false,
          max: maxHeight,
          min: 0,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    };
    return options;
  }, [languageContext.words, maxHeight, PaletteMode]);

  const getDivergenceDataFountColor = (data: Inbalance) => {
    let divergenceDataFountColor = colors.divergenceNormalColor;
    if (data.deviationValue < 0) {
      divergenceDataFountColor =
        PaletteMode === "dark"
          ? colors.DarkModeDivergenceMinusColor
          : colors.DivergenceMinusColor;
    } else {
      divergenceDataFountColor = colors.divergenceNormalColor;
    }
    return divergenceDataFountColor;
  };

  const deviationRatioDataFountColor = (data: Inbalance) => {
    let deviationRatioDataFountColor = colors.divergenceNormalColor;
    if (data.deviationRate < 0) {
      deviationRatioDataFountColor =
        PaletteMode === "dark"
          ? colors.DarkModeDivergenceMinusColor
          : colors.DivergenceMinusColor;
    } else {
      deviationRatioDataFountColor = colors.divergenceNormalColor;
    }
    return deviationRatioDataFountColor;
  };

  const transitToOtherPage = (url: string) => {
    navigate(url);
  };

  // 需要家毎のテーブルレンダー
  const customerTableRender = () => {
    if(mappingData.customerValue.length !== 0) {
      return (
        <StyledTable
          key={"customerTable"}
          sx={{
            borderCollapse: "separate",
            height: "100%",
            width:
              mappingData.customerValue.length > 1
              ? "auto"
              : "301px",
            borderTop: "none",
            borderBottom: "none",
          }}
        >
          <TableHead
            sx={{
              display: "block",
              background: "#ccccff",
              position: "sticky",
              top: 0,
              zIndex: 0,
              borderTop: borderColor,
            }}
          >
            <TableRow sx={{ display: "block" }}>
              <HeaderCell
                sx={{ display: "block" }}
                style={{ height: "calc(100%/3)" }}
              >
                {languageContext.words.demander}
              </HeaderCell>
            </TableRow>
            <TableRow sx={{ display: "flex" }}>
              {mappingData.customerValue.map((data, index) => (
                <HeaderCell
                  key={index}
                  sx={{ overflow: "hidden", display: "block" }}
                  style={{
                    height: "calc(100% / 3)",
                    //複数需要家対応
                    //width: `calc(100% / ${mappingData.customerValue.length})`,
                    width: 
                    mappingData.customerValue.length > 1
                      ? "146.5px"                    
                      : "301px",
                  }}
                >
                  <ButtonLink
                    style={{
                      textTransform: "none",
                      padding: 0,
                      textDecoration: "underline",
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    disableFocusRipple
                    onClick={() => {
                      dateValue.setPickDate(timeValue.time);
                      transitToOtherPage(
                        DEMAND_FORECAST_URL +
                          "?areaId=" +
                          data.area.toString().padStart(2, "0") +
                          "&bgId=" +
                          data.bgId +
                          "&demandId=" +
                          data.demandId
                      );
                    }}
                    title={data.customer}
                  >
                    {data.customer}
                  </ButtonLink>
                </HeaderCell>
              ))}
            </TableRow>
            <TableRow sx={{ display: "block" }}>
              {mappingData.customerValue.map((data, index) => (
                <HeaderCell
                  key={index}
                  sx={{
                    width:
                      mappingData.customerValue.length > 1
                        ? "146.5px"
                        : "301px"
                  }}
                  style={{ height: "calc(100% / 3)" }}
                >
                  {languageContext.words.deviation + "(kWh)"}
                </HeaderCell>
              ))}                
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                width: "0px",
                background: colors.tableBodyBackgroundColor,
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0px",
                  backgroundColor:
                    PaletteMode === "dark" ? "#5e5e5e" : "transparent",
                  height: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "0px",
                },
              }}
            >
              {mappingData.ariaValue.value.map((data, index) => {
                return(
                  <TableRow key={index} sx={{ display: "flex" }}>
                  {mappingData.customerValue.map((customerData, tableIndex) => {
                    let fountColor = colors.divergenceNormalColor;
                    if (customerData.deviation[index].deviationValue < 0) {
                      fountColor =
                        PaletteMode === "dark"
                          ? colors.DarkModeDivergenceMinusColor
                          : colors.DivergenceMinusColor;
                    } else {
                      fountColor = colors.divergenceNormalColor;
                    }
                    return (
                        <TableCell
                          key={"data" + index + "_" + tableIndex}
                          style={{
                            width:
                              mappingData.customerValue.length > 1
                                ? "136.5px"
                                : "291px",
                          }}
                        >
                          <label style={{ color: fountColor }}>
                            {withLossRate === false
                              ? convertToLocaleString(customerData.deviation[index].deviationValue)
                              : convertToLocaleString(
                                  customerData.deviation[index].deviationValueOrigin
                                )}
                            {/* 需要家乖離 */}
                          </label>
                        </TableCell>
                    );
                  })}
                  </TableRow>
                )
              })}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      )
    }
  }

  //テーブルのレンダー
  const TabelRender = () => {
    return (
      <ScrollSync>
        <TableContainer
          sx={{
            width: "100%",
            height: "100%",
            display: "flexed",
            overflow: "scroll",
            scrollPaddingTop: "105px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <StyledTable
            sx={{
              width: "65%",
              height: "100%",
              position: "sticky",
              left: 0,
              zIndex: 1,
              borderCollapse: "separate",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <TableHead
              sx={{
                display: "block",
                background: colors.tableHeadBackgroundColor,
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: "105px",
                borderTop: borderColor,
              }}
            >
              <TableRow
                sx={{ height: "105px", width: "100%", display: "flex" }}
              >
                <HeaderCell
                  style={{
                    lineHeight: "105px",
                    height: "calc(100% - 11px)",
                    width: "calc(25% - 14px)",
                  }}
                >
                  {languageContext.words.timeB}
                </HeaderCell>
                <TableRow sx={{ width: "100%", display: "block" }}>
                  <HeaderCell
                    sx={{
                      display: "block",
                      width: "100% - 11px",
                      height: "calc(100% / 3 - 11px)",
                    }}
                  >
                    {languageContext.words.area}
                  </HeaderCell>

                  <TableRow
                    sx={{ height: "calc(100% / 3 * 2)", display: "flex" }}
                  >
                    <AreaHeader style={{ overflow: "hidden" }}>
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          //padding: "5.5px",
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.demand_results}
                      >
                        {languageContext.words.demand_results}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader style={{ overflow: "hidden" }}>
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          //padding: "5.5px",
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.demand_plan}
                      >
                        {languageContext.words.demand_plan}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader style={{ overflow: "hidden" }}>
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          //padding: "5.5px",
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.deviation}
                      >
                        {languageContext.words.deviation}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader style={{ overflow: "hidden" }}>
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          //padding: "5.5px",
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.deviation_ratio}
                      >
                        {languageContext.words.deviation_ratio}
                        <br />
                        (%)
                      </HeaderCell>
                    </AreaHeader>
                  </TableRow>
                </TableRow>
              </TableRow>
            </TableHead>
            <ScrollSyncPane group="vertical">
              <TableBody
                sx={{
                  display: "block",
                  background: colors.tableBodyBackgroundColor,
                  overflow: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                    backgroundColor: "transparent",
                    height: "0px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#AAA",
                    borderRadius: "0px",
                  },
                }}
              >
                {mappingData?.ariaValue.value.map((data, index) => {
                  return (
                    <TableRow key={"nanika" + index}>
                      <StyledTableCell
                        align="center"
                        sx={{
                          width: "120px",
                          fontSize: "14px",
                          padding: "5px",
                        }}
                        id={"demandImbalanceCell" + index}
                      >
                        {timeData[index]}～{timeData[index + 1]}
                      </StyledTableCell>
                      <TableCell
                        sx={{
                          width: "20%",
                        }}
                      >
                        {withLossRate === false
                          ? convertToLocaleString(Math.trunc(data.actual))
                          : convertToLocaleString(Math.trunc(data.actualOrigin))}
                        {/* 需要実績 */}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "20%",
                        }}
                      >
                        {withLossRate === false
                          ? convertToLocaleString(Math.trunc(data.plan))
                          : convertToLocaleString(Math.trunc(data.planOrigin))}
                        {/* 需要計画 */}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "20%",
                        }}
                      >
                        <label
                          style={{ color: getDivergenceDataFountColor(data) }}
                        >
                          {withLossRate === false
                            ? convertToLocaleString(Math.trunc(data.deviationValue))
                            : convertToLocaleString(Math.trunc(data.deviationValueOrigin))}
                          {/* 乖離 */}
                        </label>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "20%",
                        }}
                      >
                        <label
                          style={{ color: deviationRatioDataFountColor(data) }}
                        >
                          {withLossRate === false
                            ? data.deviationRate.toFixed(1)
                            : data.deviatiionRateOrigin.toFixed(1)}
                        </label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </ScrollSyncPane>
          </StyledTable>
          {customerTableRender()}
        </TableContainer>
      </ScrollSync>
    );
  };

  if (!isLoaded) {
    return <CustomModalProgress open={true} />;
  }

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
      style={{ display: "flex" }}
    >
      <Box sx={{ width: "50%", height: "100%" }}>
        <Box sx={{ width: "100%", height: "5%", margin: "-8px 0px 10px 8px" }}>
          <CustomSelectList
            key={"demandMonitoring_CustomSelectList_area"}
            value={areaId}
            // defaultValue={0}
            options={areaOptions(areaList)}
            onChange={setAreaId}
          />
        </Box>
        <Box sx={{ width: "100%", height: "95%" }}>
          <ChartRender data={generateChartData} options={generateOption} />
        </Box>
      </Box>
      <Box sx={{ width: "50%", height: "100%" }}>
        <Box sx={{ width: "100%", height: "5%" }}>
          <Stack spacing={1} direction="row">
            <label>{languageContext.words.loss_ratio_switching}</label>
            <StyledButton
              variant="outlined"
              disabled={withLossRate}
              onClick={() => {
                setwithLossRate(true);
              }}
            >
              {languageContext.words.loss_ratio_on}
            </StyledButton>
            <StyledButton
              variant="outlined"
              disabled={!withLossRate}
              onClick={() => {
                setwithLossRate(false);
              }}
            >
              {languageContext.words.loss_ratio_off}
            </StyledButton>
          </Stack>
        </Box>
        <Box sx={{ width: "100%", height: "95%" }}>{TabelRender()}</Box>
      </Box>
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

//チャートのレンダー
const ChartRender = (props: any) => {
  const { data, options } = props;
  //chartのRef
  const chartRef = useRef<ChartJS>(null);

  //ズームリセット
  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <Chart
      type={"bar"}
      data={data}
      options={options}
      height={9}
      width={10}
      ref={chartRef}
      onDoubleClick={handleResetZoom}
    />
  );
};

export default DemandMonitoring;
