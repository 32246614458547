// 需要計画一覧
import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList, {
  CustomMultipleSelectList,
} from "../../common/customComponents/CustomSelectList";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns/fp";
import axios from "axios";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import {
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
} from "../../../common/url";
import { useAreaUtility } from "../../../common/area";
import { AREA_ID } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";

// ヘッダー ライトグレー
const DemandForecastListStyledTableHeaderLG = styled(StyledTableHeaderLG)`
  && {
    height: 42px;
    padding: 0px;
  }
`;

// セル
const DemandForecastListStyledTableCell = styled(StyledTableCell)`
  && {
    height: 42px;
    padding: 0px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
  }
`;

// 需要計画一覧画面
interface demandForecastLsit {
  areaId: string;
  areaName: string;
  demandBgId: number;
  demandBgName: string;
  demandId: number;
  demandName: string;
  demandReportId: string;
  power: number;
  dayDemand: number;
  saveDate: string;
  user: string;
}

// axios getする際のパス

// 需要BG、需要家一覧
const GET_DEMAND_FORECAST_INFO_URL = "/getDemandForecastInfoList";

// エリア一覧
const GET_DEMAND_FORECAST_LIST = "/getDemandForecastList";

const requestDateFormat = format("yyyy-MM-dd");
type searchInfo = { id: number; name: string };

//メイン的なところ
const DemandForecastList = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  const { setAreaId } = useContext(AreaContext);

  const api = useAuthedApi();

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  //計算に使う用に、IDと地域名をセットにして、配列にしておく

  //エリア一覧
  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  //セレクトタブのState
  const [area, setArea] = useState<string>(AREA_ID.NONE); //エリア
  const [demandBg, setDemandBg] = useState(0); //需要BG
  const [demand, setDemand] = useState(0); //需要家
  const [allDemandList, setAllDemandList] = useState<powerDemandInfo[]>([]);

  //マッピング用のState
  const [demandForecastListMappingData, setDemandForecastListMappingData] =
    useState<demandForecastLsit[]>([]);

  //画面遷移関数
  const navigate = useNavigate();
  function transitToOtherPage(url: string) {
    navigate(url);
  }
  // 非同期通信
  // ステータス情報APIデータ取得
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  //データコンテキストのuseContext
  const selectedDate = useContext(DateContext);

  // エリア変更時
  useEffect(() => {
    // 需要計画一覧取得
    if (
      (area === AREA_ID.NONE && demandBg !== 0 && demand !== 0) ||
      (area === AREA_ID.NONE && demandBg !== 0 && demand === 0)
    ) {
      //なにもしない
    } else {
      getDemandList(area, demandBg, demand);
    }
  }, [selectedDate]);

  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);

  // 初期検索
  useEffect(() => {
    const area = query.get("area");
    // 検索条件情報の取得
    // エリアが特定されている場合
    if (area != null && !Number.isNaN(area) && parseInt(area) != 0) {
      setArea(area);
    }
    //需要家情報一括取得
    getDemandInfoList();
    leavePageContext.setBlockLeave(false);
  }, []);

  //需要家情報一括取得
  const getDemandInfoList = () => {
    setIsLoaded(true);
    api
      .get(GET_DEMAND_FORECAST_INFO_URL)
      .then((res) => {
        setAllDemandList(
          res.data.map((v: any) => {
            return {
              areaId: v.areaId,
              bgId: v.bgId,
              bgName: v.bgName,
              powerDemandId: v.demandId,
              powerDemandName: v.demandName,
            };
          })
        );
        setIsLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 需要計画一覧の取得
  function getDemandList(areaId: string, bgId: number, demandId: number) {
    setIsLoaded(true);
    api
      .post(GET_DEMAND_FORECAST_LIST, {
        areaId: areaId,
        demandBgId: bgId,
        demandId: demandId,
        date: requestDateFormat(selectedDate.pickDate as Date),
      })
      .then((res) => {
        setDemandForecastListMappingData(res.data);
        setIsLoaded(false);
      })
      .catch((error) => {
        setError(error);
      });
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // 色情報の取得
  const palletContextBase = useContext(PaletteContext);

  return (
    <div
      key={"demandForecastData_div"}
      className={`cn-main-display ${
        palletContextBase.PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Box
        key={"demandForecastData_Box_SelectLists"}
        sx={{ marginBottom: 2, display: "flex" }}
      >
        <CustomMultipleSelectList
          powerFg={false}
          list={allDemandList}
          areaId={area}
          bgId={demandBg}
          powerDemandId={demand}
          handleValueChangeArea={setArea}
          handleValueChangeBG={setDemandBg}
          handleValueChangePowerDemand={setDemand}
          getData={getDemandList}
        ></CustomMultipleSelectList>
        <GlobalDatePicker
          key={"demandForecastData_GlobalDatePicker"}
          isDipsTodayBtn={true}
        />
      </Box>

      <TableContainer
        key={"demandForecastData_TableContainer"}
        sx={{
          width: "100%",
          height: "90%",
          "&::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "transparent",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "10px",
          },
        }}
      >
        <StyledTable
          key={"demandForecastData_StyledTable"}
          sx={{ width: "100%" }}
          stickyHeader
        >
          <TableHead key={"demandForecastData_Header"}>
            <TableRow key={"demandForecastData_TableRow"}>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_area"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.area}
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_demandBG"}
                sx={{ width: "16%" }}
                align="center"
              >
                {languageContext.words.demand_bg}
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_customer"}
                sx={{ width: "16%" }}
                align="center"
              >
                {languageContext.words.demander}
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_power"}
                sx={{ width: "10%" }}
                align="center"
              >
                {languageContext.words.contracted_power}(kw)
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_dayDemand"}
                sx={{ width: "10%" }}
                align="center"
              >
                {languageContext.words.planned_daily_demand}(kw)
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_createDate"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.save_date}
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_user"}
                sx={{ width: "12%" }}
                align="center"
              >
                {languageContext.words.updater}
              </DemandForecastListStyledTableHeaderLG>
              <DemandForecastListStyledTableHeaderLG
                key={"demandForecastData_Header_Buttons"}
                sx={{ width: "18%" }}
                align="center"
              ></DemandForecastListStyledTableHeaderLG>
            </TableRow>
          </TableHead>

          <TableBody>
            {demandForecastListMappingData.map((row, index) => (
              <TableRow key={"demandForecastData_Row" + index}>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_areaName"}
                  align="center"
                >
                  {convertIdToAreaName(row.areaId)}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_bgName"}
                  align="left"
                  title={row.demandBgName}
                >
                  {row.demandBgName}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_customerName"}
                  align="left"
                  title={row.demandName}
                >
                  {row.demandName}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_power"}
                  align="right"
                >
                  {Number(row.power).toLocaleString()}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_dayDemand"}
                  align="right"
                >
                  {Number(row.dayDemand).toLocaleString()}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_createDate"}
                  align="center"
                >
                  {row.saveDate
                    ? languageContext.convertDateToLocalizedString(
                        new Date(row.saveDate),
                        "HH:mm:ss"
                      )
                    : ""}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_user"}
                  align="center"
                  title={row.user}
                >
                  {row.user}
                </DemandForecastListStyledTableCell>
                <DemandForecastListStyledTableCell
                  key={"demandForecastData_" + index + "_buttonCell"}
                  align="center"
                >
                  <StyledButton
                    key={
                      "demandForecastData_" +
                      index +
                      "_demandForecastDataButton"
                    }
                    sx={{ marginRight: 1.5 }}
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId);
                      transitToOtherPage(
                        DEMAND_FORECAST_URL +
                          "?areaId=" +
                          row.areaId +
                          "&bgId=" +
                          row.demandBgId +
                          "&demandId=" +
                          row.demandId
                      );
                    }}
                  >
                    {languageContext.words.demandforecast}
                  </StyledButton>
                  <StyledButton
                    key={
                      "demandForecastData_" +
                      index +
                      "_transitToDemandForecastPlanButton"
                    }
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId);
                      transitToOtherPage(
                        DEMAND_FORECAST_PLAN_URL + "?areaId=" + row.areaId
                      );
                    }}
                  >
                    {languageContext.words.demand_and_procurement_plan}
                  </StyledButton>
                </DemandForecastListStyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <CustomModalProgress open={isLoaded} />
      <CustomDialog
        title={languageContext.words.demand_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

export default DemandForecastList;
