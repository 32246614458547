import React, { useContext, useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import styled from "styled-components";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TimeTable } from "../jepx/OneHourAdvanceBid/CreateTime";
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import axios from "axios";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LeavePageContextProvider } from "../common/customComponents/CustomConfirmLeavePage";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 0px;
  }
`;

//inputError初期化用
const initInputErrorArr: boolean[] = new Array(48).fill(false);

//モーダル
const SetPowerLimit = (
  open: boolean,
  HandleClose: () => void,
  powerIdPK: number
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  const tmp: number[] = [];
  for (let i = 0; i < 48; i++) {
    tmp[i] = 0;
  }
  const tmp2: number[] = [];
  for (let i = 0; i < 48; i++) {
    tmp2[i] = 0;
  }

  type limitPowerPlant = {
    powerId: number;
    upeerNums: number;
    upUpeerNums: number;
  };

  const tmpArr: limitPowerPlant[] = [];
  for (let i = 0; i < 48; i++) {
    tmpArr.push({
      upeerNums: 0,
      upUpeerNums: 0,
      powerId: 0,
    });
  }

  //前回設定値（上限）
  const [beforeUpperData, setBeforeUpperData] = useState<number[]>(
    new Array(48).fill(true).map((_) => 0)
  );
  //変更後（上限）
  const [upperValue, setUpperValue] = useState<number[]>(tmp);
  //前回設定値（上上限）
  const [beforeUpuperData, setBeforeUpUperData] = useState<number[]>(
    new Array(48).fill(true).map((_) => 0)
  );
  //変更後（上上限）
  const [upupperValue, setUpUpperValue] = useState<number[]>(tmp2);
  //最終的にDBに送りつけるやつ
  const [allData, setAllData] = useState<limitPowerPlant[]>(tmpArr);

  // レンダー管理
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputerror] = useState<boolean[]>(initInputErrorArr); //各セルにエラー属性を付与するか否かを管理する

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化
  ////

  useEffect(() => {
    if (open === true) {
      setIsLoading(true);
      (async () => {
        Promise.all([checkEditRoll(), getDemandDataLinkedPowerId()]).finally(
          () => {
            setIsLoading(false);
          }
        );
      })();
    } else {
      setUpperValue(tmp);
      setUpUpperValue(tmp2);
      setBeforeUpperData(tmp);
      setBeforeUpUperData(tmp2);

      setInputerror(initInputErrorArr);
    }
  }, [open]); //初期化

  //PKに紐づくデータ入手
  const getDemandDataLinkedPowerId = () => {

    return api
      .get(API_URL.GET_LIMITDATA, { params: { powerIdPK: powerIdPK } })
      .then((response) => {
        const responseData: limitPowerPlant[] = response.data
          .result as limitPowerPlant[];

        dispData(responseData);
      })
      .catch((err) => {
        console.log(err);
        setUpperValue(tmp);
        setUpUpperValue(tmp2);
        setBeforeUpperData(tmp);
        setBeforeUpUperData(tmp2);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    return api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function dispData(responseData: limitPowerPlant[]) {
    const upperData: number[] = [];
    const upUpperData: number[] = [];
    responseData.forEach((data) => {
      upperData.push(data.upeerNums);
      upUpperData.push(data.upUpeerNums);
    });
    setUpperValue(upperData);
    setBeforeUpperData(upperData);
    setUpUpperValue(upUpperData);
    setBeforeUpUperData(upUpperData);
  }

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) {
    let value: string = e.currentTarget.value;
    const name: string = e.currentTarget.name;

    if (value.includes(".")) {
      const cutPlaceNum = value.indexOf(".");
      value = value.slice(0, cutPlaceNum - 1);
    }
    let valueNum = isNaN(parseInt(value)) ? 0 : parseInt(value);
    //上限値及び上上限値の値が範囲超えたら修正する
    if (valueNum > 999999) {
      valueNum = 999999;
    }
    if (valueNum < 0) {
      valueNum = valueNum * -1;
    }

    let tempNums: number[] = [];
    switch (name) {
      case "upeerNums":
        // for (let i = 0; i < upperValue.length; i++) {
        //   tempNums.push(upperValue[i]);
        // }
        tempNums = [...upperValue];

        tempNums[index] = valueNum;

        setUpperValue(tempNums);
        break;
      case "upUpeerNums":
        // for (let i = 0; i < upupperValue.length; i++) {
        //   tempNums.push(upupperValue[i]);
        // }
        tempNums = [...upupperValue];
        tempNums[index] = valueNum;
        setUpUpperValue(tempNums);
        break;
      default:
        break;
    }
  }

  //正しい情報かどうか検査
  const isCorrectInfo = (): boolean => {
    let error = false;
    //1.上限値が上上限値よりも大きい箇所があればエラー
    const errorInputs = [...initInputErrorArr];
    for (let i = 0; i < 48; i++) {
      if (upperValue[i] > upupperValue[i]) {
        //あった場合、上限値が上上限値よりも大きいセルの属性をエラーにする
        errorInputs[i] = true;
        //このメソッドの結果をtrueに
        error = true;
      }
    }
    setInputerror(errorInputs);
    //popupの文言をせっとする
    setDialogMessage(languageContext.words.power_plant_setting_err_message_1);

    return error;
  };

  //demandIdの値をもとにデータを更新する
  function UpdateDatas() {
    //情報をallDataにまとめる
    const tmpList: limitPowerPlant[] = [];
    for (let i = 0; i < 48; i++) {
      tmpList.push({
        upeerNums: upperValue[i],
        upUpeerNums: upupperValue[i],
        powerId: powerIdPK,
      });
    }

    //アップデート
    const error = isCorrectInfo();
    if (error === true) {
      //setDialogMessage("入力内容を確認してください");
      setDigOpen(true);
      return false;
    } else if (error === false) {
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_LIMIT_POWERPLANT_INFO, tmpList)
        .then((response) => {
          setJobClear(true);
          setDialogMessage(
            languageContext.words.power_plant_setting_threshold_updated
          );
          setDigOpen(true);
          return true;
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.power_plant_setting_threshold_failed_update
          );
          setDigOpen(true);
          return false;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  const CloseModal = () => {
    if (UpdateDatas()) {
      open = false;
    } else {
      open = true;
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };
  console.log(upperValue);
  console.log(upupperValue);

  // Todo nagaoka CustomModalを使って統一する
  const RegistrationBtnTabIndex = 48 * 2 + 1;
  //テーブルのレンダー
  const TableRender = () => {
    return (
      <TableContainer sx={{ width: "100%", height: "100%" }}>
        <StyledTable
          sx={{
            width: "100%",
            height: "100%",
            borderCollapse: "separate",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              height: 162,
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                sx={{ minWidth: 100 }}
                align="center"
                rowSpan={4}
              >
                {languageContext.words.timeB}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG align="center" colSpan={4}>
                {languageContext.words.monitoring_threshold_weekdays}
              </StyledTableHeaderLG>
              {/* <StyledTableHeaderG align="center" colSpan={4}>
                {languageContext.words.monitoring_threshold_holiday}
              </StyledTableHeaderG> */}
            </TableRow>
            <TableRow>
              <StyledTableHeaderLG align="center" colSpan={2}>
                {languageContext.words.upper_and_lower_limits}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG align="center" colSpan={2}>
                {languageContext.words.upper_upper_and_lower_lower_limits}
              </StyledTableHeaderLG>
              {/* <StyledTableHeaderG align="center" colSpan={2}>
                {languageContext.words.upper_and_lower_limits}
              </StyledTableHeaderG>
              <StyledTableHeaderG align="center" colSpan={2}>
                {languageContext.words.upper_upper_and_lower_lower_limits}
              </StyledTableHeaderG> */}
            </TableRow>
            <TableRow>
              <StyledTableHeaderLG align="center" width={"70px"}>
                {languageContext.words.set_value}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG align="center">
                {languageContext.words.changed}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG align="center" width={"70px"}>
                {languageContext.words.set_value}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG align="center">
                {languageContext.words.changed}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderLG>
              {/* <StyledTableHeaderG align="center" width={"70px"}>
                {languageContext.words.set_value}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderG>
              <StyledTableHeaderG align="center">
                {languageContext.words.changed}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderG>
              <StyledTableHeaderG align="center" width={"70px"}>
                {languageContext.words.set_value}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderG>
              <StyledTableHeaderG align="center">
                {languageContext.words.changed}
                <br></br>
                {"(kWh)"}
              </StyledTableHeaderG> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((limitData, index) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ textAlign: "center" }}>
                  {timeData[index]}～{timeData[index + 1]}
                </TableCell>
                <TableCell align="right" style={{ padding: "0px 8px 0px 0px" }}>
                  {Number(beforeUpperData[index]).toLocaleString()}
                  {/* 上限設定値*/}
                </TableCell>
                <TableCell style={{ padding: 2 }}>
                  <TextField
                    error={inputError[index]}
                    type="number"
                    name="upeerNums"
                    InputProps={{
                      inputProps: {
                        tabIndex: index + 1,
                        step: 1,
                        min: 0,
                        max: 999999,
                        style: {
                          padding: 0,
                          textAlign: "right",
                          width: "100%",
                        },
                      },
                    }}
                    onFocus={(e) => {
                      if (Number(upperValue[index]) === 0) {
                        e.currentTarget.select();
                      }
                    }}
                    //defaultValue={limitData.upeerNums}
                    value={Number(upperValue[index]).toFixed(0)}
                    onChange={(event) => onChangeValue(event, index)}
                  ></TextField>
                </TableCell>

                <TableCell align="right" style={{ padding: "0px 8px 0px 0px" }}>
                  {Number(beforeUpuperData[index]).toLocaleString()}
                  {/* 上上限設定値 */}
                </TableCell>
                <TableCell style={{ padding: 2 }}>
                  <TextField
                    error={inputError[index]}
                    type="number"
                    name="upUpeerNums"
                    InputProps={{
                      inputProps: {
                        tabIndex: 49 + index,
                        step: 1,
                        min: 0,
                        max: 999999,
                        style: { padding: 0, textAlign: "right" },
                      },
                    }}
                    onFocus={(e) => {
                      if (Number(upupperValue[index]) === 0) {
                        e.currentTarget.select();
                      }
                    }}
                    //defaultValue={limitData.upUpeerNums}
                    value={Number(upupperValue[index]).toFixed(0)}
                    onChange={(event) => onChangeValue(event, index)}
                  ></TextField>
                </TableCell>

                {/* <TableCell>1</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        step: 1,
                        min: 0,
                        max: 999999,
                        style: { padding: 0, textAlign: "right" },
                      },
                    }}
                    defaultValue={limitData}
                  ></TextField>
                </TableCell>
                <TableCell>1</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        step: 1,
                        min: 0,
                        max: 999999,
                        style: { padding: 0, textAlign: "right" },
                      },
                    }}
                    defaultValue={limitData}
                  ></TextField>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    );
  };

  return (
    <>
      <Modal
        open={open}
        onClose={(e, reason) => {
          //背景クリックで閉じるのを無効
          if (reason !== "backdropClick") HandleClose();
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          margin: "auto",
        }}
      >
        <Container sx={{ position: "relative" }} maxWidth={false}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={HandleClose}
              sx={{ position: "relative", right: "-50px" }}
            >
              <CloseIcon fontSize="large" sx={{ color: "white" }} />
            </IconButton>
          </Box>
          <Paper>
            <DialogTitle id="modal-title">
              {languageContext.words.power_gen_threshold_setting}
            </DialogTitle>
            <Divider />
            <DialogContent
              id="modal-description"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                margin: "auto",
              }}
            >
              <Box sx={{ width: 550, height: 700 }}>
                <Box sx={{ height: "100%", width: "100%" }}>
                  {TableRender()}
                </Box>
              </Box>
            </DialogContent>
            {!enableEdit ? (
              ""
            ) : (
              <DialogActions>
                <Button
                  tabIndex={RegistrationBtnTabIndex}
                  variant="contained"
                  onClick={() => {
                    CloseModal();
                  }}
                >
                  {languageContext.words.registration}
                </Button>
              </DialogActions>
            )}
          </Paper>
          <CustomModalProgress open={isLoading} />
        </Container>
      </Modal>


      <CustomDialog
        title={languageContext.words.power_gen_threshold_setting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};
export default SetPowerLimit;
