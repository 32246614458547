import React, { useContext } from "react";
import { PaletteContext } from "../../common/paletteMode";

const LoginImage = () => {
  const palletContextBase = useContext(PaletteContext);
  return (
    <React.Fragment>
      <svg className={`company ${palletContextBase.PaletteMode === "dark" ? "dark" : "light"}`} version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="200px" height="30px" viewBox="0 0 1499.000000 246.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,246.000000) scale(0.100000,-0.100000)"
          fill="#0b4199" stroke="none">
          <path d="M3019 2450 c-258 -41 -475 -144 -691 -327 -49 -41 -88 -70 -88 -64 0
10 51 258 65 319 l5 22 -768 0 c-845 0 -825 1 -924 -61 -58 -37 -120 -104
-148 -159 -26 -52 -24 -43 -265 -1175 -107 -506 -197 -930 -200 -943 l-5 -22
365 0 c201 0 365 2 365 5 0 6 178 839 187 873 l5 22 447 -2 446 -3 7 -65 c9
-82 49 -220 87 -305 241 -531 874 -718 1448 -427 303 153 557 426 695 745 21
48 38 89 38 92 0 3 -37 5 -82 5 l-83 0 -57 -113 c-226 -444 -678 -729 -1117
-704 -212 13 -373 81 -519 221 -159 152 -247 354 -259 596 -17 340 116 675
372 939 199 205 442 336 694 372 101 14 260 7 351 -16 150 -38 319 -144 418
-262 91 -109 169 -285 187 -418 3 -27 8 -60 11 -72 l4 -23 -854 0 c-805 0
-855 -1 -860 -17 -3 -10 -12 -48 -20 -86 l-14 -67 952 2 951 3 3 60 c4 79 -14
238 -39 333 -92 351 -353 611 -707 703 -88 23 -312 34 -403 19z m-1050 -786
c-38 -75 -69 -142 -69 -150 0 -12 -68 -14 -430 -14 -236 0 -430 3 -430 8 1 38
52 220 67 240 36 49 57 51 509 51 l422 1 -69 -136z"/>
          <path d="M4870 1220 l0 -710 125 0 125 0 0 315 0 315 320 0 320 0 0 100 0 100
-317 2 -318 3 -3 188 -2 187 365 0 365 0 0 105 0 105 -490 0 -490 0 0 -710z"/>
          <path d="M7070 1825 l0 -105 115 0 115 0 0 105 0 105 -115 0 -115 0 0 -105z" />
          <path d="M7550 1825 l0 -105 115 0 115 0 0 105 0 105 -115 0 -115 0 0 -105z" />
          <path d="M8570 1220 l0 -710 520 0 521 0 -3 107 -3 108 -392 3 -393 2 0 205 0
205 360 0 360 0 0 100 0 100 -360 0 -360 0 0 190 0 190 385 0 385 0 0 105 0
105 -510 0 -510 0 0 -710z"/>
          <path d="M9760 1220 l0 -710 115 0 115 0 0 710 0 710 -115 0 -115 0 0 -710z" />
          <path d="M13680 1825 l0 -105 110 0 110 0 0 105 0 105 -110 0 -110 0 0 -105z" />
          <path d="M12400 1690 l0 -150 -85 0 -85 0 0 -85 0 -85 85 0 85 0 0 -325 c0
-340 6 -401 43 -457 45 -67 154 -97 307 -83 l80 7 0 87 0 86 -80 0 c-123 0
-114 -25 -118 360 l-3 325 101 0 100 0 0 85 0 85 -100 0 -100 0 0 150 0 150
-115 0 -115 0 0 -150z"/>
          <path d="M10458 1541 c-81 -26 -135 -61 -195 -123 -98 -105 -147 -238 -146
-403 2 -284 144 -473 392 -521 124 -25 270 -1 373 59 84 49 163 154 193 255 6
22 6 22 -102 22 l-109 0 -18 -39 c-59 -136 -280 -173 -406 -69 -51 42 -100
153 -100 225 l0 23 380 0 380 0 0 63 c-1 240 -138 445 -341 508 -80 25 -222
25 -301 0z m221 -167 c93 -27 166 -115 186 -226 l7 -38 -266 0 c-301 0 -279
-8 -251 86 26 88 110 166 197 183 63 13 69 12 127 -5z"/>
          <path d="M11558 1545 c-167 -46 -287 -174 -332 -356 -21 -85 -21 -261 0 -342
66 -256 286 -396 560 -357 189 27 313 134 368 319 28 95 32 91 -94 91 l-109 0
-12 -42 c-15 -59 -33 -90 -70 -127 -50 -50 -86 -65 -164 -65 -77 -1 -127 19
-179 70 -72 71 -109 224 -88 364 28 192 122 287 282 284 105 -2 186 -56 216
-146 l16 -48 110 0 111 0 -7 47 c-20 147 -135 264 -301 308 -72 18 -240 19
-307 0z"/>
          <path d="M13373 1536 c-66 -31 -135 -95 -162 -149 l-21 -42 0 98 0 97 -105 0
-105 0 0 -515 0 -515 109 0 109 0 4 298 c4 279 5 300 26 353 27 69 81 133 136
161 31 17 59 22 118 23 l78 0 0 108 0 107 -67 0 c-51 0 -81 -6 -120 -24z"/>
          <path d="M14387 1549 c-72 -17 -152 -63 -208 -119 -103 -102 -151 -235 -151
-415 0 -258 112 -433 323 -506 117 -41 302 -29 410 26 94 48 174 148 209 262
33 108 37 103 -90 103 l-109 0 -11 -42 c-23 -87 -84 -157 -159 -183 -86 -29
-168 -12 -236 47 -100 88 -138 245 -101 419 35 161 122 241 266 242 125 1 195
-51 234 -175 5 -16 19 -18 117 -18 l112 0 -7 47 c-20 146 -136 265 -301 309
-56 15 -239 17 -298 3z"/>
          <path d="M5930 1193 c0 -192 5 -377 10 -413 14 -88 61 -181 111 -221 56 -44
146 -71 249 -72 110 -1 186 30 260 109 l50 53 0 -70 0 -69 110 0 110 0 0 515
0 515 -109 0 -110 0 -4 -342 c-2 -243 -7 -355 -16 -383 -8 -24 -33 -59 -61
-87 -39 -38 -59 -48 -106 -58 -106 -22 -189 6 -232 79 l-27 46 -3 373 -3 372
-114 0 -115 0 0 -347z"/>
          <path d="M7068 929 l-3 -611 -24 -20 c-19 -15 -37 -19 -80 -17 l-56 3 -3 -86
-3 -87 47 -7 c140 -21 264 29 314 126 36 71 40 139 40 732 l0 578 -115 0 -115
0 -2 -611z"/>
          <path d="M7550 1025 l0 -515 115 0 115 0 0 515 0 515 -115 0 -115 0 0 -515z" />
          <path d="M13680 1025 l0 -515 110 0 110 0 0 515 0 515 -110 0 -110 0 0 -515z" />
        </g>
      </svg>
    </React.Fragment>
  );
};

export default LoginImage;