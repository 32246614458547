import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { LanguageContext } from "../common/localization/localization";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { FormLabel, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomBoxSmall } from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomTextFieldSmall } from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  resStorageBattery,
  reqStorageBattery,
  ErrorInput,
} from "../../types/master/SetStorageBattery";
import { useAuthedApi } from "../../common/axios";
import {
  API_URL,
  RESULT_CODE,
  AREA_PRICE_REF_INTERVAL,
} from "../../common/constant";
import { hourTime } from "../../common/cellTime";

export enum modalMode {
  editMode,
  registerMode,
}

const SetStorageBattery = (
  open: boolean,
  HandleClose: () => void,
  powerIdPK: number,
  contractPower: number
) => {
  const initStorageBattery: resStorageBattery = {
    batteryFlag: 0,
    batteryId: "",
    batteryCapacity: 0,
    socUpperLimit: 100,
    socLowerLimit: 0,
    switchingTimeCd: "18",
    chargeBorderPrice: "0.00",
    dischargeBorderPrice: "0.00",
    batteryOutput: 0,
    spotPriceReferenceInterval: 0,
  };
  const initErrorInput: ErrorInput = {
    batteryId: false,
    batteryCapacity: false,
    socUpperLimit: false,
    socLowerLimit: false,
    switchingTimeCd: false,
    chargeBorderPrice: false,
    dischargeBorderPrice: false,
    batteryOutput: false,
    spotPriceReferenceInterval: false,
  };

  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [storageBatteryInfo, setStorageBatteryInfo] =
    useState<resStorageBattery>(initStorageBattery);
  const [lastStorageBatteryInfo, setLastStorageBatteryInfo] =
    useState<resStorageBattery>(initStorageBattery);
  const [mode, setMode] = useState<modalMode>(modalMode.editMode);
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [isLoading, setIsLoading] = useState<boolean>(false);

  type time = { id: number; name: string };
  type interval = { id: number; name: string };

  const times: time[] = [];
  //times.push({ id: 0, name: languageContext.words.setting_unselected });
  hourTime.forEach((element, index) => {
    times.push({ id: ++index, name: element });
  });
  const constantValues = {
    lowerLimitSoc: 0,
    upperLimitSoc: 100,
    upperLimitCapa: 999999,
    upperLimitOutput: 999999,
    upperLimitBorder: 999999.99,
  };

  const intervals: interval[] = [
    { id: 0, name: languageContext.words.setting_unselected },
    {
      id: AREA_PRICE_REF_INTERVAL.DAY,
      name: languageContext.words.spot_price_interval_day,
    },
    {
      id: AREA_PRICE_REF_INTERVAL.WEEK,
      name: languageContext.words.spot_price_interval_week,
    },
  ];

  // 通信エラー
  const api = useAuthedApi();

  //初期表示
  useEffect(() => {
    setStorageBatteryInfo(initStorageBattery);
    setInputError(initErrorInput);
    setMode(modalMode.editMode);
    if (open === true) {
      setIsLoading(true);
      (async () => {
        //一行分のデータを取得
        getStorageInfoLinkedPK(powerIdPK).finally(() => {
          setIsLoading(false);
        });
      })();
    }
  }, [open]);

  //成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化

  //蓄電池情報取得api
  const getStorageInfoLinkedPK = (powerId: number) => {
    return api
      .get(API_URL.GET_STORAGE_INFO, {
        params: { powerId: powerId },
      })
      .then((res) => {
        // バックエンドからjson形式で返却されると全てstringになってしまうため
        // 各要素の型を再定義
        const tmpInfo: resStorageBattery = {
          batteryFlag: Number(res.data.result.batteryFlag),
          batteryId: res.data.result.batteryId,
          batteryCapacity: Number(res.data.result.batteryCapacity),
          socUpperLimit: Number(res.data.result.socUpperLimit),
          socLowerLimit: Number(res.data.result.socLowerLimit),
          switchingTimeCd: res.data.result.switchingTimeCd,
          chargeBorderPrice: Number(res.data.result.chargeBorderPrice).toFixed(
            2
          ),
          dischargeBorderPrice: Number(
            res.data.result.dischargeBorderPrice
          ).toFixed(2),
          batteryOutput: Number(res.data.result.batteryOutput),
          spotPriceReferenceInterval: Number(
            res.data.result.spotPriceReferenceInterval
          ),
        };
        setMode(tmpInfo.batteryFlag);
        setLastStorageBatteryInfo(tmpInfo);
        setStorageBatteryInfo(tmpInfo);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  // 蓄電池情報登録api
  const updateStorageInfo = (powerId: number, saveInfo: reqStorageBattery) => {
    // チェック処理
    Object.assign(inputError, initErrorInput);
    const checkMethods = [
      {
        // 必須項目チェック
        meth: isCorrectInfo,
        msg: languageContext.words.setting_confirm_entry,
      },
      {
        // 蓄電上限>蓄電下限チェック
        meth: isSocUpperGreaterThanLower,
        msg: languageContext.words
          .storage_battery_setting_soc_upper_greaterthan_lower,
      },
      {
        // 放電基準価格＞充電基準価格チェック
        meth: isDischrgGreaterThanChrg,
        msg: languageContext.words
          .storage_battery_setting_dischrg_greaterthan_chrg,
      },
      {
        // 定格出力＜=契約電力チェック
        meth: isContractpowerGreaterThanOrEqualOutPut,
        msg: languageContext.words
          .storage_battery_setting_contractpower_greaterthanorequal_outPut,
      },
    ];

    // 各入力チェックを行う
    // チェックエラーがある場合apiは呼び出さない
    for (const element of checkMethods) {
      if (element.meth() === false) {
        setDialogMessage(element.msg);
        setDigOpen(true);
        return;
      }
    }

    setIsLoading(true);
    api
      .post(API_URL.UPDATE_STORAGE_INFO, {
        powerId: powerId,
        saveInfo: saveInfo,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.resultCode === RESULT_CODE.SUCCESS) {
          setJobClear(true);
          setDialogMessage(
            languageContext.words.storage_battery_setting_update
          );
          setDigOpen(true);
        } else if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
          const tmpInfo = Object.assign({}, initErrorInput);
          tmpInfo.batteryId = true;
          setInputError(tmpInfo);
          setDialogMessage(
            languageContext.words.storage_battery_setting_duplicated
          );
          setDigOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setDialogMessage(
          languageContext.words.storage_battery_setting_failed_update
        );
        setDigOpen(true);
      });
  };

  // 蓄電池情報削除api
  const deleteStorageInfo = (powerId: number) => {
    setIsLoading(true);
    api
      .post(API_URL.DELETE_STORAGE_INFO, {
        powerId: powerId,
      })
      .then((res) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(languageContext.words.storage_battery_setting_delete);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setDialogMessage(
          languageContext.words.storage_battery_setting_failed_delete
        );
        setDigOpen(true);
      });
  };

  const isCorrectInfo = (): boolean => {
    let result = true;
    const tmpInfo = Object.assign({}, inputError);
    if (storageBatteryInfo?.batteryId == null || storageBatteryInfo?.batteryId === "") {
      result = false;
      tmpInfo.batteryId = true;
    }
    if (storageBatteryInfo?.batteryCapacity === 0) {
      result = false;
      tmpInfo.batteryCapacity = true;
    }
    if (storageBatteryInfo?.socUpperLimit === 0) {
      result = false;
      tmpInfo.socUpperLimit = true;
    }
    if (storageBatteryInfo?.socLowerLimit === null) {
      result = false;
      tmpInfo.socLowerLimit = true;
    }
    if (parseInt(storageBatteryInfo?.switchingTimeCd) === 0) {
      result = false;
      tmpInfo.switchingTimeCd = true;
    }
    if (storageBatteryInfo?.chargeBorderPrice === "0.00") {
      result = false;
      tmpInfo.chargeBorderPrice = true;
    }

    if (storageBatteryInfo?.dischargeBorderPrice === "0.00") {
      result = false;
      tmpInfo.dischargeBorderPrice = true;
    }

    if (storageBatteryInfo?.batteryOutput === 0) {
      result = false;
      tmpInfo.batteryOutput = true;
    }
    if (storageBatteryInfo?.spotPriceReferenceInterval === 0) {
      result = false;
      tmpInfo.spotPriceReferenceInterval = true;
    }

    setInputError(tmpInfo);
    return result;
  };
  const isSocUpperGreaterThanLower = (): boolean => {
    let result = true;
    const tmpInfo = Object.assign({}, inputError);
    if (
      !(storageBatteryInfo?.socUpperLimit > storageBatteryInfo?.socLowerLimit)
    ) {
      result = false;
      tmpInfo.socUpperLimit = true;
      tmpInfo.socLowerLimit = true;
    }
    setInputError(tmpInfo);
    return result;
  };
  const isDischrgGreaterThanChrg = (): boolean => {
    let result = true;
    const tmpInfo = Object.assign({}, inputError);
    if (
      !(
        Number(storageBatteryInfo?.dischargeBorderPrice) >
        Number(storageBatteryInfo?.chargeBorderPrice)
      )
    ) {
      result = false;
      tmpInfo.chargeBorderPrice = true;
      tmpInfo.dischargeBorderPrice = true;
    }
    setInputError(tmpInfo);
    return result;
  };
  const isContractpowerGreaterThanOrEqualOutPut = (): boolean => {
    let result = true;
    const tmpInfo = Object.assign({}, inputError);
    if (!(contractPower >= storageBatteryInfo?.batteryOutput)) {
      result = false;
      tmpInfo.batteryOutput = true;
    }
    setInputError(tmpInfo);
    return result;
  };

  //テキストフィールドの中身が変化したら、storageBatteryInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    let value: string = e.currentTarget.value;

    const tmpInfo: resStorageBattery = Object.assign({}, storageBatteryInfo);
    switch (name) {
      case "battery_id":
        // 半角英数字以外は受け取らない
        if (!value.match(/^[0-9a-zA-Z]{0,10}$/)) {
          break;
        } else {
          tmpInfo.batteryId = value;
        }
        break;
      case "soc_lower_limit":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitSoc, 0)) {
          tmpInfo.socLowerLimit = parseInt(value);
        }
        break;
      case "soc_upper_limit":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitSoc, 0)) {
          tmpInfo.socUpperLimit = parseInt(value);
        }
        break;
      case "battery_capacity":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitCapa, 0)) {
          tmpInfo.batteryCapacity = parseInt(value);
        }
        break;
      case "battery_output":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitOutput, 0)) {
          tmpInfo.batteryOutput = parseInt(value);
        }
        break;
      case "charge_border_price":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitBorder, 0)) {
          tmpInfo.chargeBorderPrice = value;
        }
        break;
      case "discharge_border_price":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (isSafeNumInTextField(value, constantValues.upperLimitBorder, 0)) {
          tmpInfo.dischargeBorderPrice = value;
        }
        break;
      default:
        break;
    }
    setStorageBatteryInfo(tmpInfo);
  }

  // セレクトリスト切り替え時データセット
  function setSwitchingTimeCd(timeCd: string) {
    const tmpInfo: resStorageBattery = Object.assign({}, storageBatteryInfo);
    tmpInfo.switchingTimeCd = timeCd;
    setStorageBatteryInfo(tmpInfo);
  }
  function setSpotPriceReferenceInterval(interval: number) {
    const tmpInfo: resStorageBattery = Object.assign({}, storageBatteryInfo);
    tmpInfo.spotPriceReferenceInterval = interval;
    setStorageBatteryInfo(tmpInfo);
  }

  // 小数点形式の文字列に変換
  const processText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    text: resStorageBattery,
    setText: React.Dispatch<React.SetStateAction<resStorageBattery>>
  ) => {
    const data = e.currentTarget.value;
    const name = e.currentTarget.name;
    const tmpInfo: resStorageBattery = structuredClone(text);
    switch (name) {
      case "soc_lower_limit":
        if (data === "0") {
          tmpInfo.socLowerLimit = constantValues.lowerLimitSoc;
        } else {
          tmpInfo.socLowerLimit = Number(data);
        }
        break;
      case "soc_upper_limit":
        if (data === "0") {
          tmpInfo.socUpperLimit = constantValues.lowerLimitSoc;
        } else {
          tmpInfo.socUpperLimit = Number(data);
        }
        break;
      case "charge_border_price":
        tmpInfo.chargeBorderPrice = Number(data).toFixed(2);
        break;
      case "discharge_border_price":
        tmpInfo.dischargeBorderPrice = Number(data).toFixed(2);
        break;
      default:
        break;
    }
    setText(tmpInfo);
  };

  //テキストフィールドに正しい数値チェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseFloat(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <>
      <CustomSettingModal
        title={languageContext.words.storage_battery_setting_title}
        open={open}
        onAcceptLeft={() => updateStorageInfo(powerIdPK, storageBatteryInfo)}
        onAcceptRight={() => deleteStorageInfo(powerIdPK)}
        onClose={() => HandleClose()}
        btnTextLeft={languageContext.words.registration}
        btnTextRight={languageContext.words.delete}
        btnType={
          mode === modalMode.editMode ? NumOfButton.Single : NumOfButton.Double
        }
        isAcceptedKey={isLoading}
      >
        <Box sx={{ height: "100%", width: "100%" }}>
          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.storage_battery_setting_id}
            </FormLabel>
            <CustomTextFieldSmall
              name="battery_id"
              type="text"
              value={storageBatteryInfo.batteryId}
              onChange={onChangeValue}
              error={inputError.batteryId}
              disabled={lastStorageBatteryInfo.batteryId != null && lastStorageBatteryInfo.batteryId.length != 0}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_lower}
              </FormLabel>

              <CustomTextFieldSmall
                name="soc_lower_limit"
                type="number"
                value={Number(storageBatteryInfo.socLowerLimit).toFixed(0)}
                onFocus={(e) => {
                  if (e.target.value === "0") {
                    e.currentTarget.select();
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-" ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                  }
                }}
                onBlur={(e) => {
                  processText(e, storageBatteryInfo, setStorageBatteryInfo);
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 1,
                  defaultValue: 1,
                  min: 0,
                  max: 100,
                }}
                error={inputError.socLowerLimit}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_upper}
              </FormLabel>
              <CustomTextFieldSmall
                name="soc_upper_limit"
                type="number"
                value={Number(storageBatteryInfo.socUpperLimit).toFixed(0)}
                onFocus={(e) => {
                  if (e.target.value === "0") {
                    e.currentTarget.select();
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                  }
                }}
                onBlur={(e) => {
                  processText(e, storageBatteryInfo, setStorageBatteryInfo);
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 1,
                  defaultValue: 100,
                  min: 0,
                  max: 100,
                }}
                error={inputError.socUpperLimit}
              />
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_capacity}
              </FormLabel>
              <CustomTextFieldSmall
                name="battery_capacity"
                type="number"
                value={Number(storageBatteryInfo.batteryCapacity).toFixed(0)}
                onFocus={(e) => {
                  if (e.target.value === "0") {
                    e.currentTarget.select();
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 1,
                  defaultValue: 0,
                  min: 0,
                }}
                error={inputError.batteryCapacity}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_output}
              </FormLabel>

              <CustomTextFieldSmall
                name="battery_output"
                type="number"
                value={Number(storageBatteryInfo.batteryOutput).toFixed(0)}
                onFocus={(e) => {
                  if (e.target.value === "0") {
                    e.currentTarget.select();
                  }
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "."
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 1,
                  defaultValue: 0,
                  min: 0,
                }}
                error={inputError.batteryOutput}
              />
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_chrgborder}
              </FormLabel>
              <CustomTextFieldSmall
                name="charge_border_price"
                type="number"
                value={storageBatteryInfo.chargeBorderPrice}
                onFocus={(e) => {
                  if (e.target.value === "0.00") {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, storageBatteryInfo, setStorageBatteryInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 0.01,
                  defaultValue: 0,
                  min: 0,
                }}
                error={inputError.chargeBorderPrice}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_dischrgborder}
              </FormLabel>

              <CustomTextFieldSmall
                name="discharge_border_price"
                type="number"
                value={storageBatteryInfo.dischargeBorderPrice}
                onFocus={(e) => {
                  if (e.target.value === "0.00") {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, storageBatteryInfo, setStorageBatteryInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                inputProps={{
                  step: 0.01,
                  defaultValue: 0,
                  min: 0,
                }}
                error={inputError.dischargeBorderPrice}
              />
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_switchingtime}
              </FormLabel>
              <CustomSelectListSmall
                name="switching_time_cd"
                value={Number(storageBatteryInfo.switchingTimeCd)}
                options={times}
                onChange={(e: any) => {
                  setSwitchingTimeCd(e);
                }}
                error={inputError.switchingTimeCd}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <FormLabel required>
                {languageContext.words.storage_battery_setting_priceinterval}
              </FormLabel>
              <CustomSelectListSmall
                name="spotprice_ref_interval"
                value={storageBatteryInfo.spotPriceReferenceInterval}
                options={intervals}
                onChange={(e: any) => {
                  setSpotPriceReferenceInterval(e);
                }}
                error={inputError.spotPriceReferenceInterval}
              />
            </CustomBoxSmall>
          </Stack>
        </Box>
        <CustomModalProgress open={isLoading} />
      </CustomSettingModal>
      <CustomDialog
        title={languageContext.words.storage_battery_setting_title}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default SetStorageBattery;
