import React, { ReactNode } from "react";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

//モーダルウィンドウもそろえた方がいいんじゃないか説
interface Props {
  children: ReactNode;
  title: string;
  btnText: string;
  onAccept: () => void;
  onClose: () => void;
  open: boolean;
  hidden?: boolean;
  isDoubleBtn?: boolean;  // モーダル左下に赤色のボタンを表示するかどうか
  btnTextDouble?: string; // モーダル左下の赤色のボタンに表示するテキスト内容
}

// Todo kushibiki 保存ボタンがchildrenの中の要素の次にタブキーでフォーカスされるようにtabIndexの値を作れるようにする（現状は入札制限のフォーカス順のみ考慮されている）
const SAVE_BTN_TAB_INDEX = 48 * 8 + 1;

const CustomModal = (props: Props) => {
  const { children, title, btnText, onAccept, onClose, open, hidden, isDoubleBtn, btnTextDouble } = props;

  return (
    <Modal
      tabIndex={-1}
      open={open}
      onClose={(e, reason) => {
        //背景クリックで閉じるのを無効
        if (reason !== "backdropClick") onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <Container sx={{ position: "relative" }} maxWidth={false}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={onClose}
            sx={{ position: "relative", right: "-50px" }}
          >
            <CloseIcon fontSize="large" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Paper>
          <DialogTitle id="modal-title">{title}</DialogTitle>
          <Divider />
          <DialogContent
            id="modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              margin: "auto",
            }}
          >
            {children}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                visibility: isDoubleBtn === true ? "visible" : "hidden",
                backgroundColor: "error.light",
                "&:hover": {
                  backgroundColor: "error.main",
                },
              }}
              onClick={() => {
                onClose();
              }}
            >
              {btnTextDouble}
            </Button>
            {!hidden &&
              <Button
                tabIndex={SAVE_BTN_TAB_INDEX}
                variant="contained"
                onClick={() => {
                  onAccept();
                  onClose();
                }}
              >
                {btnText}
              </Button>
            }
          </DialogActions>
        </Paper>
      </Container>
    </Modal>
  );
};

export default CustomModal;
