import React, { useState, useEffect, useContext, useMemo } from "react";
import "./css/SetSupplyList.css";
import { FormGroup, FormLabel, OutlinedInput, Stack } from "@mui/material";
import axios from "axios";
import { LanguageContext } from "../common/localization/localization";
import {
  resDemandBgInfo,
  setDemandInfo,
  updateDemandInfo,
} from "../../types/master/SetSupplyList";
import { modalMode } from "./SupplyList";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { useVoltageLevelUtility } from "../../common/voltageLevel";
import { useAuthedApi } from "../../common/axios";
import { API_URL, RESULT_CODE } from "../../common/constant";
import { CustomModalProgress } from "../common/customComponents/CustomProgress";
import { BusinessModelContext } from "../common/customComponents/BusinessModelProvider";
import { getBusinessModel } from "../common/getBusinessModel";

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  volLevel: boolean;
  volUnit: boolean;
  demandName: boolean;
  areaId: boolean;
  bpId: boolean;
  powerBpCode: boolean;
  powerBpName: boolean;
  supplyPointSpNo: boolean;
  demandBgId: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  volLevel: false,
  volUnit: false,
  demandName: false,
  areaId: false,
  bpId: false,
  powerBpCode: false,
  powerBpName: false,
  supplyPointSpNo: false,
  demandBgId: false,
};

/**
 * 需要家編集(登録)モーダル
 * @param toOpen
 * @param HandleClose
 * @param mode
 * @param demandIdForEdit
 * @returns
 */
const SetDemandList = (
  toOpen: boolean,
  HandleClose: () => void,
  mode: modalMode,
  demandIdForEdit: number
) => {
  type powerBpInfo = {
    bpid: number;
    name: string;
    code: string;
  };
  type demandBgInfo = {
    //需要BGセレクター用
    id: number;
    demand_bg_id: number;
    name: string;
    area_id: string;
    area_name: string;
  };

  //通信エラー
  const api = useAuthedApi();

  //エリアリスト
  const { convertIdToAreaName } = useAreaUtility();
  //電圧階級
  const { volLevelOptions } = useVoltageLevelUtility();

  // ビジネスモデル再セット
  const { setBusinessModel } = useContext(BusinessModelContext);

  // 需要BG
  const demandBgInfos = React.useRef<demandBgInfo[]>([
    { id: 0, demand_bg_id: 0, name: "", area_id: "", area_name: "" },
  ]);

  // 需要BG一覧
  const [demandBgList, setDemandBgList] = useState<demandBgInfo[]>([
    { id: 0, demand_bg_id: 0, name: "", area_id: "", area_name: "" },
  ]);

  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ
  const [demandListInfo, setDemandListInfo] = useState<setDemandInfo>();
  // 事業者名称等,事業者コード
  const [powerBp, setPowerBp] = useState<powerBpInfo>({
    bpid: 0,
    name: "",
    code: "",
  });

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // 選択されている接続エリア
  const selectedArea = useMemo(
    () =>
      demandBgList.find((v) => v.area_id === demandListInfo?.areaId)?.area_id ??
      "",
    [demandBgList, demandListInfo]
  );

  // 選択されている需要BGID
  const selectedBgNo = useMemo(
    () =>
      demandBgList.find((v) => v.demand_bg_id === demandListInfo?.demandBgId)
        ?.id ?? 0,
    [demandBgList, demandListInfo]
  );

  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化

  /**
   * 事業者情報を取得(API)
   */
  const getPowerBp = () => {
    return api
      .get(API_URL.GET_POWER_BP_DEMAND)
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.NO_BP_DATA) {
          setDialogMessage(languageContext.words.setting_can_not_display_bp);
          setDigOpen(true);
        }

        // 事業者IDをセット
        setDemandListInfo((prev) =>
          prev ? { ...prev, bpId: res.data.result[0].bpId as number } : prev
        );

        const tempPowerBp: powerBpInfo = {
          bpid: res.data.result[0].bpId as number,
          name: res.data.result[0].bpName as string,
          code: res.data.result[0].bpCode as string,
        };
        setPowerBp(tempPowerBp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 需要BGの一覧の取得
  const getDemandBgMst = () => {
    return api
      .get(API_URL.GET_DEMANDBG_MST)
      .then((response) => {
        if (response.data.resultCode === RESULT_CODE.NO_DEMAND_BG) {
          setDialogMessage(
            languageContext.words.setting_can_not_display_demandbg
          );
          setDigOpen(true);
        }

        setDemandBgList(
          convertDemandBgSelecterDatas(
            response.data.result as resDemandBgInfo[]
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertDemandBgSelecterDatas = (result: resDemandBgInfo[]) => {
    const retDemandBgData: demandBgInfo[] = [];
    result.map((data, index) => {
      const demandBgData: demandBgInfo = {
        id: 0,
        name: "",
        demand_bg_id: 0,
        area_id: "00",
        area_name: "",
      };
      demandBgData.id = index + 1; //0要素にはデフォルトのものが入るため
      demandBgData.name = data.name;
      demandBgData.demand_bg_id = data.demand_bg_id;
      demandBgData.area_id = data.area_id;
      demandBgData.area_name = data.area_name;

      retDemandBgData.push(demandBgData);
    });
    return retDemandBgData;
  };

  const setDemandBgId = (e: number) => {
    if (!e) {
      // 未選択
      const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);

      tmpDemandInfo.areaId = "";
      tmpDemandInfo.demandBgId = 0;
      setDemandListInfo(tmpDemandInfo);
      return;
    }
    const tmpBgInfo: demandBgInfo[] = createBgListJoinUnselect();
    tmpBgInfo.map(async (bginfo) => {
      if (e === bginfo.id) {
        const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
        tmpDemandInfo.areaId = bginfo.area_id;
        tmpDemandInfo.demandBgId = bginfo.demand_bg_id;
        setDemandListInfo(tmpDemandInfo);
      }
    });
  };

  const [volLevelNo, setvolLevelNo] = useState<number>(0);
  const volLevels: string[] = ["0", "1", "2", "3"];

  function SetVolLevel(id: number) {
    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    const tmpVolLevelInfo: string[] = volLevels;
    tmpDemandInfo.volLevel = Number(tmpVolLevelInfo[id]);
    setvolLevelNo(id);

    setDemandListInfo(tmpDemandInfo);
  }

  // 初期化用
  const initializeDemandListInfo: setDemandInfo = {
    demandId: 0,
    demandName: "",
    volLevel: 0,
    areaId: "00",
    supplyPointSpNo: "",
    bpId: 0,
    contractPower: 0,
    demandBgId: 0,
    remarks: "",
  };

  //初期化する
  useEffect(() => {
    setDemandListInfo(initializeDemandListInfo);
  }, []);

  useEffect(() => {
    if (toOpen === true) {
      setIsLoading(true);
      if (mode === modalMode.editMode) {
        (async () => {
          Promise.all([
            getPowerBp(),
            getDemandBgMst(),
            getRecordDemandList(demandIdForEdit),
          ]).finally(() => {
            setIsLoading(false);
          });
        })();
        //1レコード取得
      } else {
        (async () => {
          Promise.all([getPowerBp(), getDemandBgMst()]).finally(() => {
            setIsLoading(false);
          });
        })();
      }
    } else {
      setDemandListInfo(initializeDemandListInfo);
      setvolLevelNo(0);
      setInputError(initErrorInput);
    }
  }, [toOpen]);

  // 需要家1レコード取得
  const getRecordDemandList = (demandId: number) => {
    let responseData: setDemandInfo;
    return api
      .get(API_URL.GET_RECORD_DEMAND_LIST_API, { params: { id: demandId } })
      .then((response) => {
        editflg.current = true;
        responseData = response.data.result as setDemandInfo;
        setvolLevelNo(responseData.volLevel);
        setDemandListInfo(responseData); // データセット
      })
      .catch((err) => {
        console.log(err);
        setDemandListInfo(initializeDemandListInfo);
      });
  };

  //需要BGリストの先頭に未選択の選択肢を追加した配列を返す
  const createBgListJoinUnselect = () => {
    const unselect: demandBgInfo[] = [
      {
        id: 0,
        demand_bg_id: 0,
        name: languageContext.words.setting_unselected,
        area_id: "00",
        area_name: "",
      },
    ];
    return unselect.concat(demandBgList);
  };

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    let value = e.currentTarget.value;

    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    switch (name) {
      case "demandName":
        tmpDemandInfo.demandName = value;
        break;
      case "volLevel":
        tmpDemandInfo.volLevel = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      case "supplyPointSpNo":
        tmpDemandInfo.supplyPointSpNo = value;
        break;
      case "contractPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpDemandInfo.contractPower = parseInt(value);
        }
        break;
    }
    setDemandListInfo(tmpDemandInfo);
  }

  //テキストフィールドに正しい数値が入っているかチェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    if (!isNaN(parseInt(value))) {
      const valueNum = parseInt(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  const SaveClose = () => {
    if (mode === modalMode.registerMode) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  };

  // 需要家新規登録
  const InsertRecord = () => {
    let error = false;
    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      setIsLoading(true);
      api
        .post(API_URL.SET_DEMAND_LIST_API, demandListInfo)
        .then((res) => {
          if(res.data.resultCode === RESULT_CODE.SUCCESS){
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_setting_added);          
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(languageContext.words.supply_point_number_duplicated);
          }
          setIsLoading(false);
          setDigOpen(true);

        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.demand_setting_failed_add);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (demandListInfo?.volLevel === 0) {
      error = true;
      tempInputErrors.volLevel = true;
    }
    if (demandListInfo?.demandName === "") {
      error = true;
      tempInputErrors.demandName = true;
    }
    if (demandListInfo?.areaId === "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (demandListInfo?.bpId === 0) {
      error = true;
      tempInputErrors.bpId = true;
    }
    if (demandListInfo?.supplyPointSpNo === "") {
      error = true;
      tempInputErrors.supplyPointSpNo = true;
    }
    if (demandListInfo?.demandBgId === 0) {
      error = true;
      tempInputErrors.demandBgId = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  function UpdateRecord() {
    let error = false;
    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const updateDemandInfo = {
        demandId: demandIdForEdit,
        ...demandListInfo,
      } as updateDemandInfo;
      setIsLoading(true);
      api
        .post(API_URL.UPDATE_RECORD_DEMAND_LIST_API, updateDemandInfo)
        .then((res) => {
          if(res.data.resultCode === RESULT_CODE.SUCCESS){
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_setting_updated);
          }
          if (res.data.resultCode === RESULT_CODE.DUPLICATED_ID) {
            setDialogMessage(languageContext.words.supply_point_number_duplicated);
          }
          setIsLoading(false);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.demand_setting_failed_update);
          setDigOpen(true);
          setIsLoading(false);
        });
    }
  }

  // 削除(論理削除)
  const Delete = async (demandId: number | undefined) => {
    setIsLoading(true);
    await api
      .post(API_URL.DELETE_DEMAND_INFO, { demandId: demandId })
      .then((response) => {
        setIsLoading(false);
        setJobClear(true);
        setDialogMessage(languageContext.words.demand_setting_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.demand_setting_failed_delete);
        setDigOpen(true);
        setIsLoading(false);
      });
    (async () => {
      setBusinessModel(await getBusinessModel());
    })();
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.demander_editting}
      open={toOpen}
      onAcceptLeft={() => SaveClose()}
      onAcceptRight={() => Delete(demandIdForEdit)}
      onClose={() => HandleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.registerMode
          ? NumOfButton.Single
          : NumOfButton.Double
      }
      isAcceptedKey={isLoading}
    >
      <Stack sx={{ width: "550px" }}>
        <CustomBoxBig>
          <FormLabel required>{languageContext.words.demander_name}</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={demandListInfo?.demandName}
            onChange={onChangeValue}
            name="demandName"
            InputProps={{
              inputProps: {
                maxLength: 300,
              },
            }}
            error={inputError.demandName}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.business_name_etc}
            </CustomModalTypography>
            <CustomTextFieldSmall // 事業者名称等
              type="text"
              name="powerBpName"
              value={powerBp.name} // 事業者名
              disabled={true}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.setting_bp_code}
            </CustomModalTypography>
            <CustomTextFieldSmall // 事業者コード
              type="text"
              value={powerBp.code}
              name="powerBpCode"
              disabled={true}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>{languageContext.words.affiliation_demand_bg}</FormLabel>
            </FormGroup>
            <CustomSelectListBig
              name="demandBg"
              label={languageContext.words.none}
              value={selectedBgNo}
              options={createBgListJoinUnselect()}
              input={<OutlinedInput label="name" />}
              onChange={(e: number) => {
                setDemandBgId(e);
              }}
              error={inputError.demandBgId}
              disabled={mode === modalMode.editMode ? true : false}
            ></CustomSelectListBig>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.area}
            </CustomModalTypography>
            <CustomTextFieldSmall
              name="areaId"
              value={convertIdToAreaName(selectedArea)}
              disabled={true}
              error={inputError.areaId}
            />
          </CustomBoxSmall>
        </Stack>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormGroup>
              <FormLabel required>
                {languageContext.words.voltage_class}
              </FormLabel>
            </FormGroup>
            <CustomSelectListSmall // 電圧階級
              name="volLevel"
              label={languageContext.words.none}
              value={volLevelNo}
              options={volLevelOptions(volLevels)}
              input={<OutlinedInput label="name" />}
              onChange={SetVolLevel}
              error={inputError.volLevel}
            ></CustomSelectListSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.supply_point_id_number}
            </FormLabel>

            <CustomTextFieldSmall // 供給地点特定番号
              type="text"
              value={demandListInfo?.supplyPointSpNo}
              onChange={onChangeValue}
              name="supplyPointSpNo"
              InputProps={{
                inputProps: {
                  maxLength: 22,
                },
              }}
              error={inputError.supplyPointSpNo}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <FormLabel required>
              {languageContext.words.contracted_power_kw}
            </FormLabel>
            <CustomTextFieldSmall // 契約電力
              type="number"
              value={Number(demandListInfo?.contractPower).toFixed(0)}
              onFocus={(e) => {
                if (Number(demandListInfo?.contractPower) === 0) {
                  e.currentTarget.select();
                }
              }}
              onChange={onChangeValue}
              name="contractPower"
              inputProps={{
                step: 1,
                min: 0,
                max: 999999,
              }}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <FormLabel>{languageContext.words.remarks}</FormLabel>
          <CustomTextFieldBig
            type="text"
            value={demandListInfo?.remarks}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setDemandListInfo((prev) =>
                prev
                  ? {
                      ...prev,
                      remarks: e.target.value,
                    }
                  : prev
              );
            }}
            name="remarks"
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>
      <CustomModalProgress open={isLoading} />
      <CustomDialog
        title={languageContext.words.demander_editting}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetDemandList;
