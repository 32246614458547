import { Box, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

// マスタモーダルラベル部分
export const CustomModalTypography = styled(Typography)(() => ({
  height: "17px",
  fontSize: "15px",
  marginBottom: "5px !important;",
}));
