import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList, {
  CustomMultipleSelectList,
} from "../../common/customComponents/CustomSelectList";
import {
  GlobalDatePicker,
  DateContext,
} from "../../common/customComponents/GlobalDatePicker";
import CustomDatePickerTryal from "../../common/customComponents/CustomDatePickerTryal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  ChartData,
  LegendItem,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  memo,
  useCallback,
  Dispatch,
} from "react";
import { ClipboardEvent } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import { useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AxiosError, AxiosResponse } from "axios";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import Typography from "@mui/material/Typography";
import {
  addDays,
  isBefore,
  isSameDay,
  isToday,
  min,
  setDate,
  subDays,
} from "date-fns";
import { LanguageContext } from "../../common/localization/localization";
import {
  reqSaveActualPlan,
  lastUpdateInfo,
  PastPowerPlanData,
  PowerPlanTableProps,
  PowerPlanGraphProps,
  areaGraphData,
  reqPowerForecastPlanData,
  resPowerForecastPlanData,
  resBatteryFlag,
  ActualHeaderProps,
  ForecastButtonProps,
  DayAmountHeaderProps,
  CommonOutputProps,
  TableBodyCell,
  GenPowerHeaderProps,
  PowerInputCellProps,
  PastDataRadionButtonButtonProps,
  PastDataDateProps,
  PastDataCopyButtonProps,
  PastDataHeaderProps,
  reqSavePowerPlan,
  resPowerPlanLimit,
  resPastPowerPlanData,
  resGetCpnsumptionPresets,
  ConsumptionPreset,
} from "../../../types/occto/PowerForecast";
import { format } from "date-fns";
import { Button } from "@mui/material";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { cellTime, forcastGraphLabels } from "../../../common/cellTime";
import { useAuthedApi } from "../../../common/axios";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList";
import { AREA_ID, RESULT_CODE, TIME_CODE_MAX } from "../../../common/constant";
import { getPowerForecastList } from "../../../types/occto/Common";
import { CommonResponse } from "../../../types/common/Api";
import CustomModal from "../../common/customComponents/CustomModal";
import { useColorUtility } from "../../../common/color";
import { changeDateToTimeCd } from "../../common/changeDateCd";
import { autoScrollPage } from "../../common/ScrollPage";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController
);

const GET_POWER_FORECAST_INFO_URL = "/getPowerForecastInfoList";
const LeftDataUrl = "/LeftpowerForecast";
const RightDataUrl = "/RightpowerForecast";
const SaveDataUrl = "/SavepowerForecast";
const WEATHER_GET_URL = "/GetWeather";
const SOLAR_RADIATION_GET_URL = "/GetSolar_Radiation";
const DischargeForecastUrl = "/getChargeDischargePrediction";
const GetSaveTimeNameUrl = "/GetSaveTimeName";
const GetContractPowerUrl = "/GetContractPower";
// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePowerForecast";

// 定数
const PAST_REF_MAX = 7 as const;

//発電計画メインコンテンツ
const PowerForecast = () => {
  //コンテキスト
  const { words, convertDateToLocalizedString } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext); // ダークモード対応
  const { areaId, setAreaId } = useContext(AreaContext); // エリア
  const { pickDate } = useContext(DateContext); // グローバルカレンダー
  const { time, isGateClose } = useContext(TimeContext); // タイムコンテキスト
  const { setBlockLeave } = useContext(LeavePageContext);
  const { role } = useContext(RoleContext); // ロールコンテキスト

  const api = useAuthedApi();

  // レンダー管理
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const isFirstRendering = useRef<boolean>(true);

  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);
  const [error, setError] = useState<any>();

  // ステート
  const [allPowerList, setAllPowerPlantList] = useState<powerDemandInfo[]>([]);
  const [powerBg, setPowerBg] = useState(0);
  const [power, setPower] = useState(0);
  const [lastUpdateInfo, setLastUpdateInfo] = useState<lastUpdateInfo>();

  // 可変レイアウトフラグ
  const [batteryFlag, setBatteryFlag] = useState<boolean>(false);

  // 発電量
  const [planData, setPlanData] = useState<number[]>(
    Array(TIME_CODE_MAX).fill(0)
  );
  // 充放電量
  const [dischargeDatas, setDischargeDatas] = useState<number[]>(
    Array(TIME_CODE_MAX).fill(0)
  );
  // 充放電量のテキスト
  const [textDisChargeDatas, setTextDischargeDatas] = useState<string[]>(Array(TIME_CODE_MAX).fill(0).map((_) => "0"));
  // 自家消費量
  const [ownConsumptionDatas, setOwnConsumptionDatas] = useState<number[]>(
    Array(TIME_CODE_MAX).fill(0)
  );
  // 発電計画
  const powerPlanDatas = useMemo(
    () =>
      Array.from(
        { length: TIME_CODE_MAX },
        (_, index) =>
          planData[index] - ownConsumptionDatas[index] + dischargeDatas[index]
      ),
    [planData, dischargeDatas, ownConsumptionDatas]
  );
  const savediff = useRef<number[]>([]); // 保存時にGC後のセルが保存されないように比較するためのもの
  const savedDischarge = useRef<number[]>([]);
  const savedOwnConsumption = useRef<number[]>([]);

  // 蓄電容量実績
  const [socResult, setSocResult] = useState<(number | null)[]>(
    Array(TIME_CODE_MAX).fill(null)
  );
  // 蓄電
  const [startSoc, setStartSoc] = useState<number>(0);
  // 蓄電量
  const soc = useMemo(
    () =>
      socResult.map((v, index) => {
        // FindLastIndexはコンパイラオプションをES2023にする必要があるため代用
        /*const lastResultIndex = socResult
        .filter((_, i) => i <= index)；
        .findLastIndex((v) => v != null);*/
        const tempIndex = socResult
          .map((v, i) => (i <= index ? v ?? null : null))
          .reverse()
          .findIndex((v) => v !== null);
        const lastResultIndex =
          tempIndex === -1 ? -1 : socResult.length - 1 - tempIndex;
        const latestResult = socResult[lastResultIndex] ?? startSoc;
        return (
          v ??
          dischargeDatas
            .filter((_, i) => lastResultIndex < i && i <= index)
            .reduce((sum, crnt) => sum - crnt, latestResult)
        );
      }),
    [socResult, startSoc, dischargeDatas]
  );

  // 発電実績
  const [actualDatas, setActualDatas] = useState<(number | null)[]>(
    Array(TIME_CODE_MAX).fill(null)
  );
  // 発電予測
  const [predict, setPredict] = useState<number[]>(
    Array(TIME_CODE_MAX).fill(null)
  );
  // 充放電予測
  const [dischargeForecast, setDischargeForecast] = useState<number[]>(
    Array(TIME_CODE_MAX).fill(null)
  );

  // 自家消費プリセット取得結果
  const [ownConsumptionPreset, setOwnConsumptionPreset] = useState<
    ConsumptionPreset[]
  >([]);
  // 自家消費設定有無
  const [isOwnConsumption, setIsOwnConsumption] = useState<boolean>(false);

  // 選択中のプリセット番号
  const [selectedPresetNo, setSelectedPresetNo] = useState<number>(0);

  // 入力制限
  // 契約電力
  const [contractPower, setContractPower] = useState<number>(0);
  // 定格出力
  const [maxDischarge, setMaxDischarge] = useState<number | null>(null);
  // 蓄電容量上限
  const [socUpperLimit, setSocUpperLimit] = useState<number | null>(null);
  // 蓄電容量下限
  const [socLowerLimit, setSocLowerLimit] = useState<number | null>(null);

  // 過去参照検索情報
  const [pastDate, setPastDate] = useState<Date>(addDays(new Date(), 1)); // 画面右表の表示開始日。
  const [selectedValue, setSelectedValue] = useState("a"); // ラジオボタン選択状態

  // 過去参照情報
  const [pastData, setPastData] = useState<PastPowerPlanData[]>(
    Array.from({ length: PAST_REF_MAX }, () => ({
      targetDate: "",
      powerPlan: Array(TIME_CODE_MAX).fill(0),
      genPower: Array(TIME_CODE_MAX).fill(0),
      disChargePower: Array(TIME_CODE_MAX).fill(0),
      ownConsumption: Array(TIME_CODE_MAX).fill(0),
    }))
  );

  // グラフ表示対象
  const [selectedNumber, setSelectedNumber] = useState(-1);
  // 選択済み過去データ
  const selectedPastData = useMemo(
    () => (selectedNumber >= 0 ? pastData[selectedNumber] : null),
    [pastData, selectedNumber]
  );

  // グラフ エリア単位表示
  const [areaPowerPlans, setAreaPowerPlan] = useState<areaGraphData[]>([]);

  // 天気情報表示用のState
  const [WeatherImg1, setWeatherImg1] = useState<string>();
  const [WeatherImg2, setWeatherImg2] = useState<string>();
  const [WeatherImg3, setWeatherImg3] = useState<string>();
  const [WeatherImg4, setWeatherImg4] = useState<string>();
  const [WeatherImg5, setWeatherImg5] = useState<string>();
  const [WeatherImg6, setWeatherImg6] = useState<string>();
  const [WeatherImg7, setWeatherImg7] = useState<string>();
  const [WeatherImg8, setWeatherImg8] = useState<string>();

  const [WeatherWord1, setWeatherWord1] = useState<string>();
  const [WeatherWord2, setWeatherWord2] = useState<string>();
  const [WeatherWord3, setWeatherWord3] = useState<string>();
  const [WeatherWord4, setWeatherWord4] = useState<string>();
  const [WeatherWord5, setWeatherWord5] = useState<string>();
  const [WeatherWord6, setWeatherWord6] = useState<string>();
  const [WeatherWord7, setWeatherWord7] = useState<string>();
  const [WeatherWord8, setWeatherWord8] = useState<string>();

  const [WeatherTmp1, setWeatherTmp1] = useState<string>();
  const [WeatherTmp2, setWeatherTmp2] = useState<string>();
  const [WeatherTmp3, setWeatherTmp3] = useState<string>();
  const [WeatherTmp4, setWeatherTmp4] = useState<string>();
  const [WeatherTmp5, setWeatherTmp5] = useState<string>();
  const [WeatherTmp6, setWeatherTmp6] = useState<string>();
  const [WeatherTmp7, setWeatherTmp7] = useState<string>();
  const [WeatherTmp8, setWeatherTmp8] = useState<string>();

  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(!true);

  // ダイアログ関連
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);

  // オートスクロール制御用
  const [scrollFlag, setScrollFlag] = useState<boolean>(true);

  // 初期検索
  useEffect(() => {
    // 発電所情報一括取得
    (async () => {
      dicideSaveButtonVisibility();
      const { area, power } = await getPowerPlantList();
      const flag = await getBatteryViewFlag(area, power);
      await getOwnConsumptionPresets(area, power);
      Promise.all([
        getPowerPlan(area, power, flag).then((vals) =>
          GetDischargeForecast(area, power, vals)
        ),
        getPastPowerPlan(area, power),
        getPowerPlanLimitInfo(area, power),
        GetSaveTime(power),
        SerchPredictData(power),
        SetWeatherInfo(power),
      ]).finally(() => {
        setIsLoaded(true);
        isFirstRendering.current = false;
        if (
          format(pickDate as Date, "yyy/MM/dd") ===
          format(time as Date, "yyy/MM/dd")
        ) {
          autoScrollPage("powerForecastTimeCell" + changeDateToTimeCd(time));
        }
      });
    })();
  }, []);

  // 計画日付操作時
  useEffect(() => {
    if (isFirstRendering.current) return;
    (async () => {
      setIsLoaded(false);
      Promise.all([
        getPowerPlan(areaId, power, batteryFlag).then((vals) =>
          GetDischargeForecast(areaId, power, vals)
        ),
        getPastPowerPlan(areaId, power),
        GetSaveTime(power),
        SerchPredictData(power),
        SetWeatherInfo(power),
      ]).finally(() => {
        setIsLoaded(true);
        if (
          format(pickDate as Date, "yyy/MM/dd") ===
          format(time as Date, "yyy/MM/dd")
        ) {
          autoScrollPage("powerForecastTimeCell" + changeDateToTimeCd(time));
        }
      });
    })();
  }, [pickDate]);

  // 過去参照検索情報操作時
  useEffect(() => {
    if (isFirstRendering.current) return;
    setIsLoaded(false);
    getPastPowerPlan(areaId, power).finally(() => {
      setIsLoaded(true);
    });
  }, [pastDate, selectedValue]);

  const ControlWeatherBox = (weather_no: string) => {
    //発電所単位でなければ隠す
    if (power == 0) {
      return "hidden" as const;
    }

    //発電所単位だった時
    switch (weather_no) {
      case "1":
        if (WeatherImg1 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "2":
        if (WeatherImg2 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "3":
        if (WeatherImg3 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "4":
        if (WeatherImg4 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "5":
        if (WeatherImg5 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "6":
        if (WeatherImg6 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "7":
        if (WeatherImg7 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "8":
        if (WeatherImg8 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }
    }
  };

  // メソッド

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = async () => {
    return api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 発電所情報一括取得
  const getPowerPlantList = (): Promise<any> => {
    return api
      .get<getPowerForecastList[]>(GET_POWER_FORECAST_INFO_URL)
      .then((res) => {
        const powerList: powerDemandInfo[] = res.data.map((v) => {
          return {
            areaId: v.areaId,
            bgId: v.bgId,
            bgName: v.bgName,
            powerDemandId: v.powerId,
            powerDemandName: v.powerName,
          };
        });
        setAllPowerPlantList(powerList);

        // 初期表示をセット
        const initArea = query.get("areaId") ?? AREA_ID.NONE;
        let initBg = Number(query.get("bgId") ?? 0);
        let initPower = Number(query.get("plantId") ?? 0);

        //ステータス一覧、発販、インバランスからの遷移
        if (!initBg && !initPower) {
          //planstatuslistから遷移したとき

          const tmpArea = powerList.filter((v) => v.areaId === initArea);
          const tmpBg = [...new Set(tmpArea.map((v) => v.bgId))];
          const tmpPower = [...new Set(tmpArea.map((v) => v.powerDemandId))];

          //選択したエリアに所属するBGが1つの場合、そのBGを選択されているBGに設定
          if (tmpBg.length === 1) {
            initBg = tmpBg[0];
          }
          //選択したエリアに所属する発電所が1つの場合、その発電所を選択されている発電所に設定
          if (tmpPower.length === 1) {
            initPower = tmpPower[0];
          }
        }
        setAreaId(initArea);
        setPowerBg(initBg);
        setPower(initPower);
        return Promise.resolve({
          area: initArea,
          bg: initBg,
          power: initPower,
        });
      });
  };

  /**
   * 蓄電池表示有無取得
   * @param areaId
   * @param powerId
   */
  async function getBatteryViewFlag(
    areaId: string,
    powerId: number
  ): Promise<boolean> {
    return api
      .get<
        reqPowerForecastPlanData,
        AxiosResponse<CommonResponse<resBatteryFlag>>
      >("/BatteryFlag", { params: { areaId, powerId } })
      .then((res) => {
        setBatteryFlag(res.data.result);
        return Promise.resolve(res.data.result);
      });
  }

  /**
   * 自家消費表示有無取得
   * @param areaId
   * @param powerId
   */
  async function getOwnConsumptionPresets(areaId: string, powerId: number) {
    return api
      .get<CommonResponse<resGetCpnsumptionPresets>>(
        "/ownConsumptionEnableSettings",
        { params: { areaId, powerId } }
      )
      .then((res) => {
        setOwnConsumptionPreset(res.data.result.presets);
        setIsOwnConsumption(res.data.result.enable);
      });
  }

  /**
   * 画面左側のデータを取得
   * @param area エリアId
   * @param power 発電所
   */
  async function getPowerPlan(
    area: string,
    power: number,
    batteryInfo: boolean
  ): Promise<number[]> {
    //メッセージリセット

    const params = {
      areaId: area,
      powerId: power,
      targetDate: pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      batteryInfo,
    };

    return api
      .get<
        reqPowerForecastPlanData,
        AxiosResponse<CommonResponse<resPowerForecastPlanData>>
      >(LeftDataUrl, { params })
      .then((res) => {
        const tempPlanData: number[] = Array(TIME_CODE_MAX).fill(0);
        const tempDisChargePower: number[] = Array(TIME_CODE_MAX).fill(0);
        const tempOwnConsumption: number[] = Array(TIME_CODE_MAX).fill(0);
        const tempPowerActual: (number | null)[] =
          Array(TIME_CODE_MAX).fill(null);
        const tempSocActual: (number | null)[] =
          Array(TIME_CODE_MAX).fill(null);
        let tempStartSoc = 0;

        // エリア単位加味して合算
        res.data.result.forEach((element) => {
          element.genPower.forEach((v, index) => {
            tempPlanData[index] += v;
          });
          element.disChargePower.forEach((v, index) => {
            tempDisChargePower[index] += v;
          });
          element.ownConsumption.forEach((v, index) => {
            tempOwnConsumption[index] += v;
          });
          element.powerActual.forEach((v, index) => {
            tempPowerActual[index] = v ? tempPowerActual[index] ?? 0 + v : null;
          });
          element.socActual.forEach((v, index) => {
            tempSocActual[index] = v ? tempSocActual[index] ?? 0 + v : null;
          });
          tempStartSoc += element.socLowerLimit;
        });

        setPlanData(tempPlanData);
        setDischargeDatas(tempDisChargePower);
        setTextDischargeDatas(tempDisChargePower.map((v) => v.toString()));
        setOwnConsumptionDatas(tempOwnConsumption);
        setActualDatas(tempPowerActual);
        setSocResult(tempSocActual);
        setStartSoc(tempStartSoc);
        setSelectedPresetNo(res.data.result[0].consumptionNo);
        savediff.current = tempPlanData;
        savedDischarge.current = tempDisChargePower;
        savedOwnConsumption.current = tempOwnConsumption;

        const areaPowerPlans = res.data.result.map(
          (v): areaGraphData => ({
            id: v.powerId,
            values: v.supplyPowerQu,
          })
        );
        setAreaPowerPlan(areaPowerPlans);
        return Promise.resolve(tempPlanData);
      });
  }

  // 過去参照情報取得
  async function getPastPowerPlan(area: string, power: number) {
    return api
      .get<any, AxiosResponse<CommonResponse<resPastPowerPlanData>>>(
        RightDataUrl,
        {
          params: {
            areaId: area,
            powerId: power || null,
            targetDate: pastDate.toLocaleDateString("ja-JP", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            type: selectedValue,
            dataNum: PAST_REF_MAX,
          },
        }
      )
      .then((res) => {
        setPastData(res.data.result);
      })
      .catch((error) => {
        setError(error);
        console.error(error);
      });
  }

  // 制限情報取得
  async function getPowerPlanLimitInfo(
    areaId: string,
    powerId: number
  ): Promise<void> {
    return api
      .get(GetContractPowerUrl, { params: { areaId, powerId } })
      .then((res: AxiosResponse<CommonResponse<resPowerPlanLimit>>) => {
        setContractPower(res.data.result.contractPower);
        setMaxDischarge(res.data.result.maxDischarge ?? null);
        setSocUpperLimit(res.data.result.socUpper ?? null);
        setSocLowerLimit(res.data.result.socLower ?? null);
      })
      .catch((error) => {
        setError(error);
        console.error(error);
      });
  }

  // 発電予測(旧システム予測)
  async function SerchPredictData(powerId: number) {
    interface searchParams {
      power_id: number;
      forecast_time: string | undefined;
    }
    const Params: searchParams = {
      power_id: powerId,
      forecast_time: pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };

    // 発電所単位ではないとき初期化
    if (!powerId) {
      setPredict(Array(TIME_CODE_MAX).fill(0));
      return;
    }

    api.post(SOLAR_RADIATION_GET_URL, Params).then((res) => {
      const tempPredict: number[] = Array(TIME_CODE_MAX).fill(0);

      let incount = 0;

      while (incount < res.data.length) {
        tempPredict[incount] = Number(res.data[incount].solar_radiation);
        incount++;
      }
      setPredict(tempPredict);
    });
  }

  async function GetDischargeForecast(
    areaId: string,
    powerId: number,
    values: number[]
  ) {
    const params = {
      areaId,
      powerId,
      targetDate: pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      genValues: values,
    };
    api.get(DischargeForecastUrl, { params }).then((res) => {
      setDischargeForecast(res.data.result);
    });
  }

  // 天気情報
  async function SetWeatherInfo(powerId: number) {
    //プロパティ----
    const sunny_img = `/weather/FewClouds.svg`;
    const sunny_word = "晴れ";
    const cloudy_img = `/weather/BrokenClouds.svg`;
    const cloudy_word = "曇り";
    const rain_img = `/weather/Rain.svg`;
    const rain_word = "雨";

    //-------------

    interface searchParams {
      power_id: number;
      forecast_time: string | undefined;
    }

    const Params: searchParams = {
      power_id: powerId,
      forecast_time: pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };

    // 発電所単位ではないとき初期化のみ
    setWeatherImg1(undefined);
    setWeatherImg2(undefined);
    setWeatherImg3(undefined);
    setWeatherImg4(undefined);
    setWeatherImg5(undefined);
    setWeatherImg6(undefined);
    setWeatherImg7(undefined);
    setWeatherImg8(undefined);

    setWeatherWord1(undefined);
    setWeatherWord2(undefined);
    setWeatherWord3(undefined);
    setWeatherWord4(undefined);
    setWeatherWord5(undefined);
    setWeatherWord6(undefined);
    setWeatherWord7(undefined);
    setWeatherWord8(undefined);

    setWeatherTmp1(undefined);
    setWeatherTmp2(undefined);
    setWeatherTmp3(undefined);
    setWeatherTmp4(undefined);
    setWeatherTmp5(undefined);
    setWeatherTmp6(undefined);
    setWeatherTmp7(undefined);
    setWeatherTmp8(undefined);

    if (!powerId) {
      return;
    }

    //引数：電源ID、日付
    return api
      .post(WEATHER_GET_URL, Params)
      .then((res) => {
        let State_setCount = 0;

        //todo 仕様確認：画面に表示する際の画像と気温は平均をとるのか?
        for (let i = 0; i < res.data.length; i++) {
          //6の倍数で処理 * 8回分
          if ((i + 1) % 6 == 0) {
            ++State_setCount;
            if (res.data[i].precipitation != 0) {
              //雨
              switch (State_setCount) {
                case 1:
                  setWeatherImg1(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord1(rain_word);
                  setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 2:
                  setWeatherImg2(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord2(rain_word);
                  setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 3:
                  setWeatherImg3(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord3(rain_word);
                  setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 4:
                  setWeatherImg4(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord4(rain_word);
                  setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 5:
                  setWeatherImg5(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord5(rain_word);
                  setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 6:
                  setWeatherImg6(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord6(rain_word);
                  setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 7:
                  setWeatherImg7(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord7(rain_word);
                  setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 8:
                  setWeatherImg8(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord8(rain_word);
                  setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                  break;
              }
            } else if (res.data[i].precipitation == 0) {
              //雨以外
              //雲量が9以上
              if (Math.round(res.data[i].total_cloud) >= 9) {
                //曇り
                switch (State_setCount) {
                  case 1:
                    setWeatherImg1(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord1(cloudy_word);
                    setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 2:
                    setWeatherImg2(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord2(cloudy_word);
                    setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 3:
                    setWeatherImg3(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord3(cloudy_word);
                    setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 4:
                    setWeatherImg4(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord4(cloudy_word);
                    setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 5:
                    setWeatherImg5(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord5(cloudy_word);
                    setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 6:
                    setWeatherImg6(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord6(cloudy_word);
                    setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 7:
                    setWeatherImg7(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord7(cloudy_word);
                    setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 8:
                    setWeatherImg8(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord8(cloudy_word);
                    setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                    break;
                }
              } else if (Math.round(res.data[i].total_cloud) <= 8) {
                //晴れ
                switch (State_setCount) {
                  case 1:
                    setWeatherImg1(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord1(sunny_word);
                    setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 2:
                    setWeatherImg2(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord2(sunny_word);
                    setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 3:
                    setWeatherImg3(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord3(sunny_word);
                    setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 4:
                    setWeatherImg4(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord4(sunny_word);
                    setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 5:
                    setWeatherImg5(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord5(sunny_word);
                    setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 6:
                    setWeatherImg6(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord6(sunny_word);
                    setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 7:
                    setWeatherImg7(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord7(sunny_word);
                    setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 8:
                    setWeatherImg8(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord8(sunny_word);
                    setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                    break;
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 保存日時取得
  async function GetSaveTime(power: number) {
    // 発電所単位ではない場合、初期化して終了
    if (!power) {
      setLastUpdateInfo(undefined);
      return;
    }
    api
      .post(GetSaveTimeNameUrl, {
        power: power,
        targetDate: pickDate?.toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      })
      .then((res) => {
        setLastUpdateInfo({
          date: new Date(res.data[0].date),
          user: res.data[0].user,
        });
      });
  }

  // 保存
  async function SavePowerPlan() {
    //計画値48コマ中1コマでも契約電力を上回る入力値がある場合エラー
    // 発電計画が契約電力を超えていないか確認
    if (powerPlanDatas.some((v) => v * 2 > contractPower)) {
      setDialogMessage(words.check_contract_power);
      setDigOpen(true);
      return;
    }

    // 放電量が定格出力を超えていないか確認
    if (maxDischarge && dischargeDatas.some((v) => v * 2 > maxDischarge)) {
      setDialogMessage(words.check_discharge_power);
      setDigOpen(true);
      return;
    }

    // 充電量が発電量を超えていない
    if (
      dischargeDatas
        .map((v, i) => ({ index: i, value: v }))
        .filter((v) => v.value < 0)
        .some((v) => Math.abs(v.value) > planData[v.index])
    ) {
      setDialogMessage(words.check_charge_power);
      setDigOpen(true);
      return;
    }

    // 充電量上限チェック
    if (
      socUpperLimit != null &&
      soc
        .filter((_, i) => !isGateClose(i, pickDate as Date))
        .some((v) => v > socUpperLimit)
    ) {
      setDialogMessage(words.check_charge_upper_limit);
      setDigOpen(true);
      return;
    }

    // 充電量下限チェック
    if (
      socLowerLimit != null &&
      soc
        .filter((_, i) => !isGateClose(i, pickDate as Date))
        .some((v) => v < socLowerLimit)
    ) {
      setDialogMessage(words.check_charge_lower_limit);
      setDigOpen(true);
      return;
    }

    // 自家消費が発電量を超えていないか確認
    if (ownConsumptionDatas.some((v, i) => v > planData[i])) {
      setDialogMessage(words.check_own_consumption);
      setDigOpen(true);
      return;
    }

    return api
      .post<
        unknown,
        AxiosResponse<CommonResponse<lastUpdateInfo>>,
        reqSavePowerPlan
      >(SaveDataUrl, {
        areaId,
        powerId: power,
        targetDate: pickDate?.toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }) as string,
        consumptionNo: selectedPresetNo,
        powerPlan: powerPlanDatas,
        genPower: planData,
        dischargePower: dischargeDatas,
        ownConsumption: ownConsumptionDatas,
      })
      .then((res) => {
        if (res.data.resultCode === RESULT_CODE.SUCCESS) {
          setBlockLeave(false);
          setLastUpdateInfo({
            date: new Date(res.data.result.date),
            user: res.data.result.user,
          });
          setDialogMessage(words.saved);
          setDigOpen(true);
        }
        if (res.data.resultCode === RESULT_CODE.NO_NEED_TO_UPDATE) {
          setDialogMessage(words.saved_dupicated);
          setDigOpen(true);
        }
      })
      .catch((error: Error) => {
        setDialogMessage(words.saved_failed);
        setDigOpen(true);
      });
  }

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <CustomMultipleSelectList
          powerFg={true}
          list={allPowerList}
          areaId={areaId}
          bgId={powerBg}
          powerDemandId={power}
          handleValueChangeArea={setAreaId}
          handleValueChangeBG={setPowerBg}
          handleValueChangePowerDemand={setPower}
          getData={async (area, _, power) => {
            setIsLoaded(false);
            const flag = await getBatteryViewFlag(area, power);
            await getOwnConsumptionPresets(area, power);
            await Promise.all([
              getPowerPlan(area, power, flag).then((vals) =>
                GetDischargeForecast(area, power, vals)
              ),
              getPastPowerPlan(area, power),
              GetSaveTime(power),
              SerchPredictData(power),
              SetWeatherInfo(power),
              getPowerPlanLimitInfo(area, power),
            ]).finally(() => setIsLoaded(true));
          }}
        ></CustomMultipleSelectList>
        <GlobalDatePicker isDipsTodayBtn={true} />
        <Typography
          sx={{
            color: PaletteMode === "dark" ? "lightgray" : "gray",
            width: "660px",
            marginLeft: "15%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {lastUpdateInfo
            ? `${words.last_update_info}
              : ${convertDateToLocalizedString(lastUpdateInfo.date, "HH:mm:ss")}
              (${lastUpdateInfo.user})`
            : ""}
        </Typography>
        <Box
          sx={{
            height: "40px",
            display: "flex",
          }}
        >
          <SaveDataButton
            hidden={hidden}
            disabled={
              hidden ||
              !power ||
              isBefore(
                new Date(format(pickDate as Date, "yyyy/MM/dd")),
                new Date(format(time as Date, "yyyy/MM/dd"))
              )
            }
            message={words.note_saved}
            onAccept={() => {
              setIsLoaded(false);
              SavePowerPlan().finally(() => {
                setIsLoaded(true);
              });
            }}
            //保存処理追加
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex" }} width={"100%"} height={"99%"}>
        {/*画面左コンテンツ*/}
        <Box sx={{ width: "912px", height: "90%", mt: "7px" }}>
          <ChartRender
            allPowerList={allPowerList}
            powerPlanDatas={powerPlanDatas}
            planData={planData}
            dischargeDatas={dischargeDatas}
            ownConsumptionDatas={ownConsumptionDatas}
            predict={predict}
            selectedPastData={selectedPastData}
            contractPower={contractPower}
            isAreaView={!Number(power)}
            isBatteryView={batteryFlag}
            isOwnConsumptionView={isOwnConsumption}
            areaPowerPlans={areaPowerPlans}
          />
          <Box paddingLeft={"78px"} display={"flex"}>
            <Box
              visibility={ControlWeatherBox("1")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg1}
                alt={WeatherWord1}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp1}
            </Box>
            <Box
              visibility={ControlWeatherBox("2")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg2}
                alt={WeatherWord2}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp2}
            </Box>
            <Box
              visibility={ControlWeatherBox("3")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg3}
                alt={WeatherWord3}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp3}
            </Box>
            <Box
              visibility={ControlWeatherBox("4")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg4}
                alt={WeatherWord4}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp4}
            </Box>
            <Box
              visibility={ControlWeatherBox("5")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg5}
                alt={WeatherWord5}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp5}
            </Box>
            <Box
              visibility={ControlWeatherBox("6")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg6}
                alt={WeatherWord6}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp6}
            </Box>

            <Box
              visibility={ControlWeatherBox("7")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg7}
                alt={WeatherWord7}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp7}
            </Box>

            <Box
              visibility={ControlWeatherBox("8")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg8}
                alt={WeatherWord8}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp8}
            </Box>
          </Box>
        </Box>
        {/*画面右コンテンツ*/}
        <Box
          sx={{
            width: "906px",
            height: "90%",
            color: PaletteMode === "dark" ? "lightgray" : "black",
            marginTop: "15px",
            marginLeft: "6px",
          }}
        >
          {batteryFlag == true ? (
            <Box sx={{ display: "flex", justifyContent: "space-between"}}>
              <Box sx={{ marginTop: "2px" }}>
                {words.charge_and_discharge_amount}
              </Box>
              <Box sx={{ marginTop: "2px", marginRight: "8px" }}>
                {words.unit}：kWh
              </Box>
            </Box>  
          ) : (
            <Box sx={{ marginLeft: "90%", marginTop: "2px" }}>
              {words.unit}：kWh
            </Box>  
          )}
          <PowerPlnaTable
            powerPlanDatas={powerPlanDatas}
            planData={planData}
            savedDiff={savediff}
            setPlanData={setPlanData}
            dischargeDatas={dischargeDatas}
            textDischargeDatas={textDisChargeDatas}
            savedDischarge={savedDischarge}
            setDischargeDatas={setDischargeDatas}
            setTextDischargeDatas={setTextDischargeDatas}
            socResult={socResult}
            soc={soc}
            ownConsumptionDatas={ownConsumptionDatas}
            savedOwnConsumption={savedOwnConsumption}
            setOwnConsumptionDatas={setOwnConsumptionDatas}
            ownConsumptionPresets={ownConsumptionPreset}
            selectedPresetNo={selectedPresetNo}
            setSelectedPresetNo={setSelectedPresetNo}
            actualDatas={actualDatas}
            pastData={pastData}
            predict={predict}
            rePredict={() => {
              SerchPredictData(power);
            }}
            dischargeForecast={dischargeForecast}
            reDischargeForecast={() => {
              GetDischargeForecast(areaId, power, planData);
            }}
            pastDate={pastDate}
            setPastDate={setPastDate}
            isAreaView={!Number(power)}
            isBatteryView={batteryFlag}
            isOwnConsumptionView={isOwnConsumption}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectedNumber={selectedNumber}
            setSelectedNumber={setSelectedNumber}
          />
        </Box>
      </Box>
      <CustomDialog
        title={words.power_gen_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={() => {
          return;
        }}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </div>
  );
};

//グラフ表示
const ChartRender = (props: PowerPlanGraphProps) => {
  const {
    allPowerList,
    powerPlanDatas,
    planData,
    dischargeDatas,
    ownConsumptionDatas,
    predict,
    selectedPastData,
    contractPower,
    isAreaView,
    isBatteryView,
    isOwnConsumptionView,
    areaPowerPlans,
  } = props;
  const { words, convertDateToLocalizedString } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  const discharge = useMemo(
    () => dischargeDatas.map((v) => (v > 0 ? v : 0)),
    [dischargeDatas]
  );

  const charge = useMemo(
    () => dischargeDatas.map((v) => (v < 0 ? Math.abs(v) : 0)),
    [dischargeDatas]
  );

  const contractPowerBorder = useMemo(
    () => Array(TIME_CODE_MAX).fill(Math.floor(contractPower / 2)),
    [contractPower]
  );

  //グラフy軸最大値
  // 取得データが変わったタイミングでのみ再レンダリングする
  const chartMaxY = useMemo(
    () =>
      isAreaView
        ? Math.round(powerPlanDatas.reduce((a, b) => Math.max(a, b)) * 1.1)
        : Math.round((contractPower / 2) * 1.1),
    [isAreaView, contractPower, powerPlanDatas]
  );
  //グラフy軸幅
  //取得データが変わったタイミングでのみ再レンダリングする
  const chartStepY = useMemo(
    () =>
      isAreaView
        ? Math.round(
            (powerPlanDatas.reduce((a, b) => Math.max(a, b)) * 1.1) / 10
          )
        : Math.round(((contractPower / 2) * 1.1) / 10),
    [isAreaView, contractPower, powerPlanDatas]
  );

  const graphData = useMemo(() => {
    const datasets: any[] = [];

    const stackgroup1 = { xAxisID: "x", yAxisID: "y", stack: "1" };
    const stackgroup2 = { xAxisID: "x", yAxisID: "y", stack: "2" };

    if (!isAreaView) {
      // 発電量から減算する値がある場合
      if (isBatteryView || isOwnConsumptionView) {
        // 発電量
        datasets.push({
          type: "bar" as const,
          label: words.generated_power,
          borderColor: "#FFD700",
          backgroundColor: "#FFD700",
          data: planData,
          ...stackgroup1,
          order: 1,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });
        // 発電計画値
        datasets.push({
          type: "bar" as const,
          label: words.planned_gen_value,
          borderColor: "#FFA726",
          backgroundColor: "#FFA726",
          data: powerPlanDatas,
          ...stackgroup2,
          order: 2,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });
      } else {
        // 発電計画
        datasets.push({
          type: "bar" as const,
          label: words.planned_gen_value,
          borderColor: "#FFA726",
          backgroundColor: "#FFA726",
          data: powerPlanDatas,
          ...stackgroup2,
          order: 2,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });
      }
      // 蓄電池有
      if (isBatteryView) {
        // 放電量
        datasets.push({
          type: "bar" as const,
          label: words.discharge_power,
          borderColor: "#29B6F6",
          backgroundColor: "#29B6F6",
          data: discharge,
          ...stackgroup1,
          order: 1,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });

        // 充電量
        datasets.push({
          type: "bar" as const,
          label: words.charge_power,
          borderColor: "#808080",
          backgroundColor: "#808080",
          data: charge,
          ...stackgroup2,
          order: 2,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });
      }
      if (isOwnConsumptionView) {
        // 自家消費量
        datasets.push({
          type: "bar" as const,
          label: words.own_consumption,
          borderColor: "#d3d3d3",
          backgroundColor: "#d3d3d3",
          data: ownConsumptionDatas,
          ...stackgroup2,
          order: 2,
          barPercentage: 1.0,
          categoryPercentage: 0.9,
        });
      }

      // 発電予測
      datasets.push({
        type: "line" as const,
        label: words.power_gen_forecast,
        borderColor: "#FF0000",
        backgroundColor: "rgba(75, 192, 192, 0)",
        stepped: "middle" as const,
        data: predict,
        yAxisID: "y1",
        order: -3,
      });

      // 契約電力
      datasets.push({
        type: "line" as const,
        borderDash: [6, 4],
        label: words.contracted_power,
        borderColor: "#7B61FF",
        backgroundColor: "rgba(75, 192, 192, 0)",
        data: contractPowerBorder,
        order: -1,
        yAxisID: "y1",
      });

      // 参照情報
      if (selectedPastData) {
        datasets.push({
          type: "line" as const,
          label: convertDateToLocalizedString(
            new Date(selectedPastData.targetDate)
          ),
          borderColor: "#66BB6A",
          backgroundColor: "rgba(75, 192, 192, 0)",
          stepped: "middle" as const,
          data: selectedPastData.powerPlan,
          ...stackgroup2,
          yAxisID: "y1",
          order: -2,
        });
      }
    } else {
      //エリア単位
      const r = 75;
      const g = 192;
      const b = 192;

      //棒グラフ
      areaPowerPlans.forEach((plan, index) => {
        datasets.push({
          type: "bar" as const, // 棒グラフ
          label:
            allPowerList.find((v) => v.powerDemandId === plan.id)
              ?.powerDemandName ?? "",
          backgroundColor: `rgb(${r * (1 + index)}, ${g * (1 + index)}, ${
            b - index
          })`,
          data: plan.values,
          yAxisID: "y", // Y軸の設定
          borderColor: "",
          borderWidth: 0,
          stepped: false,
        });
      });
    }

    return {
      labels: forcastGraphLabels,
      datasets: datasets,
    };
  }, [
    isAreaView,
    isBatteryView,
    isOwnConsumptionView,
    contractPowerBorder,
    predict,
    planData,
    discharge,
    words,
    powerPlanDatas,
    charge,
    ownConsumptionDatas,
    selectedPastData,
    convertDateToLocalizedString,
    areaPowerPlans,
    allPowerList,
  ]);

  // グラフオプション
  // 凡例等グラフの設定をする
  // 「as const」が肝なので付けるプロパティを間違えないこと
  const options = useMemo(() => {
    ChartJS.defaults.color = PaletteMode === "dark" ? "#999" : "#666";
    ChartJS.defaults.borderColor =
      PaletteMode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
    return {
      //折れ線グラフの点を削除
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        title: {
          position: "left" as const,
          display: true,
          text: words.power_gen_energy,
          font: { size: 14 },
        },
        legend: {
          // 凡例の設定
          position: "top" as const,
          labels: {
            font: {
              size: 14,
            },
            sort: (a: LegendItem, b: LegendItem, data: ChartData): number => {
              if (
                a.datasetIndex !== undefined &&
                b.datasetIndex !== undefined
              ) {
                if (a.datasetIndex < b.datasetIndex) return -1;
                if (a.datasetIndex > b.datasetIndex) return 1;
              }
              return 0;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
          //labelの表示が斜めにならないように
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            font: { size: 14 },
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          max: chartMaxY,
          min: 0,
          ticks: {
            precision: 0,
            stepSize: chartStepY,
            font: { size: 14 },
          },
        },
        y1: {
          // y軸複数指定
          stacked: false,
          position: "right" as const,
          max: chartMaxY,
          min: 0,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
            z: 1,
          },
        },
      },
    };
  }, [PaletteMode, chartMaxY, chartStepY, words]);

  return (
    <Chart
      type={"bar"}
      data={graphData}
      // chartの動的な大きさ変更調査
      height={"176"}
      width={"205"}
      options={options}
    />
  );
};

/*---テーブル表示---*/
const PowerPlnaTable = (props: PowerPlanTableProps) => {
  const {
    powerPlanDatas,
    planData,
    setPlanData,
    savedDiff,
    dischargeDatas,
    textDischargeDatas,
    setDischargeDatas,
    setTextDischargeDatas,
    savedDischarge,
    socResult,
    soc,
    ownConsumptionDatas,
    savedOwnConsumption,
    setOwnConsumptionDatas,
    ownConsumptionPresets,
    selectedPresetNo,
    setSelectedPresetNo,
    actualDatas,
    pastData,
    predict,
    rePredict,
    dischargeForecast,
    reDischargeForecast,
    isAreaView,
    isBatteryView,
    isOwnConsumptionView,
    pastDate,
    setPastDate,
    selectedValue,
    setSelectedValue,
    selectedNumber,
    setSelectedNumber,
  } = props;
  const { PaletteMode } = useContext(PaletteContext);
  const { pickDate } = useContext(DateContext);
  const { time } = useContext(TimeContext);
  const { setBlockLeave } = useContext(LeavePageContext);
  const { words } = useContext(LanguageContext);
  const { borderColor } = useColorUtility();

  // 自家消費プリセットリスト
  const ownConsumptionPresetOptions = useMemo(
    () => [{ id: 0, name: words.setting_unselected }, ...ownConsumptionPresets],
    [ownConsumptionPresets, words]
  );
  // 選択中の自家消費プリセット
  const selectedPreset = useMemo(
    () =>
      ownConsumptionPresets.find((v) => v.id === selectedPresetNo)?.values ??
      Array(TIME_CODE_MAX).fill(0),
    [ownConsumptionPresets, selectedPresetNo]
  );

  // 乖離
  const planDiff = useMemo(
    () =>
      powerPlanDatas.map(
        (plan: number, index: number) => (actualDatas[index] ?? 0) - plan
      ),
    [powerPlanDatas, actualDatas]
  );
  // 乖離率
  const planDiffRate = useMemo(
    () =>
      powerPlanDatas.map((plan: number, index: number) =>
        actualDatas[index]
          ? Math.floor(
              (((actualDatas[index] ?? 0) - plan) / (actualDatas[index] ?? 0)) *
                100
            )
          : 0
      ),
    [powerPlanDatas, actualDatas]
  );

  // テーブルレイアウトを破壊しないようにダミーデータをつけた過去情報
  const viewPastData = useMemo(
    () =>
      [
        ...pastData,
        ...Array.from(
          { length: 7 },
          (): PastPowerPlanData => ({
            targetDate: "",
            powerPlan: Array(TIME_CODE_MAX).fill(0),
            genPower: Array(TIME_CODE_MAX).fill(0),
            disChargePower: Array(TIME_CODE_MAX).fill(0),
            ownConsumption: Array(TIME_CODE_MAX).fill(0),
          })
        ),
      ].filter((_, i) => i < PAST_REF_MAX),
    [pastData]
  );

  // GCタイミングで保存していない入力値をリセット
  useEffect(() => {
    if (!planData.length || !savedDiff.current.length) return;
    const temp = planData.map((v, i) =>
      IsOneHourLate(i) ? savedDiff.current[i] : v
    );
    setPlanData(temp);
    if (
      isBatteryView &&
      dischargeDatas.length &&
      savedDischarge.current.length
    ) {
      setDischargeDatas(
        dischargeDatas.map((v, i) =>
          IsOneHourLate(i) ? savedDischarge.current[i] : v
        )
      );
      setTextDischargeDatas(
        dischargeDatas.map((v, i) =>
          IsOneHourLate(i) ? savedDischarge.current[i].toString() : v.toString()
        )
      );
    }
    if (ownConsumptionDatas.length && savedOwnConsumption.current.length) {
      setOwnConsumptionDatas(
        ownConsumptionDatas.map((v, i) =>
          IsOneHourLate(i) ? savedOwnConsumption.current[i] : v
        )
      );
    }
  }, [time]);

  //GC判定
  const IsOneHourLate = (index: number) => {
    const nowTime = time; //  現在日時を得る
    let hourCount = nowTime.getHours() * 2; // 時間を取得を24→48最大に変更
    const minutes = nowTime.getMinutes();

    if (isAreaView) {
      // 前提としてエリア単位表示中(需要家が特定されていない場合は入力不可)
      return true;
    }

    // 現在時刻と検索日が一致したときのみ時刻判定
    if (pickDate != undefined && isSameDay(nowTime, pickDate)) {
      if (minutes >= 30) {
        hourCount += 1;
      }
      // 現在の時刻から1時間半先の時刻を判定
      if (hourCount + 2 < index) {
        return false;
      } else {
        return true;
      }
    } else if (pickDate != undefined && isBefore(nowTime, pickDate)) {
      return false;
    } else {
      return true;
    }
  };

  const isToday = pickDate !== null && isSameDay(time, pickDate);

  // 発電所表示、過去ではないとき有効
  const isButtonDisable =
    isAreaView ||
    isBefore(
      new Date(format(pickDate as Date, "yyyy/MM/dd")),
      new Date(format(time, "yyyy/MM/dd"))
    );

  //入力フィールドonChenge処理
  const createChangeValue = (
    index: number,
    datas: number[],
    setData: Dispatch<React.SetStateAction<number[]>>,
    minusInputtabled = false,
    textDatas?: string[],
    setTextData?: Dispatch<React.SetStateAction<string[]>>,
  ) => {
    return (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const minus = minusInputtabled ? "-?" : "";
      // 入力制限6桁
      const regex = new RegExp(`^(${minus}[0-9]{0,6})?$`);
      if (regex.test(event.target.value)) {
        setBlockLeave(true);
        if(textDatas !== undefined && setTextData !== undefined) {
          // マイナス入力が可能なテキストフィールドでは文字列をそのままセットする
          const tempTextDatas = [...textDatas];
          tempTextDatas[index] = event.target.value;
          setTextData(tempTextDatas);
        } else {
          const tempDatas = [...datas];
          tempDatas[index] = Number(event.target.value);
          setData(tempDatas);
          if(setTextData !== undefined) {
            setTextData(tempDatas.map((v) => v.toString()));
          }
          //連続0のみは0に変更
          if (event.target.value !== "-0") {
            event.target.value = Number(event.target.value).toFixed(0);
          }
        }
      }
    };
  };

  //複写機能
  const copyData = (
    before: number[],
    after: number[],
    setData: Dispatch<React.SetStateAction<number[]>>,
    setTextData?: Dispatch<React.SetStateAction<string[]>>
  ) => {
    setBlockLeave(true);
    setData([
      ...before.filter((_, index) => IsOneHourLate(index)).map((v) => v ?? 0),
      ...after.filter((_, index) => !IsOneHourLate(index)).map((v) => v ?? 0),
    ]);
    if(setTextData !== undefined) {
      setTextData([
        ...before.filter((_, index) => IsOneHourLate(index)).map((v) => v.toString() ?? "0"),
        ...after.filter((_, index) => !IsOneHourLate(index)).map((v) => v.toString() ?? "0"),
      ]);
    }
  };

  // 蓄電容量設定
  const batteryCapacityColor = useCallback(
    (index: number) => (socResult[index] ? "" : "green"),
    [socResult]
  );

  //実績色設定
  const ResultColor = (value: number) => {
    let textColor = "";
    if (value > 0) {
      textColor = PaletteMode === "dark" ? "aqua" : "Blue";
    }
    if (value < 0) {
      textColor = "Red";
    }
    return textColor;
  };

  return (
    <TableContainer
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        scrollPaddingTop: "183px",
        fontSize: "14px",
        "&::-webkit-scrollbar": {
          width: "10px",
          backgroundColor: PaletteMode === "dark" ? "#5e5e5e" : "transparent",
          height: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
          borderRadius: "10px",
        },
      }}
    >
      <StyledTable
        stickyHeader
        sx={{
          padding: "0px",
          height: "100%",
          zIndex: 0,
          border: "none",
          ".MuiTableCell-root": {
            borderRight: borderColor,
            borderBottom: borderColor,
          },
        }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            left: 0,
            padding: "0px",
            zIndex: 2,
            display: "block",
            borderTop: borderColor,
          }}
        >
          <TableRow>
            <TimeHeader />
            {(isBatteryView || isOwnConsumptionView) && <PowerPlanHeader />}
            <GenPowerHeader
              isHdNameChanged={isBatteryView || isOwnConsumptionView}
            />
            {isOwnConsumptionView && <OwnConsumptionHeader />}
            {isBatteryView && <DisChargePowerHeader />}
            {isBatteryView && <BatteryCapacityHeader />}
            <GenPowerForecastHeader />
            {isOwnConsumptionView && <OwnConsumptionRefHeader />}
            {isBatteryView && <DischargePowerForecastHeader />}
            <ActualHeader isShow={isToday} />
            <PastDataHeader
              span={PAST_REF_MAX}
              pastDate={pastDate}
              setPastDate={setPastDate}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </TableRow>
          <TableRow>
            {viewPastData.map((data, index) => (
              <PastDataDate key={index} date={data.targetDate} />
            ))}
            {/* 過去参照日付*/}
          </TableRow>
          <TableRow>
            <ReforcastButton disabled={isButtonDisable} onClick={rePredict} />
            {isOwnConsumptionView && (
              <OwnConsumptionModalButton
                value={selectedPresetNo}
                options={ownConsumptionPresetOptions}
                onAccept={(id: number) => {
                  setSelectedPresetNo(id);
                }}
                disabled={isAreaView}
              />
            )}
            <ReforcastButton
              isShow={isBatteryView}
              disabled={isButtonDisable || isToday}
              onClick={reDischargeForecast}
            />
            {viewPastData.map((data, index) => (
              <PastDataRadioButton
                key={index}
                value={index}
                checked={selectedNumber === index}
                onChange={() => setSelectedNumber(index)}
                disabled={isButtonDisable}
              />
            ))}
          </TableRow>
          <TableRow>
            <ForcastCopyButton
              disabled={isButtonDisable}
              onClick={() => copyData(planData, predict, setPlanData)}
            />
            <ForcastCopyButton
              isShow={isOwnConsumptionView}
              disabled={isButtonDisable}
              onClick={() =>
                copyData(
                  ownConsumptionDatas,
                  selectedPreset,
                  setOwnConsumptionDatas
                )
              }
            />
            <ForcastCopyButton
              isShow={isBatteryView}
              disabled={isButtonDisable || isToday}
              onClick={() =>
                copyData(
                  dischargeDatas, 
                  dischargeForecast, 
                  setDischargeDatas, 
                  setTextDischargeDatas
                )
              }
            />
            {viewPastData.map((data, index) => (
              <PastDataCopyButton
                key={index}
                onClick={() => {
                  copyData(planData, data.genPower, setPlanData);
                  copyData(
                    dischargeDatas,
                    data.disChargePower,
                    setDischargeDatas,
                    setTextDischargeDatas
                  );
                  copyData(
                    ownConsumptionDatas,
                    data.ownConsumption,
                    setOwnConsumptionDatas
                  );
                }}
                disabled={isButtonDisable}
              />
            ))}
          </TableRow>
          <TableRow>
            <DayAmountHeader isHeader={true} />
            {(isBatteryView || isOwnConsumptionView) && (
              <DayAmountHeader values={powerPlanDatas} isSticky={true} />
            )}
            <DayAmountHeader
              values={planData}
              isSticky={!(isBatteryView || isOwnConsumptionView)}
            />
            {isOwnConsumptionView && (
              <DayAmountHeader values={ownConsumptionDatas} />
            )}
            {isBatteryView && <DayAmountHeader values={dischargeDatas} />}
            {isBatteryView && <DayAmountHeader />}
            <DayAmountHeader values={predict} />
            {isOwnConsumptionView && (
              <DayAmountHeader values={selectedPreset} />
            )}
            {isBatteryView && <DayAmountHeader values={dischargeForecast} />}
            {isToday && <DayAmountHeader values={actualDatas} />}
            {isToday && <DayAmountHeader values={planDiff} />}
            {isToday && <DayAmountHeader />}
            {viewPastData.map((data, index) => (
              <DayAmountHeader key={index} values={data.powerPlan} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            display: "block",
          }}
        >
          {Array.from({ length: TIME_CODE_MAX }).map((_, index) => (
            <TableRow key={index}>
              {/* 時刻*/}
              <TimeBodyCell index={index} disabled={IsOneHourLate(index)} />
              {/* 発電計画 sum*/}
              {(isBatteryView || isOwnConsumptionView) && (
                <CommonOutputCell
                  value={powerPlanDatas[index]}
                  disabled={IsOneHourLate(index)}
                  isSticky={true}
                />
              )}
              {/* 発電計画or発電量*/}
              <PowerInputCell
                value={planData[index]}
                onChange={createChangeValue(index, planData, setPlanData)}
                onBlur={(e) => {
                  e.target.value = Number(e.target.value).toFixed(0);
                }}
                disabled={IsOneHourLate(index)}
                tabIndex={index + 1}
                isSticky={!(isBatteryView || isOwnConsumptionView)}
              />
              {/* 自家消費*/}
              {isOwnConsumptionView && (
                <PowerInputCell
                  value={ownConsumptionDatas[index]}
                  onChange={createChangeValue(
                    index,
                    ownConsumptionDatas,
                    setOwnConsumptionDatas
                  )}
                  onBlur={(e) => {
                    e.target.value = Number(e.target.value).toFixed(0);
                  }}
                  disabled={IsOneHourLate(index)}
                  tabIndex={TIME_CODE_MAX + index + 1}
                />
              )}
              {/* 充放電量*/}
              {isBatteryView && (
                <PowerInputCell
                  value={textDischargeDatas[index]}
                  onChange={createChangeValue(
                    index,
                    dischargeDatas,
                    setDischargeDatas,
                    true,
                    textDischargeDatas,
                    setTextDischargeDatas
                  )}
                  onBlur={(e) => {
                    e.target.value = Number(e.target.value).toFixed(0);
                      const tempDatas = [...dischargeDatas];
                      tempDatas[index] = Number(e.target.value);
                      setDischargeDatas(tempDatas);
                      const tempTextDatas = [...textDischargeDatas];
                      tempTextDatas[index] = e.target.value;
                      setTextDischargeDatas(tempTextDatas);
                  }}
                  disabled={IsOneHourLate(index)}
                  minusInputtabled={true}
                  tabIndex={TIME_CODE_MAX * 2 + index + 1}
                />
              )}
              {/* 蓄電量*/}
              {isBatteryView && (
                <CommonOutputCell
                  value={soc[index] ?? 0}
                  disabled={IsOneHourLate(index)}
                  textColor={batteryCapacityColor(index)}
                />
              )}
              {/* 発電予測*/}
              <CommonOutputCell
                value={predict[index]}
                disabled={IsOneHourLate(index)}
              />
              {/* 自家消費参照*/}
              {isOwnConsumptionView && (
                <CommonOutputCell
                  value={selectedPreset[index]}
                  disabled={IsOneHourLate(index)}
                />
              )}
              {/* 充放電予測*/}
              {isBatteryView && (
                <CommonOutputCell
                  value={dischargeForecast[index]}
                  disabled={IsOneHourLate(index)}
                />
              )}
              {/* 実績系*/}
              {isToday && (
                <CommonOutputCell
                  value={actualDatas[index] ?? 0}
                  disabled={IsOneHourLate(index)}
                />
              )}
              {isToday && (
                <CommonOutputCell
                  value={planDiff[index]}
                  disabled={IsOneHourLate(index)}
                  textColor={ResultColor(planDiff[index])}
                />
              )}
              {isToday && (
                <CommonOutputCell
                  value={planDiffRate[index]}
                  disabled={IsOneHourLate(index)}
                  textColor={ResultColor(planDiffRate[index])}
                  decimalDigits={1}
                />
              )}

              {/* 参照系*/}
              {viewPastData.map((data, i) => {
                return (
                  <CommonOutputCell
                    key={i}
                    value={data.powerPlan[index]}
                    disabled={IsOneHourLate(index)}
                  />
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

// 共通日量
const DayAmountHeader = (props: DayAmountHeaderProps) => {
  const { isHeader = false, isSticky = false, values } = props;
  const { words } = useContext(LanguageContext);
  const { borderColor } = useColorUtility();

  const dayPower = useMemo(
    () =>
      isHeader
        ? words.daily_amount + "(kWh)"
        : values
        ? (
            values.reduce(
              (sum, val) => (val != null ? (sum ?? 0) + val : null),
              0
            ) ?? 0
          ).toLocaleString()
        : "-",
    [isHeader, values, words]
  );

  return (
    <StyledTableHeaderG
      sx={
        isHeader
          ? {
              height: "34px",
              minWidth: "93px",
              maxWidth: "93px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: "0px",
              textAlign: "center",
              position: "sticky",
              left: 0,
              zIndex: 3,
              borderLeft: borderColor,
            }
          : {
              minWidth: "80px",
              padding: "0px",
              paddingRight: "8px",
              textAlign: "right",
              ...(isSticky && {
                left: "95px",
                zIndex: 3,
              }),
            }
      }
    >
      {dayPower}
    </StyledTableHeaderG>
  );
};

// 時刻
// ヘッダ
const TimeHeader = () => {
  const { words } = useContext(LanguageContext);
  const { borderColor } = useColorUtility();
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        width: "93px",
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 3,
        borderLeft: borderColor,
      }}
      rowSpan={4}
    >
      {words.timeB}
    </StyledTableHeaderLG>
  );
};
// セル
const TimeBodyCell = (props: TableBodyCell) => {
  const { index, disabled } = props;
  const { PaletteMode } = useContext(PaletteContext);
  const { borderColor } = useColorUtility();
  const bgColor = disabled
    ? PaletteMode === "dark"
      ? "rgb(90,90,90)"
      : "rgb(200,200,200)"
    : "";

  return (
    <StyledTableCell
      sx={{
        padding: 0,
        textAlign: "center",
        minWidth: "93px",
        position: "sticky",
        left: 0,
        zIndex: 1,
        borderLeft: borderColor,
      }}
      id={"powerForecastTimeCell" + index}
      style={{ height: "30px", backgroundColor: bgColor }}
    >
      {`${cellTime[index]}～${cellTime[index + 1]}`}
    </StyledTableCell>
  );
};

// 発電計画
// ヘッダ
const PowerPlanHeader = () => {
  const languageContext = useContext(LanguageContext);

  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        width: "78px",
        left: "95px",
        position: "sticky",
        zIndex: 3,
      }}
      rowSpan={4}
    >
      {languageContext.words.planned_gen_value}
      (kWh)
    </StyledTableHeaderLG>
  );
};

// 共通表示用セル
const CommonOutputCell = (props: CommonOutputProps) => {
  const {
    value,
    disabled,
    textColor,
    decimalDigits = 0,
    isSticky = false,
  } = props;
  const { PaletteMode } = useContext(PaletteContext);

  const BgColor = disabled
    ? PaletteMode === "dark"
      ? "rgb(90,90,90)"
      : "rgb(200,200,200)"
    : "";

  return (
    <StyledTableCell
      sx={{
        minWidth: "80px",
        height: "34px",
        textAlign: "right",
        padding: 0,
        paddingRight: "8px",
        ...(isSticky && {
          position: "sticky",
          left: "95px",
          zIndex: 1,
        }),
      }}
      style={{
        color: textColor,
        backgroundColor: BgColor,
      }}
    >
      {decimalDigits
        ? (value ?? 0).toFixed(decimalDigits)
        : (value ?? 0).toLocaleString()}
    </StyledTableCell>
  );
};
// セル

// 発電量
// ヘッダ
const GenPowerHeader = (props: GenPowerHeaderProps) => {
  const { isHdNameChanged } = props;
  const { words } = useContext(LanguageContext);
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        //minWidth: "78px",
        //width: "78px",
        minWidth: "88px",
        // 発電計画表示の時はヘッダ固定
        ...(!isHdNameChanged && {
          left: "95px",
          position: "sticky",
          zIndex: 3,
        }),
      }}
      rowSpan={4}
    >
      {isHdNameChanged ? words.generated_power : words.planned_gen_value}
      <br />
      (kWh)
    </StyledTableHeaderLG>
  );
};

// 充放電量
// ヘッダ
const DisChargePowerHeader = () => {
  const { words } = useContext(LanguageContext);
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        minWidth: "79px",
      }}
      rowSpan={4}
    >
      {words.charge_and_discharge_power}
      <br />
      (kWh)
    </StyledTableHeaderLG>
  );
};

// 自家消費ヘッダ
const OwnConsumptionHeader = () => {
  const { words } = useContext(LanguageContext);
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        minWidth: "79px",
      }}
      rowSpan={4}
    >
      {words.own_consumption}
      <br />
      (kWh)
    </StyledTableHeaderLG>
  );
};

// 電力量入力
// セル
const PowerInputCell = (props: PowerInputCellProps) => {
  const {
    value,
    tabIndex,
    onChange,
    onBlur,
    disabled,
    minusInputtabled = false,
    isSticky = false,
  } = props;
  const { PaletteMode } = useContext(PaletteContext);
  const { borderColor } = useColorUtility();

  //スクロールでの増減抑制(テーブルのスクロールと動作が被る)
  const onWheelHandler = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  //ペースト禁止
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!pastedText) {
      return;
    }
    // text を正規表現で分割して配列にする
    const textArray = pastedText.split(/ |\u3000/);
    const minus = minusInputtabled ? "-?" : "";
    const regex = new RegExp(`^((${minus}[1-9][0-9]{0,5})|0)?$`);
    textArray.forEach((item) => {
      // 数字以外がマッチしたらペーストできない
      if (!item.match(regex)) {
        e.preventDefault();
      }
    });
  };

  const BgColor = disabled
    ? PaletteMode === "dark"
      ? "rgb(90,90,90)"
      : "rgb(200,200,200)"
    : "";

  return (
    <StyledTableCell
      sx={{
        padding: 0,
        textAlign: "center",
        width: "88px",
        height: "30px",
        // 発電計画表示の時はヘッダ固定
        ...(isSticky && {
          left: "95px",
          position: "sticky",
          zIndex: 1,
        }),
      }}
      style={{
        backgroundColor: BgColor,
      }}
    >
      <TextField
        sx={{ margin: "2px" }}
        InputProps={{
          inputProps: {
            tabIndex: tabIndex,
            min: minusInputtabled ? null : 0,
            style: {
              padding: 0,
              height: "30px",
              textAlign: "right",
              backgroundColor: BgColor,
            },
          },
        }}
        type="number"
        disabled={disabled}
        onWheel={onWheelHandler}
        onKeyDown={(event) => {
          const isInvalid = minusInputtabled
            ? event.key === "."
            : event.key === "." || event.key === "-";
          if (isInvalid) {
            event.preventDefault();
          }
        }}
        onPaste={handlePaste} //負の値などのペースト制限
        value={value}
        onFocus={(e) => {
          if (Number(value) === 0) {
            e.target.select();
          }
        }}
        onBlur={onBlur}
        onChange={onChange}
      />
    </StyledTableCell>
  );
};

// 蓄電量
// ヘッダ
// セル
const BatteryCapacityHeader = () => {
  const { words } = useContext(LanguageContext);
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        textAlign: "center",
        minWidth: "78px",
        //height: "147.44px",
      }}
      rowSpan={4}
    >
      {words.storage_battery_charge}
      <br />
      (kWh)
    </StyledTableHeaderLG>
  );
};

// 発電予測
// ヘッダ
const GenPowerForecastHeader = () => {
  const { PaletteMode } = useContext(PaletteContext);
  const { words } = useContext(LanguageContext);
  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        color: "#00a1e9",
        minWidth: "78px",
        height: "81px",
      }}
      rowSpan={2}
    >
      {words.power_gen_forecast}
    </TableCell>
  );
};

// 充放電予測
// ヘッダ
const DischargePowerForecastHeader = () => {
  const { PaletteMode } = useContext(PaletteContext);
  const { words } = useContext(LanguageContext);
  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        color: "#00a1e9",
        width: "78px",
        height: "81px",
      }}
      rowSpan={2}
    >
      {words.charge_and_discharge_forecast}
    </TableCell>
  );
};

// 自家消費参照ヘッダ
const OwnConsumptionRefHeader = () => {
  const { PaletteMode } = useContext(PaletteContext);
  const { words } = useContext(LanguageContext);
  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        color: "#00a1e9",
        width: "78px",
        height: "81px",
      }}
      rowSpan={2}
    >
      {words.own_consumption}
    </TableCell>
  );
};

// 再予測ボタン
const ReforcastButton = (props: ForecastButtonProps) => {
  const { isShow = true, onClick, disabled } = props;
  const { words } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  if (!isShow) return null;

  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        height: "32px",
        minWidth: "78px",
      }}
    >
      <Button
        style={{
          borderColor: "#00000000",
          backgroundColor: !disabled
            ? PaletteMode === "dark"
              ? "#90CAF9"
              : "rgb(0, 100, 200)"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.12)"
            : "rgba(0, 0, 0, 0.12)",
          color: !disabled
            ? PaletteMode === "dark"
              ? "black"
              : "white"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          padding: "0px",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {words.reforecast}
      </Button>
    </TableCell>
  );
};

// コピーボタン
const ForcastCopyButton = (props: ForecastButtonProps) => {
  const { isShow = true, onClick, disabled } = props;
  const { PaletteMode } = useContext(PaletteContext);
  const { words } = useContext(LanguageContext);

  if (!isShow) return null;
  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        height: "32px",
        minWidth: "79px",
      }}
    >
      <StyledButton
        style={{
          //システム予測の複写ボタン
          color: !disabled
            ? "#00a1e9"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          borderColor: !disabled
            ? "#00a1e9"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          fontSize: "13px",
          height: "24px",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {words.copy}
      </StyledButton>
    </TableCell>
  );
};

// 自家消費モーダルボタン
const OwnConsumptionModalButton = (props: any) => {
  const { value, onAccept, disabled, options } = props;

  const { words } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  const [open, setOpen] = useState(false);
  const [tempVal, setTempVal] = useState<number>(value); // OK時のみステートを更新
  const accepted = useRef(false); // カスタムモーダルが成功時にもクローズ処理をしているためフラグで処理分岐

  // モーダル表示時に、アクセプトフラグリセット
  useEffect(() => {
    if (open) accepted.current = false;
  }, [open]);

  useEffect(() => {
    setTempVal(value);
  }, [value]);

  return (
    <TableCell
      sx={{
        backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
        padding: "0px",
        textAlign: "center",
        height: "32px",
        minWidth: "78px",
      }}
      rowSpan={1}
    >
      <StyledButton
        style={{
          borderColor: "#00000000",
          backgroundColor: !disabled
            ? PaletteMode === "dark"
              ? "#90CAF9"
              : "rgb(0, 100, 200)"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.12)"
            : "rgba(0, 0, 0, 0.12)",
          color: !disabled
            ? PaletteMode === "dark"
              ? "black"
              : "white"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          padding: "0px",
        }}
        disabled={disabled}
        onClick={() => {
          setOpen(true);
        }}
      >
        {words.option}
      </StyledButton>
      <CustomModal
        open={open}
        title={words.own_consumption_setting_tittle}
        btnText={words.confirmed}
        onAccept={() => {
          onAccept(tempVal);
          accepted.current = true;
          setOpen(false);
        }}
        onClose={() => {
          if (!accepted.current) {
            setTempVal(value);
          }
          setOpen(false);
        }}
      >
        <CustomSelectList
          value={tempVal}
          options={options}
          onChange={(id: number) => {
            setTempVal(id);
          }}
          blocked={false}
        />
      </CustomModal>
    </TableCell>
  );
};

// 当日参照(実績,乖離,乖離率)
// ヘッダ
const ActualHeader = (props: ActualHeaderProps) => {
  const { isShow } = props;
  const { words } = useContext(LanguageContext);
  if (!isShow) return null;

  return (
    <>
      <StyledTableHeaderLG
        sx={{
          padding: "0px",
          textAlign: "center",
          minWidth: "88px",
        }}
        rowSpan={4}
      >
        {words.results_jissekichi}
        <br />
        (kWh)
      </StyledTableHeaderLG>
      <StyledTableHeaderLG
        sx={{
          padding: "0px",
          textAlign: "center",
          minWidth: "88px",
        }}
        rowSpan={4}
      >
        {words.deviation}
        <br />
        (kWh)
      </StyledTableHeaderLG>
      <StyledTableHeaderLG
        sx={{
          padding: "0px",
          textAlign: "center",
          minWidth: "88px",
        }}
        rowSpan={4}
      >
        {words.deviation_ratio}
        <br />
        (%)
      </StyledTableHeaderLG>
    </>
  );
};

// ヘッダ1 検索条件
const PastDataHeader = (props: PastDataHeaderProps) => {
  const { selectedValue, setSelectedValue, pastDate, setPastDate, span } =
    props;
  const { words } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  if (!span) return null;

  return (
    <StyledTableHeaderLG
      sx={{
        textAlign: "center",
        padding: 0,
        height: "49px",
      }}
      colSpan={span}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        color={"black"}
        alignItems={"center"}
      >
        <FormControlLabel
          value="end"
          control={
            <Radio
              //実績、計画の切り替えラジオボタン「実績」押下時
              checked={selectedValue === "a"}
              onChange={(event) => {
                setSelectedValue(event.target.value);
              }}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 17,
                },
              }}
              style={{ padding: "4px" }}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px" }}>{words.results}</Typography>
          }
          sx={{ marginX: "0px" }}
        />
        <FormControlLabel
          value="end"
          control={
            <Radio
              //実績・計画の切り替えラジオボタン「計画」押下時
              checked={selectedValue === "b"}
              onChange={(event) => {
                setSelectedValue(event.target.value);
              }}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              sx={{
                minWidth: "auto",
                "& .MuiSvgIcon-root": {
                  fontSize: 17,
                },
              }}
              style={{ padding: "4px" }}
            />
          }
          label={
            <Typography sx={{ fontSize: "14px" }}>{words.plan}</Typography>
          }
          sx={{ marginX: "0px" }}
        />
        <Typography
          sx={{
            paddingLeft: "8px",
            fontSize: "14px",
            color: PaletteMode === "dark" ? "lightgray" : "black",
          }}
        >
          {words.display_start_date}
        </Typography>
        <CustomDatePickerTryal
          value={pastDate}
          setValue={(pastDate: Date) => {
            setPastDate(pastDate);
          }}
        />
        {/*実績、計画ラジオボタン横の日付オブジェクト*/}
      </Box>
    </StyledTableHeaderLG>
  );
};

// ヘッダ2 日付
const PastDataDate = (props: PastDataDateProps) => {
  const { date } = props;
  const { convertDateToLocalizedString } = useContext(LanguageContext);
  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        zIndex: 0,
        textAlign: "center",
        height: "30px",
        minWidth: "88px",
      }}
    >
      {date ? convertDateToLocalizedString(new Date(date)) : ""}
    </StyledTableHeaderLG>
  );
};

// ヘッダ3
const PastDataCopyButton = (props: PastDataCopyButtonProps) => {
  const { onClick, disabled } = props;
  const { words } = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        zIndex: 0,
        textAlign: "center",
        height: "32px",
        minWidth: "88px",
      }}
    >
      <StyledButton
        sx={{ height: "20px" }}
        style={{
          color: !disabled
            ? "#00a1e9"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          borderColor: !disabled
            ? "#00a1e9"
            : PaletteMode === "dark"
            ? "rgba(255, 255, 255, 0.3)"
            : "rgba(0, 0, 0, 0.26)",
          height: "13px",
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {words.copy}
      </StyledButton>
    </StyledTableHeaderLG>
  );
};

// ヘッダ4
const PastDataRadioButton = (props: PastDataRadionButtonButtonProps) => {
  const { value, checked, onChange, disabled } = props;

  return (
    <StyledTableHeaderLG
      sx={{
        padding: "0px",
        zIndex: 0,
        textAlign: "center",
        height: "32px",
        minWidth: "88px",
      }}
    >
      <Radio
        checked={checked}
        onChange={onChange}
        value={value}
        name="radio-buttons"
        inputProps={{ "aria-label": `${value}` }}
        sx={{
          minWidth: "auto",
          "& .MuiSvgIcon-root": {
            fontSize: 17,
          },
        }}
        disabled={disabled}
        style={{ padding: "1px" }}
      />
    </StyledTableHeaderLG>
  );
};

export default PowerForecast;
