import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { format, isBefore, isSameDay } from "date-fns";
import { LanguageContext } from "../localization/localization";
import { io } from "socket.io-client";

type timeContextType = {
  time: Date;
  setTime: (pickDate: React.SetStateAction<Date>) => void;
  isGateClose: (index: number, targetDate: Date) => boolean;
};
export const TimeContext = createContext<timeContextType>({
  time: new Date(),
  setTime: (time) => null,
  isGateClose: (index, targetDate) => false,
});

export const TimeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [time, setTime] = useState<Date>(initTime);

  const isGateClose = useCallback(
    (index: number, targetDate: Date) => {
      // 現在時刻と検索日が一致したときのみ時刻判定
      if (isSameDay(time, targetDate)) {
        let hourCount = time.getHours() * 2; // 時間を取得を24→48最大に変更
        const minutes = time.getMinutes();
        if (minutes >= 30) {
          hourCount += 1;
        }
        // 現在の時刻から1時間半先の時刻を判定
        return index <= hourCount + 2;
      } else {
        return isBefore(targetDate, time);
      }
    },
    [time]
  );

  return (
    <TimeContext.Provider value={{ time, setTime, isGateClose }}>
      {children}
    </TimeContext.Provider>
  );
};

const Clock = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { time, setTime } = useContext<timeContextType>(TimeContext);

  useEffect(() => {
    const socket = io();
    socket.on("globalTimer/", (gTime: string) => {
      setTime(new Date(gTime));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <React.Fragment>
      {languageContext.convertDateToLocalizedStringGoodAcu(time)}
    </React.Fragment>
  );
};

//最小単位を分で扱うため切り捨てし、初期値とする
const initTime = (): Date => {
  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export default Clock;
