import React, { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import styled from "styled-components";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomModal from "../../common/customComponents/CustomModal";
import { TimeTable } from "./CreateTime";
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { format } from "date-fns";
import { useAuthedApi } from "../../../common/axios";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { bitLimmitReq, bitLimitCells } from "../../../types/jepx/OneHourAdvanceBidPlan";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { BUSINESS_MODEL } from "../../../common/constant";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのヘッダーライトグレー
const HeaderCellLG = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//テーブルのヘッダーグレー
const HeaderCellG = styled(StyledTableHeaderG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
  }
`;

//数値のヘッダーライトグレー
const InputHedderCellLG = styled(HeaderCellLG)`
  && {
    width: 95px;
  }
`;

//数値のヘッダーグレー
const InputHedderCellG = styled(HeaderCellG)`
  && {
    width: 95px;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//74pxに合わせるテーブルのセル
const InputTableCell = styled(TableCell)`
  && {
    width: 95px;
  }
`;

const POST_BID_LIMIT = "/postBidRequestLimit"; //入札制限値登録
const GET_BID_LIMIT = "/getBidRequestLimit"; //入札制限値取得

//テーブルのレンダー
const TableRender = (props: any) => {
  const { setBidLimit, bidLimit, getBidLimit, areaId, setIsLoaded } = props;
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      setIsLoaded(false);
      (async() => {
        await getBidLimit();
      })();
      setIsLoaded(true);
    }
  }, []);

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    colIndex: number,
    rowIndex: number,
    limit: bitLimmitReq,
    setLimit: React.Dispatch<React.SetStateAction<bitLimmitReq>>
  ) => {
    const tempBitLimit: bitLimmitReq = Object.assign({}, bidLimit);
    if (colIndex === 0 || colIndex === 2) {
      if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
        ) > 999.99
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = "999.99";
      } else if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
        ) < 0
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = "0.00";
      } else if (
        Number(tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].lowerPowerPrice) > 
        Number(tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].upperPowerPrice)
      ){
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].lowerPowerPrice =
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].upperPowerPrice;
      } else {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].lowerPowerPrice = Number(
          bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .lowerPowerPrice
          ).toFixed(2);
      }
    } else {
      // } else if (Math.floor(colIndex / 2) % 2 === 0) {
      if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
        ) > 999.99
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = "999.99";
      } else if (
        Number(
          tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
        ) < 0
      ) {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = "0.00";
      } else if (
        Number(tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].lowerPowerPrice) > 
        Number(tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].upperPowerPrice)
      ){
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].upperPowerPrice =
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex].lowerPowerPrice;
      } else {
        tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
          rowIndex
        ].upperPowerPrice = Number(
          bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[rowIndex]
            .upperPowerPrice
          ).toFixed(2);
      }
    }
    setLimit(tempBitLimit);
  };

  //テキストフィールドを打つとそこのステイトを変化させる関数
  const changeTfState = (
    inputedValue: string,
    colIndex: number,
    rowIndex: number
  ) => {
    //連想配列のコピーはObject.createでちゃんとしたコピーが可能
    const tempBitLimit: bitLimmitReq = Object.assign({}, bidLimit);
    if (colIndex === 0 || colIndex === 2) {
      tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
        rowIndex
      ].lowerPowerPrice = inputedValue.replace(/-/g, "");
    } else {
      tempBitLimit.dayLimitBit[colIndex < 2 ? 1 : 0].cells[
        rowIndex
      ].upperPowerPrice = inputedValue.replace(/-/g, "");
    }
    setBidLimit(tempBitLimit);
  };
  return (
    <TableContainer sx={{ width: "630px", height: "633px" }}>
      <StyledTable stickyHeader={true}>
        <TableHead sx={{ display: "block", position: "sticky" }}>
          <TableRow>
            <HeaderCellLG rowSpan={3} align="center" sx={{ width: "100.5px" }}>
              {languageContext.words.timeB}
            </HeaderCellLG>
            <HeaderCellLG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.sell}
            </HeaderCellLG>
            <HeaderCellG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.buy}
            </HeaderCellG>
          </TableRow>

          <TableRow>
            <HeaderCellLG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.bid_price}
              <br />({languageContext.words.yen_kwh})
            </HeaderCellLG>
            {/* <HeaderCellLG colSpan={2} align="center" sx={{ width: "225px" }}>
              {languageContext.words.bidding_volume}
              <br />
              (MWh/h)
            </HeaderCellLG> */}
            <HeaderCellG colSpan={2} align="center" sx={{ width: "265px" }}>
              {languageContext.words.bid_price}
              <br />({languageContext.words.yen_kwh})
            </HeaderCellG>
            {/* <HeaderCellG colSpan={2} align="center" sx={{ width: "225px" }}>
              {languageContext.words.bidding_volume}
              <br />
              (MWh/h)
            </HeaderCellG> */}
          </TableRow>
          <TableRow>
            <HeaderCellLG align="center" width="132px">
              {languageContext.words.lower_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" width="132px">
              {languageContext.words.upper_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" width="132px">
              {languageContext.words.lower_limit}
            </HeaderCellLG>
            <HeaderCellLG align="center" width="132px">
              {languageContext.words.upper_limit}
            </HeaderCellLG>
            {/* <HeaderCellG align="center" sx={{ width: "122px" }}>
              {languageContext.words.lower_limit}
            </HeaderCellG>
            <HeaderCellG align="center" sx={{ width: "122px" }}>
              {languageContext.words.upper_limit}
            </HeaderCellG>
            <HeaderCellG align="center" sx={{ width: "122px" }}>
              {languageContext.words.lower_limit}
            </HeaderCellG>
            <HeaderCellG align="center" sx={{ width: "122px" }}>
              {languageContext.words.upper_limit}
            </HeaderCellG> */}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            display: "block",
            overflowY: "auto",
            // width: "1098px",
            height: "500px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {Array(48)
            .fill(0)
            .map((row, rowIndex) => {
              return (
                <TableRow key={"bidLimitRow_" + rowIndex}>
                  <StyledTableCell
                    key={"bidLimitCol_time" + rowIndex}
                    sx={{ width: "100px", padding: "0px", height: "30px" }}
                    align="center"
                  >
                    {`${timeData[rowIndex]}～${timeData[rowIndex + 1]}`}
                  </StyledTableCell>
                  {Array(4)
                    .fill(0)
                    .map((col, colIndex) => {
                      return (
                        <StyledTableCell
                          key={"bidLimitCol_buy_min" + colIndex}
                          sx={{
                            padding: "0px",
                            width: colIndex === 3 ? "121px" : "131px",
                          }}
                          align="center"
                        >
                          <TextField
                            key={`bidLimitCol_${rowIndex}_${colIndex}`}
                            InputProps={{
                              inputProps: {
                                tabIndex: colIndex + 1,
                                min: "0",
                                max: "999.99",
                                step: "0.01",
                                style: {
                                  padding: "0px",
                                  width: "100px",
                                  height: "22px",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  textAlign: "right",
                                },
                              },
                            }}
                            type="number"
                            value={
                              colIndex === 0 || colIndex === 2
                                ? bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                    .cells[rowIndex].lowerPowerPrice
                                : bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                    .cells[rowIndex].upperPowerPrice
                            }
                            onFocus={(e) => {
                              if (
                                (colIndex === 0 || colIndex === 2
                                  ? bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                      .cells[rowIndex].lowerPowerPrice
                                  : bidLimit.dayLimitBit[colIndex < 2 ? 1 : 0]
                                      .cells[rowIndex].upperPowerPrice) ===
                                "0.00"
                              ) {
                                e.currentTarget.select();
                              }
                            }}
                            onBlur={(e) => {
                              processText(
                                colIndex,
                                rowIndex,
                                bidLimit,
                                setBidLimit
                              );
                            }}
                            onKeyDown={(event) => {
                              if (
                                event.key === "e" ||
                                event.key === "E" ||
                                event.key === "-"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              changeTfState(e.target.value, colIndex, rowIndex);
                            }}
                          />
                        </StyledTableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

//入札制限のモーダル
const BidLimitSetting = (
  open: boolean,
  HaldleClose: () => void,
  limit: bitLimmitReq,
  setLimit: React.Dispatch<React.SetStateAction<bitLimmitReq>>,
  areaId: string,
  hiddenUser: boolean,
  selectedBusinessModel: string,
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const dateContext = useContext(DateContext);

  const api = useAuthedApi();

  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>(limit);

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [digOpen, setDigOpen] = useState<boolean>(false);
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  //モーダルを閉じる
  const ModalCloseEvent = () => {
    HaldleClose();
  };

  // 設定した制限値をDBに登録
  const postBidLimit = async () => {
    await api
      .post(POST_BID_LIMIT, {
        data: bidLimit,
        areaId: areaId,
      })
      .then((response) => {
        setDialogMessage(languageContext.words.saved);
        setDigOpen(true);
      })
      .catch((error) => {
        setDialogMessage(languageContext.words.saved_failed);
        setDigOpen(true);
      });
  };

  const getBidLimit = async () => {
    if(selectedBusinessModel === BUSINESS_MODEL.MARKET_TRAN || 
      selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN) {
      await api
        .get(GET_BID_LIMIT, {
          params: {
            areaId: areaId,
            targetDate: format(dateContext.pickDate as Date, "yyyy-MM-dd"),
          },
        })
        .then((response) => {
          const bidLimit: bitLimmitReq = response.data.result;
          setLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
          setBidLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
      
    } else {
      setLimit({
        area: Number(areaId),
        dayLimitBit: bidLimit.dayLimitBit.map((value) => {
          const data: bitLimitCells = {
            type: value.type,
            cells: value.cells.map((item) => ({
              upperPowerPrice: "999.99",
              lowerPowerPrice: "0.00",
            })),
          };
          return data;
        }),
      });
      setBidLimit({
        area: Number(areaId),
        dayLimitBit: bidLimit.dayLimitBit.map((value) => {
          const data: bitLimitCells = {
            type: value.type,
            cells: value.cells.map((item) => ({
              upperPowerPrice: "999.99",
              lowerPowerPrice: "0.00",
            })),
          };
          return data;
        }),
      });
    }
  };

  const SaveButtonEvent = async() => {
    setIsLoaded(false);
    await postBidLimit();
    await getBidLimit();
    setIsLoaded(true);
  }

  return (
    <>
      <CustomModal
        hidden={hiddenUser}
        title={languageContext.words.one_hour_ago_bidding_limit}
        open={open}
        onAccept={SaveButtonEvent}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.save}
      >
        <Box sx={{ width: "auto" }}>
          <TableRender
            bidLimit={bidLimit}
            setBidLimit={setBidLimit}
            getBidLimit={getBidLimit}
            areaId={areaId}
            setIsLoaded={setIsLoaded}
          />
        </Box>
      </CustomModal>
      <CustomDialog
        title={languageContext.words.one_hour_ago_bidding_limit}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={!isLoaded} />
    </>
  );
};
export default BidLimitSetting;
