import React, { useContext, useState, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import MarketDetails from "./MarketDetails";
import BulkUndo from "./BulkUndo";
import BulkBit from "./BulkBit";
import Bid from "./Bid";
import BidDelete from "./BidDelete";
import AutomationSettings from "./AutomationSettings";
import BidLimitSetting from "./BidLimitSetting";
import CommonDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TimeTable } from "./CreateTime";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { StyledButton } from "../../common/styledComponents/styledButton";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import { addDays, format, subMinutes } from "date-fns";
import { resHourBid, autoSettingData, BidingCell } from "../../../types/jepx/OneHourAdvanceBidPlan";
import { LanguageContext } from "../../common/localization/localization";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import CustomDialog from "../../common/customComponents/CustomDialog";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import AutoDeleteSettings from "./AutoDeleteSetting";
import { Table, Typography } from "@mui/material";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import {
  AUTO_BIDDING,
  CONTRACT_STATUS,
  GET_AREA_NAME_LIST,
  GET_AUTO_SETTING,
  GET_BID_LIMIT,
  GET_INTRADAY_PRODUCT_URL,
  GET_PROCUREMENT_DATA,
  GET_ROLE_JUDGMENT_URL,
  GET_SALES_DATA,
  INTRADAY_DATA_URL,
  PLAN_MAX_VALUE,
  POST_AUTO_SETTING,
  POST_INTRADAY_CONTRACT_URL,
  POST_ONEHOUR_PLAN,
  UPDATE_AFTER_GC_STATUS,
} from "./IntradayConstant";
import { useAuthedApi } from "../../../common/axios";
import { BusinessModelContext } from "../../common/customComponents/BusinessModelProvider";
import { BUSINESS_MODEL, BUY_SELL_KIND, ERR_CODE_SOAP_ERR, RESULT_CODE } from "../../../common/constant";
import { bitLimmitReq, bitLimitCells } from "../../../types/jepx/OneHourAdvanceBidPlan";
import { changeDateToTimeCd } from "../../common/changeDateCd";
import { autoScrollPage } from "../../common/ScrollPage";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのヘッダー
const HeaderCell = styled(StyledTableHeaderLG)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4px 0px;
    font-size: 14px;
  }
`;

//数値のヘッダー
const AmountHedderCell = styled(HeaderCell)`
  && {
    width: 74px;
    height: 75px;
    white-space: nowrap;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
    height: 25px;
  }
`;

//74pxに合わせるテーブルのセル
const AmountTableCell = styled(TableCell)`
  && {
    width: 75px;
    text-align: right;
    padding-right: 8px;
    box-sizing: border-box;
  }
`;

type searchInfo = { id: number; name: string };

export type createAutomationSettingsListData = {
  deadlineTime: searchInfo[];
  priceAdjustment: searchInfo[];
  deleteTime: searchInfo[];
  startTime: searchInfo[];
  adjustmentCycle: searchInfo[];
};

//1時間前入札
const IntradayBidding = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const { businessModel } = useContext(BusinessModelContext);

  const leavePageContext = useContext(LeavePageContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const api = useAuthedApi();

  //自動入札設定の選択肢
  const AutomationSettingsList: createAutomationSettingsListData = {
    deadlineTime: [
      { id: 0, name: languageContext.words.intraday_auto_setting_1 },
      { id: 1, name: languageContext.words.intraday_auto_setting_2 },
      { id: 2, name: languageContext.words.intraday_auto_setting_3 },
      { id: 3, name: languageContext.words.intraday_auto_setting_4 },
      { id: 4, name: languageContext.words.intraday_auto_setting_5 },
      { id: 5, name: languageContext.words.intraday_auto_setting_6 },
      { id: 6, name: languageContext.words.intraday_auto_setting_7 },
      { id: 7, name: languageContext.words.intraday_auto_setting_8 },
      { id: 8, name: languageContext.words.intraday_auto_setting_9 },
      { id: 9, name: languageContext.words.intraday_auto_setting_10 },
      { id: 10, name: languageContext.words.intraday_auto_setting_11 },
    ],
    priceAdjustment: [
      { id: 0, name: languageContext.words.intraday_auto_deletion_enabled },
      { id: 1, name: languageContext.words.intraday_auto_deletion_unenabled },
    ],
    deleteTime: [
      { id: 0, name: languageContext.words.intraday_auto_deletion_enabled },
      { id: 1, name: languageContext.words.intraday_auto_deletion_unenabled },
    ],
    startTime: [
      { id: 0, name: languageContext.words.intraday_auto_setting_1 },
      { id: 1, name: languageContext.words.intraday_auto_setting_2 },
      { id: 2, name: languageContext.words.intraday_auto_setting_3 },
      { id: 3, name: languageContext.words.intraday_auto_setting_4 },
      { id: 4, name: languageContext.words.intraday_auto_setting_5 },
      { id: 5, name: languageContext.words.intraday_auto_setting_6 },
      { id: 6, name: languageContext.words.intraday_auto_setting_7 },
      { id: 7, name: languageContext.words.intraday_auto_setting_8 },
      { id: 8, name: languageContext.words.intraday_auto_setting_9 },
      { id: 9, name: languageContext.words.intraday_auto_setting_10 },
      { id: 10, name: languageContext.words.intraday_auto_setting_11 },
    ],
    adjustmentCycle: [
      { id: 0, name: languageContext.words.intraday_auto_cycle_1 },
      { id: 1, name: languageContext.words.intraday_auto_cycle_2 },
      { id: 2, name: languageContext.words.intraday_auto_cycle_3 },
      { id: 3, name: languageContext.words.intraday_auto_cycle_4 },
      { id: 4, name: languageContext.words.intraday_auto_cycle_5 },
      { id: 5, name: languageContext.words.intraday_auto_cycle_6 },
    ],
  };

  const changeFlag = useRef<boolean>(false);
  const firstRenderFlag = useRef<boolean>(true);

  const { time } = useContext(TimeContext);

  const startDate = useRef<Date>(
    new Date(time.getFullYear(), time.getMonth(), time.getDate(), 17)
  );
    
  // レンダー管理
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  //エリアリスト
  const [areaList, setAreaList] = useState<string[]>([]);

  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  //自動価格調整のスイッチ
  const [switchActivity, setSwitchActivity] = useState(false);
  //自社状況のニューメリックアップダウンの活性・非活性
  const [inputNumberActivity, setInputNumberActivity] = useState(true);
  //入札状況テーブルのセル情報
  const [biddingCellInfo, setBiddingCellInfo] = useState<BidingCell>({
    timeCd: "0",
    tenderNum: "",
    type: BUY_SELL_KIND.SELL,
    bidValue: 0,
    contractValue: 0,
    bidPrice: 0,
    contractPrice: 0,
    contractStatus: "",
  });
  // テキストフィールドの小数点以下0埋めして表示するためのstate
  const [padSellingPlanText, setPadSellingPlanText] = useState<string[]>(
    new Array(48).fill(true).map((_) => "0.00")
  );
  // テキストフィールドの小数点以下0埋めして表示するためのstate
  const [padProcurementPlanText, setPadProcurementPlanText] = useState<
    string[]
  >(new Array(48).fill(true).map((_) => "0.00"));

  //DatePickerで選択可能な最大日付
  const [maxDate, setMaxDate] = useState<Date>(time);

  const hourEriaBitInfoData: resHourBid = {
    targetDate: "",
    area: 0,
    areaValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: new Array(48).fill(true).map((_, index) => ({
        saleBitValue: 0,
        saleBitLowPrice: 0,
        buyBitValue: 0,
        buyBitHighPrice: 0,
        newExecutionPrice: 0,
        averageExecutionPrice: 0,
        spotPrice: 0,
        productStatus: "0",
      })),
    },
    companyValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: new Array(48).fill(true).map((_, index) => ({
        saleBitValue: 0,
        salesContractValue: 0,
        saleBitPrice: 0,
        salesContractPrice: 0,
        buyBitValue: 0,
        buyContractValue: 0,
        buyBitPrice: 0,
        buyContractPrice: 0,
      })),
    },
    bidingValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: [],
    },
  };

  // DBから来たデータ（一時間前入札データ取得）
  const [responseMainData, setResponseMainData] =
    useState<resHourBid>(
      hourEriaBitInfoData
    );
  
  // 自動化設定取得
  const [responseAutoSettingData, setResponseAutoSettingData] = useState<autoSettingData>();
  

  // DBからデータ取得
  //datePecker
  const dateValue = useContext(DateContext);

  // 入札制限データ
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>({
    area: Number(areaId),
    dayLimitBit: new Array(2).fill(true).map((_, index) => {
      const data: bitLimitCells = {
        type: index + 1,
        cells: new Array(48).fill(true).map((_) => ({
          upperPowerPrice: "0.00",
          lowerPowerPrice: "0.00",
        })),
      };
      return data;
    }),
  });

  //自動価格調整のセレクトボックス
  const [priceAdjustment, setPriceAdjustment] = useState<string>("");
  //入札締め切り時間のセレクトボックス
  const [deadlineTime, setDeadlineTime] = useState<string>("");
  //自動札削除のセレクトボックス
  const [autoDelete, setAutoDelete] = useState<string>("");
  //開始時間のセレクトボックス
  const [startTime, setStartTime] = useState<string>("");
  //調整周期のセレクトボックス
  const [adjustmentCycle, setAdjustmentCycle] = useState<string>("");
  //調整量のテキストフィールド
  const [holdeAdjustmentAmount, setHoldeAdjustmentAmount] =
    useState<number>(0);
  // DB編集系ボタンの表示・非表示
  const [hiddenUser, setHiddenUser] = useState<boolean>(true);
  // ビジネスモデルによるボタンの表示・非表示
  const [hiddenBusinessModel, setHiddenBusinessModel] = useState<boolean>(true);
  const [tradingTypeId, setTradingTypeId] = useState<number>(Number(BUY_SELL_KIND.SELL));

  // 各コマの売り入札上限
  const upperSellVolume: string[] = useMemo(() => {
    let result: string[] = Array(48);
    if(responseMainData.bidingValue.dayBit.length !== 0) {
      for(let i = 0; i < 48; i++) {
        const bidded = 
          responseMainData.bidingValue.dayBit
          .filter(
            (v) => Number(v.timeCd) === i + 1 &&
            v.type === BUY_SELL_KIND.SELL &&
            v.contractStatus !== CONTRACT_STATUS.canceled &&
            v.contractStatus !== CONTRACT_STATUS.couldNot)
          .map((v) => v.bidValue);
        if(bidded.length !== 0) {
          const tmp = bidded.reduce((sum, i) => sum + i, 0);
        }
        result[i] = bidded.length !== 0
        ? (Number(padSellingPlanText[i]) - Math.abs(bidded.reduce((sum, i) => sum + i, 0))).toFixed(2)
        : padSellingPlanText[i];
      }
    } else {
      result = padSellingPlanText;
    }
    return result;
  },[padSellingPlanText, responseMainData.bidingValue]);

  // 各コマの買い入札上限
  const upperBuyVolume: string[] = useMemo(() => {
    let result: string[] = Array(48);
    if(responseMainData.bidingValue.dayBit.length !== 0) {
      for(let i = 0; i < 48; i++) {
        const bidded = 
          responseMainData.bidingValue.dayBit
          .filter(
            (v) => Number(v.timeCd) === i + 1 &&
            v.type === BUY_SELL_KIND.BUY &&
            v.contractStatus !== CONTRACT_STATUS.canceled &&
            v.contractStatus !== CONTRACT_STATUS.couldNot)
          .map((v) => v.bidValue);
          result[i] = bidded.length !== 0
          ? (Number(padProcurementPlanText[i]) - Math.abs(bidded.reduce((sum, i) => sum + i, 0))).toFixed(2)
          : padProcurementPlanText[i];
      }
    } else {
      result = padProcurementPlanText;
    }
    return result;
  },[padProcurementPlanText, responseMainData.bidingValue]);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    return api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHiddenUser(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 一時間前入札画面のデータ取得
  const getIntradayData = async () => {
    return api
      .get(INTRADAY_DATA_URL, {
        params: {
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          areaId: areaId,
        },
      })
      .then((res) => {
        ChartData(res.data.result);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === ERR_CODE_SOAP_ERR) {
          setDialogMessage(languageContext.words.could_not_communicate_jepx);
        } else {
          setDialogMessage(languageContext.words.get_error);
        }
        setDigOpen(true);
      });
  };

  // 1時間前商品一覧取得
  const getIntradayProduct = async () => {
    return api
      .get(GET_INTRADAY_PRODUCT_URL, {
        params: {
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          areaId: areaId,
        },
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 約定結果反映
  const setContractOrder = async () => {
    return api
      .post(POST_INTRADAY_CONTRACT_URL, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //テーブルの色生成
  function TableColor (nowColor: string, OKColor: string, NGColor: string) {
    const colorData = [];
    if (dateValue.pickDate != null) {
      //今の時間の全ての経過日数・時間(分)を求める
      const nowTime = time;
      const nowYear = nowTime.getFullYear();
      const nowMonth = nowTime.getMonth() + 1;
      let numberOfDaysElapsed = 0;
      for (let i = 1; i < nowMonth; i++) {
        const lastDate = new Date(nowYear, i, 0);
        numberOfDaysElapsed += lastDate.getDate();
      }
      numberOfDaysElapsed += nowTime.getDate();
      numberOfDaysElapsed += (nowYear - 1) * 365;
      const elapsedMinutes =
        numberOfDaysElapsed * 24 * 60 +
        nowTime.getHours() * 60 +
        nowTime.getMinutes() +
        90;

      //DatePickerの全ての経過日数・時間(分)を求める
      const pickerYear = dateValue.pickDate?.getFullYear();
      const pickerMonth = dateValue.pickDate?.getMonth() + 1;
      let pickerNumberOfDaysElapsed = 0;
      for (let i = 1; i < pickerMonth; i++) {
        const lastDate = new Date(pickerYear, i, 0);
        pickerNumberOfDaysElapsed += lastDate.getDate();
      }
      pickerNumberOfDaysElapsed += dateValue.pickDate?.getDate();
      pickerNumberOfDaysElapsed += (pickerYear - 1) * 365;
      let pickerElapsedMinutes = pickerNumberOfDaysElapsed * 24 * 60;

      //色を求める
      for (let i = 0; i < 48; i++) {
        if (
          pickerElapsedMinutes < elapsedMinutes &&
          elapsedMinutes <= pickerElapsedMinutes + 30
        ) {
          colorData[i] = nowColor;
        } else if (elapsedMinutes <= pickerElapsedMinutes) {
          colorData[i] = OKColor;
        }
        if (
          pickerElapsedMinutes + 30 <= elapsedMinutes ||
          format(dateValue.pickDate, "yyyy-MM-dd") >
            format(addDays(time, 1), "yyyy-MM-dd") ||
          (startDate.current > time &&
            format(dateValue.pickDate, "yyyy-MM-dd") ===
              format(addDays(time, 1), "yyyy-MM-dd"))
        ) {
          colorData[i] = NGColor;
        }
        pickerElapsedMinutes += 30;
      }
    }
    return colorData;
  };

  // GC後の商品で約定されていないもののステータスを「出来ず」に変更
  const updateGCOrderStatus = async () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );

    const afterGCItem: string[] = [];

    tableColor.forEach((value, index) =>{
      // GC対象のコマ情報を詰めていく
      if (value === "rgb(200,200,200)") {
        afterGCItem.push((index + 1).toString().padStart(2, "0"));
      }
    });
    if (afterGCItem.length !== 0) {
      return api
        .post(UPDATE_AFTER_GC_STATUS, {
          areaId: areaId,
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          itemArray: afterGCItem,
        })
        .then((res) => {
          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useLayoutEffect(() => {
    dateValue.setPickDate(time);
  }, []);

  const selectedBusinessModel =  useMemo(() => {
    const tmpBusinessModel = businessModel.filter((v) => v.areaId === areaId).map((v) => v.businessModel);
    // 市場取引を利用するビジネスモデルの場合、計画保存や価格調整などの機能を使用できるようにする
    if(tmpBusinessModel[0] === BUSINESS_MODEL.MARKET_TRAN ||
      tmpBusinessModel[0] === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN ) {
      setHiddenBusinessModel(false);
    } else {
      setHiddenBusinessModel(true);
    }
    return tmpBusinessModel[0];
  }, [areaId]);

  useEffect(() => {
    if(selectedBusinessModel === BUSINESS_MODEL.IKIGAI_DEMAND || selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND) {
      setTradingTypeId(Number(BUY_SELL_KIND.BUY));
    } else {
      setTradingTypeId(Number(BUY_SELL_KIND.SELL));
    }
  },[selectedBusinessModel]);

  useEffect(() => {
    const id = setInterval(() => {
      refleshMainData();
    }, 60000);

    return () => clearInterval(id);
  },[areaId, dateValue.pickDate]);

  useEffect(() => {
    if(!firstRenderFlag.current) {
      setIsLoaded(false);
      (async() => {
        if (time >= startDate.current) {
          setMaxDate(addDays(time, 1));
        } else {
          setMaxDate(time);
        }
        startDate.current = new Date(
          time.getFullYear(),
          time.getMonth(),
          time.getDate(),
          17
        );
  
        if (
          areaId !== "" &&
          areaId !== "00" &&
          format(maxDate, "yyyy-MM-dd HH:mm") >=
            format(dateValue.pickDate as Date, "yyyy-MM-dd HH:mm")
        ) {
            await setContractOrder();
            await updateGCOrderStatus();
            Promise.all([
              getIntradayData(),
              getAutoSetting(),
              getIntradayProduct(),
              getSalesPlan(),
              getProcurementPlan(),
              getLimitData(),
            ]).finally(() => {
              setIsLoaded(true);
              console.log("計画取得完了");
            });
          } else {
            setIsLoaded(true);
          }
      })();
        // オートスクロール
        if (
          format(dateValue.pickDate as Date, "yyy/MM/dd") ===
            format(time as Date, "yyy/MM/dd")
        ) {
          autoScrollPage("timeCell" + changeDateToTimeCd(time));
        }
    }
  }, [dateValue.pickDate, areaId]);

  useEffect(() => {
    if(firstRenderFlag.current) {
      if (time >= startDate.current) {
        setMaxDate(addDays(time, 1));
      } else {
        setMaxDate(time);
      }
      startDate.current = new Date(
        time.getFullYear(),
        time.getMonth(),
        time.getDate(),
        17
      );
      setIsLoaded(false);
      Promise.all([
        dicideSaveButtonVisibility(),
        getAreaList(),
      ]).finally(() => {
        if (
          areaId !== "" &&
          areaId !== "00" &&
          format(maxDate, "yyyy-MM-dd HH:mm") >=
            format(dateValue.pickDate as Date, "yyyy-MM-dd HH:mm")
        ) {
          (async() => {
            await setContractOrder();
            await updateGCOrderStatus();
            Promise.all([
              getIntradayData(),
              getAutoSetting(),
              getIntradayProduct(),
              getSalesPlan(),
              getProcurementPlan(),
              getLimitData(),
            ]).finally(() => {
              // 
            });
          })();
          }
        setIsLoaded(true);
        console.log("初回処理終了");
      });
      }
      firstRenderFlag.current = false;
  },[]);

  const refleshMainData = async() => {
    if (time >= startDate.current) {
      setMaxDate(addDays(time, 1));
    } else {
      setMaxDate(time);
    }
    startDate.current = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      17
    );
    if (
      areaId !== "" &&
      areaId !== "00" &&
      format(maxDate, "yyyy-MM-dd HH:mm") >=
        format(dateValue.pickDate as Date, "yyyy-MM-dd HH:mm")
    ) {
        await setContractOrder();
        await updateGCOrderStatus();
        getIntradayData();
        getAutoSetting();
        getIntradayProduct();
    }
  }

  // 入札制限取得
  const getLimitData = () => {
    // 市場取引を利用するビジネスモデルの場合のみ取得
    if (areaId !== "" && areaId !== "00" && 
    (selectedBusinessModel === BUSINESS_MODEL.MARKET_TRAN || selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN)) {
      return api
        .get(GET_BID_LIMIT, {
          params: {
            areaId: areaId,
            targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          },
        })
        .then((res) => {
          const bidLimit: bitLimmitReq = res.data.result;
          setBidLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    // 域外を利用するビジネスモデルの場合、入札制限は固定値
    } else if(selectedBusinessModel === BUSINESS_MODEL.IKIGAI_POWER ||
      selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER ||
      selectedBusinessModel === BUSINESS_MODEL.IKIGAI_DEMAND ||
      selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND
      ) {
      setBidLimit({
        area: Number(areaId),
        dayLimitBit: bidLimit.dayLimitBit.map((value) => {
          const data: bitLimitCells = {
            type: value.type,
            cells: value.cells.map((item) => ({
              upperPowerPrice: "999.99",
              lowerPowerPrice: "0.00",
            })),
          };
          return data;
        }),
      });
    }
  };

  // DBから取得したレスポンスの商品受付状況から市場情報テーブルに表示する記号を作成
  const createMarketDisplayData = (response: resHourBid) => {
    const tempDayBid = response.areaValue.dayBit;
    tempDayBid.forEach((dayBid) => {
      switch (dayBid.productStatus) {
        case "1":
          dayBid.productStatus = "〇";
          break;
        case "2":
          dayBid.productStatus = "◎";
          break;
        case "3":
          dayBid.productStatus = "✕";
          break;
        default:
          dayBid.productStatus = "";
      }
    });
    return tempDayBid;
  };

  // DBから取得したレスポンスの売買種別から入札状況テーブルに表示する文字列を作成
  const createTypeText = (type: string) => {
    let typeText: string;
    if (type === BUY_SELL_KIND.SELL) {
      typeText = languageContext.words.sell;
    } else if (type === BUY_SELL_KIND.BUY) {
      typeText = languageContext.words.buy;
    } else {
      typeText = "";
    }

    return typeText;
  };

  // DBから取得したレスポンスの注文ステータスから入札状況テーブルに表示する文字列を作成
  const createStatusText = (status: string) => {
    const AgreementContent = [
      languageContext.words.waiting_for_bid, //入札待ち
      languageContext.words.waiting_for_contract, //約定待ち
      languageContext.words.under_contract, //約定中
      languageContext.words.contracted, //"約定済"
      languageContext.words.deleted, //"取消済"
      languageContext.words.intraday_bid_failure, //入札失敗
      languageContext.words.unable_to_do, //出来ず
      languageContext.words.partial_contracted, //一部約定
      languageContext.words.delete_request, // 取消要求
      languageContext.words.delete_failed, // 取り消し失敗
    ];

    let statusText: string;

    switch (status) {
      case "0": //入札前
        statusText = AgreementContent[0];
        break;
      case "1": //入札済
        statusText = AgreementContent[1];
        break;
      case "2": //"約定済"
        statusText = AgreementContent[3];
        break;
      case "3": //取消要求
        statusText = AgreementContent[8];
        break;
      case "4": //"取消済";
        statusText = AgreementContent[4];
        break;
      case "5": //"入札失敗";
        statusText = AgreementContent[5];
        break;
      case "6": //取消失敗
        statusText = AgreementContent[9];
        break;
      case "7": //出来ず
        statusText = AgreementContent[6];
        break;
      default:
        statusText = "";
    }

    return statusText;
  };

  function ChartData(responseData: resHourBid) {
    if (responseData !== undefined) {
      responseData.areaValue.dayBit = createMarketDisplayData(responseData);
      setResponseMainData(responseData);
    }
  }

  // 販売計画取得
  const getSalesPlan = () => {
    // 域外（需要）を利用するビジネスモデルの場合、販売計画は取得せず計画値は全て0.00表示
    if(selectedBusinessModel === BUSINESS_MODEL.IKIGAI_DEMAND ||
      selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_IKIGAI_DEMAND) {
      const stringVolumes: string[] = new Array(48).fill(true).map((_) => "0.00");
      setPadSellingPlanText(stringVolumes);
    } else {
      return api
        .get(GET_SALES_DATA, {
          params: {
            areaId: areaId,
            targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          },
        })
        .then((res) => {
          const numVolumes: number[] = res.data.result.cellVolumes;
          const stringVolumes: string[] =
            numVolumes.length !== 0
              ? numVolumes.map((value) => value.toFixed(2))
              : new Array(48).fill(true).map((_) => "0.00");
          setPadSellingPlanText(stringVolumes);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  };

  // 調達計画取得
  const getProcurementPlan = () => {
    // 域外（発電）を利用するビジネスモデルの場合、調達計画は取得せず計画値は全て0.00表示
    const displayModel = businessModel.filter((v) => v.areaId === areaId).map((v) => v.businessModel);
    if(displayModel[0] === BUSINESS_MODEL.IKIGAI_POWER ||
      displayModel[0] === BUSINESS_MODEL.IKINAI_AND_IKIGAI_POWER) {
      const stringVolumes: string[] = new Array(48).fill(true).map((_) => "0.00");
      setPadProcurementPlanText(stringVolumes);
    } else {
      return api
        .get(GET_PROCUREMENT_DATA, {
          params: {
            areaId: areaId,
            targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
            businessModel: selectedBusinessModel,
          },
        })
        .then((res) => {
          const numVolumes: number[] = res.data.result.cellVolumes;
          const stringVolumes: string[] =
            numVolumes.length !== 0
              ? numVolumes.map((value) => value.toFixed(2))
              : new Array(48).fill(true).map((_) => "0.00");
          setPadProcurementPlanText(stringVolumes);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //販売計画のテキストフィールド
  const SellingPriceHandle = (inputBoxNumber: number, inputValue: string) => {
    const tempStringValues = padSellingPlanText.map((value) => value);
    tempStringValues[inputBoxNumber] = inputValue.replace(/-/g, "");
    setPadSellingPlanText(tempStringValues);
  };

  //調達計画のテキストフィールド
  const ProcurementPlanHandle = (
    inputBoxNumber: number,
    inputValue: string
  ) => {
    const tempStringValues = padProcurementPlanText.map((value) => value);
    tempStringValues[inputBoxNumber] = inputValue.replace(/-/g, "");
    setPadProcurementPlanText(tempStringValues);
  };

    // 入札可能な最初のコマ
    const [firstBidableTime, setFirstBidableTime] = useState<number>(0);

  // 各コマの入札可・不可情報
  const timeBidDisable = useMemo(
    () => {
      const disable: boolean[] = [];
      const bidableTime: number[] = [];
      if(responseAutoSettingData !== undefined) {
        for (let index = 0; index < 48; index++) {
          //const status = responseMainData.bidingValue.dayBit[index].contractStatus;
    
          const deadline = createStartDeleteTime(
            responseAutoSettingData.bidDeadline,
            (index + 1).toString().padStart(2, "0"),
            format(dateValue.pickDate as Date, "yyyy-MM-dd")
          );
    
          const tableColor = TableColor(
            "rgb(255,255,255)",
            "rgb(255,255,255)",
            "rgb(200,200,200)"
          );
    
          if (
            tableColor[index] === "rgb(200,200,200)" ||
            responseAutoSettingData.priceAdjust === "00"
          ) {
            disable.push(true);
          } else {
            if (
              // status !== CONTRACT_STATUS.waitingContract &&
              // status !== CONTRACT_STATUS.cancellationFailure &&
              // status !== CONTRACT_STATUS.waitingBid &&
              // status !== CONTRACT_STATUS.couldNot &&
              // status !== CONTRACT_STATUS.contracted &&
              format(deadline, "yyyy/MM/dd HH:mm") >
                format(time, "yyyy/MM/dd HH:mm")
            ) {
              disable.push(false);
            } else {
              disable.push(true);
            }
          }
        }
      } else {
        return new Array(48).fill(true).map((_) => true);
      }
            
      setFirstBidableTime(
        disable.every((v) => v === true)
        ? 0
        : disable.findIndex((v) => v === false) + 1);
      return disable;  
    }
  , [/*responseMainData,*/ responseAutoSettingData, dateValue.pickDate, time]);

  // 入札取消ボタンのdisable管理
  const deleteBidDisabled = useMemo(() => {
    const disable = [];
    const tableDisabled = TableDisabled();
    if(responseAutoSettingData !== undefined) {
      for (let index = 0; index < responseMainData.bidingValue.dayBit.length; index++) {
        const status = responseMainData.bidingValue.dayBit[index].contractStatus;
        const tableColor = TableColor(
          "rgb(255,255,255)",
          "rgb(255,255,255)",
          "rgb(200,200,200)"
        );
        if (
          tableColor[Number(responseMainData.bidingValue.dayBit[index].timeCd) - 1] === "rgb(200,200,200)" ||
          responseAutoSettingData?.priceAdjust === "00"
        ) {
          disable.push(true);
        } else {
          if (
            status === CONTRACT_STATUS.waitingContract ||
            (status === CONTRACT_STATUS.cancellationFailure &&
              tableDisabled[index] === true)
          ) {
            disable.push(false);
          } else {
            disable.push(true);
          }
        }
      }
    } else {
      return [];
    }
    return disable;
  },[responseMainData, responseAutoSettingData, dateValue.pickDate, time]);

  // 入札・一括入札ボタンのdisable管理
  const allBidDisable = useMemo(() => {
    return !timeBidDisable.some((v, index) => v === false);
  },[timeBidDisable]);
  // 一括入札取消ボタンのdisable管理
  const bulkDeleteBidDisable = () => {
    const tableDisabled = TableDisabled();
    const array = responseMainData.bidingValue.dayBit.filter(
      (value, index) =>
        (value.contractStatus === CONTRACT_STATUS.waitingContract ||
          value.contractStatus === CONTRACT_STATUS.cancellationFailure) &&
        tableDisabled[Number(responseMainData.bidingValue.dayBit[index].timeCd) - 1] === true
    );

    let disable = false;
    if (array.length === 0) {
      disable = true;
    } else {
      disable = false;
    }
    return disable;
  };

  //テーブルの非活性
  function TableDisabled () {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const tableDisabled = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,255,255)") {
        tableDisabled[i] = true;
      } else {
        tableDisabled[i] = false;
      }
    }
    return tableDisabled;
  };

  //ボタンの非活性
  const ButtonDisabled = () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const tableDisabled = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(200,200,200)" || priceAdjustment === "00") {
        tableDisabled[i] = false;
      } else {
        tableDisabled[i] = true;
      }
    }
    return tableDisabled;
  };

  //状況のテーブル
  const StatusTable = () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const statusTableContent = ["◎", "〇", "✕"];
    const statusTable = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,255,0)") {
        statusTable[i] = statusTableContent[1]; //yellow
      } else if (tableColor[i] === "rgb(255,255,255)") {
        statusTable[i] = statusTableContent[0]; //white
      } else if (tableColor[i] === "rgb(200,200,200)") {
        statusTable[i] = statusTableContent[2]; //gray
      }
    }
    return statusTable;
  };

  //約定状況のテーブル
  const AgreementStatusTable = () => {
    const tableColor = TableColor(
      "rgb(255,204,255)",
      "rgb(255,255,204)",
      "rgb(204,255,204)"
    );
    const AgreementContent = [
      languageContext.words.waiting_for_bid,
      languageContext.words.waiting_for_contract,
      languageContext.words.under_contract,
      languageContext.words.contracted,
      languageContext.words.deleted,
      languageContext.words.unable_to_do,
      languageContext.words.partial_contracted,
    ];
    const agreementTable = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,204,255)") {
        agreementTable[i] = AgreementContent[2]; //yellow
      } else if (tableColor[i] === "rgb(255,255,204)") {
        agreementTable[i] = AgreementContent[1]; //white
      } else if (tableColor[i] === "rgb(204,255,204)") {
        agreementTable[i] = AgreementContent[3]; //gray
      }
      if (i === 15 || i === 21) {
        agreementTable[i] = AgreementContent[3]; //gray
      }
    }
    return agreementTable;
  };

  //市場詳細モーダル
  const [openMarketDetails, setOpenMarketDetails] =
    useState<boolean>(false);
  //入札モーダル
  const [openBid, setOpenBid] = useState<boolean>(false);
  //個別削除モーダル
  const [openBidDelete, setOpenBidDelete] = useState<boolean>(false);
  //一括入札モーダル
  const [openBulkBit, setOpenBulkBit] = useState<boolean>(false);
  //一括取消モーダル
  const [openBulkUndo, setOpenBulkUndo] = useState<boolean>(false);
  //自動化設定モーダル
  const [openAutomationSettings, setOpenAutomationSettings] =
    useState<boolean>(false);
  //入札締切・自動削除モーダル
  const [openAutoDeleteSettings, setOpenAutoDeleteSettings] =
    useState<boolean>(false);
  //入札制限モーダル
  const [openBidLimitSetting, setOpenBidLimitSetting] =
    useState<boolean>(false);



  //エリアのハンドル
  const AreaHandleChange = (selectItem: number) => {
    setAreaId(selectItem.toString().padStart(2, "0"));
  };

  // 自動価格調整ON/OFF
  const autoBidding = async (): Promise<string> => {
    return api
      .post(AUTO_BIDDING, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        return Promise.resolve(res.data.resultCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //DBに設定内容を登録する
  const postAutoSetting = async (adjustId: string): Promise<string> => {
    return api
      .post(POST_AUTO_SETTING, {
        areaId: areaId,
        startTime: startTime,
        deleteTime: autoDelete,
        priceAdjust: adjustId,
        bidDeadline: deadlineTime,
        adjustCycle: adjustmentCycle,
        adjustValue: holdeAdjustmentAmount,
      })
      .then((res) => {
        return Promise.resolve(res.data.resultCode);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //DBから設定内容を取得する
  const getAutoSetting = async () => {    
    return api
      .get(GET_AUTO_SETTING, {
        params: { areaId: areaId },
      })
      .then((res) => {
        setDeadlineTime(res.data.result.bidDeadline);
        setPriceAdjustment(res.data.result.priceAdjust);
        setAutoDelete(res.data.result.deleteTime);
        setStartTime(res.data.result.startTime);
        setAdjustmentCycle(res.data.result.adjustCycle);
        setHoldeAdjustmentAmount(res.data.result.adjustValue);
        if (res.data.result.priceAdjust === "01") {
          setSwitchActivity(false);
          setInputNumberActivity(true);
        } else if (res.data.result.priceAdjust === "00") {
          setSwitchActivity(true);
          setInputNumberActivity(false);
        }
        setResponseAutoSettingData(res.data.result);
      })
      .catch((err) => console.log(err));
  };

  // エリアリストを取得
  const getAreaList = async () => {
    return api
      .get(GET_AREA_NAME_LIST)
      .then((res) => {
        setAreaList(res.data.result.map((v: any) => v.areaId));
        setAreaId(
          Number(areaId) === 0 || areaId === "00" || !res.data.result.some((v: any, index: any) => v.areaId === areaId)
            ? res.data.result[0].areaId.toString().padStart(2, "0")
            : areaId.padStart(2, "0")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 日付文字列を作成
  function createDateString (tradeDate: string, time: string) {
    const dateString = tradeDate + " " + time;
    return dateString;
  };

  // 商品IDを日時に変換
  function convertItemId (itemId: string, tradeDate: string) {
    let itemTime = new Date();
    timeData.forEach((time, index) => {
      if (itemId === (index + 1).toString().padStart(2, "0")) {
        itemTime = new Date(createDateString(tradeDate, time));
      }
    });

    return itemTime;
  };

  //入札締切・削除設定入力チェック
  const isInputSetting = (): boolean => {
    let result = false;

    //調整時間(調整開始時間 - 入札締切時間)
    const adjustTime =
      convertToTimeNum(startTime) - convertToTimeNum(deadlineTime);
    if (Number(startTime) > Number(deadlineTime)) {
      if (adjustTime > convertToAdjustCycleNum(adjustmentCycle)) {
        result = true;
      }
    }
    return result;
  };

  //開始時間、入札締切時間を数値に変換
  const convertToTimeNum = (timeId: string): number => {
    const initTime = 120;
    let time: number;

    if (timeId !== "00") {
      time = initTime + Number(timeId) * 60;
    } else {
      time = initTime;
    }
    return time;
  };

  //調整周期を数値に変換
  const convertToAdjustCycleNum = (timeId: string): number => {
    const initCycle = 10;
    let time: number;

    if (timeId !== "00") {
      time = initCycle + Number(timeId) * 10;
    } else {
      time = initCycle;
    }
    return time;
  };

  // 自動入札の開始時間ID、締切時間IDと対象商品の商品IDから、自動入札開始時刻、締切時刻を作成
  function createStartDeleteTime (
    timeId: string,
    itemId: string,
    tradeDate: string
  ) {
    //timeIdから開始時間を計算
    const startIdNum = 60 * (parseInt(timeId) + 2);
    const date = subMinutes(convertItemId(itemId, tradeDate), startIdNum);

    return date;
  };


  //調整周期のIDから表示する文字列を作成
  const createCycleStr = (id: string) => {
    let adjustCycleStr = "";

    for (let i = 0; i < AutomationSettingsList.adjustmentCycle.length; i++) {
      if (Number(id) === AutomationSettingsList.adjustmentCycle[i].id) {
        adjustCycleStr = AutomationSettingsList.adjustmentCycle[i].name;
        break;
      }
    }

    return adjustCycleStr;
  };

  //自動価格調整のスイッチのハンドル
  const AutomaticPriceAdjustmentHandle = async () => {
    let resPostAutoSetting;
    let resAutoBidding;
    setCheckDigOpen(false);
    if (switchActivity) {
      setSwitchActivity(false);
      setInputNumberActivity(true);
      setIsLoaded(false);
      resPostAutoSetting = await postAutoSetting("01");
      await getAutoSetting();
      resAutoBidding = await autoBidding();

      setIsLoaded(true);
      if(resPostAutoSetting === RESULT_CODE.SUCCESS && resAutoBidding === RESULT_CODE.SUCCESS) {
        setCheckDigMessage(languageContext.words.bid_automation_stop);
      } else {
        setCheckDigMessage(languageContext.words.bid_automation_stop_failed);
      }

    } else {
      setSwitchActivity(true);
      setInputNumberActivity(false);
      setIsLoaded(false);
      resPostAutoSetting = await postAutoSetting("00");
      await getAutoSetting();
      resAutoBidding = await autoBidding();
      setIsLoaded(true);
      if(resPostAutoSetting === RESULT_CODE.SUCCESS && resAutoBidding === RESULT_CODE.SUCCESS) {
        setCheckDigMessage(languageContext.words.bid_automation_start);
      } else {
        setCheckDigMessage(languageContext.words.bid_automation_start_failed);
      }
    };
    setCheckDigButtonType(ButtonType.OkOnly);
    setCheckDigOpen(true);
  };

  //自動化設定ON時に表示する文字の色を赤にする
  const AutomaticPriceAdjustmentTextColor = () => {
    let textColor = "rgb(0,0,0)";
    if (priceAdjustment === "00") {
      textColor = "rgb(255,0,0)";
    } else {
      textColor = "";
    }
    return textColor;
  };

  //ニューメリックアップダウンの活性非活性の判定
  const TableVisiblity = (tableDisabled: boolean) => {    
    return !tableDisabled || !inputNumberActivity;
  };

  //市場詳細入札モーダル開く
  const HandleOpenMarketDetails = () => setOpenMarketDetails(true);
  //市場詳細モーダル閉じる
  const HandleCloseMarketDetails = () => {
    setOpenMarketDetails(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札モーダル開く
  const HandleOpenBid = () => {
    // setCellNumber(firstBidableTime);
    leavePageContext.setBlockLeave(false);
    setOpenBid(true);
  };
  //入札モーダル閉じる
  const HandleCloseBid = () => {
    setOpenBid(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //個別削除モーダル開く
  const HandleOpenBidDelete = (buttonNumber: number) => {
    setBiddingCellInfo(responseMainData.bidingValue.dayBit[buttonNumber]);
    setOpenBidDelete(true);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //個別削除モーダル閉じる
  const HandleCloseBidDelete = () => {
    setOpenBidDelete(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //一括入札モーダル開く
  const HandleOpenBulkBit = () => setOpenBulkBit(true);
  //一括入札モーダル閉じる
  const HandleCloseBulkBit = () => setOpenBulkBit(false);
  //一括取消モーダル開く
  const HandleOpenBulkUndo = () => {
    leavePageContext.setBlockLeave(false);
    setOpenBulkUndo(true);
  };
  //一括取消モーダル閉じる
  const HandleCloseBulkUndo = () => {
    setOpenBulkUndo(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //自動化設定モーダル開く
  const HandleOpenAutomationSettings = () => setOpenAutomationSettings(true);
  //自動化設定モーダル閉じる
  const HandleCloseAutomationSettings = () => {
    setOpenAutomationSettings(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札締切・自動削除設定モーダル開く
  const HandleOpenAutoDeleteSettings = () => setOpenAutoDeleteSettings(true);
  //入札締切・自動削除設定モーダル閉じる
  const HandleCloseAutoDeleteSettings = () => {
    setOpenAutoDeleteSettings(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札制限モーダル開く
  const HandleOpenBidLimitSetting = () => setOpenBidLimitSetting(true);
  //入札制限モーダル閉じる
  const HandleCloseBidLimitSetting = () => setOpenBidLimitSetting(false);
  //カスタムダイアログのフラグ
  const [digOpen, setDigOpen] = useState<boolean>(false);
  //カスタムダイアログに表示するメッセージ
  const [dialogMessage, setDialogMessage] = useState<string>("");

  //入札自動化確認ダイアログのフラグ
  const [checkDigOpen, setCheckDigOpen] = useState<boolean>(false);
  //カスタムダイアログに表示するメッセージ
  const [checkDigMessage, setCheckDigMessage] = useState<string>("");
  const [checkDigButtonType, setCheckDigButtonType] = useState<string>("");

  // カスタムダイアろグ表示後処理
  const acceptHandler = () => {
    // なし
  };

  //販売・調達計画保存
  const insertSalesValues = async () => {
    return api
      .post(POST_ONEHOUR_PLAN, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
        salesValues: padSellingPlanText as unknown as number[],
        procurementValues: padProcurementPlanText as unknown as number[],
        businessModel: selectedBusinessModel,
      })
      .then((res) => {
          setDialogMessage(languageContext.words.save_sales_plan_procurement_plan);
          setDigOpen(true);
          leavePageContext.setBlockLeave(false);
        // }
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.cannot_save_sales_plan_procurement_plan
        );
        setDigOpen(true);
      });
  };

  //GC直前のコマのインデックスを取得
  const CreateEditableFirstTime = () => {
    const editableTableList = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    let editableFirstTime = 0;
    for (let i = 0; i < editableTableList.length; i++) {
      if (editableTableList[i] === "rgb(255,255,255)") {
        editableFirstTime = i;
        break;
      }
    }
    return editableFirstTime;
  };

  //ツールチップに表示する文字列を作成
  const createToolTipText = (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => {
    if (languageContext.mode === "jp") {
      return (
        convertToLocaleString(Number(lowerEnd)) +
        "～" +
        convertToLocaleString(Number(upperEnd)) +
        unit +
        "の範囲で入力してください。"
      );
    } else {
      return (
        "Enter in the range " +
        convertToLocaleString(Number(lowerEnd)) +
        " to " +
        convertToLocaleString(Number(upperEnd)) +
        " " +
        unit
      );
    }
  };

  //小数点第二位までかつカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  //保存ボタンのイベント
  const SaveButtonEvent = async () => {
    setIsLoaded(false);
    await insertSalesValues();
    setIsLoaded(true);
  };

  //左側のテーブルのレンダー
  const BidManagementTableRender = () => {
    const tableColor = TableColor(
      "",
      "",
      PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)"
    );
    const tableDisabled = TableDisabled();

    // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
    const processText = (
      index: number,
      text: string[],
      setText: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
      if (Math.floor(Number(text[index]) * 10) / 10 > PLAN_MAX_VALUE) {
        text[index] = PLAN_MAX_VALUE.toString();
      }
      const tempText = text.map((value) => value);
      tempText[index] = (Math.floor(Number(text[index]) * 10) / 10).toFixed(2);
      setText(tempText);
    };

    return (
      <TableContainer
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <StyledTable
          sx={{
            borderCollapse: "separate",
            minWidth: "100%",
            height: "100%",
          }}
        >
          <TableHead
            sx={{
              height: "auto",
              width: "100%",
              display: "block",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow sx={{ display: "flex" }}>
              <HeaderCell sx={{ width: "110px" }}>
                {languageContext.words.timeB}
              </HeaderCell>
              <TableRow sx={{ display: "block" }}>
                <HeaderCell
                  sx={{
                    display: "block",
                    width: "calc(100% - 1px)",
                  }}
                  style={{ height: "calc(20% + 1px)" }}
                >
                  {languageContext.words.market_status_entire_area}
                </HeaderCell>
                <TableRow sx={{ display: "flex" }}>
                  <HeaderCell
                    sx={{
                      width: "40px",
                    }}
                    title={languageContext.words.status_jokyo}
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.status_jokyo}
                    </Box>
                  </HeaderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.lowest_price_sell +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.lowest_price_sell}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh / h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.highest_price_buy +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.highest_price_buy}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.latest +
                      " " +
                      languageContext.words.contrac_price +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.latest}
                      <br />
                      {languageContext.words.contrac_price}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.average +
                      " " +
                      languageContext.words.contrac_price +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.average}
                      <br />
                      {languageContext.words.contrac_price}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                </TableRow>
              </TableRow>
              <TableRow sx={{ display: "block" }}>
                <HeaderCell
                  sx={{
                    display: "block",
                    width: "calc(100% - 1px)",
                  }}
                >
                  <span style={{ marginRight: "5px" }}>
                    {languageContext.words.in_house_status}
                  </span>
                  { !hiddenUser && !hiddenBusinessModel &&
                    <SaveDataButton
                      hidden={hiddenUser || hiddenBusinessModel}
                      disabled={!TableDisabled()[47] || hiddenUser}
                      message={languageContext.words.saved}
                      onAccept={SaveButtonEvent}
                    />
                  }
                </HeaderCell>
                <TableRow sx={{ display: "flex" }}>
                  <AmountHedderCell
                    title={
                      languageContext.words.sale +
                      " " +
                      languageContext.words.plan +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sale}
                      <br />
                      {languageContext.words.plan}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.supply +
                      " " +
                      languageContext.words.plan +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.supply}
                      <br />
                      {languageContext.words.plan}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.contract_quantity +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.contract_quantity}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.contract_quantity +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.contract_quantity}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                </TableRow>
              </TableRow>
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                width: "100%",
                height: "647px",
                display: "block",
                overflowX: "hidden",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  backgroundColor: "transparent",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "10px",
                },
              }}
            >
              {responseMainData.areaValue.dayBit.map((marketData, index) => (
                <TableRow key={responseMainData.areaValue.blockNumber}>
                  <TableCell
                    id={"timeCell" + index}
                    key={"timeCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "110px" }}
                  >
                    {timeData[index]}～{timeData[index + 1]}
                  </TableCell>
                  <TableCell
                    key={"statusCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "40px" }}
                  >
                    {marketData.productStatus}
                  </TableCell>
                  <AmountTableCell
                    key={"saleBitValueCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.saleBitValue)}
                    {/* 売り入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"saleBitLowPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.saleBitLowPrice)}
                    {/* 売り最安値 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"buyBitValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.buyBitValue)}
                    {/* 買い入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"buyBitHighPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.buyBitHighPrice)}
                    {/* 買い最高値 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"newExecutionPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.newExecutionPrice)}
                    {/* 最新約定価格 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"averageExecutionPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.averageExecutionPrice)}
                    {/* 平均約定価格 */}
                  </AmountTableCell>
                  <TableCell
                    key={"companySalesPlanCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "74px" }}
                  >
                    <TextField
                      type="number"
                      disabled={TableVisiblity(tableDisabled[index] && !hiddenBusinessModel)}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: PLAN_MAX_VALUE,
                          step: "0.1",
                          style: {
                            padding: 0,
                            textAlign: "right",
                            fontSize: "14px",
                            backgroundColor: tableColor[index],
                          },
                        },
                      }}
                      onFocus={(e) => {
                        if (padSellingPlanText[index] === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(e) => {
                        processText(
                          index,
                          padSellingPlanText,
                          setPadSellingPlanText
                        );
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        SellingPriceHandle(index, event.target.value);
                        leavePageContext.setBlockLeave(true);
                        changeFlag.current = true;
                      }}
                      value={padSellingPlanText[index]}
                    ></TextField>
                  </TableCell>
                  <TableCell
                    key={"companyProcurementPlanCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "74px" }}
                  >
                    <TextField
                      type="number"
                      disabled={TableVisiblity(tableDisabled[index] && !hiddenBusinessModel)}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: PLAN_MAX_VALUE,
                          step: "0.1",
                          style: {
                            padding: 0,
                            textAlign: "right",
                            fontSize: "14px",
                            backgroundColor: tableColor[index],
                          },
                        },
                      }}
                      onFocus={(e) => {
                        if (padProcurementPlanText[index] === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(e) => {
                        processText(
                          index,
                          padProcurementPlanText,
                          setPadProcurementPlanText
                        );
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        ProcurementPlanHandle(index, event.target.value);
                        leavePageContext.setBlockLeave(true);
                        changeFlag.current = true;
                      }}
                      value={padProcurementPlanText[index]}
                    ></TextField>
                  </TableCell>
                  <AmountTableCell
                    key={"companySaleBitValueCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseMainData.companyValue.dayBit[index].saleBitValue
                    )}
                    {/* 売り入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companySalesContractValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseMainData.companyValue.dayBit[index].salesContractValue
                    )}
                    {/* 売り約定量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companyBuyBitValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseMainData.companyValue.dayBit[index].buyBitValue
                    )}
                    {/* 買い入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companyBuyContractValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseMainData.companyValue.dayBit[index].buyContractValue
                    )}
                    {/* 買い約定量 */}
                  </AmountTableCell>
                </TableRow>
              ))}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      </TableContainer>
    );
  };

  //入札状況のテーブルのレンダー
  const BiddingStatusTableRender = () => {
    const agreementTableData = AgreementStatusTable();
    const buttonDisabled = ButtonDisabled();
    const tableBodyColor = TableColor(
      "",
      "",
      PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)"
    );
    const contractTableColor = TableColor(
      "rgb(38,181,246)",
      "rgb(255,156,10)",
      "rgb(150,150,150)"
    );

    return (
      <TableContainer
        sx={{
          minWidth: "100%",
          height: "100%",
        }}
      >
        <StyledTable
          sx={{
            minWidth: "100%",
            height: "100%",
            borderCollapse: "separate",
          }}
          aria-label="spanning table"
        >
          <TableHead
            sx={{
              height: "auto",
              width: "100%",
              display: "block",
              position: "sticky",
              overflow: "auto",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow sx={{ display: "block" }}>
              <TableRow sx={{ display: "flex" }}>
                <HeaderCell sx={{ width: "561px", borderRight: "none"}}>
                  {languageContext.words.details_of_bid_status}
                </HeaderCell>
                <HeaderCell sx={{ width: "166px", borderLeft: "none" }}>
                  <StyledButton
                    variant="outlined"
                    onClick={HandleOpenBid}
                    disabled={allBidDisable || 
                      !TableDisabled()[47] ||
                      !buttonDisabled[47] || hiddenUser}
                    sx={{ margin: "0px 0px 0px 13px" }}
                  >
                    {languageContext.words.bidding}
                  </StyledButton>
                  {Bid(
                    openBid,
                    HandleCloseBid,
                    firstBidableTime,
                    bidLimit as bitLimmitReq,
                    upperSellVolume,
                    upperBuyVolume,
                    convertIdToAreaName(areaId),
                    areaId,
                    format(dateValue.pickDate as Date, "yyyy-MM-dd"),
                    setDigOpen,
                    setDialogMessage,
                    getIntradayData,
                    getAutoSetting,
                    responseMainData.bidingValue.dayBit,
                    createToolTipText,
                    selectedBusinessModel,
                    tradingTypeId,
                    setTradingTypeId,
                  )}
                </HeaderCell>
              </TableRow>
              <TableRow sx={{ display: "flex" }}>
                <HeaderCell sx={{ width: "110px" }}>
                  {languageContext.words.bidding_time_jikoku}
                </HeaderCell>
                <AmountHedderCell title={languageContext.words.salebuy_type}>
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.salebuy_type}
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.bid_price +
                    " " +
                    "(" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.bid_price}
                    <br />({languageContext.words.yen_kwh})
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.contrac_price +
                    " " +
                    "(" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contrac_price}
                    <br />({languageContext.words.yen_kwh})
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={languageContext.words.bidding_volume + " " + "(MWh/h)"}
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.bidding_volume}
                    <br />
                    (MWh/h)
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.contract_quantity + " " + "(MWh/h)"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contract_quantity}
                    <br />
                    (MWh/h)
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={languageContext.words.contract_status_jokyo}
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contract_status_jokyo}
                  </Box>
                </AmountHedderCell>
                <HeaderCell sx={{ width: "166px" }}>
                  <Box sx={{ display: "flex" }}>
                    <StyledButton
                      disabled={
                        !TableDisabled()[47] ||
                        !buttonDisabled[47] ||
                        allBidDisable ||
                        hiddenUser
                      }
                      variant="outlined"
                      onClick={HandleOpenBulkBit}
                      sx={{ margin: "0px 0px 0px 5px" }}
                    >
                      {languageContext.words.bulk_bidding}
                    </StyledButton>
                    {BulkBit(
                      TableColor,
                      openBulkBit,
                      HandleCloseBulkBit,
                      responseMainData.areaValue.dayBit,
                      responseMainData.bidingValue.dayBit,
                      format(dateValue.pickDate as Date, "yyyy-MM-dd"),
                      areaId,
                      bidLimit as bitLimmitReq,
                      upperSellVolume,
                      upperBuyVolume,
                      getIntradayData,
                      getAutoSetting,
                      setDigOpen,
                      setDialogMessage,
                      timeBidDisable,
                      hiddenUser,
                      selectedBusinessModel,
                      createToolTipText,
                    )}
                    <StyledButton
                      disabled={
                        !TableDisabled()[47] ||
                        bulkDeleteBidDisable() ||
                        !buttonDisabled[47] ||
                        hiddenUser
                      }
                      variant="outlined"
                      onClick={HandleOpenBulkUndo}
                      sx={{ margin: "0px 0px 0px 5px" }}
                    >
                      {languageContext.words.bulk_revocation}
                    </StyledButton>
                    {BulkUndo(
                      openBulkUndo,
                      HandleCloseBulkUndo,
                      CreateEditableFirstTime(),
                      TableColor(
                        "rgb(255,255,255)",
                        "rgb(255,255,255)",
                        "rgb(200,200,200)"
                      ),
                      responseMainData.bidingValue.dayBit,
                      responseMainData.areaValue.dayBit,
                      format(dateValue.pickDate as Date, "yyyy-MM-dd"),
                      areaId,
                      setDigOpen,
                      setDialogMessage,
                      getIntradayData,
                      getAutoSetting,
                      hiddenUser,
                      createTypeText,
                      createStatusText,
                    )}
                  </Box>
                </HeaderCell>
              </TableRow>
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                width: "100%",
                height: "646px",
                display: "block",
                overflowX: "hidden",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  backgroundColor: "transparent",
                  height: "10px",
                  borderLeftStyle: "solid",
                  borderLeftColor: PaletteMode === "light" ? "#d3d3d3" : "#808080",
                  borderLeftWidth: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "10px",
                },
              }}
            >
              {responseMainData.bidingValue.dayBit.length !== 0 && responseMainData.bidingValue.dayBit.map((bidData, index) => {
                return (
                  <TableRow key={responseMainData.bidingValue.blockNumber}>
                    <TableCell
                      key={"timeCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                      sx={{ width: "110px" }}
                    >
                      {timeData[Number(bidData.timeCd) - 1]}～{timeData[Number(bidData.timeCd)]}
                    </TableCell>
                    <TableCell
                      key={"typeCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                      sx={{ width: "74px" }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? createTypeText(bidData.type)
                        : ""}
                    </TableCell>
                    <AmountTableCell
                      key={"bidPriceCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? convertToLocaleString(bidData.bidPrice)
                        : "0.00"}
                      {/* 入札価格 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"contractPriceCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                    >
                      {convertToLocaleString(bidData.contractPrice)}
                      {/* 約定価格 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"bidVolumeCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? convertToLocaleString(bidData.bidValue)
                        : "0.00"}
                      {/* 入札量 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"contractValueCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                    >
                      {convertToLocaleString(bidData.contractValue)}
                      {/* 約定量 */}
                    </AmountTableCell>
                    <TableCell
                      key={"contractStatusCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                      sx={{ width: "74px" }}
                      title={createStatusText(bidData.contractStatus)}
                    >
                      <Box
                        style={{
                          color:
                            bidData.contractStatus ===
                            CONTRACT_STATUS.contracted
                              ? "orange"
                              : "",
                        }}
                        sx={{
                          width: "70px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {createStatusText(bidData.contractStatus)}
                      </Box>
                    </TableCell>
                    <TableCell
                      key={"bidProcessingCell" + index}
                      style={{ backgroundColor: tableBodyColor[Number(bidData.timeCd) - 1] }}
                      sx={{ width: "166px" }}
                    >
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <StyledButton
                          variant="outlined"
                          onClick={() => {
                            HandleOpenBidDelete(index);
                            leavePageContext.setBlockLeave(false);
                          }}
                          disabled={deleteBidDisabled[index] || hiddenUser}
                          sx={{
                            margin: "0px 0px 0px 13px",
                            whiteSpace: "nowrap",
                          }}
                          title={languageContext.words.cancellation}
                        >
                          <Box
                            sx={{
                              maxWidth: "52px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {languageContext.words.cancellation}
                          </Box>
                        </StyledButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      </TableContainer>
    );
  };

  return (
    <>
    <ScrollSync>
      <div
        //ダークモード対応
        className={`cn-main-display ${
          PaletteMode === "dark" ? "dark" : "light"
        }`}
        style={{ display: "flex" }}
      >
        <Box sx={{ width: "59%", height: "100%", margin: "0px 10px 0px 0px" }}>
          <Box sx={{ width: "100%", height: "105px" }}>
            <Stack spacing={1} direction="row">
              <CustomSelectList
                label={""}
                value={areaId}
                options={areaOptions(areaList, false)}
                onChange={(e: number) => AreaHandleChange(e)}
              />
              <GlobalDatePicker isDipsTodayBtn={true} maxDate={maxDate} />
            </Stack>
            <Stack
              spacing={1}
              direction="row"
              style={{ margin: "20px 0px 0px 0px" }}
            >
              <label>*{languageContext.words.example_one_hour}</label>
            </Stack>
          </Box>
          <Box sx={{ width: "100%", height: "88%" }}>
            {BidManagementTableRender()}
          </Box>
        </Box>
        <Box sx={{ width: "41%", height: "100%", margin: "0px 0px 0px 10px" }}>
          <Box sx={{ width: "100%", height: "105px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ padding: "20px 0px 0px 0px" }}
            >
              <StyledButton
                // disabled={!TableDisabled()[47]}
                onClick={() => {
                  HandleOpenMarketDetails();
                  leavePageContext.setBlockLeave(false);
                }}
                variant="outlined"
              >
                {languageContext.words.market_details}
              </StyledButton>
              {MarketDetails(
                openMarketDetails,
                HandleCloseMarketDetails,
                areaId
              )}

              {!hiddenBusinessModel &&
                <StyledButton
                  variant="outlined"
                  onClick={HandleOpenBidLimitSetting}
                >
                  {languageContext.words.bidding_limit}
                </StyledButton>
              }
              {BidLimitSetting(
                openBidLimitSetting,
                HandleCloseBidLimitSetting,
                bidLimit as bitLimmitReq,
                setBidLimit,
                areaId,
                hiddenUser,
                selectedBusinessModel,
              )}

              {!hiddenBusinessModel &&
                <StyledButton
                  disabled={switchActivity || hiddenUser }
                  variant="outlined"
                  onClick={() => {
                    HandleOpenAutomationSettings();
                    leavePageContext.setBlockLeave(false);
                  }}
                >
                  {languageContext.words.automatic_setting}
                </StyledButton>
              }
              {AutomationSettings(
                AutomationSettingsList,
                openAutomationSettings,
                HandleCloseAutomationSettings,
                areaId,
                setSwitchActivity,
                startTime,
                autoDelete,
                priceAdjustment,
                deadlineTime,
                adjustmentCycle,
                holdeAdjustmentAmount,
                setDeadlineTime,
                setPriceAdjustment,
                setAutoDelete,
                setStartTime,
                setAdjustmentCycle,
                setHoldeAdjustmentAmount,
                hiddenUser
              )}
              <StyledButton
                disabled={switchActivity || hiddenUser }
                variant="outlined"
                onClick={() => {
                  HandleOpenAutoDeleteSettings();
                  leavePageContext.setBlockLeave(false);
                }}
              >
                {languageContext.words.automatic_delete_deadline_setting}
              </StyledButton>
              {AutoDeleteSettings(
                AutomationSettingsList,
                openAutoDeleteSettings,
                HandleCloseAutoDeleteSettings,
                areaId,
                setSwitchActivity,
                startTime,
                autoDelete,
                priceAdjustment,
                deadlineTime,
                adjustmentCycle,
                holdeAdjustmentAmount,
                setDeadlineTime,
                setPriceAdjustment,
                setAutoDelete,
                setStartTime,
                setAdjustmentCycle,
                setHoldeAdjustmentAmount,
                getIntradayData,
                getAutoSetting,
                hiddenUser
              )}
            </Stack>
            {!hiddenBusinessModel &&
              <Stack direction="row" style={{ padding: "15px 0px 5px 0px" }}>
                <label
                  style={{
                    color: "rgb(150, 150, 150)",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {languageContext.words.cycle}
                </label>
                <label
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    // width: "50px",
                  }}
                >
                  {createCycleStr(adjustmentCycle)}
                </label>
                <label
                  style={{
                    color: "rgb(150, 150, 150)",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    width: languageContext.mode === "en" ? "143px" : "70px",
                    paddingLeft: "5px",
                  }}
                >
                  {languageContext.words.adjustment_amount_comma}
                </label>
                <label
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {holdeAdjustmentAmount.toFixed(2)}(
                  {languageContext.words.yen_kwh})
                </label>
                <label
                  style={{
                    color: "rgb(150, 150, 150)",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                  }}
                >
                  {languageContext.words.status_jotai_colon}
                </label>
                <label
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    width: "150px",
                    color: AutomaticPriceAdjustmentTextColor(),
                  }}
                >
                  {priceAdjustment === "00"
                    ? languageContext.words.auto_bidding
                    : languageContext.words.auto_bidding_off}
                </label>
                <FormControlLabel
                  sx={{
                    marginLeft: languageContext.mode === "en" ? "2%" : "18%",
                    marginRight: "0px",
                    fontSize: "14px",
                    visibility: hiddenUser ? "hidden" : "visible",
                    width: languageContext.mode === "en" ? "155px" : "157px",
                  }}
                  control={
                    <Switch
                      disabled={!TableDisabled()[47] || hiddenUser}
                      onChange={() => {
                        if (switchActivity) {
                          setCheckDigOpen(true);
                          setCheckDigMessage(languageContext.words.auto_bidding_stop_check_msg);
                          setCheckDigButtonType(ButtonType.OkCancel);
                        } else {
                          if (isInputSetting()) {
                            setCheckDigOpen(true);
                            setCheckDigMessage(languageContext.words.auto_bidding_start_check_msg);
                            setCheckDigButtonType(ButtonType.OkCancel);
                          } else {
                            setSwitchActivity(false);
                            setDialogMessage(
                              languageContext.words.bid_automation_start_failed +
                                "\n" +
                                languageContext.words.reconsider_automatic_setting
                            );
                            setDigOpen(true);
                          }
                        }
                      }}
                      checked={switchActivity}
                      style={{ fontSize: "14px" }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px" }}>
                      {languageContext.words.automation_setting_enabled}
                    </Typography>
                  }
                  color="primary"
                  style={{ fontSize: "14px" }}
                />
                <CustomDialog
                  title={languageContext.words.auto_bidding_check_title}
                  message={checkDigMessage}
                  buttonType={checkDigButtonType}
                  open={checkDigOpen}
                  onAccept={checkDigButtonType === ButtonType.OkCancel 
                    ? AutomaticPriceAdjustmentHandle
                    : acceptHandler
                  }
                  onClose={() => setCheckDigOpen(false)}
                />
              </Stack>
            }
          </Box>
          <Box sx={{ width: "100%", height: "88%" }}>
            {BiddingStatusTableRender()}
            {BidDelete(
              openBidDelete,
              HandleCloseBidDelete,
              biddingCellInfo,
              areaId,
              convertIdToAreaName(areaId),
              format(dateValue.pickDate as Date, "yyyy-MM-dd"),
              setDigOpen,
              setDialogMessage,
              getIntradayData,
              getAutoSetting
            )}
          </Box>
          <CustomDialog
            title={languageContext.words.one_hour_ago_bidding}
            message={dialogMessage}
            buttonType={ButtonType.OkOnly}
            open={digOpen}
            onAccept={acceptHandler}
            onClose={() => setDigOpen(false)}
          />
        </Box>
      </div>
    </ScrollSync>
    <CustomModalProgress open={!isLoaded} />
    </>
  );
};

export default IntradayBidding;
