import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CustomModal from "../../common/customComponents/CustomModal";
import TextField from "@mui/material/TextField";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import Grid from "@mui/material/Grid";
import { LanguageContext } from "../../common/localization/localization";
import { BidingCell } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import axios, { AxiosError } from "axios";
import { TimeTable } from "./CreateTime";
import { PaletteContext } from "../../common/paletteMode";
import { useAuthedApi } from "../../../common/axios";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { BUSINESS_MODEL, BUY_SELL_KIND, ERR_CODE_SOAP_ERR, RESULT_CODE } from "../../../common/constant";
import { bitLimmitReq } from "../../../types/jepx/OneHourAdvanceBidPlan";

//エリアと入札日のグリッドのコンテナ
const GetGridConStyle = () => {
  const { PaletteMode } = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#2e2e2e" : "rgb(245, 245, 245)";
};
const GridContainer = styled(Grid)`
  && {
    padding: 10px;
    border-radius: 5px;
    background-color: ${GetGridConStyle};
  }
`;

//販売種別、入札価格、入札量のグリッドのコンテナ
const InputGridContainer = styled(Grid)`
  && {
    border-radius: 5px;
  }
`;

//アイテムグリッド
const ItemGrid = styled(Grid)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

//内容を表示するグリッド
const TitleGrid = styled(ItemGrid)`
  && {
    color: rgb(150, 150, 150);
  }
`;

//内容を表示するグリッド
const ContentGrid = styled(ItemGrid)`
  && {
    font-weight: 700;
  }
`;

const POST_INTRADAY_BID = "/postIndividualBid"; //入札値登録
const INTRADAY_BIDDING = "/bidIntraday"; //入札実行

//個別入札のモーダル
const Bid = (
  open: boolean,
  HaldleClose: () => void,
  firstBidableTime: number,
  limit: bitLimmitReq,
  upperSellVolume: string[],
  upperBuyVolume: string[],
  areaName: string,
  areaId: string,
  targetDate: string,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  getIntradayData: () => Promise<void>,
  getAutoSetting: () => Promise<void>,
  dayBid: BidingCell[],
  createToolTipText: (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => string,
  selectedBusinessModel: string,
  tradingTypeId: number,
  setTradingTypeId: React.Dispatch<React.SetStateAction<number>>,
) => {

  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const api = useAuthedApi();

  //30分刻みの時間の文字列の配列
  const timeData = TimeTable();
  const [selectedTime, setSelectedTime] = useState<number>(0);

  //販売種別のセレクトボックス
  const [tradingType, setTradingType] = useState<string>("");
  //入札価格のテキストフィールド
  const [inputBidPrice, setInputBidPrice] = useState<string>(
    // 入札可能なコマ数が0の場合firstBidableTimeが0になる
    firstBidableTime !== 0
    ? limit?.dayLimitBit[1].cells[firstBidableTime - 1].lowerPowerPrice
    : "0.00"
  );
  //入札量のテキストフィールド
  const [inputBidAmount, setInputBidAmount] = useState<string>(
    "0.00"
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  //入札価格のテキストフィールドの最低入力値
  const [minInputBidPrice, setMinInputBidPrice] = useState<number>(
    firstBidableTime !== 0
    ? Number(limit?.dayLimitBit[1].cells[firstBidableTime - 1].lowerPowerPrice)
    : 0
  );
  //入札価格のテキストフィールドの最大入力値
  const [maxInputBidPrice, setMaxInputBidPrice] = useState<number>(
    firstBidableTime !== 0
    ? Number(limit?.dayLimitBit[1].cells[firstBidableTime - 1].upperPowerPrice)
    : 0
  );
  //入札量のテキストフィールドの最低入力値
  const [minInputBidAmount, setMinInputBidAmount] = useState<number>(
    0
  );
  //入札量のテキストフィールドの最大入力値
  const [maxInputBidAmount, setMaxInputBidAmount] = useState<number>(
    firstBidableTime !== 0
    ? Number(upperSellVolume[firstBidableTime - 1])
    : 0
  );

  //入札値が制限内か保持する
  const whithinRangeValue = useRef<boolean>(true);
  //入札実行下かのフラグ
  const executeFlag = useRef<boolean>(false);

  useEffect(() => {
    resetData(tradingTypeId, selectedTime);
  }, [open]);

  const resetData = (tradingTypeId: number, selectedTime: number) => {
    if (tradingTypeId.toString() === BUY_SELL_KIND.SELL) {
      setInputBidPrice("0.00");
      setInputBidAmount("0.00");
      setTradingType(languageContext.words.sell);
      setMinInputBidPrice(
        Number(limit?.dayLimitBit[1].cells[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ].lowerPowerPrice)
      );
      setMaxInputBidPrice(
        Number(limit?.dayLimitBit[1].cells[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ].upperPowerPrice)
      );
      setMinInputBidAmount(0);
      setMaxInputBidAmount(
        Number(upperSellVolume[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ])
      );
    } else {
      setInputBidPrice("0.00");
      setInputBidAmount("0.00");
      setTradingType(languageContext.words.buy);
      setMinInputBidPrice(
        Number(limit?.dayLimitBit[0].cells[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ].lowerPowerPrice)
      );
      setMaxInputBidPrice(
        Number(limit?.dayLimitBit[0].cells[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ].upperPowerPrice)
      );
      setMinInputBidAmount(0);
      setMaxInputBidAmount(
        Number(upperBuyVolume[
          firstBidableTime !== 0
          ? selectedTime + firstBidableTime - 1
          : 0
          ])
      );
    }
  };

  //販売種別のセレクトボックス
  const TradingTypeHandleChange = (selectItem: number) => {
    setTradingTypeId(selectItem);
    resetData(selectItem, selectedTime);
  };

  const BiddingTimeOptions = useCallback(() => {
    const timeList = [];

    for(let i = 0; i <= 48 - firstBidableTime; i++) {
      timeList.push({id: i, name: timeData[i + firstBidableTime - 1] +
        "～" +
        timeData[firstBidableTime + i]});
    }
    return timeList;
  },[firstBidableTime]);

  const BiddingTimeHandleChange = (index: number) => {
    setSelectedTime(index);
    resetData(tradingTypeId, index);
  }

  //入札価格のセレクトボックス
  const BidPriceHandle = (inputValue: string) => {
    setInputBidPrice(inputValue.replace(/-/g, ""));
  };

  const dispDialog = () => {
    setDialogMessage(
      languageContext.words.outside_bid_limit +
        "\n" +
        languageContext.words.reconsider_bidding
    );
    setDigOpen(true);
  };

  const handleOutBidPrice = (
    inputValue: string,
    lower: number,
    upper: number
  ) => {
    processText(inputBidPrice, setInputBidPrice);
    if (lower > Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else if (upper < Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else {
      whithinRangeValue.current = true;
    }
  };

  //入札量のセレクトボックス
  const BidAmountHandle = (inputValue: string) => {
    setInputBidAmount(inputValue.replace(/-/g, ""));
  };

  const handleOutBidAmount = (
    inputValue: string,
    lower: number,
    upper: number
  ) => {
    let tempText = inputBidAmount;
    tempText = (Math.floor(Number(tempText) * 10) / 10).toFixed(2);
    setInputBidAmount(tempText);
    if (lower > Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else if (upper < Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else {
      whithinRangeValue.current = true;
    }
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let tempText = text;
    tempText = Number(text).toFixed(2);
    setText(tempText);
  };

  //datePecker
  const dateValue = useContext(DateContext);

  //入札日の出力とnullチェックを行う
  const CreateBidDate = () => {
    let bidDate = "Date get error";
    if (dateValue != null) {
      bidDate = languageContext.convertDateToLocalizedStringPoorAcu(
        dateValue.pickDate as Date
      ) as string;
    }
    return bidDate;
  };

  //売買種別IDを作成
  const createBuySellNumber = (type: string) => {
    let typeNum = 1;
    if (type === languageContext.words.sell) {
      typeNum = 1;
    } else if (type === languageContext.words.buy) {
      typeNum = 2;
    }

    return typeNum;
  };

  //DBに入札情報を登録する
  async function postIndividualBid(): Promise<string>{
    return api
      .post(POST_INTRADAY_BID, {
        targetDate: targetDate,
        areaId: areaId,
        itemId: (selectedTime + firstBidableTime).toString().padStart(2, "0"),
        buysellKind: createBuySellNumber(tradingType),
        orderPrice: inputBidPrice,
        orderQu: inputBidAmount,
        businessModel: selectedBusinessModel
      })
      .then((res) => {
        if(res.data.resultCode === RESULT_CODE.EXCESS_QUANTITY) {
          dispDialog();
        }
        return Promise.resolve(res.data.resultCode);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.unable_bid);
        setDigOpen(true);
      });
  };

  //一時間前入札APIを呼び出す
  const bidIntraday = async () => {
    await api
      .get(INTRADAY_BIDDING, {
        params: {
          targetDate: targetDate,
          areaId: areaId,
          itemId: (selectedTime + firstBidableTime).toString().padStart(2, "0"),
        },
      })
      .then((res) => {
        setDialogMessage(languageContext.words.execute_bidding);
        setDigOpen(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === ERR_CODE_SOAP_ERR) {
          setDialogMessage(languageContext.words.could_not_communicate_jepx);
        } else {
          setDialogMessage(languageContext.words.unable_bid);
        }
        setDigOpen(true);
      });
  };

  //モーダルを閉じる処理
  const ModalCloseEvent = () => {
    if (whithinRangeValue.current || executeFlag.current === false) {
      HaldleClose();
    } else {
      dispDialog();
    }
    executeFlag.current = false;
  };

  //入札実行ボタン
  const BidButtonEvent = async () => {
    executeFlag.current = true;
    if (
      whithinRangeValue.current &&
      Number(inputBidAmount) !== 0 &&
      Number(inputBidPrice) !== 0
    ) {
      setIsLoaded(false);
      // DBへの入札要求情報登録が成功したら、JEPX一時間前入札APIを叩く
      const resultCode = await postIndividualBid();
      if(resultCode === RESULT_CODE.SUCCESS) {
        await bidIntraday();
      };
      Promise.all([
        getIntradayData(),
        getAutoSetting(),
      ]).finally(() => {
        setIsLoaded(true);
      })
    } else {
      dispDialog();
    }
  };

  const BuySellDisable = (): boolean => {
    let disable = true;
    if(selectedBusinessModel === BUSINESS_MODEL.MARKET_TRAN ||
    selectedBusinessModel === BUSINESS_MODEL.IKINAI_AND_MARKET_TRAN) {
      disable = false;
    }
    return disable;
  }

  //入札
  return (
    <>
      <CustomModal
        title={languageContext.words.new_bid}
        open={open}
        onAccept={() => BidButtonEvent()}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.bid_execution}
      >
        <Box sx={{ height: "150px", width: "690px" }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <GridContainer container>
                <TitleGrid item>{languageContext.words.area}</TitleGrid>
                <ContentGrid item>{areaName}</ContentGrid>
              </GridContainer>
            </Grid>
            <Grid item xs={3} sx={{ alignItems: "center" }}>
              <GridContainer container>
                <TitleGrid item>
                  {languageContext.words.bidding_date_and_time}
                </TitleGrid>
                <ContentGrid item>{CreateBidDate()}</ContentGrid>
              </GridContainer>
            </Grid>
            <Grid sx={{display: "flex", marginTop: "15px"/*width: "80%"*/}}>
              <Grid sx={{marginLeft: "20px", marginRight: "14px"}}>
                <InputGridContainer container>
                  <TitleGrid item>{languageContext.words.bidding_time_zone}</TitleGrid>
                  <Grid item sx={{ margin: "-8px" }}>
                    <CustomSelectList
                      maxWidth = {100}
                      label={languageContext.words.bidding_time_zone}
                      value={selectedTime}
                      options={BiddingTimeOptions()}
                      onChange={(index: number) => BiddingTimeHandleChange(index)}
                    />
                  </Grid>
                </InputGridContainer>
              </Grid>
              <Grid item sx={{ alignItems: "center", marginRight: "6px"}}>
                <InputGridContainer container>
                  <TitleGrid item>{languageContext.words.salebuy_type}</TitleGrid>
                  <Grid item sx={{ margin: "-8px" }}>
                    <CustomSelectList
                      maxWidth = {100}
                      disabled = {BuySellDisable()}
                      label={languageContext.words.salebuy_type}
                      value={tradingTypeId}
                      options={[
                        { id: 1, name: languageContext.words.sell },
                        { id: 2, name: languageContext.words.buy },
                      ]}
                      onChange={TradingTypeHandleChange}
                    />
                  </Grid>
                </InputGridContainer>
              </Grid>
              <Grid item sx={{ alignItems: "center", marginRight: "6px"}}>
                <InputGridContainer container>
                  <TitleGrid item>
                    {languageContext.words.bid_price}(
                    {languageContext.words.yen_kwh})
                  </TitleGrid>
                  <Grid item>
                    <TextField
                      title={createToolTipText(
                        limit?.dayLimitBit[
                          tradingType === languageContext.words.sell ? 1 : 0
                        ].cells[
                          firstBidableTime !== 0
                          ? selectedTime + firstBidableTime - 1
                          : 0
                        ].lowerPowerPrice,
                        limit?.dayLimitBit[
                          tradingType === languageContext.words.sell ? 1 : 0
                        ].cells[
                          firstBidableTime !== 0
                          ? selectedTime + firstBidableTime - 1
                          : 0].upperPowerPrice,
                        languageContext.words.yen_kwh
                      )}
                      type="number"
                      sx={{ width: "180px" }}
                      InputProps={{
                        inputProps: {
                          step: "0.01",
                          min: minInputBidPrice,
                          max: maxInputBidPrice,
                          style: { padding: "8.5px 14px", textAlign: "right" },
                        },
                      }}
                      value={inputBidPrice}
                      onFocus={(e) => {
                        if (inputBidPrice === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(event) =>
                        handleOutBidPrice(
                          event.target.value,
                          minInputBidPrice,
                          maxInputBidPrice
                        )
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => BidPriceHandle(event.target.value)}
                    ></TextField>
                  </Grid>
                </InputGridContainer>
              </Grid>
              <Grid item sx={{ alignItems: "center", marginRight: "6px"}}>
                <InputGridContainer container>
                  <TitleGrid item>
                    {languageContext.words.bidding_volume}(MWh/h)
                  </TitleGrid>
                  <Grid item>
                    <TextField
                      title={createToolTipText(
                        "0.00",
                        tradingType === languageContext.words.sell 
                        ? upperSellVolume[selectedTime + firstBidableTime - 1] 
                        : upperBuyVolume[selectedTime + firstBidableTime - 1],
                        "MWh/h"
                      )}
                      type="number"
                      sx={{
                        width: "180px",
                      }}
                      InputProps={{
                        inputProps: {
                          min: minInputBidAmount,
                          max: maxInputBidAmount,
                          step: "0.1",
                          style: { padding: "8.5px 14px", textAlign: "right" },
                        },
                      }}
                      value={inputBidAmount}
                      onFocus={(e) => {
                        if (inputBidAmount === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(event) =>
                        handleOutBidAmount(event.target.value, minInputBidAmount, maxInputBidAmount)
                      }
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => BidAmountHandle(event.target.value)}
                    ></TextField>
                  </Grid>
                </InputGridContainer>
              </Grid>
            </Grid>  
          </Grid>
        </Box>
      </CustomModal>
      <CustomModalProgress open = {!isLoaded} />
    </>
  );
};
export default Bid;
